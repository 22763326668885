@import '../hubbox';
$mobile-m-width: 375px;
$mobile-l-width: 425px;
$media-xs: 320px;

.checkout-step {
  &--shipping {
    padding: 0 map-get($spacers, 2);
  }
  &__subheading {
    display: none;
  }
  &__heading--shipping {
    margin-bottom: 1rem !important;
  }
}

.checkout-shipping {
  &__container {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  &__type {
    margin-bottom: 1rem !important;
    &--only {
      max-width: rem-calc(900);
      margin-left: auto;
      margin-right: auto;
    }

    &--odd {
      padding-right: rem-calc(2);
    }

    &--even {
      padding-left: rem-calc(2);
    }
  }

  &__type-link,
  &__type-indicator {
    border-width: rem-calc(2);
    border-color: $grey2;
  }

  &__type-link {
    padding: 1rem 1rem 1rem;
  }

  &__type-icon {
    display: inline;
    font-size: rem-calc(18);
    margin-right: map-get($spacers, 2);
    position: relative;
    top: rem-calc(4);
  }

  &__type-title {
    display: inline;
    font-weight: bold;
  }

  &__type-description {
    margin-top: map-get($spacers, 2);
  }

  &__address {
    padding: 0;
  }

  &__gift-message {
    padding-left: 1rem;

    @include media-breakpoint-up(lg) {
      padding-left: 2rem;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &__packaging {
    margin: 0;

    legend {
      margin-bottom: 3%;
      @include media-breakpoint-up(lg) {
        margin-bottom: 3%;
      }
      @include media-breakpoint-down(lg) {
        margin-bottom: 9%;
      }
    }
  }

  .saved-addresses {
    &__selected {
      font-size: $fontsize-xlarge;
      padding: map-get($spacers, 3);
      background-color: $grey3;
    }
  }

  &__method {
    margin-bottom: map-get($spacers, 4);
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  &__method-content,
  &__gift-content,
  &__gift-product-content,
  &__gift-receipt-content,
  &__gift-message-content {
    border-width: rem-calc(2);

    &::before,
    &::after {
      display: none;
    }
  }

  &__gift-product-content {
    display: flex !important;
    position: relative;
    align-items: center;

    .checkout-shipping__gift-body {
      margin-left: 1rem !important;
    }
  }
  &__gift-input:checked + &__gift-content,
  &__gift-product-input:checked + &__gift-product-content,
  &__gift-receipt-input:checked + &__gift-receipt-content,
  &__gift-message-input:checked + &__gift-message-content {
    @include checkout-selected-option;

    border: none !important;
  }

  &__method-input:checked + &__method-content {
    border-width: rem-calc(2);
  }

  &__gift-input:checked + &__gift-content &__gift-selector,
  &__gift-product-input:checked + &__gift-product-content &__gift-product-selector,
  &__gift-receipt-input:checked + &__gift-receipt-content &__gift-receipt-selector,
  &__gift-message-input:checked + &__gift-message-content &__gift-message-selector {
    @include checkout-selection-indicator-active;
  }

  &__method-body {
    @include make-row();
    @include make-col-offset(0);

    @include media-breakpoint-up(lg) {
      @include make-col(10);
    }
  }

  &__method-name,
  &__method-arrival-time {
    @include make-col-ready();
    @include make-col(12);

    margin-top: 0;
    padding-left: 0;

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }

  &__method-name {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: normal;
    margin-bottom: map-get($spacers, 2);
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__method-price {
    font-size: $fontsize-medium;
    letter-spacing: rem-calc(4);
  }

  &__method-indicator,
  &__gift-selector,
  &__gift-product-selector {
    display: block;
    width: rem-calc(26);
    height: rem-calc(26);
    bottom: calc(50% - 13px);
    left: calc(100% - 13px);

    .icon-fa-check {
      line-height: rem-calc(26);
    }
  }

  &__gift-receipt-selector {
    display: block;
    width: 1.625rem !important;
    height: 1.625rem !important;
    bottom: calc(50% - 13px);
    right: 0;
    left: auto !important;

    .icon-fa-check {
      line-height: rem-calc(26);
    }
  }

  &__gift {
    padding: map-get($spacers, 3) 0;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }

  &__gift-toggle {
    padding-left: 0;
    margin-bottom: 0;
  }

  &__gift-icon {
    display: inline;
  }

  &__gift-toggle-text {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: rem-calc(5.6);
    margin-left: map-get($spacers, 2);
  }

  &__gift-toggle-icon {
    float: right;
  }

  &__gift-label,
  &__gift-product-label,
  &__gift-receipt-label,
  &__gift-message-label {
    padding: 0;
    border: none;
    margin-bottom: map-get($spacers, 3);

    &::before {
      display: none;
    }
  }

  &__gift-receipt-label {

    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 90%;
    }

    @include media-breakpoint-up(md) {
      width: 93%;
    }
  }

  &__gift-product-label {
    width: 100%;
    height: 42px;
    position: static !important;
    @include media-breakpoint-up(sm) {
      width: 90%;
    }
  }

  &__gift-label {
    margin-left: 0;
    margin-bottom: 0 !important;

    &__gift-body {
      margin: 0 2rem 0 0;
    }
  }

  &__gift-product-selector,
  &__gift-receipt-selector,
  &__gift-message-selector {
    @include checkout-selection-indicator;
  }

  &__gift-product-selector {
    top: calc(50%);
    right: 39px;
    left: auto !important;
    transform: translateY(-50%);
    width: 1.625rem !important;
    height: 1.625rem !important;
    @include media-breakpoint-down(sm) {
      right: 0 !important;
    }
  }

  &__gift-input:checked + &__gift-content &__gift-label,
  &__gift-product-input:checked + &__gift-product-content &__gift-product-label,
  &__gift-message-input:checked + &__gift-message-content &__gift-message-label,
  &__gift-receipt-input:checked + &__gift-receipt-content &__gift-receipt-label {
    &::before {
      @include fa-icon-override('check', $fa-var-check);
    }
  }

  &__gift-body {
    @include make-col(12);
    @include make-col-offset(0);

    padding-left: 0;
    margin: 0 1rem;
    padding-right: 2rem;

    @include media-breakpoint-up(sm) {
      margin: 0 2rem;
    }
  }

  &__summary-content {
    border: none;
  }

  &__summary-title {
    @include h2;
    @include border-after();

    width: 100%;
    padding: 0;
  }

  &__gift-product-title,
  &__gift-message-title,
  &__gift-receipt-title {
    text-transform: uppercase;
    letter-spacing: rem-calc(5);
    display: inline;
  }

  &__gift-title {
    display: inline;
  }

  &__methods-title,
  &__address-title,
  &__gift-packaging-title {
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 800;
  }

  &__gift-product-title {
    @media only screen and (max-width: $mobile-m-width) {
      margin-left: 1.5rem;
    }
    @media only screen and (max-width: $mobile-l-width) {
      width: min-content;
    }
  }

  &__gift-section-container {
    border-top: 0.0625rem solid $green;
    border-bottom: 0.0625rem solid $green;
    margin: 2rem 0;
  }

  &__gift-message-selector {
    display: block;
    top: 15px;
    width: 1.625rem !important;
    height: 1.625rem !important;
    bottom: calc(50% - 13px);
    right: 0;
    left: auto;

    .icon-fa-check {
      line-height: rem-calc(26);
    }

    @include media-breakpoint-down(sm) {
      right: 0;
      left: auto;
    }
    @include media-breakpoint-up(sm) {
      left: calc(100% - 60px);
    }

    @include media-breakpoint-up(md) {
      left: calc(300% + 40px);
      bottom: calc(50% - 20px);
    }

    @include media-breakpoint-up(lg) {
      left: 260%;
      bottom: 50%;
    }
  }

  &__gift-content {
    .checkout-shipping__gift-body {
      margin: 0;
    }
  }

  input[type="text"],
  .form-control,
  .country-dial-code-address-form {
    border: 1px solid #d7d7d7 !important;
  }

  fieldset {
    legend {
      &.checkout-shipping {
        &__address-title::after,
        &__methods-title::after,
        &__gift-packaging-title::after {
          display: none;
        }
      }
    }
  }
  &__types {
    margin-bottom: 1rem !important;
  }
}

.gift-message-saved-text {
  color: $validate-green;
}

.gift-product-error-message {
  transform: translateX(25px);
  @include media-breakpoint-down(md) {
    transform: translateX(48px);
  }
}

.packaging-shipping {
  &__main {
    margin-top: 1%;
  }
  &__p {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 20px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
  }
}

.gift-hr-first {
  display: none !important;
}

.gift-messaging,
.checkout-shipping__gift-receipt-content {
  position: relative;
  margin-bottom: 1rem;
}
.checkout-shipping__gift-message-label,
.checkout-shipping__gift-receipt-label {
  position: static;
}

@include media-breakpoint-up(sm) {
  .checkout-shipping__gift-message-selector,
  .checkout-shipping__gift-receipt-selector {
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }

  .checkout-shipping__gift-message {
    width: 40%;
    padding: 2rem 0 0;
  }
}

@include media-breakpoint-up(md) {
  .checkout-shipping__gift-message-selector,
  .checkout-shipping__gift-receipt-selector {
    right: 40px;
  }
}

.gift-message-tooltip {
  @include media-breakpoint-down(xl) {
    left: 8rem;
  }

  @include media-breakpoint-up(xl) {
    left: 12rem;
  }
}

.gift-receipt-added-text-product-card {
  margin-top: -1rem;
  padding-bottom: 2rem;
}

.gift-message-saved-text-product-card {
  margin-top: -1rem;
  padding-bottom: 2rem;
}

.gift-product-img {
  margin-left: 0;

  @include media-breakpoint-up(sm) {
    margin-left: 1rem;
  }
}

.gift-receipt-message-orderSummary-container {
  letter-spacing: rem-calc(5);
  font-weight: normal;
  text-transform: uppercase;
  border-bottom: 1px solid $green;
  padding-bottom: 0;
}

.gift-packaging-payment-container {
  border-bottom: 1px solid $green;
}

.modal:not(#quickViewModal) {
  .modal-header {
    .close {
      top: 5.2rem;
    }
  }
}

.mobile-number-dial-code {
  width: 100%;
}

.country-dial-code-address-form {
  width: 50% !important;
  border: 1px solid $primary;

  .intl-tel-input {
    .selected-flag {
      width: 130px !important;

      .iti-flag {
        bottom: 0.5rem;
      }

      .selected-dial-code {
        top: 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    width: 20% !important;
  }
  @include media-breakpoint-up(md) {
    width: 40% !important;
  }
}

.mobile-number-address-form {
  width: 100% !important;

  @include media-breakpoint-up(sm) {
    width: 80% !important;
  }
  @include media-breakpoint-up(md) {
    width: 60% !important;
  }
  @include media-breakpoint-up(xl) {
    width: 60% !important;
  }
}

.down-arrow-address-form {
  bottom: 0.5rem;

  @media (min-width: $media-xs) {
    left: 6rem;
  }
  @media (min-width: $mobile-m-width) {
    left: 7rem;
  }
  @media (min-width: $mobile-l-width) {
    left: 8rem;
  }
  @include media-breakpoint-up(sm) {
    left: 7rem;
  }
  @include media-breakpoint-up(lg) {
    left: 6rem;
  }
  @include media-breakpoint-up(xl) {
    left: 8rem;
  }
}

.checkout-billing__address {
  .country-dial-code-address-form {
    @include media-breakpoint-up(xl) {
      width: 40% !important;
    }
  }

  .mobile-number-address-form {
    @include media-breakpoint-up(xl) {
      width: 60% !important;
    }
  }
}
