@mixin margin-horizontal($left, $right) {
  margin-left: $left;
  margin-right: $right;
}

@mixin padding-vertical($top, $bottom) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin font-size-letter-spacing($font-size-mobile, $letter-spacing-mobile, $font-size-desktop, $letter-spacing-desktop) {
  font-size: $font-size-mobile;
  letter-spacing: $letter-spacing-mobile;
  @include media-breakpoint-up(lg) {
    font-size: $font-size-desktop;
    letter-spacing: $letter-spacing-desktop;
  }
}

@mixin letter-spacing($mobile, $desktop) {
  letter-spacing: $mobile;

  @include media-breakpoint-up(lg) {
    letter-spacing: $desktop;
  }
}

@mixin touch-scroll() {
  display: block;
  white-space: nowrap;
  justify-content: center;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin payment-form-font() {
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 700;
}

@mixin gift-form-font() {
  color: #828282;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px; /* 140% */
  letter-spacing: 0.64px;
}

@mixin horizontal-filter-header-text() {
  color: $dark-background;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.65px;
}
