@import "css-toggle-switch/src/toggle-switch-rem";

.toggle-switch {
  vertical-align: middle;

  @include media-breakpoint-down(sm) {
    float: right;
  }

  @include media-breakpoint-down(lg) {
    display: inline-flex;
  }

  @include media-breakpoint-up(lg) {
    float: right;
  }

  .switch-container {
    width: rem-calc(30);
    margin-top: rem-calc(2);

    label {
      input {
        left: 0;
        height: rem-calc(16);
      }

      input:checked ~ span {
        background-color: $grey2;
      }

      input:checked ~ span a {
        background-color: $secondary;
      }

      span {
        width: rem-calc(30);
        height: rem-calc(10);
        background-color: $grey2;
      }

      a {
        width: rem-calc(16);
        height: rem-calc(16);
        background-color: $secondary;
      }
    }
  }

  .switch-light.switch-material.switch-light input ~ span::before,
  .switch-light.switch-material.switch-light input ~ span::after,
  .switch-material.switch-toggle input + label::after {
    visibility: hidden;
  }

  .switch-light.switch-material.switch-light input:focus:checked ~ span::before,
  .switch-light.switch-material.switch-light input:focus:not(:checked) ~ span::after,
  .switch-material.switch-toggle input:focus:checked + label::after {
    visibility: hidden;
  }
}
