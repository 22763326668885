.cart-page {
  .cart-item {
    padding: 0 0 map-get($spacers, 2) 0;

    &:last-child {
      border-color: $primary;

      @include media-breakpoint-down(md) {
        border-bottom: none;
      }
    }

    .line-item-quantity {
      max-width: rem-calc(190);
      .form-group {
        margin-bottom: rem-calc(20);
      }
    }
  }

  .product-card {
    &__content {
      .line-item-availability {
        display: inline-block;
        margin-bottom: rem-calc(10);
      }

      &__heading {
        font-weight: 300;
      }
    }

    &__modify-overlay {
      &__icon-wrapper {
        margin-bottom: map-get($spacers, 2);

        .wishlist-icon {
          &--active {
            display: none;
          }

          &--inactive {
            display: block;
            font-size: rem-calc(35);
          }
        }
      }

      &__text {
        text-transform: uppercase;
        letter-spacing: rem-calc(7.2);
        font-size: rem-calc($fontsize-xlarge);
        margin-bottom: map-get($spacers, 2);
      }

      &__undo {
        &__link {
          font-weight: bold;
        }
      }
    }
  }

  .quantity-form {
    .custom-select {
      margin-bottom: rem-calc(20);
      border-color: $primary;
    }
  }

  .remove-movetowishlist-btns {
    @include media-breakpoint-up(lg) {
      position: absolute;
      font-weight: bold;
      bottom: 0;
      right: rem-calc(0);
      padding-left: rem-calc(5);
      font-size: $fontsize-medium;

      a {
        color: $primary;
      }
    }
  }

  .continue-shopping-link {
    display: flex;
    align-items: center;
  }

  .totals {
    .order-discount {
      color: $red;
    }
  }

  .apple-pay-store-delivery-msg {
    padding-left: 11px;
    padding-right: 11px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 1.12px;
  }

  .cart-left {
    .dw-apple-pay-button.apple-pay-cart,
    .dw-apple-pay-button.apple-pay-cart:hover {
      margin-top: 8px;
      margin-bottom: 0;
    }
    .apple-pay-store-delivery-msg {
      margin-top: 8px;
    }
  }

  .checkout-and-applepay {
    .dw-apple-pay-button.apple-pay-cart,
    .dw-apple-pay-button.apple-pay-cart:hover,
    .apple-pay-store-delivery-msg {
      margin-top: 0;
    }

    .apple-pay-express-checkout {
      background-color: $white;
      @include media-breakpoint-down(sm) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }

  .apple-pay-cart-consents {
    font-size: 14px;
    color: $dark-grey;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1.12px;
  }

  .cart-input-checkbox {

    ~ .checkbox-tick {
      border: 1px solid $dark-grey;
    }

    &:checked ~ .checkbox-tick {
      &::before {
        border: solid $dark-grey;
        border-width: 0 1px 1px 0;
      }
    }
  }

  .privacy-notice {
    color: $dark-grey;
  }
}

.cart-container {
  @include make-container-max-widths();

  padding: 0;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .cart-error-messaging,
  .cart-empty,
  .cart-left,
  .cart-mobile-wishlist {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
  }

  .cart-mobile-wishlist {
    order: 2;
  }

  .cart-left {
    @include media-breakpoint-up(lg) {
      padding-right: rem-calc(125);
    }
  }

  .cart-right {
    padding-left: 0;
    padding-right: 0;
  }

  .cart-header,
  .wishlist__products h5,
  .totals__summary__title {
    @include border-after();

    padding-bottom: 0;
    margin-bottom: map-get($spacers, 3);
    font-weight: lighter;

    span {
      @include h2;
      font-weight: 300;
    }
  }

  .totals {
    .order-totals {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .order-subtotal {
      &__label {
        padding-left: 0;
      }

      &__value {
        padding-right: 0;
      }
    }

    &__summary {
      border: none;
      background-color: $grey3;

      &__title {
        border-bottom: none;

        span {
          background-color: $grey3;
        }
      }

      @include media-breakpoint-up(lg) {
        background-color: transparent;

        &__title {
          span {
            background-color: white;
          }
        }
      }

      @include media-breakpoint-down(md) {
        order: 2;
      }
    }

    .cart-shipping-methods {
      .free-shipping-discount-message {
        font-size: rem-calc(14);
        line-height: 1.57;
        letter-spacing: 1.05px;
        color: $grey5;
        font-weight: 300;
      }
      .cart-next-day-cta {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 1.05px;
        color: #757575;
      }
      .form-group {
        &.shipping-methods-row {
          @extend .has-float-label;

          label {
            display: block;
          }
        }
      }
    }

    .promo-section {
      border-top: rem-calc(1) solid $primary;
      border-bottom: rem-calc(1) solid $primary;
      margin: map-get($spacers, 3) 0;
      padding: map-get($spacers, 3) 0;

      .promo-collapse {
        border: none;
        text-transform: uppercase;
        font-size: rem-calc($fontsize-large);
        padding: 0;

        @include media-breakpoint-up(lg) {
          margin: 0;
        }
      }
    }

    .cart-promo {
      padding-left: map-get($spacers, 4);
      padding-right: map-get($spacers, 4);
    }

    .cart-summary-banner {
      padding-left: map-get($spacers, 4);
      padding-right: map-get($spacers, 4);
    }

    .cart-promo-section {
      margin-top: map-get($spacers, 3);

      &-input {
        @include make-col(7);

        padding-right: map-get($spacers, 2);
      }

      &-button {
        @include make-col(4);

        padding-left: map-get($spacers, 2);

        button {
          @include btn-secondary();
        }
      }

      .coupons-and-promos {
        font-style: italic;
        font-size: rem-calc($fontsize-medium);

        button {
          font-size: rem-calc(25);
        }
      }
    }
  }
}

.wishlist__products {
  position: relative;

  .carousel-control {
    display: initial;
    position: absolute;
    top: calc(50% - #{$icon-default-size / 2});
    z-index: $wishlist-carousel-controls;

    &__prev {
      left: rem-calc(-30);
    }

    &__next {
      right: rem-calc(-30);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.cartPageAddOns {
  .cart-item {
    .line-item-quantity {
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
  }

  .remove-movetowishlist-btns {
    font-weight: bold;
    @include media-breakpoint-up(lg) {
      bottom: unset;
      top: 7.5rem;
    }
  }

  .productLineItemRecomBtn {
    font-size: 13px;
    border-radius: unset;
    color: $black;
    line-height: 16px;
    letter-spacing: 0.1em;
    font-weight: 600;
    padding: 12px 16px;
    border: 1px solid $green;
    background-color: #d2d7d6;
  }
}

.glide__arrows {
  .glide__arrow {
    border-color: $black;
  }
}

.slider__bullets.glide__bullets {
  .glide__bullet {
    border: 1px solid $green;
    background-color: #d2d7d6;

    &--active {
      background-color: $white;
    }
  }
}

.cart-recommendations-bottom-row {

  .cart-recommendation-container-bottom {
    background-color: #f3f3f3;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 8%;
    }

    @include media-breakpoint-between(md, lg) {
      padding-bottom: 8.5%;
      .product-detail__tile-row-container {
        overflow: unset;
      }
    }

    .glide__arrows {
      .glide__arrow {
        top: 110%;
        border: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cart-item.cartItemActive,
  .cart-item.cartItemActivePrev {
    border-bottom: 0.25rem solid #f3f3f3;
    border-color: #f3f3f3 !important;
  }
  .cart-item.cartItemActive:first-of-type {
    border-color: rgba(28, 54, 50, 0.2);
    margin-left: 0 !important;

    .product-card {
      &__wrapper {
        margin-bottom: 0;
      }
      &__content {
        &_qty {
          .line-item-quantity .form-group {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .remove-movetowishlist-btns {
      @include media-breakpoint-up(lg) {
        right: 10px;
      }
    }

    .cart-recommendation-container {
      .cart-recommendation-title-container {
        margin-right: 0;
        margin-left: -10px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}

.cart-recommendation-title,
.cart-recommendation-title-bottom {
  h5 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4em;
  }
}

.klarna-cart-osm {
  min-height: 5.75rem;
  letter-spacing: normal;

  @include media-breakpoint-up(lg) {
    min-height: 8.125rem;
  }

  @include media-breakpoint-up(xl) {
    min-height: 5.75rem;
  }
}

.paypal-cart-osm {
  min-height: 2.5rem;
}
