// Product Badge
.product-detail__brand-badge {
  @extend .rd-productbadge;
  margin-bottom: 0;
}

//Product Title
.product-detail__product-name {
  @extend .rd-productTitle;
  margin-bottom: 0;
}

//Product Price
.product-detail__prices {
  @extend .rd-productPrice;

  .strike-through {
    .prefix {
      display: none;
    }
  }

  .has-strike-through {
    span.value {
      color: #e24d95;
    }
  }

  .bestprice {
    display: block;
    table {
      width: 15rem;
    }
    span {
      font-size: 0.75rem;
      .value,
      .date {
        letter-spacing: 2.4px;
        text-transform: uppercase;
      }
    }

    .bestprice_close-icon {
      position: relative;
      top: 3px;

      &::after {
        content: '';
        position: absolute;
        @extend .rd-minus-sign;
        top: 0;
        left: -2px;
      }

      &::before {
        content: '';
      }
    }

    .price-label {
      cursor: pointer;
      color: #817d78;
      text-decoration: underline !important;
      font-size: 0.75rem;
      padding-right: 0.5rem;
      text-transform: uppercase;
      position: relative;
      text-underline-position: under;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        margin-left: 2px;
        @extend .rd-plus-sign;
      }
    }

    .price-label.active {
      &::after {
        content: '';
        background: none;
      }
    }
  }
}

//Product Promo
.product-detail__promotions {
  @extend .rd-productPromo;
  .callout {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    background-color: #e24d95;
    padding: 0.25rem 0.5rem;
    width: max-content;
  }
}

.product-information {

  .brand-label {
    display: none;
  }

  .product-detail__brand-badge {
    display: none;
  }

  .promoSection {
    .product-detail__brand-badge {
      display: block;
      padding: 0.25rem 0.5rem;
      background: $grey11;
      margin-top: 0.5rem;
    }
  }
}
