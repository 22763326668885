$plp-filters-heading-padding: 3 !default;
$plp-filters-heading-padding-md-up: 4 !default;
$plp-filters-selected-sort-bg: $grey2 !default;
$plp-image-type-toggle-width: rem-calc(140) !default;
$plp-sort-dropdown-font-size: $fontsize-large !default;

.search-results {
  &__controls {
    .page-size-toggle {
      margin-bottom: map-get($spacers, 3);
      text-align: left;
      order: 0;

      @include media-breakpoint-up(lg) {
        text-align: center;
        margin-bottom: 0;
        margin-top: map-get($spacers, 3);
        order: 1;
      }
    }

    > .filters {
      padding-left: 0;
      padding-right: 0;
    }

    .filters {
      &__main-toggle {
        margin-right: map-get($spacers, 1);
      }
    }
  }

  &__count-row {
    &__image-type-toggle {
      padding-right: 0;
      min-width: $plp-image-type-toggle-width;
      max-width: $plp-image-type-toggle-width;

      .toggle-switch {
        @include media-breakpoint-up(lg) {
          float: right;
        }

        justify-content: center;
        min-width: $plp-image-type-toggle-width;
        max-width: $plp-image-type-toggle-width;

        .switch-light {
          > span {
            min-height: initial;
          }
        }
      }
    }
  }

  .results-count {
    &--mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &--desktop {
      font-weight: bold;
    }

    &--desktop,
    &__separator,
    &__page-sizes {
      display: none;

      @include media-breakpoint-up(lg) {
        display: inline;
      }
    }

    &__page-sizes {
      .page-size-options {
        &__item {
          &--link {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.filters,
.sorting {
  &__section {
    &__btn {
      font-size: rem-calc($fontsize-large);
      color: $black;
      z-index: 1;

      &:hover,
      &:active {
        text-decoration: none;
      }

      &-wrapper {
        &::after {
          content: '';
          display: block;
          background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 100%);
          width: rem-calc(20);
          position: absolute;
          right: rem-calc(30);
          top: rem-calc(1);
          height: calc(100% - 2px);
        }

        &.has-float-label {
          .sorting__section__btn {
            padding-right: rem-calc(30);
          }
        }
      }
    }

    &__btn-icon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  // Handles text and icon changes when the controlled section is toggled
  &__toggle-section {
    &__btn {
      &[aria-expanded="true"] {
        .hide-text,
        .hide-icon {
          display: inline;
        }

        .show-text,
        .show-icon {
          display: none;
        }
      }

      &[aria-expanded="false"] {
        .hide-text,
        .hide-icon {
          display: none;
        }

        .show-text,
        .show-icon {
          display: inline;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__main-toggle {
      background: $grey3;
    }
  }
}

.filters {
  &__section {
    &__btn {
      display: block;
      line-height: 1.5;
      border: rem-calc(1) solid $border-color;
      padding: map-get($spacers, 2);
      padding-right: 0;

      @include media-breakpoint-up(lg) {
        padding: map-get($spacers, 4) 0;
        line-height: 1.4;
        border-left: none;
        border-right: none;
      }

      &-icon {
        margin-top: map-get($spacers, 2);
        margin-right: map-get($spacers, 3);

        @include media-breakpoint-up(lg) {
          margin-top: map-get($spacers, 4);
          margin-right: 0;
        }
      }
    }
  }
}

.sorting {
  height: 100%;

  @include media-breakpoint-up(lg) {
    margin-top: map-get($spacers, 2);
  }

  &__panels {
    position: absolute;
    top: 100%;
    min-width: 100%;
    background-color: $page-bg;
    z-index: $plp-filters-sorting-panels;

    @include media-breakpoint-up(lg) {
      min-width: auto;
      width: calc(100% - #{$grid-gutter-width});
    }
  }

  &__grid-size {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }

  &__sort-option {
    &.selected {
      background-color: $plp-filters-selected-sort-bg;
    }
  }

  &__section {
    &__btn-icon {
      @include media-breakpoint-up(lg) {
        position: static;
      }
    }
  }

  &__toggle-section {
    height: 100%;

    &__btn-wrapper {
      width: 100%;
      z-index: 1;

      select.input-select {
        &.form-control {
          &.sorting__section__btn {
            &.sorting__toggle-section__btn {
              font-size: $plp-sort-dropdown-font-size;
            }
          }
        }
      }

      .form-icon {
        margin: 0;
        font-size: $fontsize-xlarge;
        top: calc(50% - 14px);
        right: rem-calc(10);
      }
    }

    &__btn {
      + label {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      align-items: center;

      &__btn-wrapper {
        height: auto;
        width: auto;
        padding-right: rem-calc(15);
      }

      &__btn {
        height: auto;
      }
    }
  }
}
