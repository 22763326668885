@mixin einsteinRecModule() {
  .product-detail__tile-row-container {

    .product-recommendations__heading {
      @extend .rd-recently-viewed-rec-heading;
    }

    .pdp-glide-container {

      .product-detail__tile-row__item {
        padding-left: 0;
        padding-right: 0;
      }

      .product-tile {
        .product-tile__name .pdp-link a {
          @extend .rd-recently-viewed-product-name;
        }

        .product-tile__price {
          .price {
            @extend .rd-recently-viewed-product-price;
          }
        }
      }

      .glide__arrow {
        top: 45%;
        cursor: pointer;
        box-shadow: none;

        &.glide__arrow--left {
          background-color: transparent;
          border: none;
        }

        &.glide__arrow--right {
          background-color: transparent;
          border: none;
        }

        &:hover {
          border: none;
          background-color: transparent;
        }

        @media (max-width: $bp-medium-max) {
          display: none;
        }

        @media (min-width: $bp-large) {
          padding: 1rem;
        }

        &.glide__arrow--disabled {
          &:hover {
            opacity: 0.33;
          }
        }
      }
    }
  }
}
