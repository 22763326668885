@import '../productCard';

.checkout {
  .summary {
    border-left: rem-calc(1) solid $border-color;
    border-right: rem-calc(1) solid $border-color;
    padding: 0 map-get($spacers, 2) map-get($spacers, 5) map-get($spacers, 2);

    &__products-heading {
      text-align: center;
      border-top: rem-calc(1) solid $border-color;
      border-bottom: rem-calc(1) solid $border-color;
      padding-top: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 4);

      @include media-breakpoint-up(md) {
        border-top: none;
        padding-bottom: map-get($spacers, 4);
      }
    }

    &__products-container,
    &__totals-container {
      border-bottom: rem-calc(1) solid $border-color;
    }

    &__products,
    &__totals,
    &__products-container,
    &__totals-container,
    &__totals-grand-total-container {
      padding-left: map-get($spacers, 3);
      padding-right: map-get($spacers, 3);
    }

    &__totals-container {
      padding-bottom: map-get($spacers, 1);
    }

    &__totals-grand-total-container {
      font-weight: bold;
      border-bottom: solid rem-calc(1) $border-color;

      @include media-breakpoint-up(md) {
        border-bottom: none;
      }
    }

    .arrival-time,
    .arrival-time-label {
      color: $grey1;
    }

    .product-card {
      margin-bottom: map-get($spacers, 4);

      &__quantity {
        &.inline-quantity {
          display: none;
        }
      }
    }
  }
}

.hide-order-discount,
.hide-shipping-discount {
  display: none;
}

.gift-messaging {
  &__gift-message-edit-link,
  &__gift-message-save-link {
    letter-spacing: normal;
    text-transform: none;
    text-decoration: underline;
    margin-left: auto;
    display: block;
    font-size: 0.875rem;
    padding: 0;
  }
}

.order-content {
  &__gift-message-content,
  &__gift-receipt-content {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid rem-calc(1) $grey3;
  }

  &__gift-message-display-heading,
  &__gift-receipt-added-text {
    font-weight: bold;
  }
}

.gift-receipt-text-product-card {
  font-weight: bold;
  padding-bottom: 1.5rem;
}

.gift-messaging__gift-message-content {
  padding-bottom: 0;
}
