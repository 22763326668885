.store-locator-container {

  .detect-location-text {
    display: none;
  }

  .store-locator-filter-result-container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: $store-locator-filter-result-container-z-index;
    }
    top: rem-calc(295);
  }

  .search-form {
    max-height: rem-calc(610);

    .scroll-y-element {
      max-height: rem-calc(210);
      overflow-y: auto;
      overflow-x: hidden;
    }

    p {
      font-weight: bold;
    }

    .accordion-element {
      .collapse:not(.accordion-element__content) {
        border-bottom: rem-calc(1) solid $primary;
      }
    }

    .btn-storelocator-search {
      &--icon {
        display: none;
      }
    }
  }

  #heading-filter {
    font-weight: bold;
  }

  .toggle-store-locator-container {
    &-left,
    &-right {
      cursor: pointer;
      min-height: rem-calc(65);
      min-width: rem-calc(22);
      max-width: rem-calc(25);
      align-items: center;
      justify-content: center;
      padding: rem-calc(20) 0;
      background-color: $dark;

      .text {
        display: none;
      }

      a {
        align-items: center;
        justify-content: center;
        color: $white;

        .tippy-popper {
          display: none;
        }
      }
    }
  }

  .hide-left {
    position: fixed;
    left: 0;
    z-index: $storeLocator-hide-left-right;

    .tippy-popper {
      display: block;
      min-width: rem-calc(150);
    }
  }

  .hide-right {
    position: fixed;
    right: 0;
    z-index: $storeLocator-hide-left-right;

    .tippy-popper {
      display: block;
      min-width: rem-calc(170);
    }
  }

  .stores-details-container {
    height: rem-calc(700);
    border: solid 1px $grey4;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $white;

    @include media-breakpoint-down(md) {
      overflow-y: hidden;
      height: auto !important;
    }

    .store-show-more {
      display: none;
      @include media-breakpoint-down(md) {
        margin: 20px;
        display: block;
      }
    }
  }

  .mobile-map-key {
    display: none;
  }

  .store-locator-tabs-container {
    .tab-list,
    .tab-map {
      border: solid 1px $grey3;
    }

    .active-tab {
      border: none;
    }
  }

  .pointer-none {
    @include media-breakpoint-up(lg) {
      pointer-events: none;
    }
  }

  .pointer-all {
    @include media-breakpoint-up(lg) {
      pointer-events: all;
    }
  }

  .clear-filters {
    cursor: pointer;
  }

  .custom-checkbox .custom-control-label {
    color: initial;
    line-height: 2;
  }

  .filter-heading-paragraph {
    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 4);
      padding-right: map-get($spacers, 4);
    }
  }

  .accordion-element {
    .filter-heading {
      border-top: rem-calc(1) solid $grey2;

      &--last {
        border-bottom: none;
      }
    }

    &__content {
      @include media-breakpoint-up(lg) {
        padding-left: map-get($spacers, 4);
        padding-right: map-get($spacers, 4);
      }
    }
  }

  #accordion-store-working-days {
    .collapse-trigger {
      font-weight: bold;
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: rem-calc(700);
  }

  .map-canvas {
    min-height: rem-calc(420);

    @include media-breakpoint-up(lg) {
      min-height: rem-calc(700);
    }
  }

  .results {
    .store-img {
      max-width: rem-calc(150);
      margin: 0 auto;
    }

    &-wrapper {
      text-align: center;
      margin: map-get($spacers, 4) map-get($spacers, 5);
    }
  }

  .store-locator {
    &__spacers {
      margin-top: map-get($spacers, 5);
    }

    &__spacers--first {
      margin-top: map-get($spacers, 5);
    }

    &__subheaders {
      justify-content: center;
    }

    &-spacers-container {
      width: 100%;
      max-width: calc(100% - 25% - 33.33333%);
    }

    &__intro {
      display: none;
    }
  }
}

.store-locator-container,
.store-details-page-container {

  // Custom scroll bar

  /* width */
  ::-webkit-scrollbar {
    width: rem-calc(8);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 rem-calc(5) $grey3;
    border-radius: rem-calc(10);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey1;
    border-radius: rem-calc(10);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $grey2;
  }

  .map-canvas {
    display: block;
  }

  .store-img {
    &.store-img-img-alternative {
      max-width: 100%;
      max-height: unset;
    }
  }

  .more-info-btn {
    max-width: rem-calc(145);
    margin: 0 auto;
    display: block;

    &.under-image-btn {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  .store-details-heading,
  .range-text,
  .directions-link {
    font-weight: bold;
  }

  .store-details-text {
    font-size: $fontsize-medium;
    color: $grey1;
    display: block;

    i,
    span {
      vertical-align: middle;
    }
  }

  .store-picture-container {
    text-align: center;
  }

  .store-details {
    border-top: 1px solid $grey2;

    .container {
      padding-top: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 4);
    }

    .more-info-btn {
      &.mb-more-info {
        display: none;
      }
    }
  }

  .store-info-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: map-get($spacers, 4);

    .accordion {
      padding: 0;
    }
    .mobile-store-picture {
      display: none;
    }
  }
  .mb-store-hours {
    display: none;
  }

  .form-divider {
    .divider-text {
      text-align: center;
    }
  }
}

.store-details-page-container {
  min-height: rem-calc(1000);
  padding-top: map-get($spacers, 4);
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);

  @include media-breakpoint-up(lg) {
    margin-left: map-get($spacers, 5);
    margin-right: map-get($spacers, 5);
    padding-left: map-get($spacers, 5);
    padding-right: map-get($spacers, 5);
  }

  .icon-container {
    margin-right: map-get($spacers, 2);
  }

  .ranges-title,
  .services-title {
    padding-bottom: map-get($spacers, 3);
  }

  hr {
    border-top: 1px solid $grey4;
  }

  .font-icon {
    font-size: $fontsize-xxlarge;
  }
  [class*="icon-fa-"] {
    font-size: $fontsize-medium;
  }

  .store-work-times {
    .current-day {
      font-weight: bold;
      color: $secondary;
    }

    li {
      span {
        font-size: rem-calc(14);
        line-height: rem-calc(26);

        &.col-5 {
          padding-left: 0;
        }
      }
      &.current-day {
        font-weight: bold;
      }
    }
  }

  .store-details {
    &__phone {
      font-weight: bold;
    }

    &__name {
      font-size: rem-calc($fontsize-xxlarge);
    }
  }

  .map-store-photos-container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 50%;
    }
    .map-container {
      min-width: rem-calc(310);
      min-height: rem-calc(245);

      @include media-breakpoint-up(lg) {
        min-height: rem-calc(535);
      }
    }
  }

  .rightHandSlots {
    position: unset;
    top: 550px;
    .store-photo {
      img {
        width: unset !important;
      }
    }
  }

  .reviews {
    // Placeholder for reviews...
    min-height: rem-calc(400);
  }

  .address-container {
    border-top: rem-calc(1) solid $border-color;
  }

  .nearest-store {
    padding-left: 0;

    .store-image-link {
      display: none;
    }

    .nearest-store-title {
      text-align: center;
    }

    .store-details {
      border: none;

      .address-container,
      .store-services {
        padding-left: map-get($spacers, 3);
        padding-right: map-get($spacers, 3);

        @include media-breakpoint-up(lg) {
          padding-right: 0;
        }
      }

      .store-picture-container {
        order: 1;
        text-align: left;
        padding-left: 0;

        .more-info-btn {
          margin: 0;
        }

        @include media-breakpoint-up(lg) {
          max-width: 25%;
        }
      }

      .store-info-container {
        order: 2;

        h6 {
          font-weight: bold;
        }

        @include media-breakpoint-up(lg) {
          padding-left: 0;
          max-width: 50%;
        }
      }

      .store-info {
        &__phone {
          font-weight: bold;
        }
      }
    }
  }

  .holiday-hours-container {
    background-color: $gold;
    color: $white;
  }
  .map-canvas,
  .map-container {
    min-height: rem-calc(420);

    @include media-breakpoint-up(lg) {
      min-height: rem-calc(520);
    }
  }

  .form-divider {
    .divider-text {
      text-align: center;
    }
  }
  .store-details-extra {
    z-index: -1;
  }
  .store-services {
    border-bottom: solid rem-calc(1) $grey3;
    .row {
      &.standard-shipping,
      &.express-shipping {
        margin-bottom: map-get($spacers, 2);
      }
    }
  }

  .store-ranges {
    border-bottom: solid rem-calc(1) $grey3;
  }

  .opening-hours-section {
    @include media-breakpoint-up(lg) {
      @include make-col(2);
    }
  }
}

.store-details-container {
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
}
