.fit-variation {
  margin-bottom: 1.5rem;
  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }
  .fit-style {
    border: 1px solid $gray15;
    width: -webkit-fill-available;
    padding: 0.625rem 0;
    max-width: 100%;
    text-align: center;
    margin-right: 0.5rem;

    span {
      @extend .rd-fitValuetext;
    }
  }

  .outOfStock {
    text-decoration: line-through;
    background-image: none;
    cursor: pointer;
    border: 1px solid $gray15;
    color: $gray15;

    span {
      color: $gray15;
    }
  }

  .selectedFitVariant.outOfStock {
    color: $gray15;
    border: 1px solid $gray15;

    span {
      color: $gray15;
      font-weight: normal;
    }
  }

  .selectedFitVariant {
    border: 1px solid $black;

    span {
      font-weight: 600;
      font-size: 0.75rem;
      letter-spacing: 0.15rem;
      line-height: 0.9rem;
    }
  }
}

.attribute_section.active {
  .product-detail__attribute__error {
    display: none;
  }
}

.set-item {
  .attribute_section {
    .product-detail__attribute__error {
      position: relative;
      bottom: 4px;
    }
  }
}

.attribute_section {
  .color-section {
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  .color_label {
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 0;
    margin-right: 0.5rem;
    border: 0.0625rem solid $gray15;

    img {
      width: 2rem;
      height: 2rem;
    }
  }

  .attribute_value {
    @extend .rd-colorText;
  }

  .attribute_current {
    border-bottom: 1px solid $black;
  }

  .attribute_unselectable {
    color: #dee2e6;
    &::before {
      content: '';
      position: absolute;
      @extend .rd-outofstock-sign;
    }
  }
}
