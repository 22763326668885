.customer-rating-cta-container {
  @extend .rd-customer-rating-cta-text;
  z-index: 1;
  opacity: 0; /* Start with opacity 0 for fade-in effect */
  top: 1.5rem;
  height: 2.5rem;
  left: 8.438rem;
  display: none;
  gap: 0.125rem;
  cursor: pointer;
  position: absolute;
  border-radius: 0.5rem;
  flex-direction: column;
  justify-content: center;
  transition: opacity 1s ease;
  padding: 0.25rem 0.5rem 0.375rem 0.5rem;
  background-color: $overlay-white;
  @media only screen and (max-width: $bp-medium-max) {
    top: 3.625rem;
    left: 1rem;
  }
}

.customer-cta-rating {
  gap: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.125rem;

  &::before,
  .icon-fa-star-o::before,
  .icon-fa-star::before,
  .icon-fa-star-half-o::before {
    content: none;
  }
}
