$color-swatch-size: rem-calc(40);
$color-swatch-border-radius: 0;
$product-reviews-pane-max-width: 90%;
$product-reviews-pane-max-width-desktop: rem-calc(600);
$product-size-chart-pane-max-width: 90%;
$product-size-chart-pane-max-width-desktop: rem-calc(600);
$product-stock-check-pane-max-width: 90%;
$product-stock-check-pane-max-width-desktop: rem-calc(600);
$product-content-details-width: rem-calc(408);
$flyout-style-headings: true;

$pdp-widths: map-merge($container-max-widths, (xl: 1800px));
