.shopping-with-us {
  &__hero-container {
    margin-bottom: rem-calc(30);
    background-position: center;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
      margin-bottom: rem-calc(90);
    }

    &__heading {
      font-size: rem-calc(18);
      letter-spacing: rem-calc(7.2);
      font-weight: lighter;

      @include media-breakpoint-up(md) {
        font-size: rem-calc(34);
        letter-spacing: rem-calc(13.6);
      }
    }
  }
}
