$checkout-selection-indicator-size: 30px !default;

@mixin checkout-selection-indicator() {
  display: block;
  width: $checkout-selection-indicator-size;
  height: $checkout-selection-indicator-size;
  line-height: $checkout-selection-indicator-size;
  color: $white;
  border: 1px solid $border-color;
  border-radius: 50%;
  background-color: $page-bg;
  position: absolute;
  left: calc(100% - #{$checkout-selection-indicator-size / 2});
  bottom: calc(50% - #{$checkout-selection-indicator-size / 2});
  text-align: center;
}

@mixin checkout-selection-indicator-active() {
  background-color: $secondary;
  border: rem-calc(1) solid $secondary;
}
