$modal-bg-animation-duration: 0.3s;
$content-gutter: rem-calc(10) !default;

html {
  font-size: $global-font-size;

  body {
    position: relative;
    color: $primary;
    font-family: $body-font-family;
    font-size: rem-calc($fontsize-medium);
    font-weight: $global-weight-normal;
    line-height: 1.4;
    letter-spacing: 0.04em;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-ligatures: no-common-ligatures !important;

    .page {
      background-color: $page-bg;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .modal-background {
      position: absolute;
      z-index: $modal-bg-z-index;
      opacity: 0.4;
      background-color: $black;
      transition: opacity $modal-bg-animation-duration;
      cursor: pointer;

      &.show {
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .content-asset,
    .html-slot-container {
      ol,
      ul {
        line-height: 1.9;
      }

      .row {
        &:not(.no-gutters) {
          margin-left: -$content-gutter;
          margin-right: -$content-gutter;
        }
      }

      .container,
      .container-fluid,
      [class^=col-] {
        padding-left: $content-gutter;
        padding-right: $content-gutter;
      }
    }
  }
}
