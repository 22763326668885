.rd {
  &-h3 {
    @include heading();
  }
  &-customer-rating-cta-text {
    @include heading(0.625rem, 600, 120%, rem-calc(2), $light-black, uppercase);
  }
  &-style-with-CTA-text {
    @include heading(0.625rem, 400, 120%, 0.125rem, $light-black, uppercase);
  }
  &-global-banner-text {
    @include heading(0.75rem, 400, 120%, 0.15rem, $light-black, uppercase);
  }
  &-promo-banner-text {
    @include heading(0.75rem, 400, 120%, 0.15rem, $white, uppercase);
  }
  &-back-to-tops {
    @include heading(0.75rem, 700, 120%, 0.06rem, #1d1d1d, uppercase);
  }
  &-customer-fit-rating-label {
    @include heading(0.75rem, 400, 120%, 0.15rem, #1d1d1d, uppercase);
    height: 0.875rem;
  }
  &-fits-small {
    @include heading(0.75rem, 400, 120%, 0.15rem, #1d1d1d, uppercase);
    flex-grow: 1;
  }
  &-prefect-fit {
    @include heading(0.75rem, 400, 120%, 0.15rem, #1d1d1d, uppercase);
    flex-grow: 1;
  }
  &-fits-large {
    @include heading(0.75rem, 400, 120%, 0.15rem, #1d1d1d, uppercase);
  }
  &-sticky-atb-cart-atom {
    @include heading(0.875rem, 400, 150%, 0.0875rem, #fff, uppercase);
  }
  &-sticky-atb-product-name {
    @include heading(1.25rem, 400, 150%, 0.1rem, #000, capitalize);
  }
  &-featureTile-heading {
    @include heading(1.25rem, $line-height: 1.5rem, $font-weight: 400, $letter-spacing: 0.25rem, $text-transform: uppercase);
  }
  &-product-tab-body-text {
    @include heading(0.875rem, 400, 150%, 0.07rem, $light-black, none);
  }
  &-product-detail__description-tab {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $light-black;
    @media only screen and (max-width: $sm-width) {
      font-size: 0.875rem;
    }
    @include heading(1.25rem, 400, 120%, 0.25rem, $light-black, uppercase);
    &:not(.active) {
      border: none;
      color: $mid-grey;
    }
  }
  &-reviewHeading {
    @include heading(0.875rem, $line-height: 1.05rem,$color: $light-black, $font-weight: 400, $letter-spacing: 0.175rem, $text-transform: uppercase);
  }
  &-totalReviews {
    @include heading(0.75rem, $line-height: 0.9rem, $font-weight: 400, $letter-spacing: 0.15rem, $text-transform: uppercase);
  }
  &-reviewMsg {
    @include heading(0.875rem, $line-height: 1.313rem, $color: $primary, $font-weight: 400, $letter-spacing: 0.07rem);
  }
  &-reviewDetails {
    @include heading(0.75rem, $line-height: 120%, $font-weight: 400, $color: $primary, $letter-spacing: 0.15rem, $text-transform: uppercase);
  }
  &-review-count-text {
    @include heading(0.75rem, 700, normal, 0.06rem, $light-black, uppercase);
  }
  &-style-with-rec-heading {
    @media only screen and (max-width: $sm-width) {
      font-size: 0.875rem;
      line-height: 1.05rem;
      letter-spacing: 0.175rem;
    }
    @include heading(1.25rem, 400, 1.5rem, 0.25rem, $light-black, uppercase);
  }
  &-style-with-product-name {
    @include heading(1rem, 400, 1.5rem, 0.08rem, $light-black, capitalize);
  }
  &-style-with-product-price {
    @include heading(1rem, 400, 1.5rem, 0.08rem, $light-black, capitalize);
  }
  &-recently-viewed-rec-heading {
    @include heading(1.25rem, 400, 1.5rem, 0.25rem, $light-black, uppercase);
  }
  &-recently-viewed-product-name-price {
    @include heading(0.875rem, 400, 1.5rem, 0.08rem, $light-black, uppercase);
  }
  &-notify-me-title-text {
    @media only screen and (max-width: $bp-large) {
      font-size: 0.875rem;
      line-height: 1.05rem;
      letter-spacing: 0.175rem;
    }
    @include heading(1.25rem, 400, 1.5rem, 0.25rem, $light-black, uppercase);
  }
  &-notify-me-description-text {
    @include heading(0.875rem, 400, 1.313rem, 0.07rem, $light-black, none);
  }
  &-notify-me-btn-text {
    @include heading(0.875rem, 600, normal, 0.175rem, $white, uppercase);
  }
  &-notify-me-placeholder-text {
    @include heading(0.75rem, 400, normal, 0.15rem, $mid-grey, uppercase);
  }
  &-recently-viewed-product-name {
    @include heading(1rem, 400, 1.5rem, 0.08rem, $light-black, capitalize);
  }
  &-recently-viewed-product-price {
    @include heading(1rem, 400, 1.5rem, 0.08rem, $light-black, capitalize);
  }
  &-set-page-heading {
    @include heading(1.75rem, 400, 2.625rem, 0.14rem, $light-black, capitalize);
  }
  &-set-page-sub-heading {
    @include heading(0.875rem, 400, 1.05rem, 0.175rem, $light-black, uppercase);
  }
  &-store-result-direction {
    @include heading(0.75rem, 400, 0.9rem, 0.15rem, $light-black, uppercase);
  }
  &-store-heading {
    @include heading(0.875rem, 400, 1.05rem, 0.175rem,  $light-black, uppercase);
  }
  &-store-name {
    @include heading(0.75rem, 600, 0.9rem, 0.15rem, $dark-black, uppercase);
  }
  &-store-text {
    @include heading(0.875rem, 400, 1.313rem, 0.007rem, $dark-black, none);
  }
  &-stock-heading {
    @include heading(0.875rem, 600, 1.05rem, 0.175rem, $black, uppercase);
  }
  &-nostock-subheading {
    @include heading(0.75rem, 400, 1.313rem, 0.07rem, $black, none);
  }
  &-findinstore-cta {
    @include heading(0.75rem, 400, 0.9rem, 0.07rem, $light-black, uppercase);
  }
  &-store-form-text {
    @include heading(0.75rem, 600, 0.9rem, 0.15rem, $light-black, uppercase);
  }
  &-store-invalid-text {
    @include heading(0.875rem, 400, 0.9rem, 0.07rem, $dark-red, none);
  }
  &-store-placeholder-text {
    @include heading(0.75rem, 400, normal, 0.15rem, $mid-grey, uppercase);
  }
}
