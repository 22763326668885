$media-breakpoint-tablet-min: 767px;
$media-breakpoint-desktop: 1279px;
$media-breakpoint-desktop-large: 1439px;

.country-selector {
  &__current {
    display: flex;
    justify-content: flex-end;
    margin-bottom: map-get($spacers, 2);

    &__selection {
      order: 2;
      margin-right: 0;
      align-self: center;
      font-weight: normal;
      font-size: rem-calc(12);
      letter-spacing: rem-calc(1.8px);
    }
    &__country {
      order: 1;
      margin-right: map-get($spacers, 2);
      align-self: center;

      &-text {
        margin-top: map-get($spacers, 6);
        margin-bottom: 0;
        font-size: rem-calc($fontsize-medium);
      }
    }

    &-country {
      font-size: rem-calc($fontsize-medium);
    }
  }

  &__container {
    padding: rem-calc(60 20);
    letter-spacing: rem-calc(1);

    @include media-breakpoint-up(md) {
      padding-left: rem-calc(100);
      padding-right: rem-calc(100);
    }
  }

  &__close {
    text-align: center;
    padding-right: 0;

    &-icon {
      font-size: rem-calc($fontsize-xmedium);
      padding-top: 0;

      &::before {
        font-size: $fontsize-xlarge;
      }
    }

    &__text {
      display: inline;
      text-transform: uppercase;
      letter-spacing: rem-calc(4);
    }
  }

  &__pane & {
    &__original-country,
    &__original-country-prompt {
      display: none;
    }
  }

  &__gateway & {
    &__original-country {
      @include h2;

      font-weight: normal;
      text-align: left;
    }

    &__original-country-prompt {
      text-align: left;
    }
  }

  &__form {
    padding-left: 0;
    padding-right: 0;

    &__label {
      text-transform: uppercase;
      margin-bottom: map-get($spacers, 4);
      font-size: rem-calc($fontsize-medium);
      letter-spacing: rem-calc(5.6);
    }

    .choices {
      @include change-choices-chevron();

      &__inner {
        border: 1px solid $primary;
        color: $primary;
        border-radius: 0;
      }
    }

    .btn {
      @include btn-secondary();

      letter-spacing: rem-calc(5.6);
    }
  }

  &__stores-link {
    text-align: left;

    a {
      font-weight: bold;
    }
  }
}

.header-banner.slide-up {

  .banner-container {
    @media only screen and (min-width: $media-breakpoint-tablet-min) {
      margin-left: 8.33% !important;
      @include make-col(10);
    }
  }
  .js-page-locale-loader {
    position: relative;
    right: 0;
    @media only screen and (min-width: $media-breakpoint-tablet-min) {
      @include make-col(1);
    }
    @media only screen and (min-width: $media-breakpoint-desktop) {
      right: 50px;
    }
  }
  .country-selector__current {
    flex-direction: row-reverse;
    justify-content: end;
    margin-bottom: 0;
    margin-right: 10px;
    display: flex;
    @media only screen and (max-width: $media-breakpoint-tablet-min) {
      display: none;
    }
  }
  .country-selector__current__selection {
    margin-right: 8px;
  }
}
