.continuity-flyout {
  &__container {
    display: flex;
    flex-direction: column;
    max-width: 23.438rem !important;
    @media only screen and (min-width: $tab-width-max) {
      max-width: 30rem !important;
      padding: 0 5rem !important;
      justify-content: center;
    }
    .continuity-flyout__button {
      justify-content: center;
      @extend .rd-notify-me-btn-text;
      height: 2.5rem;
      @media only screen and (min-width: $tab-width-max) {
        height: 3rem;
      }
    }
  }
  &__header {
    align-self: flex-start;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static !important;
  }
  &__close {
    top: 0.5rem;
    right: 0.5rem;
    position: absolute;
    @media only screen and (min-width: $tab-width-max) {
      top: 3.625rem;
      right: 4.5rem;
    }
    &__text {
      display: none !important;
    }
  }
  &__title {
    @extend .rd-notify-me-title-text;
    margin-bottom: 1rem;
    @media only screen and (min-width: $tab-width-max) {
      margin-bottom: 1.5rem;
    }
  }
  &__description {
    @extend .rd-notify-me-description-text;
    @media only screen and (min-width: $tab-width-max) {
      margin-bottom: 1.5rem;
    }
  }
  &__form {
    margin-bottom: 1rem;
    min-width: 100%;
    input {
      height: 3rem;
      border: 1px solid $gray15;
    }
    .form-group {
      margin-bottom: 1rem;
      @media only screen and (min-width: $tab-width-max) {
        margin-bottom: 1.5rem;
      }
    }
    @media only screen and (min-width: $bp-large) {
      margin-bottom: 9.375rem;
    }
    .has-float-label input:placeholder-shown:not(:focus) + * {
      top: 0.9rem;
      @extend .rd-notify-me-placeholder-text;
    }
  }
}
