.saved-payments-list {
  &__item {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    .address-summary {
      &__name {
        font-weight: bold;
        margin-bottom: map-get($spacers, 2);
      }
    }
  }

  &__item-wrapper,
  &__card-make-default label {
    border-color: $grey3;
    background-color: $grey3;
  }
  &__card-make-default label[for]::before,
  &__card-make-default label[for]::after {
    border-color: $primary;
    background-color: transparent;
  }

  &__card-make-default label[for]::after {
    left: rem-calc(-8);
    top: rem-calc(5);
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__add-wrapper {
    background-color: $white;
  }
  &__add-icon--card {
    display: none;
  }
  &__card-header {
    order: 2;
  }
  &__card-body {
    order: 1;
    border-bottom: 1px solid $grey8;
  }
  &__card-footer {
    order: 3;
  }

  &__card-expired {
    font-weight: bold;
  }

  &__card-type {
    text-transform: uppercase;
    letter-spacing: rem-calc(5.6);
  }

  &__card-separator {
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
  }

  &__card-details--expired {
    font-weight: normal;
  }

  &__card-make-default {
    border-bottom: none;
    margin: map-get($spacers, 2) 0;
  }

  &__card-address {
    border-color: $grey8;
  }

  &__edit,
  &__delete {
    @extend .btn;
    @include btn-primary();

    background-color: $grey3;

    &:hover {
      background-color: $primary;
    }
  }
  &__edit {
    margin-right: rem-calc(5);
    width: calc(100% - #{$button-height} - #{rem-calc(10)});
  }
  &__delete {
    padding-left: 0;
    padding-right: 0;
    width: $button-height;

    &__text {
      display: none;
    }
    &__icon {
      display: inline;
    }
  }
  &__separator {
    display: none;
  }

  &__add-text {
    text-decoration: none;
    text-transform: uppercase;
    font-size: $fontsize-xlarge;
    letter-spacing: rem-calc(7.2);
  }
}

.save-payment {
  .payment-form {
    &__fields {
      padding: 0;
    }

    &__expiry {
      align-items: flex-start;
    }

    &__expiry-label {
      display: none;
    }
    &__expiry-month {
      padding-right: 0;
      max-width: rem-calc(105);
      .has-float-label {
        label {
          width: 100%;
        }
      }
    }
    &__expiry-year {
      padding-left: 0;
      max-width: rem-calc(105);

      .form-control {
        border-left: none;
      }

      label {
        display: none;
      }
    }

    &__expiry-disclaimer {
      &:not(.required-form-indication) {
        @include make-col(4);
      }

      display: flex;

      @include media-breakpoint-up(xl) {
        display: none;
      }

      &.required-form-indication {
        @include media-breakpoint-up(xl) {
          display: block;
          text-align: right;
          width: 50%;
        }
      }
    }

    .payment-bottom-fields {
      padding: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 50%;
    }
  }

  .address-fields {
    padding: rem-calc(20) 0;
    margin: rem-calc(20) rem-calc(-10);

    &__row--required-disclaimer {
      visibility: hidden;
    }

    @include media-breakpoint-down(md) {
      background-color: $grey3;

      .form-control,
      .has-float-label label,
      .address-lookup__field,
      .address-lookup__title span {
        background-color: $grey3;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .save-payment-form-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.account-section {
  .saved-payments-list {

    &__item-wrapper {
      height: 100%;
    }

    &__card-body {
      flex-grow: 1;
    }

    &__card-make-default label[for]::after {
      left: rem-calc(-8);
      top: rem-calc(5);
    }

    &__edit,
    &__delete {
      color: $black;

      &__icon {
        line-height: rem-calc(24);
      }

      &:hover {
        color: $white;
      }
    }
  }

  .save-payment .payment-form__card-number[data-type="mastercard"]::after {
    width: rem-calc(33);
  }
}
