$confirmation-indicator-size-top: rem-calc(-15);
$confirmation-password-hint-size: rem-calc(12);
$confirmation-elements-default-size: rem-calc(14);
$confirmation-size: rem-calc(16);

.order-content {
  &__print-btn {
    width: 75%;
    margin: 0 auto;
  }

  &__right {
    &--container {
      border: rem-calc(1) solid $grey3;
    }
  }

  .order-details {
    &__summary-items {
      padding: 0 rem-calc(24);

      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }
    &__summary-item {
      &--shipping-address,
      &--billing-address {
        display: block;
        margin-bottom: map-get($spacers, 3);
      }
      &--billing-address {
        .order-details__summary-item-value {
          float: none;
        }
      }

      &-address {
        float: left;
      }
    }
  }

  .product-card__content {

    .product-card {
      &__quantity {

        &.inline-quantity {
          display: none;
        }
      }
    }
  }
}

.confirmation {
  font-size: $confirmation-size;
  border-color: $grey5 !important;

  .checkout__registration--options {
    span,
    p {
      color: $grey1;
    }
    .password-hint {
      font-size: $confirmation-password-hint-size;
    }
    label {
      font-size: $confirmation-elements-default-size;
    }
  }

  .checkout__account-message {
    font-size: $confirmation-elements-default-size;
  }

  &.checkout-shipping {
    &__type-link {
      border: rem-calc(1) solid $black;
      padding: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 4);
    }
  }

  &__indicator {
    background: $secondary;
    top: $confirmation-indicator-size-top;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      top: $confirmation-indicator-size-top;
    }
  }

  &__register-user-container {
    text-align: center;
    max-width: 66%;
    margin: 0 auto;

    &.multiple-parcels-message {
      display: none;
    }
  }

  &__login-form {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }
  }

  &__logout-container {
    margin-bottom: rem-calc(-40);
  }

  &__order-summary-title {
    text-align: center;

    h5 {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}
