@import '../utils/mixins';
@import '../components/accountNav';
@import '../components/accountHeading';

.account-sections {
  padding-top: map-get($spacers, 5);

  .account-section {
    @include make-col-ready();
    @include make-col(6);

    padding: 0;
    text-align: left;
    margin-bottom: rem-calc(70);

    &:nth-child(even) {
      padding-left: map-get($spacers, 3);
    }
    &:nth-child(odd) {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }

    &__name {
      @include h2;
      @include border-after();

      font-weight: lighter;
    }

    &__link {
      border: none;
      margin-top: auto;
      position: relative;
      padding-bottom: rem-calc(50);
      height: 100%;

      @include media-breakpoint-up(lg) {
        padding: 0 rem-calc(70) rem-calc(50) rem-calc(70);
      }
    }

    &__icon {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0;
      width: auto;
      border: none;
      padding: rem-calc(0 0 5 10);
      font-weight: lighter;
      font-size: rem-calc($fontsize-xxlarge);
      color: $grey6;
      background-color: $page-bg;
    }

    &__cta {
      position: absolute;
      bottom: 0;
      left: 0;

      @include media-breakpoint-up(lg) {
        width: calc(100% - #{rem-calc(140)});
        left: rem-calc(70);
      }
    }

    .backlink {
      text-decoration: none;

      ~ .font-icon {
        font-size: $fontsize-medium;
      }
    }

    .account-pages-heading {
      &__text {
        @include border-after();

        font-weight: lighter;
      }
    }
  }
}
