#footer {
  .contact-band {
    opacity: 0;
  }

  .footer-social-icons {
    i {
      font-size: rem-calc(35);
    }
  }

  .top-bottom-border {
    border-bottom: none;

    @include media-breakpoint-up(lg) {
      border-top: none;
    }
  }

  .contacts {
    @include font-size-letter-spacing(rem-calc(14), rem-calc(1), rem-calc(14), rem-calc(1));

    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    background-color: $beige;

    @include media-breakpoint-up(lg) {
      padding: map-get($spacers, 1) 0;
    }

    .footer-top {
      background-color: $page-bg;
    }

    > .row {
      max-width: rem-calc(1280);
      margin: 0 auto;
    }

    &-asset-left {
      @include make-col(12);

      display: block;
      padding-top: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 4);

      @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);

        padding-right: map-get($spacers, 4);
      }

      span {
        font-size: rem-calc($fontsize-medium);
      }
    }

    &-asset-right {
      display: none;
    }

    .footer-contacts-link {
      padding: 0;
      margin-bottom: $content-gutter;
      width: calc(50% - #{1.5 * $content-gutter});
      background-color: $grey9;

      a {
        margin-bottom: map-get($spacers, 0);
        border: none;
      }

      &:nth-child(odd) {
        margin-right: $content-gutter / 2;
      }

      &:nth-child(even) {
        margin-left: $content-gutter / 2;
      }

      .font-icon {
        padding-bottom: map-get($spacers, 4);
        font-size: rem-calc(50);
        color: $grey5;
      }

      .contact-link-text {
        font-size: rem-calc($fontsize-medium);
        letter-spacing: rem-calc(2);
      }
    }

    .email-newsletter {
      display: flex;
      justify-content: center;

      .form-group {
        width: 80%;
        height: 100%;
      }

      &-wrapper {
        margin-top: map-get($spacers, 2);
      }

      @include media-breakpoint-up(lg) {
        padding: rem-calc(80) rem-calc(10) rem-calc(80) 0;
        max-width: rem-calc(1280);
        justify-content: flex-end;
      }

      &__form {
        margin: 0;
        max-width: 100%;
        height: rem-calc(60);
        color: $grey5;
        display: flex;
      }

      &__email-input {
        height: 100%;
        width: 100%;
        border: none;
        color: $grey5;
        padding: map-get($spacers, 3);
      }

      &__submit {
        display: flex;
        top: 0;
        height: 100%;
        width: 19%;
        font-size: rem-calc($fontsize-small);
        background-color: $white;
        padding-left: 0;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .font-icon::before {
          display: block;
          font-size: rem-calc(24);
          color: $grey5;
        }

        span {
          display: inline-block;
          font-size: rem-calc(10);
          line-height: rem-calc(14);
          font-weight: 500;
          letter-spacing: rem-calc(1);

          @include media-breakpoint-up(lg) {
            letter-spacing: rem-calc(3);
          }
        }
      }
    }

    .contacts-asset-left {
      display: block;
    }
  }

  .fullwidth-color {
    .footer-columns {
      max-width: rem-calc(1280);

      @include media-breakpoint-down(md) {
        background-color: $grey11;
      }

      .accordion {
        margin-left: 0;
        margin-right: 0;

        ul {
          @include font-size-letter-spacing(rem-calc($fontsize-large), rem-calc(1), rem-calc($fontsize-medium), rem-calc(1));

          li {
            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &-element {
          padding-left: map-get($spacers, 3);
          padding-right: map-get($spacers, 3);

          @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
          }

          &.about {
            order: 4;
          }
        }

        .footer-column {
          h6 {
            margin-left: 0;
            margin-right: 0;
          }

          &__link {
            font-weight: 300;
          }

          .collapsed ul li {
            font-weight: 300;

            a:hover {
              @include media-breakpoint-down(md) {
                text-decoration: underline;
              }
            }
          }

          &.last-column {
            @include media-breakpoint-down(md) {
              border-bottom: rem-calc(1) solid $grey4;
            }
          }
        }
      }
    }

    .social-icons-mobile {
      display: block;
      padding-top: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 4);

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .fullwidth {
    .bottom-footer-icons {
      background-color: $page-bg;
      max-width: rem-calc(1280);
      margin: 0 auto;

      .social {
        padding-top: map-get($spacers, 1);
        padding-bottom: map-get($spacers, 1);
        background-color: $page-bg;
        justify-content: center;
        order: 3;

        @include media-breakpoint-up(lg) {
          @include make-col(6);
          justify-content: flex-start;
          order: 1;
        }
      }

      .site-reviews {
        display: none;
      }

      .payment-methods {
        padding-bottom: 0;
        padding-top: map-get($spacers, 3);

        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: flex-end;
          padding-top: 0;
          padding-right: 0;
        }

        &.col-lg-4 {
          order: 1;

          @include media-breakpoint-up(lg) {
            order: 3;
            display: block;
          }
        }
      }
    }

    .footer-copyright {
      background-color: $page-bg;
      margin-left: map-get($spacers, 2);
      margin-right: map-get($spacers, 2);
      color: $primary;

      &.row {
        display: none;
      }
      &:not(.row) {
        display: block;
      }
    }
  }
}
