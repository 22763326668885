@import "../utils/mixins";

.reset-password-form {
  max-width: rem-calc(410);
  margin-left: auto;
  margin-right: auto;
  padding: 0 rem-calc(10);

  .request-password-title {
    @include border-after();

    text-align: left;
    font-weight: lighter;
  }

  .request-password-body {
    text-align: left;
  }

  .send-email-btn .btn {
    @include btn-secondary();

    white-space: unset;
    height: auto;
  }
}
