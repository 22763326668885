@mixin btn-primary() {
  @include button-variant(
    $background: $button-primary-bg,
    $border: $button-primary-border,
    $hover-background: $button-primary-hover-bg,
    $hover-border: $button-primary-hover-border,
    $active-background: $button-primary-active-bg,
    $active-border: $button-primary-active-border
  );

  color: $button-primary-color;

  &:hover {
    color: $button-primary-hover-color-mobile;

    @include media-breakpoint-up(lg) {
      color: $button-primary-hover-color;
    }
  }

  &#{$active-btn-selector} {
    color: $button-primary-active-color;
  }
}

@mixin btn-secondary() {
  @include button-variant(
    $background: $button-secondary-bg,
    $border: $button-secondary-border,
    $hover-background: $button-secondary-hover-bg,
    $hover-border: $button-secondary-hover-border,
    $active-background: $button-secondary-active-bg,
    $active-border: $button-secondary-active-border
  );

  color: $button-secondary-color;

  &:hover {
    color: $button-secondary-hover-color-mobile;

    @include media-breakpoint-up(lg) {
      color: $button-secondary-hover-color;
    }
  }

  &#{$active-btn-selector} {
    color: $button-secondary-active-color;
  }
}

@mixin btn-colors($bgColor:$button-primary-bg, $borderColor:$button-primary-border, $color:$button-primary-color) {
  background-color: $bgColor;
  border-color: $borderColor;
  color: $color;
}
