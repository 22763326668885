$content-icon-font-size: rem-calc(40);

.shopping-with-us {
  &__hero-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: map-get($spacers, 5);

    &__heading {
      max-width: 75%;
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      color: $white;
      margin-left: rem-calc(20);

      @include media-breakpoint-up(md) {
        margin-left: rem-calc(80);
      }
    }
  }

  &__container {
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
}

#content__return-policy {
  .content-icons {
    color: $secondary;
    font-size: $content-icon-font-size;
  }
}

.content-campaign-image {
  max-width: rem-calc(800);
  height: auto;
}

#content__size-charts {
  .sizes-content-table {
    padding-top: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);
    border: 1px solid $primary;
    border-collapse: collapse;

    thead {
      th {
        background-color: $grey3;
        border-top: 1px solid $primary !important;
        border-bottom: 1px solid $primary !important;
      }
    }
    tbody {
      tr:nth-child(even) {
        background-color: $grey3;
      }
    }

    td {
      border: none;
    }
  }

  .size-chart__measure-unit-switch {
    .radio-link {
      @include checkout-option;
      width: rem-calc(95) !important;
      height: rem-calc(40);

      &.active {
        @include checkout-selected-option;

        border: rem-calc(1) solid $secondary;
      }
    }
  }

  .table-headings {
    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }
}

#content__careers {
  .careers__desktop-navigation {
    font-size: $fontsize-large;
    a.active {
      border-top: rem-calc(2) solid $secondary !important;
      border-bottom: none !important;
    }
  }

  .careers__content {
    max-width: rem-calc(1030);

    .locations-select {
      margin-left: rem-calc(-15);
      margin-right: rem-calc(-15);

      &__form {
        @include media-breakpoint-up(lg) {
          width: rem-calc(300);
        }

        #ddlLocations {
          font-size: rem-calc($fontsize-large);
        }
      }
    }
  }
}
