$components-border-style: rem-calc(1) solid $primary;

.store-locator-container {
  border: 0;
  margin-bottom: map-get($spacers, 5);
  overflow: hidden;

  .detect-location-text {
    display: none;
  }

  .card {
    border-radius: 0;
    border: none;
  }

  .store-locator-filter-result-container {
    top: rem-calc(380);
  }

  .filter-heading-paragraph {
    letter-spacing: rem-calc(5);

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .store-locator {
    &__heading {
      @include h2;

      max-width: rem-calc(200);
      margin: 0 auto;
      font-weight: 300;
      letter-spacing: rem-calc(9.6);

      @include media-breakpoint-up(lg) {
        max-width: none;
      }
    }

    &__subheaders {
      .marker-container {
        margin: 0 map-get($spacers, 4);
      }
      span {
        text-transform: uppercase;
        letter-spacing: rem-calc(5.6);
        font-weight: 300;
      }

      .standalone {
        color: $green;
      }

      .stockist {
        color: $red;
      }

      .outlet {
        color: $blue;
      }

      .icon-map-marker {
        position: relative;
        top: rem-calc(2);

        &::before {
          content: map-get($icon-symbols, 'map-marker-full');
        }
      }
    }

    &__spacers {
      position: static;
      margin-top: 0;

      &--first {
        @include media-breakpoint-up(lg) {
          min-width: rem-calc(360);
          max-width: rem-calc(360);
        }
      }
    }

    &-results-container {
      @include media-breakpoint-up(lg) {
        min-width: rem-calc(480);
        max-width: rem-calc(480);
      }
    }

    &-spacers-container {
      width: 100%;
      max-width: calc(100% - #{rem-calc(360)} - #{rem-calc(480)});
    }

    &-no-results {
      padding-top: map-get($spacers, 4);
    }
  }

  .stores-details-container {
    border-radius: 0;
    height: rem-calc(600);
    border: none;

    h5 {
      @include h4;
      font-weight: 300;
    }

    .results {

      &-wrapper {
        text-align: left;
        margin-left: map-get($spacers, 3);
        margin-right: map-get($spacers, 3);
      }

      .store-info-container {
        @include media-breakpoint-up(lg) {
          @include make-col(7);
        }
      }

      .store-picture-container {
        @include media-breakpoint-up(lg) {
          @include make-col(5);
        }

        .store-details-button {
          border: $components-border-style;
        }
      }
    }
  }

  .store-info {
    &__name {
      text-transform: uppercase;
      letter-spacing: rem-calc(5.6);
      font-weight: 300;
      font-size: rem-calc($fontsize-medium);
    }

    &__address {
      margin-bottom: 0;
    }

    &__phone {
      font-weight: normal;
    }

    &__distance-and-type {
      font-weight: 300;
      font-size: rem-calc($fontsize-medium);
    }

    .more-info-btn {
      border: $components-border-style;
    }
  }

  .store-details-text {
    font-size: rem-calc(12);
    font-weight: 300;

    &.store-info__address,
    &.store-info__phone {
      line-height: rem-calc(18);
      font-size: rem-calc(12);
    }

    &.store-info__distance-and-type {
      font-size: rem-calc(14);
    }
  }

  address {
    margin-bottom: 0;
  }

  .store-details {
    margin-right: map-get($spacers, 4);

    .more-info-btn {
      display: none;

      &.mb-more-info {
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-color: $primary;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  button {
    &.detect-location {
      @include btn-secondary();
    }
  }

  .form-divider {
    clear: both;
    padding-top: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);

    span {
      text-transform: uppercase;
    }

    hr {
      border-color: $grey8;
    }
  }

  #heading-filter {
    border-bottom: $components-border-style;
    font-weight: 300;

    span {
      padding-top: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 3);
    }
  }

  .store-search-field {
    white-space: nowrap;
    width: calc(100% - #{rem-calc(40)});
    float: left;

    .form-group {
      margin-bottom: 0;
    }
  }

  .search-form {
    p {
      font-weight: 300;
    }

    .invalid-feedback {
      font-size: rem-calc(14);
      margin-bottom: rem-calc(-15);
      margin-top: rem-calc(10);
    }

    .has-float-label input:placeholder-shown:not(:focus) + * {
      font-size: rem-calc(14);
      top: rem-calc(10);
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: rem-calc(20);

      &.detect-location {
        .font-icon {
          margin-right: map-get($spacers, 2);
        }
      }
    }
  }

  .btn-storelocator-search {
    @include btn-secondary();

    width: rem-calc(40);
    padding: 0;

    &--text {
      display: none;
    }

    &--icon {
      display: block;
      line-height: rem-calc(18);

      color: $white;
    }
  }

  .accordion-element {
    .filter-heading {
      letter-spacing: rem-calc(5);
      border-top: 1px solid $grey8;

      &:first-child {
        border-top: none;
      }
    }

    &__content {
      @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
      }

      .form-group {
        margin-bottom: map-get($spacers, 3);
      }
    }
  }

  .toggle-store-locator-container {
    &-left,
    &-right {
      min-width: rem-calc(40);
      min-height: rem-calc(40);
      padding: rem-calc(9) 0;
      background-color: $secondary;

      .tippy-popper {
        display: none;
      }

      &.hide-right,
      &.hide-left {
        position: absolute;

        .tippy-popper {
          display: block;

          .tippy-tooltip {
            border-radius: 0;
            display: flex;
            height: rem-calc(40);
            justify-content: center;
            align-items: center;

            .tippy-arrow {
              display: none;
            }
          }
        }
      }
    }
  }

  .custom-checkbox {
    margin-bottom: 10px;

    .custom-control-label {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.7px;
      left: 0.5rem;

      &::before {
        height: 21px;
        width: 21px;
        top: -0.1rem;
        left: -1.5rem;
      }

      &::after {
        height: 21px;
        width: 21px;
        top: -0.1rem;
        left: -1.5rem;
      }
    }
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background: transparent;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background: none;
    font-family: $icon-font;
    content: map-get($icon-symbols, 'fa-check');
    line-height: $forms-checkbox-indicator-size;
    top: rem-calc(2);
    padding-left: rem-calc(2);
    text-align: center;
    font-size: rem-calc(18);
  }

  .store-locator-tabs-container {
    margin-bottom: map-get($spacers, 4);

    .row {
      margin: 0;

      .tab-list,
      .tab-map {
        border: none;
      }

      .active-tab {
        border-bottom: rem-calc(2) solid $green;
      }
    }
  }
}

.store-details-page-container {
  padding-top: map-get($spacers, 2);

  .store-work-times {
    .current-day {
      color: $black;
    }
  }
  h6 {
    letter-spacing: rem-calc(5.6);
    font-weight: 300;
    margin-bottom: rem-calc(20);
  }

  .font-icon {
    font-size: $fontsize-xlarge;
  }

  .opening-hours,
  .address-container,
  .store-services {
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 5);
  }

  .book-appointment-container {
    @include media-breakpoint-up(lg) {
      @include make-col(10);
    }
  }

  .store-details {
    &__heading {
      @include h2;

      font-weight: 300;
      letter-spacing: rem-calc(9.6);
      margin-top: rem-calc(30);
    }

    &__name {
      font-size: rem-calc($fontsize-medium);
      letter-spacing: rem-calc(5);
      font-weight: 100;

      @include media-breakpoint-up(lg) {
        font-size: rem-calc($fontsize-xlarge);
        color: $primary;
        font-weight: 300;
        letter-spacing: rem-calc(6.75);
      }
    }

    &-text {
      color: $primary;
      font-weight: 300;

      &.go-back {
        margin-top: map-get($spacers, 2);
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .nearest-store {
    padding-left: 0;

    .store-details {
      .store-info-container {
        order: 1;

        @include media-breakpoint-up(lg) {
          order: 2;
          max-width: 100%;
        }

        padding-left: $content-gutter;
        padding-right: $content-gutter;

        h6 {
          font-weight: normal;
        }

        .store-info {
          &__address a {
            text-decoration: none;
          }
        }
      }

      .store-picture-container {
        order: 2;
        padding-left: $content-gutter;
        padding-right: $content-gutter;

        @include media-breakpoint-up(lg) {
          max-width: 100%;
          order: 1;
        }

        .store-img {
          max-width: 100%;
          max-height: 100%;
        }

        .more-info-btn {
          margin: auto;
          max-width: 100%;
          @include btn-secondary();
        }
      }
    }
  }
}
