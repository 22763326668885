.wishlist {
  .product-info {
    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
  }

  &__size-selector {
    select {
      border: rem-calc(1) solid;
    }
  }
}
