@import "../atoms/button";
@import "../atoms/divider";
@import "../atoms/input";
@import "../atoms/image";
@import "../atoms/links";
@import "../atoms/icons";
@import '../atoms/label';
@import "../atoms/textElement";
@import "../atoms/lists";
@import "../atoms/breadcrumb";
@import "../atoms/pageElement";
@import "../components/imageGallery";
@import "../atoms/fit-rating-scale";

@import "../molecules/stylewithCTA";
@import "../molecules/customerRatingCTA";
@import "../molecules/sizeDropdown";
@import "../molecules/backtotop";
@import "../molecules/fitRating";
@import "../molecules/breadcrumbs";
@import "../molecules/productInfo";
@import "../molecules/colorSwatch";
@import "../molecules/stickyATBdesktop";
@import "../molecules/featureTiles";
@import "../molecules/productDescTab";
@import "../molecules/customerReviews";
@import "../molecules/ratingScoreTop";
@import "../molecules/styleWithRecommendation";
@import "../molecules/notifyMeSection";
@import "../molecules/einsteinRecModule";
@import "../molecules/findInStore";

$product-elements-border-style: rem-calc(1) solid $primary;
$product-add-to-wishlist-size: rem-calc(40);
$size-chart-border-style: rem-calc(1) solid $black;
$flyout-pane-inner-max-width: rem-calc(408);
$pdp-elements-letter-spacing: rem-calc(5.6);
$wishlist-icon-size: rem-calc(26);
$pdp-size-tooltip-bg: $beige;
$landscape : landscape;
$color-swatch-size: rem-calc(32) !default;
$color-swatch-border-radius: 50% !default;
$color-selected-swatch-padding: rem-calc(3);
$flyout-style-headings: false !default;
$product-content-padding: rem-calc(10);
$product-size-attribute-height: rem-calc(45);
$product-add-to-wishlist-size: $button-height;
$product-add-to-wishlist-offset: rem-calc(5);
$product-add-to-wishlist-icon-size: rem-calc(24);
$product-delivery-pane-max-width: 90% !default;
$product-delivery-pane-max-width-desktop: rem-calc(600) !default;
$product-stock-check-pane-max-width: 90% !default;
$product-stock-check-pane-max-width-desktop: rem-calc(754) !default;
$product-stock-check-result-min-height: rem-calc(530) !default;
$product-stock-check-color-swatch-size: rem-calc(22) !default;
$product-stock-check-color-swatch-offset: rem-calc(4) !default;
$product-stock-check-product-col-width-desktop: rem-calc(420) !default;
$product-stock-check-color-result-details-offset: rem-calc(20) !default;
$product-stock-check-color-result-details-arrow-size: rem-calc(20) !default;
$product-stock-check-color-result-stores-width: $product-stock-check-product-col-width-desktop - $product-stock-check-color-result-details-offset !default;
$product-reviews-pane-max-width: 90% !default;
$product-reviews-pane-max-width-desktop: rem-calc(750) !default;
$product-size-chart-pane-max-width: 90% !default;
$product-size-chart-pane-max-width-desktop: rem-calc(440) !default;
$product-content-details-width: rem-calc(420) !default;
$continuity-flyout-pane-max-width: 90% !default;
$continuity-flyout-pane-max-width-desktop: rem-calc(440) !default;
$mobile-m-width: 375px;
$mobile-max-width: 768px;
$mobile-min-width: 501px;

.product-detail--set {
  .set-top-section {
    @media (min-width: $bp-desktop-min) {
      margin: auto;
      max-width: 70rem;
      padding-bottom: 2.5rem;
    }
  }
  .container {
    max-width: none;
  }
  .product-detail__set-container {
    @media (max-width: $bp-medium-max) {
      padding-left: 1rem;
    }

    @media (min-width: $bp-large) and (max-width: $bp-large-max) {
      padding-left: 2rem;
      padding-right: 1rem;
    }

    @media (min-width: $bp-desktop-min) {
      padding-left: 2.5rem;
      padding-right: 0.3125rem;
    }
  }

  .breadcrumb-top {
    .product-detail__nav-row {
      padding-top: 0.8rem;
      @media (min-width: $bp-desktop-min) {
        padding-left: 1.5rem;
      }
    }
  }

  .product-detail .addtobagbtn .add-to-cart {
    height: 48px;
    top: 0;
  }
  .product-detail__details-section--product {
    flex: 0 0 50%;
    max-width: 50%;

    @media (max-width: $bp-large) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .container .product-detail__product-carousel {
    @media (min-width: $bp-desktop-min) {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-top: 0 !important;
      padding-bottom: 2.5rem !important;
    }

    @media (min-width: $bp-large) and (max-width: $bp-med-max) {
      margin-left: 0;
      padding-right: 2rem;
      padding-left: 2rem;
      padding-top: 4rem !important;
      padding-bottom: 0 !important;
    }
  }

  .product-detail__product-carousel:has(.product-detail__recently-viewed) {
    padding-left: 0;
    margin-left: 0.5rem;
    margin-right: 0;

    @media (min-width: $bp-large) and (max-width: $bp-med-max) {
      padding-right: 1.375rem;
      padding-left: 1.375rem;
      margin-left: 0;
    }

    @media (min-width: $bp-desktop-min) {
      padding-left: 4.375rem;
      padding-right: 4.375rem;
      margin-left: 0;
    }
  }

  .product-detail__tile-row-container {
    .einsteinCustomStyles {
      position: relative;

      .product-recommendations__heading {
        margin-top: 0;
      }
    }
  }

  .backToTop {
    margin-top: 4rem;
    margin-bottom: 4rem;
    @media (max-width: $bp-medium-max) {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  }
}

.product-detail__details-section--set {
  @include media-breakpoint-down(md) {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0.5rem;
  }

  @media (min-width: $bp-desktop-min) {
    padding-right: 0 !important;
  }
  .product-detail__details-section {
    padding: 0;
  }

  .product-detail__product-images {
    padding-right: 0;
  }

  .product-detail__stock-check {
    padding: 0;
    margin-top: 0.4rem;
    margin-bottom: 0;
    @media only screen and (max-width: $bp-large) {
      margin-top: 0.8rem;
    }

    .product-detail__stock-check__link {
      padding: 0;

      .product-detail__stock-check__link__text {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        letter-spacing: 2.4px;
      }
    }
  }

  .select-dropdown__list.active {
    @media (min-width: $bp-large) {
      z-index: 1;
    }
  }

  .product-detail__brand-name + a {
    display: inline-block;
    width: fit-content;
  }

  .product-detail__fit-rating {
    @media (min-width: $bp-large) {
      .fit-labels {
        .d-lg-none {
          display: block !important;
        }
        .d-lg-block {
          display: none !important;
        }
      }
    }
  }
  .sizeguidelabel {
    letter-spacing: 0.15rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.9rem;
    text-transform: uppercase;
  }
}

@include media-breakpoint-down(md) {

  .rd_PDP {
    .compact-header {
      .right-container .wishlist {
        display: none;
      }
      .header-element__user.left {
        display: none !important;
      }
      .header-element__user.right {
        display: block;
      }
    }

    .header .header-item__menu {
      display: flex !important;
      flex: 0 0 30%;
      max-width: 30%;
    }

    .header .right-container .stores,
    .header .header-element__wishlist {
      display: none !important;
    }

    .header-element.logo.center {
      margin: 0 auto;
      max-width: rem-calc(180);
    }

    nav .header {
      max-height: rem-calc(58);
    }
  }
}

.new-pdp {
  .size_addtocart {
    .cart-and-ipay {
      .add-to-cart {
        height: 3rem;
        letter-spacing: 0.175rem;
        font-size: 0.875rem;
        @include media-breakpoint-down(md) {
          height: 2.5rem;
        }
      }
    }
  }

  .header-sticky {
    &.header-z-index {
      z-index: 2;
    }
  }

  .low_stock_message,
  .mobile-message {
    display: none;
  }

  .header-banner {
    position: relative;
    z-index: 1;
    &.new-promo-banner {
      padding: 0.313rem 1rem;
      @include media-breakpoint-down(md) {
        background-color: $pink !important;
      }
      .header-banner-promo {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
        }
        a {
          color: $white;
        }
      }
      .banner-container {
        padding: 0;
      }
      p {
        margin-bottom: unset;
      }
    }
    &.new-global-banner {
      @include media-breakpoint-up(lg) {
        padding: 0.3125rem 1rem;
        &:has(.rd-global-banner-text > div:not(.carousel), .rd-global-banner-text p, .rd-global-banner-text a),
        &:has(.rd-global-banner-text .carousel-item) {
          background-color: $light-stone;
        }
      }
      .header-banner-global {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      p {
        margin-bottom: unset;
      }
      .row {
        align-items: center;
      }
    }
    &:not(.new-global-banner) {
      .header-banner-global {
        display: none;
      }
    }
    &:not(.new-promo-banner) {
      min-height: unset;
      @media (max-width: $sm-width) {
        height: 0;
        .globale-selector,
        .js-page-locale-loader {
          display: none;
        }
      }
    }
    .globale-selector.country-selector .country-selector__current {
      margin-right: 0;
    }
  }
  .header-banner-bottom-new {
    padding-left: 0;
    padding-right: 0;
    &.header-banner-promo {
      text-align: center;
      background-color: $pink !important;
      padding: 0.313rem 1rem;
      a {
        color: $white !important;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
      display: block;
    }
    .header-banner-global-bottom {
      display: none;
      text-align: center;
      @include media-breakpoint-down(md) {
        display: block;
      }
      &.desktop {
        display: none;
        @media (min-width: $bp-large) {
          display: block;
        }
      }
      &.carousel-inner:has(div),
      &:has(> div:not(.carousel), p, a) {
        padding: 0.313rem 1rem;
        background-color: $light-stone;
        @include media-breakpoint-up(lg) {
          margin-bottom: 1.5rem;
        }
      }
    }
    p {
      margin-bottom: unset;
    }
  }
  .product-detail__product-images.image-carousel-sticky {
    @media only screen and (min-width: $bp-large) {
      position: sticky;
      top: 130px;
      height: fit-content;
    }
  }
  .product-details__social-icons-container {
    display: none;
  }
  .product-set-detail {
    .header-banner-bottom-new {
      .header-banner-global-bottom {
        display: block;
      }
    }
  }
}

.header-banner-bottom-new.header-banner-promo {
  display: none;
}

body.new-pdp {
  .header-banner.header-banner-bottom.slide-up {
    display: none;
  }
  // To do: Need to check & remove important
  header.header-sticky {
    transition: opacity 0.25s ease-in-out;
    position: fixed !important;
    @include media-breakpoint-up(lg) {
      position: sticky !important;
      top: 0;
    }
    @include media-breakpoint-down(md) {
      &.scroll-down {
        display: none;
        &.active-flyout {
          display: block;
        }
      }
    }
  }
}

.redesign {
  @include media-breakpoint-up(lg) {
    .product-main-content-wrapper {
      z-index: 1;
    }

    .product-detail__product-bottom {
      z-index: 0;
    }
  }
}

@mixin product-name {
  order: 1;
  flex-basis: 100%;
}

.primary-images {
  &__wrapper {
    padding-left: 0;
  }
}

.product-detail__attribute--color.attribute_section {
  .color_label {
    margin-bottom: 0.75rem;
  }
}

.product-detail {
  padding-right: 0;
  padding-left: 0;

  .add-to-bag-msg {
    display: none;
    position: absolute;
    color: $dark-red;
    bottom: -1.563rem;
    letter-spacing: 1.12px;
    &.active {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    overflow: hidden;
    position: relative;
  }

  .btn.btn-secondary:hover {
    background-color: $green;
    border-color: $green;
    color: $white;
  }

  .bottom-section-header {
    overflow-x: hidden;
  }

  .product-detail__rating {
    border: none;
    .card-header {
      background-color: none;
      border: none;
      padding: 0 !important;
    }
  }

  .continuity-flyout__pane {
    height: 100% !important;
  }

  &__oos-message {
    display: none;
  }

  .giftcardbtn .cart-and-ipay {
    width: 100%;
  }

  .addtobagbtn {
    display: inline;
    vertical-align: bottom;
  }

  .addtobagbtn .add-to-cart,
  .addtobagbtn .continuity-flyout__button {
    position: relative;
    left: 4px;

    @media (min-width: $bp-large) {
      top: -1px;
    }
  }

  .continuity-flyout__pane.show .continuity-flyout__container {
    right: 0;
  }

  &__top-row-container {
    margin-right: 0;
    margin-left: 0;
  }

  &__attribute__error {
    position: absolute;
    color: #c13c4c;
    bottom: -25px;
    letter-spacing: 1.12px;
  }

  &__attribute--size,
  .cart-and-ipay {
    display: inline-block;
    width: 49%;
  }

  .primary-images__wrapper .primary-images__image-wrapper__inner {
    width: 100%;
    height: auto;
    aspect-ratio: 5 / 7; // stylelint-disable-line
    overflow: hidden;
    padding-right: 10px;
  }

  .custom-loader {
    animation: spin 1s linear infinite;
  }

  .product-information {
    margin-bottom: 1rem;
  }

  .price_rating {
    display: flex;
    align-items: center;
  }

  .continuity-flyout {
    @include flyout($continuity-flyout-pane-max-width, $continuity-flyout-pane-max-width-desktop);

    .btn-secondary {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      span {
        display: block !important;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    .continuity-flyout__form {
      .continuity-flyout__button {
        left: 0;
      }
    }

    &__close-icon {
      padding-top: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }

    &__add-to-wishlist,
    .mobile-message {
      display: none;
    }

    &__container {
      padding: map-get($spacers, 3);
      position: absolute;
    }

    &__header {
      display: block;
      overflow: hidden;
    }

    &__title {
      float: left;
    }
  }

  &:not(.product-detail--quickview) {
    .primary-images {
      &__image-wrapper {
        &:only-child {
          margin: 0 auto;
        }
      }

      .third-image {
        @include media-breakpoint-up(lg) {
          @include make-col(9);

          margin: 0 auto;
        }
      }

      &.primary-video {
        margin-bottom: 4rem;
      }

      &__carousel {
        .primary-images__video-link-button {
          background: $grey11;

          .primary-images__video-link {
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0.34em;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &--quickview {
    .full-pdp-link {
      height: auto;
      line-height: normal;
    }
  }

  &__details-section {
    padding-top: map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
      padding-top: map-get($spacers, 1);
      padding-left: 1rem;
    }

    @media only screen and (max-width: $sm-width) {
      padding: 0 rem-calc(26);
    }

    @media only screen and (min-width: $md-extra-large) {
      padding-left: 2.5rem;
    }

    .product-detail--quickview & {
      padding: map-get($spacers, 4);
    }

    &-sticky {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    &__breadcrumb {
      @include media-breakpoint-down(lg) {
        margin-bottom: 1rem;
      }
    }
  }

  .product-set-main-image-sticky {
    text-align: center;
    @media (min-width: $bp-large) {
      position: sticky;
      top: rem-calc(120px);
      padding-left: 2rem;
    }
    @media (min-width: $bp-desktop-min) {
      padding-right: 2.5rem;
      padding-left: 0;
    }
  }

  .product-detail__set-image {
    @media (min-width: $bp-large) {
      width: 100%;
    }
  }

  .set-items {
    .product-detail__product-images {
      padding-right: 0;
      @media (max-width: $bp-large) {
        width: 100%;
      }
      .carousel-indicators {
        display: flex;
        bottom: rem-calc(16);
        @media (min-width: $bp-large) {
          display: none;
        }

        li {
          border-radius: 100%;
          border: none;
          &.active {
            background-color: $mid-grey;
          }
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        bottom: 6%;
        color: $light-black;
        @media (max-width: $bp-large) {
          display: none;
        }
      }
      .third-image {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 1em;
      }
    }

    .product-detail__attribute--size,
    .product-detail .cart-and-ipay {
      @media (min-width: $bp-large) {
        width: 100%;
        padding-bottom: rem-calc(8);
      }
    }

    .product-detail__attribute__display-value {
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 2.4px;
    }

    .product-detail .addtobagbtn .add-to-cart,
    .product-detail .addtobagbtn .continuity-flyout__button {
      @media (min-width: $bp-large) {
        left: 0;
      }
    }

    .product-detail__stock-check__link {
      border: none;
      display: flex !important;
      align-items: baseline;

      .icon-chevron-right {
        margin-left: 0.3125rem;
      }
    }

    .set-item {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 2rem;

      @media (max-width: $bp-medium-max) {
        margin-bottom: 2.5rem;
      }

      .product-detail__product-name {
        margin-top: 0;
      }
      .product-detail__ratings {
        cursor: default;
      }
      .primary-images__wrapper.carousel-inner.row.no-gutters {
        @media (min-width: $bp-large) {
          margin-top: 0;
        }
      }
      .carousel-item.active {
        transition: transform 0.25s ease;
      }

      .set-item-product-section {
        margin-left: 0;
        margin-right: 0;
      }

      .add-to-bag-msg {
        position: relative;
        bottom: 0.5rem;
      }
    }

    .primary-images {
      @media (max-width: $bp-large) {
        width: 100%;
      }
    }

    img {
      &.primary-images__image.cld-responsive {
        width: 100%;
      }
    }

    .primary-images__carousel {
      width: 100%;
    }

    .primary-images__image-wrapper__inner {
      padding-right: 0;
    }

    @media (min-width: $bp-large) {
      img {
        &.primary-images__image.cld-responsive {
          height: auto;
        }
      }

      .primary-images__carousel {
        height: auto;
        width: 150px;
      }

      .primary-images__image-wrapper__inner {
        padding-right: 0;
      }
    }
  }

  .d-grid-mobile {
    @media (max-width: $bp-large) {
      display: grid;
    }
  }

  &__product-images {
    z-index: 0;

    .carousel-indicators {
      bottom: -5.25rem;
      display: none;

      li {
        background-color: $white;
        border: $size-chart-border-style;
        width: rem-calc(7);
        height: rem-calc(7);

        &.active {
          background-color: $green;
          border: none;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      padding-right: 1rem;
    }
    @media only screen and (min-width: $md-extra-large) {
      padding-right: 2.5rem;
    }

    .primary-images {
      @media only screen and (max-width: $sm-width) {
        aspect-ratio: 5 / 7; // stylelint-disable-line
      }
    }
  }

  .product-breadcrumb {
    margin-bottom: 0.5rem;
    ol {
      justify-content: initial;
      margin: 0 map-get($spacers, 2);

      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }
  }

  &__ratings {
    @include make-col-ready();
    @include make-col(6);
    order: 4;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 0;
    flex: auto;
    max-width: max-content;

    .rating__count {
      color: $green;
    }

    .font-icon {
      flex-basis: rem-calc(15);

      &.icon-fa-star {
        color: $green;
      }

      &.icon-fa-star-o {
        &::before {
          @include fa-icon-override('fa-star', $fa-var-star);

          color: $grey8;
        }
      }
    }
  }

  &__prices {
    @include make-col-ready();
    @include make-col(6);
    order: 3;
    padding-left: 0;
    flex: auto;
    max-width: max-content;

    .strike-through {
      text-decoration: none !important;
      span.prefix {
        text-decoration: line-through;
      }
      span.value {
        text-decoration: line-through;
      }
    }
  }

  &__attributes-accordion {
    flex-basis: 100%;
  }

  &__main-section-inner {
    border-bottom: rem-calc(1) solid $beige;
  }

  &__attribute {
    position: relative;
    letter-spacing: $pdp-elements-letter-spacing;

    &__display-name,
    &__display-value,
    &__value__text {
      text-transform: uppercase;
    }

    &--color {
      .product-detail__attribute__value {
        &:not(&--current):hover {
          border: rem-calc(1) solid $primary;
        }

        &--current {
          border: rem-calc(1) solid $black;
        }
      }
      .product-detail__attribute__label--inner {
        max-width: 100%;
        min-width: fit-content;
      }
    }

    &--size {
      .product-detail__attribute__value {
        &__text {
          letter-spacing: rem-calc(1.05);
        }

        &--low-stock,
        &--unselectable {
          background: none;
          border-style: dashed;
          border-color: $grey5;
          color: $grey5;

          &:hover {
            color: $primary;
            border-style: solid;
            border-color: $pdp-size-tooltip-bg;
            background-color: $pdp-size-tooltip-bg;
          }
        }

        &--continuity {
          text-decoration: line-through;
          background-image: none;
        }

        &--unselectable {
          text-decoration: line-through;
          cursor: not-allowed;

          .product-detail__attribute__value__text {
            background: transparent;
          }
        }

        &--current {
          background: $primary;
          color: $white;
        }
      }
    }

    &--size & {
      &__value-wrapper {
        @include make-col(2);

        .tippy-popper {
          max-width: rem-calc(140);
          width: rem-calc(140);
          .tippy-tooltip {
            border: none;
            border-radius: 0;
            background-color: $pdp-size-tooltip-bg;
            padding: rem-calc(5);

            .tippy-arrow {
              border: none;
              &::after {
                border-top-color: $beige;
              }
            }

            .tippy-content {
              font-weight: lighter;
              letter-spacing: rem-calc(1.5);
            }
          }
        }
      }
    }

    .size-chart {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.96px;
      text-decoration: underline;
      font-family: "Proxima Nova";
      line-height: normal;
    }

    &__display {
      &-value {
        font-weight: bold;
      }
    }

    &__fitVariants {
      text-transform: uppercase;
      letter-spacing: 0.35rem;
      margin-bottom: 1.5rem;
    }
  }

  &__accordion {
    .card {
      border-bottom: $product-elements-border-style;

      &:first-child {
        border-top: $product-elements-border-style;
        border-bottom: $product-elements-border-style;
      }
    }

    .card-body {
      ul {
        margin-top: rem-calc(26);
        line-height: 1.9;
      }
    }

    &-heading {
      .btn {
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem-calc($fontsize-medium);
        color: $primary;

        i {
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }

        &.collapsed .icon-chevron-up {
          &::before {
            content: map-get($icon-symbols, 'chevron-down');
          }
        }
      }
    }
  }

  &__nav-row {
    @include media-breakpoint-up(lg) {
      padding: 0 2rem;
    }
    @media only screen and (min-width: $md-extra-large) {
      margin: auto;
      padding: 0;
      max-width: $pdp-main-container-width;
    }
    &__breadcrumbs {
      .row {
        margin-left: 0;
        margin-right: 0;
      }

      .col {
        padding-left: 0;
        padding-right: 0;
      }

      ol {
        justify-content: center;

        @include media-breakpoint-up(lg) {
          justify-content: initial;
        }

        li {
          a {
            color: $primary;
            font-size: rem-calc($fontsize-medium);
            text-decoration: underline;
          }

          &:last-child a {
            text-decoration: none;
          }
        }
      }
    }

    &__nav > a {
      align-self: center;
    }
  }

  &__details-row {
    .product-main-content-wrapper {
      @include media-breakpoint-up(lg) {
        padding: 0 2rem 4rem 2rem;
      }
      @media only screen and (min-width: $md-extra-large) {
        padding: 0 5rem 4rem 5rem;
      }
      @media only screen and (min-width: $md-extra-large-max) {
        margin: auto;
        padding: 0 0 4rem 0;
        max-width: $pdp-main-container-width;
      }
    }
  }

  &__social-icons {
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;

    .product-detail--quickview & {
      display: none;
    }

    &__callout {
      display: inline-block;
      font-size: rem-calc($fontsize-medium);
    }

    li {
      display: block;
    }

    .share-mail,
    .share-pinterest,
    .share-whatsapp {
      display: none;
    }

    li,
    i {
      color: $primary;

      &.font-icon {
        font-size: rem-calc(26);
      }

      &.icon-fa-twitter::before {
        @include fa-icon-override('fa-twitter-square', $fa-var-twitter-square);
      }
    }
  }

  &__add-to-cart {
    &__notification {
      background-color: $white;
      margin-top: 0.75rem;
    }
  }

  .size_addtocart {
    margin-bottom: 1.5rem;
    .continuity-flyout__button {
      @extend .rd-notify-me-btn-text;
      height: 2.5rem;
      @media only screen and (min-width: $tab-width-max) {
        height: 3rem;
      }
    }
  }

  &__add-to-wishlist {
    &__notification {
      display: block;
    }
  }

  .add-to-wish-list {
    display: none;
    width: $product-add-to-wishlist-size;
    height: $product-add-to-wishlist-size;
    margin-left: $product-add-to-wishlist-offset;
    background-color: $white;
    line-height: 1;

    &:hover {
      background-color: $white;
    }

    .wishlist-icon {
      color: $primary;
      font-size: $wishlist-icon-size;
    }

    &[disabled] {
      background-color: $primary;
      opacity: 1;

      .wishlist-icon {
        color: $white;
      }
    }
    &.active {
      background-color: $primary !important;
      opacity: 1;

      .wishlist-icon {
        color: $white;
      }
    }
  }
  button.add-to-wish-list {
    &.active {
      &:focus {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }
  &__description-pills,
  &__size-chart__content {
    .nav-tabs {
      width: 100%;
    }

    .nav-item {
      width: 50%;
      text-transform: uppercase;

      .nav-link {
        font-size: rem-calc($fontsize-medium);
        color: $primary;
        border-radius: 0;
        text-align: center;
        letter-spacing: rem-calc(4.2);
        padding-bottom: map-get($spacers, 3);

        &.active {
          color: $primary;
          border-bottom: rem-calc(3) solid $green;
        }
      }
    }
  }

  .pills-wrapper {
    .tab-pane {
      line-height: rem-calc(22);
      li {
        margin-bottom: rem-calc(10);
      }
    }
  }

  &__size-chart {
    @include flyout($product-size-chart-pane-max-width, $product-size-chart-pane-max-width-desktop, $flyout-style-headings);

    .size-guide-link {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: map-get($spacers, 2);
    }

    &__close-label {
      display: none;
    }

    &__container {
      padding: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 4);
    }

    &__content {

      font-size: $fontsize-large;

      .size-chart__measure-unit-switch {
        .radio-link {
          @include checkout-option;
          width: rem-calc(95);
          height: rem-calc(40);
          border: rem-calc(1) solid $border-color;

          &.active {
            @include checkout-selected-option;

            border: rem-calc(1) solid $secondary;
          }

          .radio-label {
            margin-left: map-get($spacers, 4);
          }
        }
      }

      .sizes-content-table {
        .chart-table-heading {
          letter-spacing: rem-calc(5);
        }

        td {
          font-size: rem-calc($fontsize-medium);
        }

        thead tr {
          background-color: transparent;
          border-top: $size-chart-border-style;

          th {
            border: 0;
          }
        }

        tbody tr {
          border-bottom: $size-chart-border-style;

          td {
            border: 0;
          }

          &:first-child {
            border-top: $size-chart-border-style;
          }

          &:nth-child(even) {
            background-color: transparent;
          }
        }
      }
    }
  }

  .product-detail__product-carousel {
    @media (min-width: $bp-desktop-min) {
      padding: 0 5rem;
    }

    @media (max-width: $bp-med-max) {
      padding: 0 2rem;
    }

    @include media-breakpoint-down(md) {
      padding: 0 0 0 1.6rem;
    }
  }

  &__tile-row-container {
    margin-left: 0;
  }

  &__product-bottom {
    &__heading {
      @include border-after();
    }

    &__heading-new::after {
      border-top: none !important;
    }

    .product-tile {
      &__body {
        text-align: center;
      }

      &__name {
        margin-bottom: map-get($spacers, 2);
        font-size: rem-calc($fontsize-large);
      }

      &__rating {
        display: none;
      }
    }
    @media screen and (max-width: $sm-width) {
      padding: rem-calc(6) 0;
    }
  }

  &--set & {
    &__set-heading {
      @extend .rd-set-page-heading;
      margin-bottom: 0.5rem;
      text-align: left;
      @media (max-width: $bp-medium-max) {
        margin-bottom: 1.5rem;
      }
    }

    &__set-sub-heading {
      @extend .rd-set-page-sub-heading;
      text-align: left;
      margin-bottom: 2rem;
      visibility: visible;
      &::after {
        content: '';
        visibility: hidden;
      }
      @media (max-width: $bp-medium-max) {
        margin-bottom: 1rem;
      }
    }
  }

  &__addToCartStickyBar {
    position: fixed;
    bottom: 0;
    z-index: 5;
  }

  &__tile-row {
    flex-wrap: nowrap;
  }

  .add-to-cart {
    width: 100%;
  }

  &__content {
    min-height: 25rem;
    width: 100%;
    background: gainsboro;
  }

  &__rating__reviews {
    margin-bottom: 1.5rem;
  }
  .attribute-color {
    span {
      width: rem-calc(35);
      height: 2rem;
      background: black;
      display: block;
      margin-right: rem-calc(5);
    }
    .content {
      border: 1px solid;
      padding: 0.5rem rem-calc(20);
      margin-right: rem-calc(10);
    }

    .swatch-container,
    .fit-container {
      margin-bottom: 1.5rem;
    }
    .color-text {
      margin-bottom: rem-calc(12);
    }
  }
  .size-dropdown {
    background: grey;
  }
}

.continuity-flyout {
  &__container {
    padding: rem-calc(60 20);
    letter-spacing: rem-calc(1);

    @include media-breakpoint-up(md) {
      padding-left: rem-calc(100);
      padding-right: rem-calc(100);
    }
  }

  &__close {
    text-align: center;
  }
  &__close__text {
    display: inline;
    text-transform: uppercase;
    letter-spacing: rem-calc(4);
  }

  &__pane & {
    &__header {
      display: block;
      overflow: hidden;
    }

    &__title {
      float: left;
      font-weight: normal;
    }
  }
}

@media (max-width: $mobile-max-width) {
  .exclusiveOfferWrap {
    bottom: 40px !important;
  }
}

.product-detail__attribute__display-name-fitVariants {
  color: $grey1;
}

.product-detail__attribute__fitVariants-selected-length {
  color: $primary;
  text-transform: uppercase;
  font-weight: bold;
}

.einsteinCustomStyles {
  .product-tile__body {
    margin-top: 0.5rem !important;
  }
  a {
    margin: 0 !important;
  }
}

.recommendation-carousel-pdp {
  .product-tile__action-add {
    font-weight: 400;
  }
  a.product-tile__add-overlay {
    margin: 0;
  }

  .product-tile__body {
    .product-tile__name a,
    .product-tile__sticker a,
    .product-tile__promotions a {
      white-space: initial;
    }
  }
}

.product-detail .product-detail__product-reviews {
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  @include media-breakpoint-up(lg) {
    padding: 0 2rem;
    margin-bottom: 4rem;
  }
  @media only screen and (min-width: $md-extra-large) {
    padding: 0;
    margin: auto;
    margin-bottom: 4rem;
    max-width: $pdp-main-container-width;
  }
}

.pagination-review {
  color: $light-black;
  margin-bottom: 24px 0;
  text-align: center;
}

.back-to-tops {
  margin: auto;
  color: black;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 24px;
  text-align: center;
}

.klarna-pdp-osm {
  min-height: 8.125rem;
  letter-spacing: normal;

  /* need custom media query as klarna placeholder size changes at this width */
  @media (min-width: $mobile-min-width) {
    min-height: 5.625rem;
  }
}

.paypal-pdp-osm {
  min-height: 2.5rem;
  padding-bottom: 1.5rem;
}
