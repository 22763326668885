.minicart {
  &__container {
    padding: $spacer-4;
  }

  &__content {
    max-width: rem-calc(475);
    margin: 0 auto;
    padding: 0;
    @include media-breakpoint-up(md) {
      padding: map-get($spacers, 4);
    }

    .checkout-btn {
      white-space: normal;
      height: auto;

      @include media-breakpoint-down(sm) {
        line-height: rem-calc(20);
        padding-top: rem-calc(7);
        padding-bottom: rem-calc(7);
      }
    }
  }

  &__header {
    margin-bottom: map-get($spacers, 2);

    .row {
      .minicart__header__heading {
        @include make-col(7);

        @include media-breakpoint-up(md) {
          @include make-col(10);
        }

        padding-bottom: map-get($spacers, 3);
      }
      .minicart__header__close-icon {
        @include make-col(3);
        @include make-col-offset(2);

        @include media-breakpoint-up(md) {
          @include make-col(2);
          @include make-col-offset(0);
        }

        text-align: center;
      }
    }

    &__close-icon {
      .font-icon {
        font-size: rem-calc($fontsize-xmedium);
      }
    }
  }

  &__footer {
    padding: 0;
    @include media-breakpoint-up(md) {
      padding: 0 $spacer-4 $spacer-4;
    }
  }

  &__totals {
    padding: map-get($spacers, 4);

    .estimated-total {
      &.discount {
        color: $red;
      }
    }
  }

  &__notes {
    padding: 0 map-get($spacers, 3);
    font-size: rem-calc($fontsize-medium);
    line-height: rem-calc(19.6);
  }

  .product-card {

    line-height: rem-calc(26);

    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }

    &__details {
      @include media-breakpoint-down(md) {
        margin-bottom: rem-calc(-5);
      }
    }

    &__content__heading {
      text-transform: capitalize;
      font-weight: 300;
      font-size: rem-calc($fontsize-medium);
      letter-spacing: rem-calc(1.05);
    }

    &__name {
      margin-bottom: 0;
      line-height: rem-calc(21);
    }

    &__attribute {
      margin-bottom: 0;
      vertical-align: middle;

      span {
        display: none;
      }
      b {
        font-weight: 300;
        text-transform: lowercase;
        display: inline-block;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    &__quantity {
      display: none;

      &.inline-quantity {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
      }
    }

    &__price {
      margin-top: 0;
    }
  }

  &__close-btn {
    line-height: rem-calc(14);
    text-align: center;
  }

  .border-top,
  .border-bottom {
    border-color: $grey12 !important;
  }

  .flyout__heading {
    font-size: rem-calc(16);
    letter-spacing: rem-calc(6);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(22);
      letter-spacing: rem-calc(8);
    }
  }
  .flyout__close-label {
    display: block;
    font-size: rem-calc(10);
    letter-spacing: rem-calc(4);
  }
}
