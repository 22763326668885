$color-swatch-size: rem-calc(32) !default;
$color-swatch-border-radius: 50% !default;
$color-selected-swatch-padding: rem-calc(3);
$flyout-style-headings: false !default;
$product-content-padding: rem-calc(10);
$product-size-attribute-height: rem-calc(45);
$product-add-to-wishlist-size: $button-height;
$product-add-to-wishlist-offset: rem-calc(5);
$product-add-to-wishlist-icon-size: rem-calc(24);
$product-delivery-pane-max-width: 90% !default;
$product-delivery-pane-max-width-desktop: rem-calc(600) !default;
$product-stock-check-pane-max-width: 90% !default;
$product-stock-check-pane-max-width-desktop: rem-calc(754) !default;
$product-stock-check-result-min-height: rem-calc(530) !default;
$product-stock-check-color-swatch-size: rem-calc(22) !default;
$product-stock-check-color-swatch-offset: rem-calc(4) !default;
$product-stock-check-product-col-width-desktop: rem-calc(420) !default;
$product-stock-check-color-result-details-offset: rem-calc(20) !default;
$product-stock-check-color-result-details-arrow-size: rem-calc(20) !default;
$product-stock-check-color-result-stores-width: $product-stock-check-product-col-width-desktop - $product-stock-check-color-result-details-offset !default;
$product-reviews-pane-max-width: 90% !default;
$product-reviews-pane-max-width-desktop: rem-calc(750) !default;
$product-size-chart-pane-max-width: 90% !default;
$product-size-chart-pane-max-width-desktop: rem-calc(440) !default;
$product-content-details-width: rem-calc(420) !default;
$continuity-flyout-pane-max-width: 90% !default;
$continuity-flyout-pane-max-width-desktop: rem-calc(440) !default;
$mobile-m-width: 375px;
$mobile-max-width: 768px;
$product-content-padding: rem-calc(10);

.product-detail__product-bottom .space-bottom {
  margin-bottom: 5.5rem;
  @include media-breakpoint-down(md) {
    margin-bottom: 3.5rem;
  }
}

.product-detail__product-bottom .product-recommendations__heading {
  margin: 0 0 3.5rem;
  text-align: center;
  @include media-breakpoint-down(md) {
    margin: 0 0 1.5rem;
  }
}

.product-detail__tile-row__item--4 {
  max-width: 25%;
  flex: auto;
}

@media (max-width: $mobile-max-width) {
  #gnatta-chat-container {
    bottom: 6rem !important;
  }

  button#gwc-chatAvailable,
  button#gwc-chatBusy,
  button#gwc-chatOffline {
    bottom: 6rem !important;
  }

  .exclusiveOfferWrap {
    bottom: 48px !important;
  }
}

.product-detail {
  .product-tile__body {
    padding-left: 10px;
    padding-right: map-get($spacers, 2);
  }

  @media only screen and (max-width: $sm-width) {
    &__tile-row-container {
      overflow: hidden;
      margin-left: $product-content-padding;
    }

    &__tile-row {
      gap: 0.5rem;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;

      &__item {
        flex: 0 0 auto;
        max-width: rem-calc(225);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__tile-row {
      &__item {
        @for $i from 1 through 12 {
          &--#{$i} {
            max-width: percentage(1 / $i);
            flex: auto;
          }
        }
      }
    }
  }

  &__stock-check {
    @include flyout($product-stock-check-pane-max-width, $product-stock-check-pane-max-width-desktop, $flyout-style-headings);

    &__container {
      padding: map-get($spacers, 3);
    }

    &__link {
      display: block;
      font-weight: bold;
      text-align: center;
      width: calc(100% - #{$product-add-to-wishlist-size + $product-add-to-wishlist-offset});

      &:hover {
        text-decoration: underline;
      }
      &__icon {
        font-size: rem-calc(24);
      }
      &__text {
        font-size: $fontsize-large;
      }
    }

    &__form {
      &__image {
        width: rem-calc(85);
        height: auto;
      }

      &__fields {
        position: relative;

        &__background {
          display: inline-block;
          width: $product-stock-check-color-swatch-size;
          height: $product-stock-check-color-swatch-size;
          position: absolute;
          top: $product-stock-check-color-swatch-offset;
          left: $product-stock-check-color-swatch-offset;
          z-index: 1;
          border-radius: 50%;
        }
      }

      &__attribute-container {
        .form-icon {
          margin-top: map-get($spacers, 1);
        }
      }

      &__attribute {
        &--color {
          .has-float-label & {
            padding-left: $product-stock-check-color-swatch-size + (2 * $product-stock-check-color-swatch-offset);
          }
        }
      }

      &__store {
        margin-top: map-get($spacers, 4);

        .btn-storelocator-search {
          @include btn-secondary();
        }

        .detect-location-text {
          display: none;
          outline: none;
          cursor: pointer;
          background: none;
          border: none;
        }

        @include media-breakpoint-up(lg) {
          position: relative;
          margin-top: map-get($spacers, 3);
        }
      }
    }

    &__result {
      position: relative;

      &__row {
        width: 100%;
        cursor: pointer;
        position: relative;
        padding-top: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 4);

        @include media-breakpoint-up(lg) {
          width: $product-stock-check-color-result-stores-width;
        }

        &--sizes {
          padding: map-get($spacers, 4) 0;
        }
      }

      &__store-details-arrow {
        display: none;
        width: $product-stock-check-color-result-details-arrow-size;
        height: $product-stock-check-color-result-details-arrow-size;
        border-top: rem-calc(1) solid $border-color;
        border-left: rem-calc(1) solid $border-color;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(50% - #{$product-stock-check-color-result-details-arrow-size / 2});
        right: -1.5 * $product-stock-check-color-result-details-arrow-size;
        z-index: 1;
        background-color: $white;
      }

      &__store-details {
        display: none;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;

        @include media-breakpoint-up(lg) {
          top: 0;
        }

        &__phone {
          &__icon {
            font-size: rem-calc(25);
          }

          &__text {
            font-size: $fontsize-large;
          }
        }

        &__distance {
          color: $grey1;
        }

        &__worktimes {
          .current-day {
            color: $secondary;
            font-weight: bold;
          }
        }
      }

      &__store-details-toggle:checked + &__row {
        background-color: $grey3;
      }

      &__store-details-toggle:checked + &__row + &__store-details {
        display: block;
      }

      &__nearest-stocked-check {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-top: map-get($spacers, 5);

        &__store-details-toggle:checked + &__row &__store-details-arrow {
          display: block;
        }
      }
    }
  }

  .product-tile {
    &__swatches,
    &:hover .quickview,
    .quickview {
      display: none;
    }
  }

  &__rating {
    .rating-star {
      font-size: $fontsize-medium;
    }

    @include flyout($product-reviews-pane-max-width, $product-reviews-pane-max-width-desktop, $flyout-style-headings);

    &__review {
      border-bottom: rem-calc(1) solid $grey3;

      &-score {
        color: $secondary;
        text-align: center;
      }
    }

    &__heading {
      text-align: center;
      padding-top: map-get($spacers, 3);
    }

    &__score {
      color: $secondary;
      text-align: center;
    }
    &__status {
      &--sort {
        margin-bottom: map-get($spacers, 3);
      }
    }
    &__reviews-sort-label {
      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  }
}

.new-pdp {
  .product-detail {
    &__tile-row {
      &__item {
        @media (min-width: $bp-large) {
          max-width: 100% !important;
        }
      }
    }
    @media only screen and (max-width: $sm-width) {
      .glide__slides {
        overflow-x: auto !important;
        touch-action: unset !important;
      }
    }
  }
}
