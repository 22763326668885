$product-card-name-color: $primary;
$product-card-list-price-color: $grey5 !default;
$product-card-discounted-price-color: $lipstick !default;
$product-card-image-width: 85px !default;

.product-card {
  width: 100%;
  border: none;
  padding-bottom: rem-calc(10);

  &__price {
    .strike-through {
      @include h5;
      color: $primary;
      text-decoration: line-through;
    }

    .has-strike-through {
      @include h4;
      font-weight: normal;
      color: $red;
    }

    &__promo {
      color: $red;
    }
  }

  &__content {
    &__heading {
      @include h4;
    }
  }

  &__quantity {
    &.inline-quantity {
      display: none;
    }
  }
}

.gift-messaging {

  &__gift-message-display-heading {
    font-weight: normal;
    letter-spacing: rem-calc(5);
    text-transform: uppercase;
  }
}
