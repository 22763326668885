.contact-form {
  background-color: $grey3;

  &__heading {
    @include border-after($bgColor: $grey3);
    @include h3;

    font-weight: normal;
    text-align: left;
    margin-bottom: rem-calc(30);
  }

  &__icon,
  &__required-prompt {
    display: none;
  }

  &__content {
    border-width: rem-calc(1);
    border-style: solid;
    border-color: $black;
    font-size: rem-calc($fontsize-medium);

    &-field {
      &.form-control {
        border: none;
      }
    }
  }

  &__subject-field {
    font-size: rem-calc($fontsize-medium);
  }

  &__order-number-field {
    label {
      white-space: nowrap;
      padding-right: rem-calc(0);
      font-size: $fontsize-small;
    }
  }

  &__submit {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(lg) {
      @include make-col(3);

      float: right;
    }
  }
}

.contact-us {
  &__container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  &__wrapper {
    @include make-container();
    @include make-container-max-widths();

    .confirmation-page {
      text-align: left;
    }
  }

  &__content {
    @include media-breakpoint-up(lg) {
      padding-right: map-get($spacers, 5);
    }
  }

  &__form {
    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 5);
    }
  }
}
