@import "../utils/mixins";

input,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="time"],
input[type="number"],
input[type="date"],
input[type="password"],
select,
textarea {
  padding: rem-calc(10);
  letter-spacing: rem-calc(1.1);

  &:not([disabled]) {
    &.is-valid:not(select) {
      border-color: $validate-green;
    }

    &.is-invalid:not(select) {
      border-color: $invalid-red;

      &:focus {
        border-color: $invalid-red;
      }

      ~ .invalid-feedback {
        ~ .form-icon {
          color: $invalid-red;
        }
      }
    }
  }
}

.form-control {
  border-color: $form-control-border-color;

  &:not([disabled]) {
    &.is-valid {
      border-color: $validate-green;
    }

    &.is-invalid {
      border-color: $invalid-red;
    }
  }
}

.form-group {
  &--checkbox {
    label {
      font-weight: lighter;
      font-size: $fontsize-medium;

      &::before {
        width: rem-calc(17);
        height: rem-calc(17);
      }

      &::after {
        left: rem-calc(-10);
        z-index: 2;
        background-color: transparent;
      }
    }
  }

  &--textarea {
    &.has-float-label {
      border: rem-calc(1) solid $form-control-border-color;

      textarea {
        border: none;
      }
    }
  }

  &.has-float-label {
    .form-control:disabled,
    .form-control[readonly] {
      ~ label,
      ~ span {
        background-color: transparent;
      }
    }
  }
}

input[placeholder],
input::placeholder,
textarea::placeholder {
  font-size: $fontsize-large;

  @include media-breakpoint-up(md) {
    font-size: $fontsize-medium;
  }
}

.has-float-label label,
.has-float-label > span {
  top: rem-calc(-8);
  left: rem-calc(10);
  background-color: $white;
  padding: rem-calc(0 10);
}

.has-float-label input[type="hidden"][placeholder]:not(.focus) + *,
.has-float-label input:placeholder-shown:not(:focus) + *,
.has-float-label select:placeholder-shown:not(:focus) + *,
.has-float-label textarea:placeholder-shown:not(:focus) + * {
  top: rem-calc(8);
}

.has-float-label input:focus::placeholder {
  color: transparent;
}

.has-float-label {
  select {
    padding-top: rem-calc(8);
    border-color: $form-control-border-color;

    ~ .form-icon {
      margin-top: rem-calc(8);
    }
  }

  .input-checkbox {
    ~ .form-icon {
      &::before {
        position: relative;
        left: rem-calc(3);
        bottom: rem-calc(1);
        font-size: rem-calc(21);
      }
    }
  }
}

select {
  &.is-invalid {
    border-color: $invalid-red;
  }

  &.is-valid {
    border-color: $validate-green;
  }
}

.invalid-feedback {
  &::before {
    content: '\00d7';
    font-size: $fontsize-xmedium;
    line-height: 1;
    vertical-align: middle;
    margin-right: rem-calc(5);
  }
}

.alert-danger {
  color: $invalid-red;
  border: none;
  padding: 0;

  ul {
    margin-top: rem-calc(15);
  }

  a {
    text-decoration: underline;
    color: $invalid-red;
  }
}

.error-summary {
  &__heading {
    &:not(&--custom) {
      display: none;
    }
  }

  &__messages {
    width: 100%;
    padding: rem-calc(0);
    list-style-type: none;
  }

  &__message {
    line-height: rem-calc(22);

    a {
      font-weight: bold;
    }
  }
}

fieldset {
  legend {
    @include h3;
    @include border-after();

    width: 100%;
    margin-left: 0;
    margin-right: 0;
    font-weight: lighter;
  }

  &.inset-legend {
    padding: 0;
    border: none;

    legend {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: map-get($spacers, 5);
    }
  }
}
