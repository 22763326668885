.checkout-billing_ok {
  &__payments-list-title {
    display: block;
    font-weight: lighter;
    letter-spacing: rem-calc(7.2);
    margin-bottom: map-get($spacers, 4);
  }

  &__payment {
    border: none;
  }

  &__payment-selector {
    border: rem-calc(2) solid $border-color;

    &::before,
    &::after {
      display: none;
    }
  }
  &__payment-input:checked + &__payment-selector,
  &__payment-input + &__payment-selector--SAVED_CREDIT_CARD {
    border-width: rem-calc(2);
    border-color: $primary;
    border-bottom: none;
  }
  &__payment-input:checked ~ &__payment-content,
  &__payment-input ~ &__payment-content--SAVED_CREDIT_CARD {
    border: rem-calc(2) solid $primary;
    border-top: none;
  }
  &__payment-input:not(:checked) + &__payment-selector--SAVED_CREDIT_CARD,
  &__payment-input:not(:checked) ~ &__payment-content--SAVED_CREDIT_CARD {
    border-color: $border-color;
  }

  &__payment-indicator {
    @include checkout-selection-indicator;

    display: block;
    border-width: rem-calc(2);
  }
  &__payment-input:checked + &__payment-selector &__payment-indicator {
    @include checkout-selection-indicator-active;
  }
  &__payment-input:checked ~ &__payment-selector--CREDIT_CARD &__payment-selector-image {
    display: inline;
  }

  &__selector-heading {
    @include make-col(10);
    @include make-col-offset(0);
  }

  &__method-name {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: rem-calc(5.6);
  }

  &__payment-form {
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__payment-form--CREDIT_CARD &__credit-card-form {
    &__number-container,
    &__owner-container {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }

    &__expiry-container,
    &__cvv-container {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
    }

    @include media-breakpoint-up(lg) {
      &__number-container {
        padding-right: map-get($spacers, 2);
      }
      &__owner-container {
        padding-left: map-get($spacers, 2);
      }
    }
  }

  &__payment-form--SAVED_CREDIT_CARD {
    padding: map-get($spacers, 4) map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      padding-left: map-get($spacers, 5);
      padding-right: map-get($spacers, 5);
    }

    .choices[data-type*="select-one"] {
      @include change-choices-chevron();

      .choices__inner {
        padding-top: 0;
        padding-bottom: 0;
        background-color: $white;
      }
    }
  }

  &__cvv {
    &__field {
      width: 100%;

      input:not([disabled]).is-valid:not(select) ~ .form-icon {
        display: none;
      }
    }

    &__image {
      display: none;
    }

    &__tooltip-wrapper {
      position: absolute;
      top: 0;
      right: rem-calc(20);
    }
  }

  &__saved-card {
    text-transform: uppercase;
    letter-spacing: rem-calc(4.8);
  }

  &__saved-card-image-wrapper {
    border-color: transparent;
  }

  &__address {
    padding-left: 0;
    padding-right: 0;
  }

  &__address-card {
    &__heading {
      @include h3;
      @include border-after();

      font-weight: lighter;
    }
  }

  &__submit-btn-wrapper {
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(0);

      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      @include make-col(6);
      @include make-col-offset(0);
    }
  }

  &__submit-terms__wrapper {
    color: $primary;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  &__credit-card-number {
    .form-control {
      padding: rem-calc(5 7);
    }

    &::after {
      display: none;
    }
  }

  &__credit-card-number[data-type="mastercard"]::after {
    width: rem-calc(33);
  }
}

.checkout-billing {
  &__selector-heading {
    margin-left: 36px !important;
  }
  &__address {
    padding-left: 0 !important;
    padding-right: 0;
  }
  &__address.address-border-top {
    border-top: 1px solid #cac7c0;
  }
  &__address-card {
    padding-top: 32px;
    padding-bottom: 8px;
    padding-left: 0 !important;
    &__heading {
      @include h3;
      @include border-after();
      margin-bottom: 22px;
      font-weight: lighter;
      &::after {
        display: none;
      }
    }
  }
  &__submit-wrapper {
    padding-top: 48px;
  }
  &__submit-btn-wrapper {
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(0);

      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      @include make-col(6);
      @include make-col-offset(0);
    }
  }

  &__submit-terms__wrapper {
    color: $primary;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }
  &__payments-list {
    margin-bottom: 0;
  }
  &__payment {
    border: unset;
    border-bottom: 1px solid #cac7c0;
    margin-bottom: 0;
    &--GIFT_CERTIFICATE {
      border-top: 1px solid #cac7c0;
      .checkout-billing__payment-selector-image {
        border: unset !important;
        padding: 0 !important;
      }
    }
    &-selector--GIFT_CERTIFICATE::after {
      content: url('../../../../../../static/default/images/icons/plus.svg');
      border: unset;
    }
    &-selector--GIFT_CERTIFICATE::before {
      border: unset;
    }
    &-input[value=GIFT_CERTIFICATE]:checked + &-selector--GIFT_CERTIFICATE::after {
      content: url('../../../../../../static/default/images/icons/minus.svg');
      top: calc(50% - 0.825rem);
    }
    &-input[value=GIFT_CERTIFICATE]:checked + &-selector--GIFT_CERTIFICATE::before {
      background-color: transparent;
    }
    &-selector {
      padding: 22px 0;
      justify-content: space-between;
      &-image:not(.image-width) {
        width: 40px !important;
        height: 28px !important;
        border-radius: 4px;
        border: 1px solid #d7d7d7;
        padding: 2px;
      }
      &::after {
        left: unset;
        border-color: #a9a39b;
      }
      &::before {
        left: unset;
        border: none;
      }
    }
    .checkout-billing__selector-heading {
      flex: 0 0 65%;
      max-width: 65%;
      margin-left: 36px;
    }
    &-content:not(.checkout-billing__payment-content--AdyenComponent) {
      padding-bottom: 24px;
    }
    &-form--GIFT_CARD {
      background-color: #f8f8f8;
      .has-float-label input {
        margin-bottom: 0 !important;
      }
    }
    &-content--GIFT_CERTIFICATE {
      .gift-card__add-gc-button {
        color: #1c3632;
        font-weight: 600;
        letter-spacing: 2.8px;
      }
      .form-group:not(.has-float-label) label,
      .cancel-gc-btn {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      .form-group:not(.has-float-label) label {
        font-weight: 500;
        text-transform: capitalize;
      }
      .form-group.has-float-label input {
        height: 48px;
        padding-left: 16px;
        padding-top: 24px;
        padding-bottom: 8.5px;
      }
      .form-group.has-float-label label {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        background: transparent;
        margin: 0;
        padding: 0;
        left: 16px;
      }
      .has-float-label input::placeholder {
        color: transparent;
      }
      .has-float-label input:placeholder-shown:not(:focus) + * {
        top: 0.8rem;
      }
      .has-float-label label {
        top: 0.3rem;
      }
      .gc-content__description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 1.12px;
      }
      .cancel-gc-btn {
        text-transform: uppercase;
      }
      .btn-secondary.disabled {
        border: 1px solid #cac7c0;
        background: #fff;
        color: #a9a39b;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.8px;
      }
      input {
        border: 1px solid #cac7c0;
        background: #fff;
        color: #1d1d1d;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
      }
      input::placeholder {
        color: #817d78;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase !important;
      }
    }
  }
  &__payment-input:checked + &__payment-selector {
    border: unset;
  }
  &__method-name {
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 1.12px;
    color: #1d1d1d;
  }
  &__payment-selector--DW_APPLE_PAY {
    padding: 23.5px 0 !important;
    .checkout-billing__method-name {
      font-weight: 600;
    }
    .checkout-billing__payment-selector-image {
      width: 40px !important;
      height: 25px !important;
    }
  }
  &__payment--DW_APPLE_PAY {
    .applepay-content {
      padding: 16px 16px 24px 16px;
      @include media-breakpoint-up(lg) {
        width: 400px;
        margin-left: 32px;
        padding-left: 0;
        padding-right: 0;
      }
      .dw-apple-pay-button,
      .dw-apple-pay-button:hover,
      .dw-apple-pay-button:active {
        background-size: 30% 45%;
      }
    }
  }
  &__payment-selector--PayPal {
    .checkout-billing__method-name {
      font-weight: 600;
    }
    .checkout-billing__payment-selector-image {
      padding: 4px 10px !important;
    }
  }

  &__giftcard-list {
    @include media-breakpoint-up(lg) {
      padding-left: 100px !important;
      padding-right: 100px !important;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 160px !important;
      padding-right: 160px !important;
    }
    &__remove {
      color: #1d1d1d;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    .remaining-balance-wrapper {
      color: #1d1d1d;
    }
  }
  &__gift-card-form,
  &__giftcard-list {
    color: #817d78;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 1.12px;
  }
}

.paypal-content {
  @include media-breakpoint-up(lg) {
    padding: 0 map-get($spacers, 3);
  }

  &__description {
    color: $primary;
    padding: 0;
  }
}

.payment-method--has-submethods {
  .checkout-billing__payment-content,
  .checkout-billing__payment-input:checked ~ .checkout-billing__payment-content {
    border: none;
  }
  li.paymentMethod:not(:last-of-type) {
    border-bottom: 1px solid #cac7c0;
  }
  li.paymentMethod {
    .paymentMethod-radio {
      label {
        border: none;
      }
    }
  }
}
