.order-content {
  &__left {

    .summary {
      border-left: none;
      border-right: none;
      padding-bottom: map-get($spacers, 5);

      &__products {
        padding-left: 0;
        padding-right: 0;
      }

      &__products-heading {
        border-top: 1px solid $grey2;
        padding-top: map-get($spacers, 4);
      }

      &__products-container {
        border-bottom: solid rem-calc(1) $grey3;

        .product-card {
          margin-bottom: map-get($spacers, 4);
        }
      }

      &__totals-container {
        padding-bottom: map-get($spacers, 1);
        margin-bottom: map-get($spacers, 3);
      }
    }
  }

  .address-actions {
    .order-details {
      &__summary-item-value {
        @extend .float-none;

        .address-summary {
          clear: both;
          padding-top: map-get($spacers, 3);
        }
      }
    }
  }

  &__right {
    order: 3;

    &--container {
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-up(md) {
        padding-left: map-get($spacers, 4);
        padding-right: map-get($spacers, 4);
      }
    }

    &--order-title-legend {
      display: block;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__footer {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .order-details {
    &__payment-card-type {
      text-transform: uppercase;
    }
    .payment-card-type-name {
      display: none;
    }
  }
}
