@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../../../static/fonts/proximanova-light-webfont.woff2') format('woff2'),
    url('../../../static/fonts/proximanova-light-webfont.woff') format('woff');
  font-weight: 100;
  font-weight: 200;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../../../static/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('../../../static/fonts/proximanova-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../../../static/fonts/proximanova-medium-webfont.woff2') format('woff2'),
    url('../../../static/fonts/proximanova-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../../../static/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
    url('../../../static/fonts/proximanova-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-weight: 700;
  font-weight: 800;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HobbsIconFont';
  font-style: normal;
  font-weight: normal;
  src:
    url('../../../static/fonts/HobbsIconFont.eot?b953vc#iefix') format('embedded-opentype'),
    url('../../../static/fonts/HobbsIconFont.ttf?b953vc') format('truetype'),
    url('../../../static/fonts/HobbsIconFont.woff?b953vc') format('woff'),
    url('../../../static/fonts/HobbsIconFont.svg?b953vc#HobbsIconFont') format('svg');
}
