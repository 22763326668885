// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Converts one or more pixel values into matching rem values.
@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $global-font-size;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }

  @if $count == 1 {
    @return px-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, px-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

// Converts a pixel value to matching rem value.
@function px-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == 'em' {
    $value: strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
  }

  @return $value;
}
