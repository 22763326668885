$plp-side-col-width: 275px !default;
$plp-category-heading-bg: $grey9 !default;
$plp-category-heading-subcategory-icon-size: 85px !default;
$plp-category-heading-subcategory-icon-color: $contrast !default;

@import '../components/paging';
@import '../components/searchControls';
@import '../components/searchRefinements';
@import '../components/productGrid';
@import '../components/quickView';
@import "../components/intelligentReach";

.side-col {
  &--right,
  &--left {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(lg) {
    width: $plp-side-col-width;
    max-width: $plp-side-col-width;

    &--right {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }
  }
}

.search-header {
  &__phrase {
    font-weight: normal;
    text-transform: uppercase;
  }

  &__category {
    text-align: center;
    padding: map-get($spacers, 2) rem-calc(10);

    @include media-breakpoint-up(lg) {
      padding-top: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 4);
      background-color: $plp-category-heading-bg;
    }

    &-description {
      display: none;
      font-weight: lighter;
      font-size: rem-calc(15);
      max-width: rem-calc(850);

      @include media-breakpoint-up(lg) {
        display: block;
        font-weight: normal;
      }
    }
  }

  &__no-results {
    text-align: center;
    background-color: $plp-category-heading-bg;

    &__heading {
      padding-bottom: map-get($spacers, 3);

      &__search-keywords {
        font-weight: bold;
      }
    }

    .site-search {
      margin: auto;
    }

    .search-field.form-control {
      background-color: $white;
    }
  }

  &__category-name {
    font-size: rem-calc(26);
  }

  &__subcategory {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: rem-calc($plp-category-heading-subcategory-icon-size);

    & + & {
      &::before {
        content: '|';
        display: none;

        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    &-name {
      color: $black;

      &:hover {
        color: $grey5;
      }
    }
  }

  &__subcategory-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: $plp-category-heading-subcategory-icon-color;

    .font-icon {
      font-size: rem-calc($plp-category-heading-subcategory-icon-size);
    }
  }
}

.search-results {
  .results-with-image-toggle {
    @include media-breakpoint-down(sm) {
      float: left;
    }
  }
}

.search-content {
  .search-results {
    &__controls {
      padding-top: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 3);
    }
  }
}

.product-recommendations {
  &__heading {
    margin: map-get($spacers, 5) 0;
    text-align: center;
  }

  &__wrapper {
    display: none;
  }
}

.suggestions__products {
  .bestprice {
    display: none;
  }
  a.bestprice-close-btn {
    display: none;
  }
  .price-label {
    display: none;
  }
}

.product-grid-container {
  .price-label {
    display: none;
  }
}

.link {
  .discount {
    display: none;
  }
}

.product-tiles {
  .link {
    .discount {
      border: 1px solid black;
      width: fit-content;
      padding: 5px;
      display: block;
      margin-top: 5px;
      font-weight: bold;
    }
  }
}

.product-tile__image {
  .image-container {
    .carousel-item {
      transition: transform 0.3s ease-in-out;
    }
    .primary-images__controls {
      &.carousel-indicators {
        z-index: 1;
        li {
          width: 5px;
          height: 5px;
          margin-left: 0.25rem;
          margin-right: 0.25rem;
          background-color: white;
          border-radius: 50%;
        }
      }
    }

    .plp-image-container {
      .plp-image.alternate-image.carousel-item {
        @media (min-width: $bp-tablet-med) {
          display: block;
        }
      }
    }
  }

  .carousel-controls {
    &.chevrons {
      display: none;
    }
  }

  @media (min-width: $bp-tablet-med) {
    .plp-image {
      transition: opacity 0.3s ease;
    }

    .primary-image {
      display: block;
      opacity: 1;
    }

    .alternate-image {
      position: absolute;
      top: 0;
      display: block;
      opacity: 0;
    }

    .plp-image-container:hover .primary-image {
      opacity: 0;
    }

    .plp-image-container:hover .alternate-image {
      opacity: 1;
    }
  }
}

.horizontal-filter-container .filters__filter-body {
  @media (min-width: $bp-large) {
    &.grid-row {
      &-2 .values {
        grid-template-rows: repeat(2, auto);
      }
      &-3 .values {
        grid-template-rows: repeat(3, auto);
      }
      &-4 .values {
        grid-template-rows: repeat(4, auto);
      }
      &-5 .values {
        grid-template-rows: repeat(5, auto);
      }
      &-6 .values {
        grid-template-rows: repeat(6, auto);
      }
    }
  }
}
