@import '~app_storefront_base-css/components/spinner';

body > .veil {
  z-index: $loading-indicator-veil-in-body-z-index;
}
.veil {
  z-index: $loading-indicator-veil-z-index;
  .underlay {
    background: transparent;
  }
}

.spinner {
  animation: none;
  background-image: url('../../../../static/default/images/loading_icon.svg');
  background-size: cover;
}

.dot1,
.dot2 {
  display: none;
}
