.style_with_product_recs_container {
  max-width: 100%;
  &.product-main-section .product-detail__product-carousel {
    padding-left: 0;
    @media (min-width: $bp-large) {
      padding: 0;
    }
  }
  &.product-main-section {
    @media (max-width: $bp-large) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
  .rd-style-with-rec-heading {
    margin-bottom: 1rem;
    font-weight: 400;
    @media (min-width: $bp-large) {
      margin-bottom: 1.5rem;
    }
  }
  .product-tile {
    &__body {
      padding-top: 1rem;
      padding-left: 0;
    }
    &__price {
      @extend .rd-style-with-product-price;
    }
    &__name {
      @extend .rd-style-with-product-name;
    }
    &__rating {
      display: none;
    }

    .product-tile__name {
      margin-bottom: 0;
    }
  }

  .glide__arrow {
    top: 0.625rem;
    left: unset;
    cursor: pointer;
    box-shadow: none;
    &.glide__arrow--left {
      margin-right: 1.875rem;
      border: none;
      right: 2rem;
      background-color: transparent;
      background-image: url('../../../../static/default/images/icons/recs-chevron-left.svg');
    }

    &.glide__arrow--right {
      background-color: transparent;
      border: none;
      background-image: url('../../../../static/default/images/icons/recs-chevron-right.svg');
    }

    display: none;

    @media (min-width: $bp-large) {
      display: block;
      padding: 1rem;
    }

    &.glide__arrow--disabled {
      opacity: 0.2;
    }
  }
}
