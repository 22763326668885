.product-detail__ratings {
  padding-right: 0;
  cursor: pointer;

  .product-detail__ratings-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .rating__score {
    display: flex;
    gap: 0.25rem;

    .icon-fa-star {
      @extend .rd-icon-fa-star;
    }
    .icon-fa-star-half-o {
      @extend .rd-icon-fa-star-half-o;
    }
    .icon-fa-star-o {
      @extend .rd-icon-fa-star-o;
    }

    i.icon-fa-star,
    i.icon-fa-star-half-o,
    i.icon-fa-star-o {
      &::before {
        content: '';
      }
    }
  }

  span.rating__count {
    @extend .rd-review-count-text;
    position: relative;
    padding-left: 0.5rem;
    line-height: 0.9rem;
    margin-right: 0;
    margin-left: 0;
    text-decoration: underline;

    .review-bracket-left,
    .review-bracket-right {
      display: none;
    }
  }
}
