@import '../components/storeSearch';

.saved-addresses {
  &__card-wrapper {
    font-size: $fontsize-medium;
    @include media-breakpoint-up(md) {
      min-height: rem-calc(295);
    }

    &--new {
      border: solid rem-calc(1) $secondary;

      .font-icon {
        color: $secondary;
        font-weight: bold;
      }
    }
  }

  &__card-header {
    border-bottom: rem-calc(1) solid $border-color;
  }

  &__card-body {
    line-height: rem-calc(20);
    font-size: $fontsize-medium;
  }

  &__make-default {
    .has-float-label.form-row-checkbox label {
      font-size: $fontsize-medium;
    }

    .form-group {
      margin-bottom: rem-calc(5) !important;
    }
  }

  &__add-address-btn {
    text-decoration: underline;
  }
}
