@import '../flyout';

$saved-addresses-pane-max-width: 90% !default;
$saved-addresses-pane-max-width-desktop: rem-calc(650) !default;

.saved-addresses {
  @include flyout($saved-addresses-pane-max-width, $saved-addresses-pane-max-width-desktop);

  &__address-content {
    border: solid rem-calc(1) $grey3;
  }

  &__addresses-title {
    margin-bottom: 0;
    text-align: center;
  }

  &__addresses-close {
    &__text {
      display: none;
    }
  }

  &__saved-address {
    cursor: pointer;
  }

  &__address-body {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }

  &__address-input-label {
    @include form-radio-label;

    &::before,
    &::after {
      top: rem-calc(25);
      left: rem-calc(20);
    }
  }

  &__address-input:checked + &__address-input-label {
    @include form-checked-radio-label;

    &::before {
      top: rem-calc(30);
    }
  }

  &__new-toggle {
    &[aria-expanded="false"] & {
      &__icon--collapsed {
        display: none;
      }
    }
    &[aria-expanded="true"] & {
      &__icon--expanded {
        display: none;
      }
    }

    &__text {
      text-decoration: underline;
    }
  }

  &__delete {
    &__icon {
      display: none;
    }
  }

  &__new-btn-container .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 75%;
    }
  }
}
