@import '../components/productImages';

$tile-swatch-size: rem-calc(20) !default;
$tile-list-price-color: $grey5 !default;
$tile-discounted-price-color: $lipstick !default;
$tile-selected-swatch-padding: rem-calc(3px);
$tile-action-bg: rgba($grey3, 0.85);
$tile-action-hover-bg: $secondary !default;
$tile-action-hover-color: white !default;
$tile-add-to-cart-overlay-bg: $grey9 !default;
$tile-add-to-cart-overlay-size-hover-bg: $gold !default;
$tile-wishlist-icon-bg: $grey10 !default;
$tile-add-to-cart-overlay-opacity: 0.85 !default;
$tile-add-to-cart-overlay-size-size: rem-calc(30px) !default;
$paging-top-height: rem-calc(120px) !default;
$tile-sticker-outofstock-font-size: rem-calc(22);
$tile-mobile-padding: rem-calc(5) !default;
$tile-mobile-gutter: rem-calc(10) !default;
$tile-desktop-padding: rem-calc(20) !default;
$tile-y-desktop-padding: rem-calc(10) !default;
$tiles-offset-from-filter: $tile-desktop-padding !default;
$tile-color-swatch-border-radius: 50% !default;
$tile-name-height-mobile: auto !default;

@mixin product-swatch() {
  display: inline-block;
  width: $tile-swatch-size + (2 * $tile-selected-swatch-padding);
  height: $tile-swatch-size + (2 * $tile-selected-swatch-padding);
  line-height: ($tile-swatch-size + (2 * $tile-selected-swatch-padding)) / 2;
  padding: $tile-selected-swatch-padding;
  border-radius: $tile-color-swatch-border-radius;
}

@mixin tile-action-button {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

@mixin wishlist-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
  padding: map-get($spacers, 3);
  z-index: $wishlist-icon-z-index;
  cursor: pointer; // Without this clicks don't work on Safari - yes, on touch devices, too

  .wishlist-icon {
    svg {
      width: $fontsize-large;
      height: $fontsize-large;
    }

    &--active {
      display: none;
    }
  }

  &.active,
  &.disabled,
  &[disabled] {
    .wishlist-icon {
      pointer-events: all;

      &--inactive {
        display: none;
      }
      &--active {
        display: inline;
      }
    }
  }
}

// Hacky solution that moves the top paging container, if present, above the sorting options,
// so it can stay in the product grid template and can be updated as part of the response
// rather than having to update it manually
.search-results-container {
  &.paged {
    padding-bottom: $paging-top-height;
  }
  &--paging-top {
    position: relative;
    padding-top: 0;

    .side-col--right {
      @include media-breakpoint-up(lg) {
        max-width: rem-calc(400);
      }
    }

    .product-grid-container {
      position: static;
    }

    .grid-footer {
      .paged-bottom {
        .paging__page--of-last {
          display: none;
        }
        .paging__page--of {
          display: none;
        }

        p.paging__page {
          cursor: pointer;
        }
      }
      &.single-page {
        .paging__pages {
          display: none;
        }
      }
    }

    .paging-container {
      &--bottom {
        position: absolute;
        bottom: rem-calc(50);
        left: 0;
        display: block;

        &.infinite-scroll {
          display: none;
        }
        .paging {
          max-width: none;
          position: relative;

          &__page {
            &--next,
            &--back {
              position: absolute;

              .font-icon {
                display: none;
              }
            }
            &--next {
              right: rem-calc(10);
            }
            &--back {
              left: rem-calc(10);
            }
            &--of,
            &--of-last {
              display: none;
            }
          }
        }
        &.paged {
          display: none;

          &-bottom {
            display: block;
          }
        }
      }
      &.single-page {
        .paging-status {
          display: block;
        }
        .paging__pages {
          display: none;
        }
      }
      &--top {
        position: absolute;
        top: rem-calc(16);
        left: 0;
        padding-top: 0;
        padding-right: rem-calc(10);
        text-align: right;

        @include media-breakpoint-up(lg) {
          max-width: rem-calc(190);
          left: auto;
          right: 0;
          top: rem-calc(50px);
        }

        .paging__page--back,
        .paging__page--next {
          .font-icon {
            display: inline-block;
          }
        }
        .paging__ellipsis {
          .ellipsis {
            display: none;
          }
        }
        .pagination-page {
          display: inline-block;
        }
        .paging__pages {
          display: inline-block;
        }
        .paging-status,
        .paging__page {
          display: none;
          width: auto;
          height: auto;
          line-height: rem-calc(18);
          padding: 0;
          border: none;

          &--current,
          &--next,
          &--back,
          &--of-last,
          &--of {
            display: inline-block;
            background-color: transparent;
            color: $primary;
          }
          &--next,
          &--back {
            position: relative;
            top: rem-calc(-1);

            .paging__page-text {
              display: none;
            }
          }
        }
      }
    }
  }
  .paging-container--bottom {
    display: none;
  }
  .applied-refinements-container {
    ul {
      padding-left: 0;
    }
  }
}

// necessary to equalize space between, before and after tiles
.product-grid {
  @include media-breakpoint-up(lg) {
    padding-left: $tiles-offset-from-filter;
  }

  @include media-breakpoint-up(xl) {
    .product-tile {
      &__add-overlay-sizes {
        @include make-col(4);
        @include make-col-offset(4);
      }
    }
  }

  .plp-sale-category-link {
    margin-inline: auto;
    width: 100%;
    padding: 0 10px;

    @include media-breakpoint-up(md) {
      width: fit-content;
      padding: 0;
    }

    a {
      letter-spacing: 0.014em;
    }
  }
}

.product-tile {
  width: 100%;
  position: relative;

  &__wrapper {
    padding: $tile-mobile-padding 0;

    @include media-breakpoint-down(sm) {
      &--odd.col-6 {
        padding-right: $tile-mobile-padding;
      }

      &--even.col-6 {
        padding-left: $tile-mobile-padding;
      }

      &.col-12 {
        padding: $tile-mobile-padding $tile-mobile-gutter;
      }
    }

    @include media-breakpoint-up(md) {

      // use another class to increase specificity
      .product-grid & {
        padding: $tile-y-desktop-padding $tile-desktop-padding $tile-y-desktop-padding 0;
      }
    }
  }

  &__body {
    padding-top: map-get($spacers, 1);
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      .product-tile__action {
        bottom: 0;
      }
    }
  }

  .product-tile__outofstock--sticker {
    height: 100%;
    bottom: 0;
    background: $grey3;
    font-size: $tile-sticker-outofstock-font-size;
    opacity: 0.6;
    z-index: $tile-sticker-outofstock-z-index;
    display: none;
  }
  &.outofstock {
    .product-tile__outofstock--sticker {
      display: flex;
    }
    .product-tile__action {
      display: none;
    }
  }

  &__image {
    @include product-image-container();

    position: relative;
    overflow: hidden;
  }

  &__sticker {
    &--image {
      position: absolute;
      bottom: rem-calc(50);

      span {
        padding: rem-calc(5) rem-calc(10);
      }
    }

    &--feature {
      &--empty {
        display: none;
      }
    }
  }

  &__add-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba($tile-add-to-cart-overlay-bg, $tile-add-to-cart-overlay-opacity);
    align-items: center;
  }

  &__add-overlay-header {
    position: absolute;
    top: 0;
    left: 0;
    padding: map-get($spacers, 3);
  }

  &__wishlist--link {
    @include wishlist-icon;

    padding: map-get($spacers, 3);
  }

  &__add-overlay-sizes-title {
    flex-basis: 100%;
    text-align: center;
    font-weight: bold;
  }

  &__add-overlay-size-wrapper {
    flex-grow: 1;
    flex-basis: percentage(1/3);
  }
  &__add-overlay-size-wrapper--only-child & {
    &__add-overlay-size--one-size {
      width: auto;
      height: auto;
      padding: rem-calc(5);
    }
  }

  &__add-overlay-size {
    width: $tile-add-to-cart-overlay-size-size;
    height: $tile-add-to-cart-overlay-size-size;
    line-height: $tile-add-to-cart-overlay-size-size;
    border-radius: 50%;

    &--disabled {
      text-decoration: line-through;
    }

    &:not(&--disabled) {
      cursor: pointer;

      &:hover,
      &:active {
        color: white;
        background-color: $tile-add-to-cart-overlay-size-hover-bg;
      }
    }
  }

  &__add-overlay-size-btn {
    .add-to-cart,
    .continuity-flyout {
      @include tile-action-button;

      display: none;
      background: $grey2;
      color: $black;
      border-color: $grey2;
      font-weight: bold;
      height: rem-calc(38);
      font-size: rem-calc(13);
      line-height: rem-calc(26);
      &:hover {
        border-color: $grey2;
        background: $grey2;
        color: $black;
      }
    }

    .add-to-wish-list {
      @include wishlist-icon;

      display: none;
      background: none;
      border: none;
      color: inherit;
      height: auto;
      padding: map-get($spacers, 3);

      &:focus {
        border: none;
        box-shadow: none;
      }

      &:hover {
        background: none;
        color: inherit;
      }

      &:active {
        background: none !important;
        color: inherit;
      }

      &.disabled,
      &:disabled {
        color: $secondary;
        background: none;
      }
    }

    .cart-and-ipay {
      .add-to-wish-list {
        position: absolute;
        line-height: inherit;
        font-size: 0;
        font-weight: 100;
        z-index: $product-tile-cart-and-ipay-add-to-wish-list-z-index;
        opacity: 1;

        .wishlist-icon--inactive {
          background-color: $tile-wishlist-icon-bg;
        }
      }
    }
  }

  &__add-overlay-size-input:checked + &__add-overlay-size {
    color: $white;
    background-color: $secondary;
  }
  &__add-overlay-size-input:checked + &__add-overlay-size + &__add-overlay-size-btn {
    .add-to-cart,
    .continuity-flyout {
      display: block;
      background: $secondary;
      border-color: $secondary;
      color: $white;
      z-index: $tile-active-quick-add-to-bag-btn-z-index;

      .desktop-message {
        display: none;
      }

      @include media-breakpoint-up(sm) {
        .mobile-message {
          display: none;
        }
        .desktop-message {
          display: block;
        }
      }
    }
    .continuity-flyout {
      z-index: 5;
    }
    .add-to-wish-list {
      display: block;
    }
  }

  &__action {
    @include tile-action-button;

    background-color: $tile-action-bg;
    transition-property: background-color, opacity, bottom;
    color: $black;
    height: rem-calc(38);
    font-size: rem-calc(13);
    line-height: rem-calc(26);

    &-add {
      font-weight: bold;
      margin: 0;
    }

    &__arrow {
      display: none;
    }

    &:hover,
    &:active {
      opacity: 1;
      color: $tile-action-hover-color;
      background-color: $tile-action-hover-bg;
    }

    @include media-breakpoint-up(lg) {
      bottom: -$button-height;
    }
  }

  &__name {
    margin-bottom: map-get($spacers, 1);

    @include media-breakpoint-down(lg) {
      height: $tile-name-height-mobile;
    }
    .link {
      color: $primary;
    }
  }

  &__price {
    .strike-through {
      color: $tile-list-price-color;
      text-decoration: line-through;
    }

    .has-strike-through {
      font-weight: bold;
      color: $tile-discounted-price-color;
    }

    .bestprice {
      display: none;
    }

    .price-label {
      display: none;
    }
  }

  &__rating {
    display: inline-block;
    font-size: $fontsize-medium;

    .font-icon {
      font-size: $fontsize-medium;
      &.icon-fa-star-o {
        &.rating-star-empty {
          color: $grey2;
        }
      }
    }
  }

  &__swatch {
    @include product-swatch();

    &--current {
      border: rem-calc(1) solid $dark;
      padding: $tile-selected-swatch-padding - rem-calc($border-width);
    }
  }

  &__swatches-pdp-link {
    text-decoration: underline;
  }

  &__swatch-icon {
    border-radius: $tile-color-swatch-border-radius;
    width: $tile-swatch-size;
    height: $tile-swatch-size;
  }

  .product-tile__add-overlay {
    &-close {
      color: $black;
    }
  }

  &__promotions {
    color: $tile-discounted-price-color;
  }

  &__slot {
    // Content assets in grid tile slots
    .html-slot-container {
      height: 100%;
    }
  }
}
.intelligent-reach-section {
  .rating {
    color: $secondary;
    .rating__count {
      color: $secondary;
    }
  }
}

.category-search-homewares {
  .product-tile {
    &__add-overlay-sizes {
      display: block !important;

      .product-tile__add-overlay-size {
        width: max-content;
        border-radius: 0;
        padding: 0 0.5rem;
      }

      .product-tile__add-overlay-size-input:checked + .product-tile__add-overlay-size {
        background-color: $dark-background !important;
      }
    }

    &__add-overlay-size {
      &:not(.product-tile__add-overlay-size--disabled) {
        &:hover,
        &:active {
          background-color: $dark-background !important;
        }
      }
    }
  }
}
