@import "~app_storefront_base-css/utilities/responsiveUtils";

@each $size in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($size) {
    $infix: breakpoint-infix($size, $grid-breakpoints);

    // Responsive text transform classes
    .text#{$infix}-lowercase { text-transform: lowercase !important; }
    .text#{$infix}-uppercase { text-transform: uppercase !important; }
    .text#{$infix}-capitalize { text-transform: capitalize !important; }
    .text#{$infix}-transform-none { text-transform: none !important; }

    // Responsive font weight classes
    .font-weight#{$infix}-bold { font-weight: bold !important; }
    .font-weight#{$infix}-normal { font-weight: normal !important; }
    .font-weight#{$infix}-light { font-weight: light !important; }

    // Responsive border classes
    .border#{$infix} { border: $border-width solid $border-color !important; }
    .border#{$infix}-top { border-top: $border-width solid $border-color !important; }
    .border#{$infix}-right { border-right: $border-width solid $border-color !important; }
    .border#{$infix}-bottom { border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-left { border-left: $border-width solid $border-color !important; }

    .border#{$infix}-top-0 { border-top: 0 !important; }
    .border#{$infix}-right-0 { border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0 { border-left: 0 !important; }

    .border#{$infix}-0 {
      border-top: 0 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }

    @each $color, $value in $theme-colors {
      .border#{$infix}-#{$color} {
        border-color: $value !important;
      }
    }

    // Responsive positioning classes
    .position#{$infix}-static { position: static !important; }
    .position#{$infix}-relative { position: relative !important; }
    .position#{$infix}-absolute { position: absolute !important; }
    .position#{$infix}-fixed { position: fixed !important; }
    .position#{$infix}-sticky { position: sticky !important; }

    // Responsive sizing classes
    @each $prop, $abbrev in (width: w, height: h, max-width: mw, max-height: mh) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}
