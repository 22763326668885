@import "../utils/mixins";

.login {
  @include media-breakpoint-up(md) {
    &__tab-link,
    &__form {
      margin: 0 auto;
      max-width: rem-calc(410);
    }

    &__tab-item--login,
    &__tab-pane--login {
      border-right: none;
    }
    &__tab-pane--login {
      padding-right: rem-calc(10);
    }
    &__tab-pane--pre-register {
      padding-left: rem-calc(10);
    }
  }

  &__tab-items,
  &__tab-panes {
    @include media-breakpoint-up(xl) {
      @include make-col(8);
    }
  }

  &__tab-link {
    padding-top: 0;
    padding-bottom: 0;
    letter-spacing: rem-calc(4);

    &.nav-link {
      border: none;
    }

    &__text {
      display: inline-block;
      width: 90%;
      padding-bottom: map-get($spacers, 2);
      border-bottom: 3px solid transparent;
    }

    &.active & {
      &__text {
        border-bottom-color: $primary;
      }
    }

    @include media-breakpoint-up(md) {
      @include border-after();

      padding: 0;
      text-align: left;
      text-transform: uppercase;
      font-size: rem-calc(36);
      letter-spacing: rem-calc(14);
      margin-bottom: map-get($spacers, 4);

      &::after {
        bottom: rem-calc(11);
      }

      &__text {
        display: inline;
        border-bottom: none;
      }
    }
  }

  &__form {
    &__heading {
      font-size: $fontsize-medium;
      text-align: left;
      font-weight: 300;

      @include media-breakpoint-up(md) {
        margin-bottom: map-get($spacers, 4);
      }
    }

    .has-float-label.form-row-checkbox {
      label {
        font-size: rem-calc(14);

        &::before {
          width: rem-calc(17);
          height: rem-calc(17);
        }
      }

      .input-checkbox:checked ~ .form-icon {
        width: rem-calc(15);
        height: rem-calc(15);

        &::before {
          font-size: rem-calc(18);
          left: rem-calc(2);
          bottom: rem-calc(2);
        }
      }
    }

    .btn {
      @include btn-secondary();
    }
  }

  &__pass-requirements {
    font-style: italic;
    font-size: $fontsize-small;
    letter-spacing: rem-calc(1);
  }

  &__forgot-password {
    font-weight: bold;
    letter-spacing: rem-calc(1);
  }

  &__register-security {
    display: none;
  }
}

.registration {
  @include login-registration-form(&);

  &__form {
    .form-title {
      @include h1;
      @include border-after();

      text-align: left;
      font-weight: lighter;
    }

    .required-text {
      position: relative;
    }
  }

  &__intro {
    text-align: left;
  }

  &__disclaimer {
    font-size: rem-calc($fontsize-medium);

    &--large {
      font-size: rem-calc($fontsize-large);
    }

    &--intro {
      order: 1;
    }
  }

  &__terms-info {
    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  &__password {
    order: -1;
  }

  .newsletter-form__optin__wrapper {
    .phone-fields {
      margin-top: map-get($spacers, 4);
    }

    .address-fields {
      margin: 0 map-get($spacers, 2);
    }
  }

  .btn {
    @include btn-secondary();
  }
}
