.product-reviews.productreviews-section {
  height: auto !important;
}

.reviewSection {
  @media (min-width: $bp-desktop-min) {
    padding: 0 4.5rem;
  }

  @media (max-width: $bp-med-max) {
    padding: 0 1.5rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 1rem;
  }

  .product-detail__rating {
    &__score__count {
      display: none;
    }
    &__heading {
      padding-top: 0;
      text-align: left;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.25rem;
    }
    &__status {
      &-row {
        justify-content: space-between;
      }
      &--sort {
        margin-bottom: 0;
        select {
          border: 1px solid $light-stone;
          letter-spacing: 0.15rem;
          font-size: 0.75rem;
          padding: 0 3rem 0 1rem;
        }
        .form-icon {
          &::before {
            content: none;
          }
          @extend .rd-down-arrow-small;
          right: 1rem;
          margin-top: 0.625rem;
        }
      }
    }
    &__review {
      display: none;
      border-top: none;
      &.show {
        display: block;
        padding: 1.5rem 0;
        border-bottom: 1px solid $light-stone; // stylelint-disable-line
      }
      &-message {
        margin: 1.25rem 0;
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      &-detail {
        &--size {
          margin-right: 4.188rem;
        }
      }
      &-score {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }
  }
  .review-star-bottom {
    &::before,
    .icon-fa-star-o::before,
    .icon-fa-star::before,
    .icon-fa-star-half-o::before {
      content: none;
    }
  }
  .pagination-container {
    .pagination-btn {
      cursor: pointer;
      display: inline;
      &.disabled {
        opacity: 0.4;
      }
      .font-icon::before {
        font-size: 1rem;
      }
    }
    color: $light-black;
    margin: 24px 0;
    text-align: center;
  }
}
