.ATBRecommendation_container {
  $tab-width: 767px;
  $tab-height: 900px;
  .modal {
    padding-right: 0 !important;
    &-dialog {
      max-width: 966px;
      @include media-breakpoint-down(md) {
        max-width: 720px;
      }
      @include media-breakpoint-down(xs) {
        width: 100% !important;
        margin: 4rem auto;
        margin-bottom: 0;
      }
    }
    &-content {
      padding: 0.5rem;
      border: none;
      text-align: left;

      @include media-breakpoint-down(xs) {
        border-radius: 1rem 1rem 0 0;
        padding: 0;
        position: absolute;
        bottom: 0;
        @media (max-height: $tab-height) {
          bottom: -12rem;
        }
      }

      .modal-header {
        h6 a {
          text-decoration: underline;
          text-underline-position: under;
        }
        @include media-breakpoint-down(xs) {
          padding: 1rem 0.5rem;
          padding-bottom: 1rem !important;
        }
      }

      .ATBRecommendations-content {
        @media (max-width: $tab-width) {
          .addToBagOverlay-recommendations-LowASP {
            display: none;
          }
        }
        @media (min-width: $bp-medium) {
          .addToBagOverlay-recommendations-LowASP-Mobile {
            display: none;
          }
        }

        .product-card__wrapper {
          padding-right: 0;

          .product-card {
            border-top: 1px solid $grey2;
            border-bottom: 1px solid $grey2;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;

            @include media-breakpoint-down(xs) {
              padding: 1rem 0;
              border-bottom: none;
            }

            &__content {
              margin-left: 1rem !important;

              .product-brand-name {
                margin-top: 1rem;
              }

              .product-card__name {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
              }

              .product-card__attribute {
                display: inline-block;
                margin-bottom: 0.5rem;
              }

              .product-card__quantity {
                display: inline-block;
                margin-bottom: 0.5rem;
              }

              .product-card__quantity.inline-quantity {
                display: none;
              }
            }
          }
        }
        @include media-breakpoint-down(xs) {
          padding: 0 0.5rem;
        }
      }

      .modal-footer {
        .btn {
          height: 3rem;
        }

        @include media-breakpoint-down(xs) {
          position: sticky;
          bottom: 0;
          background-color: $white;
          padding: 1rem 0.5rem;
          padding-bottom: 0.5rem;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.55);
          z-index: 3;
        }
      }
    }
  }
  .modal.modalWithoutCarousel {
    .modal-content {
      @media (max-height: $tab-height) {
        bottom: 0;
      }
    }
  }
  .addToBagOverlay-recommendation-container {
    padding-top: 0.5rem;

    .addToBagOverlay-product-recommendations {
      .product-tile {
        &__image {
          .image-container {
            a.link {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .addToBagOverlay-recommendation-container-lowASP {
    padding: 1.5rem 0.5rem;
    background-color: #f5f2e8;

    @include media-breakpoint-down(xs) {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }

    .addToBagOverlay-product-recommendations-lowASP {
      @media (min-width: $bp-medium) {
        .product-detail_tile-row__item_container {
          display: flex;
          column-gap: 1rem;
          .product-detail__tile-row__item {
            width: calc(50% - 0.5rem);
            float: left;
          }
        }
      }
      .product-tile {
        display: flex;
        align-items: center;
        background-color: $white;
        padding: 0.5rem;
        margin-bottom: 1.5rem !important;

        &__image {
          height: 100%;
          width: 50%;
          padding-bottom: unset;

          .image-container {
            a.link {
              display: inline-block;
            }
          }
        }

        &__body {
          width: 50%;
          padding-left: 1rem;
          background-color: $white;
          text-align: left;
        }

        &__rating {
          display: none;
        }
      }
    }
    .glide__bullets {
      bottom: 0;

      .glide__bullet {
        border: 1px solid #c5b392;
        background-color: #c5b392;

        &--active {
          background-color: $white;
        }
      }
    }
  }
  .glide__track {
    display: grid;
  }
  .glide__arrows {
    .glide__arrow {
      top: 50%;
      transform: translateY(-50%);
      color: $black;
      background-color: rgba(255, 255, 255, 0.5);
      border-width: 1px;
      border-radius: 0;
      border: 1px solid $white;
      background-repeat: no-repeat;
      padding: 20px;
      background-position: center;
      @media (min-width: $bp-medium) {
        display: block;
      }
      &:hover {
        color: $black;
        background-color: $grey1;
        border: 1px solid $grey2;
        opacity: 1;
      }
      &:active {
        background-color: $grey3;
        border: 1px solid $black;
        color: $white;
      }
    }
    .glide__arrow--left {
      left: 0;
      background-image: url('../../../../static/default/images/icons/prev.svg');
      &:hover {
        opacity: 1;
      }
    }
    .glide__arrow--right {
      right: 0;
      background-image: url('../../../../static/default/images/icons/next.svg');
      &:hover {
        opacity: 1;
      }
    }
  }
}
