@import '../../flyout';
@import '../../creditCard';
@import 'choices.js/public/assets/styles/choices';

$checkout-credit-card-form-bg: $grey3 !default;

.checkout-billing {
  &__payments-list-title {
    display: none;
  }

  &__payment {
    border: 1px solid $border-color;
    margin-bottom: map-get($spacers, 3);
  }

  &__payment-selector {
    @include checkout-option;
    padding: map-get($spacers, 3);
  }

  &__selector-heading {
    @include make-col(9);
    @include make-col-offset(1);
  }

  &__payment-indicator {
    display: none;
  }

  &__payment-input:checked + &__payment-selector {
    @include checkout-selected-option;
  }

  &__payment-form {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      padding-left: map-get($spacers, 5);
      padding-right: map-get($spacers, 5);
    }

    .cvv-container {
      @include make-col-ready();
      @include make-col(6);
    }
  }

  &__payment-form--SAVED_CREDIT_CARD,
  &__payment-selector--SAVED_CREDIT_CARD,
  &__payment-input:checked ~ &__payment-selector--CREDIT_CARD,
  &__payment-input:checked ~ &__payment-content &__payment-form--CREDIT_CARD {
    background-color: $checkout-credit-card-form-bg;
  }

  &__payment-input:checked ~ &__payment-selector--CREDIT_CARD &__payment-selector-image {
    display: none;
  }

  &__credit-card-number {
    @include credit-card-number-wrapper;

    .form-control {
      padding: rem-calc(15 40 5 7);

      &[type="hidden"].is-valid {
        ~ .invalid-feedback {
          display: none;
        }
      }
    }
  }

  &__cvv {
    @include credit-card-cvv-wrapper;
  }

  &__saved-card-image {
    width: 100%;
    height: auto;
  }

  &__saved-card-image-wrapper {
    display: inline-block;
    max-width: rem-calc(50);
    border: 1px solid $border-color;
  }

  &__address {
    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 5);
      padding-right: map-get($spacers, 5);
    }
  }

  &__submit-btn-wrapper {
    @include media-breakpoint-up(md) {
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col-offset(2);
    }
  }

  &__submit-btn {
    white-space: unset;
  }

  &__submit-terms__wrapper {
    color: $grey1;
    text-align: center;
  }

  &__submit-terms__link {
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }

  &__credit-card-form {
    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 4);
      padding-right: map-get($spacers, 4);
    }

    &__expiry-container,
    &__cvv-container {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
  }
}

.paypal-content {
  padding: 0 map-get($spacers, 3);

  @include media-breakpoint-up(lg) {
    padding: 0 map-get($spacers, 5);
  }

  &__description {
    color: $grey1;
    padding: 0 map-get($spacers, 4);
  }
}

.payerauth {
  text-align: center;
}

.enter-new-details {
  cursor: pointer;
}

#editGiftMessage {
  border: 1px solid $grey2;
  font-size: 0.875rem;
  line-height: 1.1rem;
  resize: none;
  height: 3rem;
  padding: 0.8rem;
  letter-spacing: normal;

  @include media-breakpoint-down(md) {
    margin-left: 1rem;
  }
}
