@import "../utils/mixins";
@import "../components/checkout/progressIndicator";
@import "../components/checkout/summary";
@import "../components/checkout/checkoutLogin";
@import "../components/checkout/savedAddresses";
@import "../components/checkout/pages/shipping";
@import "../components/checkout/pages/billing";
@import "../components/checkout/pages/summary";
@import "../components/adyenCss";

$tab-width-max:768px;
$desktop-width-min:1280px;

.checkout {
  &__step {
    padding-left: 0;
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }
  @media (min-width: $desktop-width-min) {
    &__content-wrapper {
      padding: 0 rem-calc(32px) 0 0 !important;
    }
  }
}

.checkout-header {
  .navbar-header {
    text-align: center;
    border-bottom: 1px solid $grey3;
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 2);

    @include media-breakpoint-up(md) {
      border-bottom: none;
      padding-bottom: 0;
    }

    &__logo-link {
      position: relative;
      z-index: 1; // Makes it clickable over the elements on its sides
    }
  }

  .logo {
    max-width: rem-calc(96);

    @include media-breakpoint-up(md) {
      max-width: none;
    }
  }

  .header-secure-checkout,
  .header-need-help {
    @include make-col(12);

    @include media-breakpoint-up(md) {
      // .col-md
      flex: 0 0 auto;
      max-width: none;
    }

    font-size: $fontsize-medium;
    text-align: center;

    .font-icon {
      font-size: $fontsize-xlarge;
    }
  }
  .header-need-help {
    width: auto;
    margin-top: map-get($spacers, 2);
    right: 0;

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  .header-continue {
    width: auto;
  }

  @include media-breakpoint-up(md) {
    .checkout-header {
      position: relative;
    }

    .header-continue,
    .header-need-help {
      position: absolute;
    }
    .header-continue {
      top: rem-calc(35);
    }
    .header-need-help {
      top: rem-calc(25);
    }
  }
}

#checkout-footer {
  border-color: $primary;
  padding-top: map-get($spacers, 5);

  &__content {
    @include make-container();
    @include make-container-max-widths();

    max-width: rem-calc(1280);
    margin-left: auto;
    margin-right: auto;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  li {
    text-align: center;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      width: auto;
      display: inline-block;
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }
  }
}

.checkout-step {
  padding-left: 1rem;
  padding-right: 1rem;
  &__heading {
    text-align: left;
  }

  &__title {
    @include h2;
    font-weight: normal;
    letter-spacing: 0.3rem;

    &::after {
      border-color: $grey8;
    }
  }

  &__content {
    .address-summary {
      &__name {
        font-weight: bold;
        margin-bottom: map-get($spacers, 2);
      }
    }
    .checkout-shipping__address {
      .address-fields {
        .address-lookup {
          &__title {
            font-size: 1rem;
            letter-spacing: 0.2rem;
            font-weight: 800;
            &::after {
              border-top: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $tab-width-max) {
  .checkout {
    &-step {
      &__title {
        &::after {
          display: none;
        }
      }
      &__content {
        .checkout-billing__payments {
          &-list-title {
            display: none;
          }
        }
      }
    }
  }
}
