@import "../components/dateOfBirth";

.edit-profile {
  max-width: rem-calc(950);
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  .form-group {
    padding: 0 !important;

    @include media-breakpoint-up(lg) {
      @include make-col-ready();
      @include make-col(6);
      &:nth-child(odd) {
        padding-right: map-get($spacers, 4) !important;
      }
      &--select:nth-child(odd) {
        padding-right: 0 !important;
      }
    }
  }

  .required-field,
  .birthday-disclaimer {
    font-weight: normal;
    letter-spacing: rem-calc(1);
    font-size: $fontsize-medium;
  }

  .change-password-disclaimer {
    font-size: rem-calc(12);
    font-family: $font-family-sans-serif;
  }

  .edit-profile-form {
    .password-icon {
      @include media-breakpoint-up(lg) {
        right: rem-calc(34);
      }
    }

    .form-group--password {
      @include media-breakpoint-up(lg) {
        padding-right: map-get($spacers, 4) !important;
      }
    }
  }

  .change-password-form {
    .change-password-disclaimer {
      font-style: italic;
    }

    @include media-breakpoint-up(lg) {
      .form-group:nth-child(even) {
        padding-right: map-get($spacers, 4) !important;
      }
    }
  }

  .edit-profile-form,
  .change-password-form {
    input,
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="time"],
    input[type="number"],
    input[type="date"],
    input[type="password"],
    select,
    textarea {
      &.is-valid:not(select) {
        @include media-breakpoint-up(lg) {
          ~ .form-icon {
            right: rem-calc(40);
          }
        }
      }

      &.is-invalid:not(select) {
        ~ .invalid-feedback {
          ~ .form-icon {
            @include media-breakpoint-up(lg) {
              right: rem-calc(40);
            }
          }
        }
      }
    }

    .btn {
      @include media-breakpoint-up(lg) {
        width: calc(50% - #{map-get($spacers, 4)});
      }
    }
  }

  @include dob-container();
  .birthday-inputs {
    padding-top: 0 !important;
    width: 100%;
    max-width: rem-calc(400);
    .form-group {
      padding: 0 !important;
    }
    .input-container {
      &.dob-asset {
        @include make-col(12);
      }
    }
  }
}

.birthday-inputs .input-container {
  margin-right: 0;

  input[type="text"] {
    &.is-valid:not(select),
    &.is-invalid:not(select) {

      @include media-breakpoint-up(lg) {
        ~ .form-icon {
          right: rem-calc(10);
        }
      }
    }
  }
}

.mobile-number-dial-code {
  width: 50%;
}

.phone-fields {
  @include media-breakpoint-down(sm) {
    max-width: unset;
  }

  @include media-breakpoint-up(sm) {
    max-width: 100% !important;
  }
}

.down-arrow-profile-form {
  left: 8rem;
  bottom: 0.3rem;

  @include media-breakpoint-down(sm) {
    left: 6.7rem;
  }
}

.mobile-number-profile-form {
  flex: 0 67% !important;

  @include media-breakpoint-down(sm) {
    width: max-content !important;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 38% !important;
  }
  @include media-breakpoint-up(md) {
    flex: 0 27% !important;
  }
  @include media-breakpoint-up(xl) {
    flex: 0 31% !important;
  }
}

.country-dial-code-profile-form {
  border: 1px solid $primary;

  @include media-breakpoint-down(sm) {
    width: 130px !important;
  }
  @include media-breakpoint-up(sm) {
    width: 20% !important;
  }
  @include media-breakpoint-up(xl) {
    width: 16% !important;
  }
}

.save-payment-form-wrapper {
  .addressFields_mobile_dialCode {
    .country-dial-code-address-form {
      width: 50% !important;
      @include media-breakpoint-up(sm) {
        width: 20% !important;
      }
      @include media-breakpoint-up(md) {
        width: 17% !important;
      }
      @include media-breakpoint-up(xl) {
        width: 35% !important;
      }
    }

    .mobile-number-address-form {
      @include media-breakpoint-up(sm) {
        width: 80% !important;
      }
      @include media-breakpoint-up(md) {
        width: 83% !important;
      }
      @include media-breakpoint-up(xl) {
        width: 65% !important;
      }
    }
  }
}

.registration {
  .country-dial-code-newsletter {
    margin: 1.5rem 0 1.5rem 1rem;
    border: 1px solid $primary;
  }
}
