@import '../components/hubbox';
@import '../../intlTelInput';

$mobile-m-width: 375px;
$mobile-l-width: 425px;

.checkout-shipping {
  &__methods {
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);
  }

  &__container {
    @include media-breakpoint-up(md) {
      max-width: 50%;
    }

    margin: rem-calc(24) auto;
    padding: 0 rem-calc(20);
  }

  &__type {
    &--only,
    &--odd,
    &--even {
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-up(md) {
      &--odd {
        padding-right: map-get($spacers, 3);
      }

      &--even {
        padding-left: map-get($spacers, 3);
      }
    }
  }

  &__summary-content {
    border: rem-calc(1) $grey3 solid;
  }

  &__summary-title {
    padding: 0 rem-calc(24);
  }

  &__type-link {
    position: relative;
    border: 1px solid $border-color;
    text-align: center;
    padding: map-get($spacers, 3) map-get($spacers, 3) map-get($spacers, 4) map-get($spacers, 3);
  }

  &__type-indicator {
    @include checkout-selection-indicator;

    @include media-breakpoint-up(md) {
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -($checkout-selection-indicator-size / 2);
    }
  }

  &__type-icon {
    display: block;
    font-size: rem-calc(28);
    margin-bottom: map-get($spacers, 2);
  }

  &__type.active & {
    &__type-link {
      border-color: $secondary;
    }

    &__type-indicator {
      @include checkout-selection-indicator-active;
    }
  }

  &__address {
    padding: map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 5);
      padding-right: map-get($spacers, 5);
    }
  }

  &__method {
    margin-bottom: map-get($spacers, 3);
  }

  &__method-content {
    @include checkout-option;

    border: 1px solid $border-color;
    padding: map-get($spacers, 3);
  }

  &__method-indicator,
  &__gift-selector,
  &__gift-product-selector,
  &__gift-receipt-selector,
  &__gift-message-selector {
    @include checkout-selection-indicator;

    display: none;
  }

  &__method-input:checked + &__method-content {
    @include checkout-selected-option;

    border: rem-calc(1) solid $secondary;
  }

  &__method-input:checked + &__method-content &__method-indicator {
    @include checkout-selection-indicator-active;
  }

  &__method-body {
    @include make-col-offset(1);
  }

  &__method-name,
  &__method-price {
    font-size: $fontsize-xlarge;
  }

  &__method-name {
    margin-bottom: 0;
  }

  &__method-arrival-time {
    margin-top: map-get($spacers, 2);
  }

  &__gift-toggle {
    font-size: $fontsize-large;
    padding-left: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 2);

    &[aria-expanded="true"] &-icon--collapsed {
      display: none;
    }
    &[aria-expanded="false"] &-icon--expanded {
      display: none;
    }
  }
  &__gift-toggle-text {
    text-decoration: underline;
    margin-left: map-get($spacers, 4);
  }

  &__gift-icon {
    display: none;
  }

  &__gift-label,
  &__gift-message-label,
  &__gift-receipt-label {
    position: relative;
    cursor: pointer;
    padding: map-get($spacers, 3);

    &::before {
      @include form-checkbox-control;

      left: 2rem;
      top: calc(50% - #{$custom-control-indicator-size / 2});
    }
  }

  &__gift-product-label {
    position: relative;
    cursor: pointer;
    padding: map-get($spacers, 3);

    &::before {
      @include form-checkbox-control;
      display: none;
    }
  }

  &__gift-message-label,
  &__gift-receipt-label {
    margin-left: 5 rem;

    &::before {
      top: 2.33rem;
    }
  }

  &__gift-body {
    @include make-col-offset(1);

    margin-bottom: auto;
    padding: 1rem;
    margin-left: 3rem;

    @media only screen and (max-width: $mobile-m-width) {
      max-width: 250px;
    }
  }

  &__gift-input:checked + &__gift-content &__gift-label,
  &__gift-message-input:checked + &__gift-message-content &__gift-message-label,
  &__gift-product-input:checked + &__gift-product-content &__gift-product-label,
  &__gift-receipt-input:checked + &__gift-receipt-content &__gift-receipt-label {
    &::before {
      @include fa-icon-override('check', $fa-var-check);
    }
  }

  &__gift-input:checked + div label {
    border: none !important;
  }

  &__gift-title,
  &__gift-product-title,
  &__gift-message-title,
  &__gift-receipt-title {
    margin-bottom: 0;
    font-size: $fontsize-xlarge;
  }

  &__gift-product-title {
    margin-left: 1.5rem;
    width: auto;

    @media only screen and (max-width: $mobile-m-width) {
      width: min-content;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 1.5rem !important;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0 !important;
    }
    @media only screen and (max-width: $mobile-m-width) {
      margin-top: 0 !important;
    }
  }

  &__gift-product-description {
    margin-left: 1.5rem;
    margin-top: 0.5rem;

    @media only screen and (max-width: $mobile-m-width) {
      width: min-content;
    }
  }

  &__gift-message {
    width: 90%;
    padding-left: 5rem;
    padding-bottom: 2rem;

    @include media-breakpoint-up(sm) {
      width: 60%;
      padding: 2rem 0;
    }
  }

  &__symbols-left-text {
    color: $grey2;
    padding-bottom: 1rem;
  }

  &__gift-content {
    margin-bottom: 1rem;

    &__gift-label {
      padding: 0;
    }
  }

  &__gift-receipt-description,
  &__gift-product-description {
    color: $grey2;
  }

  &__gift-message-area {
    height: 6rem;

    &:hover {
      .gift-message-tooltip {
        display: block;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  &__gift-product-details {
    height: auto;
    transform: translateY(25%);

    @include media-breakpoint-down(sm) {
      transform: none;
    }
  }
}

.packaging-shipping {
  &__main {
    margin-top: 0%;
  }
  &__p {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 20px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
  }
}

.checkout-step__shipping-form-submit-container .btn {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-up(xl) {
    width: 50%;
  }
}

#giftMessage {
  border: 2px solid $grey2;
  resize: none;
  height: 6rem;
  padding: 0.67rem;
}

#giftMessage:focus::placeholder {
  color: transparent;
}

#giftMessage:not(:focus)::placeholder {
  color: $grey2;
}

.gift-message-tooltip {
  display: none;
  width: 16rem;
  background-color: rgba($grey9, 0.8);
  color: $grey1;
  text-align: left;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  position: relative;
  bottom: 8rem;
  z-index: 1;

  @include media-breakpoint-down(xl) {
    left: 15rem;
  }

  @include media-breakpoint-up(xl) {
    left: 21rem;
  }
}

.gift-receipt-message-orderSummary-container {
  padding-bottom: 1rem;
  font-weight: bold;
}

.gift-message-saved-text {
  color: $green;
}

.gift-receipt-added-text-product-card,
.gift-message-saved-text-product-card {
  padding-bottom: 1rem;
}

.gift-product-error-message {
  transform: translateX(65px);
  margin-bottom: 2%;
  color: red;
  @include media-breakpoint-down(md) {
    transform: translateX(87px);
  }
}

.gift-image-carousel {
  height: 430px;
  width: 280px;
}

.gift-product-img {
  height: 119.08px !important;
  margin-left: 1rem;
}

.gift-packaging-modal-content {
  width: fit-content;
  background-color: transparent;
  border: none;
  margin: auto;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}

.modal:not(#quickViewModal) {
  .modal-header {
    .close {
      top: 3.2rem;
      right: 3.25rem;
      z-index: 1;
      color: white;
      opacity: 1;
    }
  }
}

.country-dial-code-address-form {
  width: 17% !important;

  @include media-breakpoint-down(sm) {
    width: 119px !important;
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(md) {
    width: 25% !important;
  }

  @include media-breakpoint-up(xl) {
    width: 17% !important;
  }

  .intl-tel-input {
    .selected-flag {
      width: 110px !important;

      .iti-flag {
        bottom: 3px;
      }
    }
  }
}

.mobile-number-address-form {
  width: 83%;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
  @include media-breakpoint-up(md) {
    width: 75% !important;
  }
  @include media-breakpoint-up(xl) {
    width: 83% !important;
  }
}

.down-arrow-address-form {
  left: 5.75rem;
}

.checkout-page {
  .intl-tel-input {
    .country-list {
      width: 20rem;
      position: relative;
    }
  }
}

.checkout-billing__address {
  .country-dial-code-address-form {
    @include media-breakpoint-up(xl) {
      width: 20% !important;
    }
  }

  .mobile-number-address-form {
    @include media-breakpoint-up(xl) {
      width: 80% !important;
    }
  }
}

.intl-tel-input {
  .country-list {
    bottom: 3rem;
  }
}
