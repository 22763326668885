.custom-checkbox {
  .custom-control-label {
    &::before {
      border-radius: 0;
    }
  }
}

.text-light {
  color: $grey1 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.clearfix-self {
  clear: both !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0;
}

a,
.btn-link {
  color: $primary;
}

.border-dashed {
  border-style: dashed !important;
}

.border-black {
  border-color: $grey8 !important;
}

// Half-columns
$half-col-suffix: 'h';

@mixin half-col($size, $breakpoint: '') {
  $half-size: $size + 0.5;
  $infix: breakpoint-infix($breakpoint);

  @extend .col#{$infix}-#{$size} !optional;

  @include make-col($half-size);
}

@for $i from 1 through $grid-columns {
  .col-#{$i}#{$half-col-suffix} {
    @include half-col($i);
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .col-#{$breakpoint}-#{$i}#{$half-col-suffix} {
        @include half-col($i, $breakpoint);
      }
    }
  }
}
