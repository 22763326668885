.preference-centre {
  @include media-breakpoint-up(lg) {
    padding-left: map-get($spacers, 6);
  }
  .detect-location-text {
    display: none;
  }
  .newsletter {
    @include media-breakpoint-up(lg) {
      border: none;
      padding: 0 map-get($spacers, 5) map-get($spacers, 6) 0;
    }

    &__heading {
      h4 {
        font-weight: lighter;
      }

      p {
        line-height: rem-calc(24);
      }

      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }

    .newsletterForm {
      .form-row {
        padding: rem-calc(0);
        label {
          &::before {
            width: rem-calc(17);
            height: rem-calc(17);
            top: rem-calc(7);
          }
        }
      }
      .has-float-label.form-row-checkbox .input-checkbox:checked ~ .form-icon {
        width: rem-calc(15);
        height: rem-calc(15);
        top: rem-calc(7);
        &::before {
          left: rem-calc(2);
          bottom: rem-calc(2);
          font-size: rem-calc(18);
        }
      }
    }
  }

  .preferred-store {
    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }

    &__heading {
      p {
        line-height: rem-calc(24);
      }

      h4 {
        font-weight: lighter;
      }

      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }

    @include media-breakpoint-up(lg) {
      text-align: left;
    }

    .preferred-store-details {
      text-align: left;
      margin-bottom: rem-calc(20);

      .store-address-wrapper {
        margin-left: rem-calc(30);
        margin-bottom: rem-calc(30);

        p {
          margin-bottom: rem-calc(5);

          &.store-title,
          &.store-phone {
            position: relative;

            .font-icon {
              left: rem-calc(-30);
              font-size: rem-calc(20);
              display: block;
            }
          }

          &.store-phone {
            margin-top: rem-calc(20);
          }
        }
      }

      .store-hours-wrapper {
        @include media-breakpoint-up(lg) {
          @include make-col(12);
        }

        border-width: rem-calc(1) rem-calc(0);
        border-style: solid;

        .open-hours-text {
          @include h5;

          i {
            float: right;
          }
        }

        .store-work-times {
          li {
            padding: rem-calc(10) rem-calc(0);
          }

          .week-day {
            @include h5;
          }

          .current-day {
            color: $black;
            font-weight: bold;
            background-color: $grey3;
          }
        }
      }
    }
  }

  .save-preffered-store {
    white-space: initial;
    height: auto;
  }
}
