.shipment-summary {
  &__shipment {
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    padding-top: map-get($spacers, 4);
  }

  &__product.has-border-bottom {
    border-bottom: solid rem-calc(1) $border-color;
  }

  &__shipment-title {
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__shipment-status {
    text-align: center;
  }

  &__shipment-tracking {
    text-align: center;
    padding-top: map-get($spacers, 1);

    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }
}
