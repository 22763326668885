$icon-symbols: (
  "aim"                         : "\e94c",
  "back-to-top"                 : "\e907",
  "click-and-collect-express"   : "\eb81",
  "click-and-collect"           : "\eb7d",
  "error-cross"                 : "\e914",
  "exchanges"                   : "\e915",
  "fa-arrow-right"              : "\e939",
  "fa-check"                    : "\edfb",
  "fa-facebook-square"          : "\e91a",
  "fa-instagram"                : "\e922",
  "fa-search"                   : "\edd4",
  "fa-twitter"                  : "\e93d",
  "filter"                      : "\e91e",
  "grid1"                       : "\e801",
  "grid2"                       : "\e802",
  "grid3"                       : "\e803",
  "grid4"                       : "\e804",
  "heart2-filled"               : "\e91c",
  "heart2-half-filled"          : "\e91d",
  "hide"                        : "\e920",
  "home4"                       : "\e903",
  "image"                       : "\e921",
  "list"                        : "\e926",
  "lock5"                       : "\e9ae",
  "map-marker-full"             : "\eb66",
  "play"                        : "\e932",
  "returns-international-globe" : "\e924",
  "returns-international"       : "\e923",
  "returns"                     : "\e935",
  "show"                        : "\e938",
  "track-order"                 : "\e93c",
  "trash-cross"                 : "\e9d8",
);
