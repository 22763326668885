$icon-default-size: rem-calc(18);

[class*="font-icon icon-"] {
  font-family: $icon-font;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $icon-default-size;

  &:active,
  &.active {
    color: $secondary;
  }
}

[class*="font-icon #{$fa-css-prefix}-"] {
  font-family: $icon-font-fa;
}

[class*="font-icon icon-washicon-"] {
  font-family: $icon-font-wash;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: rem-calc(21);
}

$icon-symbols: () !default;
$icon-symbols: map-merge(
  (
    "aim":                        "\e94c",
    "arrow-left":                 "\ee0d",
    "bag":                        "\eb21",
    "box-cross":                  "\eb1c",
    "box2":                       "\eb1a",
    "calendar-check":             "\eb78",
    "check":                      "\edfb",
    "checklist2":                 "\ede9",
    "chevron-down":               "\ee04",
    "chevron-left":               "\ee05",
    "chevron-right":              "\ee06",
    "chevron-up":                 "\ee03",
    "cross":                      "\edfc",
    "debit-card":                 "\eb36",
    "earth":                      "\ed18",
    "envelope":                   "\e9f2",
    "exit":                       "\ea50",
    "gift":                       "\ec51",
    "group":                      "\eac2",
    "hanger":                     "\eb05",
    "heart2":                     "\ec2d",
    "home4":                      "\e903",
    "lock":                       "\e9ae",
    "lock2":                      "\e9b4",
    "lock5":                      "\e9b4",
    "map-marker":                 "\eb65",
    "menu":                       "\ede5",
    "minus":                      "\edff",
    "mouse-click-both":           "\eb81",
    "mouse-click-left":           "\eb7f",
    "mouse":                      "\eb7d",
    "notebook":                   "\e91f",
    "paper":                      "\ea55",
    "plus":                       "\edfe",
    "printer":                    "\ea1d",
    "question-circle":            "\ee27",
    "telephone":                  "\eb4d",
    "trash-cross":                "\e9d9",
    "trash":                      "\e9d8",
    "truck-time":                 "\ecd9",
    "truck":                      "\ecd7",
    "user":                       "\eab4",
  ),
  $icon-symbols
);

@each $icon-name in map-keys($icon-symbols) {
  .icon-#{$icon-name}::before {
    // Allow for the override of FontAwesome icons
    @if str-index($icon-name, 'fa-') == 1 {
      font-family: $icon-font;
    }

    content: map-get($icon-symbols, $icon-name);
  }
}

@mixin fa-icon-override($icon-name, $default-content) {
  @if map-has-key($icon-symbols, $icon-name) {
    font-family: $icon-font;
    content: map-get($icon-symbols, $icon-name);
  }
  @else {
    font-family: $icon-font-fa;
    content: $default-content;
  }
}
