@mixin border-after($forceAlignLeft: true, $bgColor: $white) {
  @if $forceAlignLeft {
    text-align: left !important;
  }

  position: relative;
  z-index: 0;
  font-weight: 300;

  span {
    background-color: $bgColor;
    padding-right: rem-calc(5);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: rem-calc(3);
    left: 0;
    right: 0;
    border-top: rem-calc(1) solid $beige;
    z-index: -1;
  }
}

@mixin productRecommendationStyles() {
  &__heading {
    @include border-after();
    text-align: left;

    font-size: rem-calc($fontsize-large);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(24);
    }
  }

  .product-tile {
    &__body {
      text-align: center;
    }

    &__name {
      margin-bottom: map-get($spacers, 2);
      font-size: rem-calc($fontsize-large);
    }

    &__rating,
    &__swatches {
      display: none;
    }
  }
}

@mixin change-choices-chevron() {
  &::after {
    content: map-get($icon-symbols, "chevron-down");
    font-family: $icon-font;
    margin-top: 0;
    transform: translateY(-50%);
    border: none;
    width: auto;
    height: auto;
  }

  &.is-open {
    &::after {
      margin-top: 0;
    }
  }
}

@mixin horizontal-filter-header-text() {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: $light-black;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}

@mixin horizontal-filter-text() {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  color: $light-black;
  letter-spacing: 0.07rem;
  text-transform: none;
}
