$back-to-top-size: rem-calc(50) !default;
$back-to-top-right-offset: rem-calc(20) !default;
$back-to-top-bottom-offset: rem-calc(20) !default;
$back-to-top-bottom-offset-md: rem-calc(70) !default;
$back-to-top-text-color: $white !default;
$back-to-top-background: $secondary !default;

.back-to-top {
  position: fixed;
  width: $back-to-top-size;
  height: $back-to-top-size;
  right: $back-to-top-right-offset;
  bottom: $back-to-top-bottom-offset;
  color: $back-to-top-text-color;
  background-color: $back-to-top-background;
  z-index: $back-to-top-link-z-index;
  cursor: pointer;
  display: flex;

  @include media-breakpoint-up(md) {
    bottom: $back-to-top-bottom-offset-md;
  }
}
