.checkout-summary {
  .checkout-shipping__summary-content {
    padding-left: 0;

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }

  .order-details {
    &__summary-items {
      @include make-row();

      margin-left: 0;
      margin-right: 0;
    }

    &__summary-item-label {
      @include h4;
    }

    &__summary-item {
      @include make-col-ready();
      @include make-col(12);

      display: flex;
      flex-direction: column;

      a {
        margin-top: rem-calc(5);
        font-weight: bold;
        float: none;
      }

      &--type,
      &--date {
        display: none;
      }

      &--shipping-address,
      &--billing-address {
        display: flex;

        b {
          width: auto;
        }

        u {
          order: 2;
        }
      }
    }
  }

  &__submit {
    .checkout-billing__submit-subscribe {
      @include media-breakpoint-up(md) {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}
