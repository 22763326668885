$filters-border-style: rem-calc(1) solid $primary;

.search-results {
  .results-count {
    @include media-breakpoint-down(lg) {
      font-weight: bold;
    }

    @include media-breakpoint-only(lg) {
      &--desktop,
      &__page-sizes {
        display: block;
      }

      &__separator {
        display: none;
      }
    }
  }

  &__count-row {
    &__image-type-toggle {
      max-width: none;

      &.infinite-scroll {
        padding-top: rem-calc(10);

        @include media-breakpoint-up(lg) {
          padding-top: 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      padding-left: 0;

      &__count {
        padding-left: 0;
      }
    }

    @include media-breakpoint-up(xl) {
      padding-left: $tiles-offset-from-filter;

      [data-grid-size="4"] & {
        padding-left: $tiles-extended-offset-from-filter;
      }

      &__count {
        text-align: left;
      }
    }
  }

  &__controls {
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);

    .filters,
    .sort-toggle,
    .page-size-toggle {
      align-self: center;
    }

    .applied-refinements-container {
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }

    .filters {
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-up(lg) {
        margin-right: map-get($spacers, 4);
      }

      @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
      }

      &__main-toggle {
        margin-right: 0;

        @include media-breakpoint-down(lg) {
          background: none;
        }
      }
    }

    .page-size-toggle {
      margin-top: map-get($spacers, 4);

      @include media-breakpoint-up(lg) {
        padding-left: 0;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    .filters__section {
      &__btn {
        text-transform: uppercase;
        position: relative;
        border-top: $filters-border-style;
        border-bottom: rem-calc(1) solid $grey8;

        @include media-breakpoint-down(sm) {
          border: $filters-border-style;
        }

        @include media-breakpoint-up(lg) {
          padding-top: map-get($spacers, 3);
          padding-bottom: map-get($spacers, 3);
        }

        &-icon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          margin-top: 0 !important;
        }

        &[aria-expanded="false"] {
          border-bottom-color: $primary;
        }
      }

      &__btn-text {
        color: $grey5;
        text-transform: none;
        font-size: $fontsize-medium;

        @include media-breakpoint-up(lg) {
          color: $primary;
          text-transform: uppercase;
          font-size: $fontsize-large;
          letter-spacing: rem-calc(6.4);
        }
      }
    }

    .sorting {
      margin-top: 0;

      &__section {
        &__btn {
          color: $grey5;
          border: none;

          @include media-breakpoint-up(lg) {
            border: $filters-border-style;
          }

          &-wrapper {
            &::after {
              display: none;
            }
          }
        }
      }

      &__grid-size {
        @include media-breakpoint-up(lg) {
          padding-left: map-get($spacers, 2);
          padding-right: map-get($spacers, 2);
          padding-bottom: map-get($spacers, 1);
        }

        .cell {
          background-color: $black;
        }

        &.current {
          border-bottom: rem-calc(3) solid $green;
        }
      }

      &__toggle-section {
        &__btn {
          font-size: rem-calc($fontsize-medium);
          letter-spacing: rem-calc(1.05);
        }

        &__btn-wrapper {
          border: rem-calc(1) solid $primary;
          padding-right: 0;

          .form-icon {
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
          }
        }
      }
    }
  }
}

.filters {
  &__section {
    &__btn {
      display: flex;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__btn-icon {
      line-height: 1;
    }
  }

  &__close-button {
    &__icon {
      display: block;
    }
  }
}

.sorting {
  &__section {
    &__btn {
      height: rem-calc(37);
    }
  }
}
