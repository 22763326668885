.heading {
  @extend .rd-featureTile-heading;
  margin-bottom: 1.5rem;
  @include media-breakpoint-down(sm) {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
}

.featureTile_content {
  background: #ecebe8;
  padding: 4.25rem 10rem;

  @include media-breakpoint-down(sm) {
    .tile_content {
      overflow-x: auto;
    }
  }

  @include media-breakpoint-down(xl) {
    padding: 4rem 5rem;
  }

  @include media-breakpoint-down(lg) {
    padding: 4rem 2rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 4rem 0 4rem 1rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 2rem 0 2rem 1rem;
  }

  img {
    width: 100%;
  }

  .tile_info {
    padding: 0 !important;
    margin-right: 0.5rem;

    @include media-breakpoint-down(sm) {
      flex: 0 0 auto;
      max-width: 20rem;
    }

    @include media-breakpoint-down(xs) {
      margin-right: 1rem;
      max-width: 15rem;
    }
  }

  .img-section {
    margin-bottom: 1.25rem;
  }

  .text-section {
    @extend .rd-featureText;
    padding-bottom: 1.25rem;
  }
}
