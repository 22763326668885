$header-search-border-style: rem-calc(1) solid $grey2;
$media-breakpoint-desktop: 1279px;
$media-breakpoint-desktop-large: 1439px;
$media-breakpoint-tablet-down: 1120px;
$media-breakpoint-tablet-large: 1366px;
$media-breakpoint-tablet-min: 767px;
$media-mobile-min: 375px;
$media-mobile-max: 950px;
$landscape: 'landscape';
$portrait: 'portrait';

.cookie-warning-messaging.cookie-warning {
  background-color: $grey10;

  .valid-cookie-warning {
    border: none;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(1280);
    background-color: transparent;

    p {
      display: block;
      margin: 0;
      width: 100%;
      letter-spacing: rem-calc(1.1);
      line-height: rem-calc(20);

      @include media-breakpoint-down(md) {
        font-size: rem-calc(12);
      }
    }

    button.btn {
      background-color: transparent;
      color: $primary;
      font-size: rem-calc(12);
    }
  }
}

.header {
  @media only screen and (min-width: $media-breakpoint-desktop) {
    max-width: 1120px;
  }

  @media only screen and (min-width: $media-breakpoint-desktop-large) {
    max-width: 1280px;
  }

  .header-items {
    padding: rem-calc(12) rem-calc(16);

    @include media-breakpoint-up(sm) {
      padding: rem-calc(16) rem-calc(32);
    }

    @media only screen and (min-width: $media-breakpoint-desktop) {
      padding: rem-calc(16) rem-calc(0);
    }

    &__controls {
      align-items: center;
    }

    .header-item {
      &__menu {
        @include make-col-ready();
        @include make-col(4);

        padding-left: 0;

        @include media-breakpoint-down(sm) {
          display: flex;
          order: -1;
          @include make-col(4);
        }

        @include media-breakpoint-only(sm) {
          @include make-col(4);
        }

        @include media-breakpoint-up(lg) {
          display: none !important;
        }
        .stores.left {
          display: none;
          @media only screen and (max-width: $media-breakpoint-tablet-min) {
            display: block;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        display: flex;
      }

      &__logo {
        @include make-col(5);
        text-align: center;

        @include media-breakpoint-only(md) {
          @include make-col(3);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }

        .brand {
          // important needed here to override shared header bootstrap classes
          @include media-breakpoint-up(md) {
            padding-left: 1.8rem !important;
          }

          @include media-breakpoint-up(lg) {
            padding-left: 0 !important;
          }
        }
      }

      &__controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &--right {
          @include make-col(5);
          padding: 0;

          @include media-breakpoint-only(xs) {
            @include make-col(3);
          }

          @include media-breakpoint-only(sm) {
            @include make-col(3);
          }

          @include media-breakpoint-up(md) {
            @include make-col(4);
          }
        }

        &--left {
          .header-element__stores {
            display: none;
          }
        }
      }
    }

    .header-element {
      vertical-align: middle;
      margin: 0;
      line-height: 0;
      margin-right: 0;

      &__text {
        display: none;
      }
      &__icon {
        display: inline-block;

        .font-icon,
        .font-icon.active,
        .font-icon:not(.active) {
          color: $grey5;
          &.icon-menu {
            margin-bottom: 0;
          }
        }
      }

      &.stores.right {
        display: none;
        @media screen and (min-width: $bp-medium) {
          display: block;
          margin-right: rem-calc(24);
        }
      }

      &__menu {
        margin-right: rem-calc(12);

        @include media-breakpoint-up(sm) {
          margin-right: rem-calc(24);
        }

        .font-icon {
          font-size: rem-calc(30);
        }

        .nav-menu__link__text {
          display: none;
          margin-top: -#{map-get($spacers, 3)};
        }
        order: -1;

        @include media-breakpoint-between(md, lg) {
          display: none;
        }
      }
      &__user {
        order: 0;
        line-height: 0;
        margin-right: rem-calc(16);

        @include media-breakpoint-up(sm) {
          margin-right: rem-calc(24);
        }

        &.left {
          display: none;
          @media only screen
            and (min-device-width: $bp-medium)
            and (max-device-width: $media-breakpoint-tablet-down)
            and (orientation: $portrait) {
            display: block !important;
          }
        }

        @media only screen
          and (min-device-width: $bp-medium)
          and (max-device-width: $media-breakpoint-tablet-down)
          and (orientation: $portrait) {
          &.right {
            display: none;
          }
        }
      }

      &__wishlist {
        order: 2;
        @include media-breakpoint-up(sm) {
          margin-right: rem-calc(24);
        }
        @media screen and (min-width: $bp-medium) {
          display: block;
        }
      }

      &__minicart {
        order: 3;
        margin-right: 0;
        line-height: 0;
      }

      &__country-selector {
        margin-top: rem-calc(5);

        &__current {
          display: none;
        }

        .flag-icon {
          font-size: $fontsize-xlarge;
        }
      }

      &__stores {
        display: inline-block;
        margin: 0;
        order: -1;

        @include media-breakpoint-up(md) {
          display: none;
        }

        @include media-breakpoint-up(sm) {
          margin-right: rem-calc(24);
        }
      }

      .minicart-link {
        display: inline-block;
        position: relative;
      }
      .minicart-icon {
        margin-bottom: 0;
        font-size: rem-calc(22);
        margin-top: rem-calc(-2);
      }
      .minicart-quantity {
        display: inline-block;
        width: 100%;
        position: absolute;
        left: 0;
        text-align: center;
        font-weight: normal;
        color: $white;
        line-height: initial;
      }

      .minicart-total {
        @include media-breakpoint-up(lg) {
          margin-left: rem-calc(5);
        }
      }

      .minicart-grand-total {
        position: relative;
        bottom: rem-calc(3);
        font-size: rem-calc(12);
        font-weight: normal;
        margin-left: map-get($spacers, 3);
        display: none;
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
      .minicart-round-element {
        position: absolute;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: black;
        top: 12px;
        left: rem-calc(13);
        @include media-breakpoint-up(lg) {
          left: rem-calc(15);
        }
      }
    }
  }

  .header-item {
    &__search {
      padding: rem-calc(10) 0 0 0;

      .search {
        &::before {
          display: block;
          content: '';
          position: absolute;
          left: -10px;
          top: 0;
          width: calc(100% + #{2 * map-get($spacers, 2)});
          height: 1px;
          background: $grey3;
        }
      }
    }

    &__logo {
      @include media-breakpoint-up(lg) {
        text-align: center;
        margin-top: rem-calc(10);
      }
    }

    @include media-breakpoint-up(md) {
      &__logo {
        order: 2;
      }
      &__search {
        order: -1;
        padding: 0;
        .search {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

// header banner
.header-banner {
  background-color: $red-accent;

  .carousel-item {
    font-size: rem-calc($fontsize-medium);
  }

  &-bottom {
    background-color: $grey11;

    .carousel-item {
      color: $grey5;
    }
  }

  &.slide-up:not(.header-banner-bottom) .carousel-item {
    font-size: 12px;
    color: black;
    text-align: center;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .country-selector {
    &__current,
    &__current:not([href]):not([tabindex]) { // Overrides the Bootstrap reboot style
      color: $black;

      &:hover {
        color: $black;
      }
    }
  }
}

header {
  &.js-is-sticky,
  &.js-is-stuck {
    .header {
      .header-items {
        @include media-breakpoint-up(lg) {
          padding-top: map-get($spacers, 2);
          padding-bottom: map-get($spacers, 2);
        }
      }
      .header-item {
        &__logo {
          @include media-breakpoint-up(lg) {
            margin-top: rem-calc(4);
          }
        }
      }
    }
  }
}

.primary-category-slider {
  position: relative;
  padding: rem-calc(10) 0 rem-calc(10) 0;
  padding-left: 1rem;
  background-color: #f8f8f8;
  font-size: 12px;
  line-height: 1;

  @media only screen and (min-device-width: $bp-medium) {
    padding-left: rem-calc(26);
  }

  span {
    font-weight: 400;
    letter-spacing: rem-calc(2.4px);
  }

  @media only screen
    and (min-device-width: $media-breakpoint-tablet-down)
    and (max-device-width: $bp-medium)
    and (orientation: $portrait) {
    display: block;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.header-item__search {
  @include media-breakpoint-only(xs) {
    top: rem-calc(45);
  }

  @media only screen and (max-width: $bp-medium) {
    display: none;
  }

  &.lower {

    @media screen and (min-width: $bp-medium) {
      .search-field {
        padding-left: 70px;
      }
      .search-button {
        left: 25px;
        right: auto;
      }
    }

    @include media-breakpoint-up(md) {
      display: block;
    }

    @media only screen
      and (min-device-width: $media-breakpoint-tablet)
      and (max-device-width: $media-breakpoint-tablet-large)
      and (orientation: $portrait) {
      display: none !important;
    }

    .clear-search {
      @media only screen
        and (min-width: $bp-medium)
        and (max-width: $media-breakpoint-tablet) {
        display: none;
      }
    }
  }

  &.center {
    @media only screen
      and (min-device-width: $bp-medium)
      and (max-device-width: $media-breakpoint-tablet-down)
      and (orientation: $portrait) {
      display: none !important;
    }
  }
}

.header.header-item {
  padding: rem-cal(12) rem-calc(16);
}

.compact-header .header .header-items .header-element .minicart-quantity {
  color: $white !important;
}

.header .header-item__search.center {
  @media only screen and (min-width: $media-breakpoint-tablet)
    and (max-width: $media-breakpoint-tablet-large)
    and (orientation: $portrait) {
    display: block !important;
  }

  @include media-breakpoint-between(sm, md) {
    display: none;
  }
}
