// cookie pop up block
.cookie-warning-messaging.cookie-warning {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $cookie-warning-message-z-index;

  .alert {
    padding: rem-calc(12 23 12 42);
    box-sizing: border-box;
    white-space: nowrap;
    @include media-breakpoint-down(sm) {
      padding: rem-calc(12 9);
      text-align: center;
    }

    p {
      text-align: left;
      letter-spacing: rem-calc(0.7);
      display: inline-block;
      width: calc(100% - 295px);
      white-space: normal;
      vertical-align: middle;

      @include media-breakpoint-down(sm) {
        text-align: center;
        display: block;
        width: 100%;
        padding-bottom: rem-calc(16);
      }
    }

    .btn {
      width: rem-calc(295);
      white-space: normal;
      vertical-align: middle;

      @include media-breakpoint-down(sm) {
        width: rem-calc(258);
        text-align: center;
      }
    }
  }
}

.valid-cookie-warning {
  margin-bottom: 0;
  background-color: $grey9;
  border-color: $grey2;

  @include media-breakpoint-down(md) {
    padding-right: rem-calc(50);
  }

  p {
    margin: 0 auto;
    text-align: left;
    color: $black;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    a {
      text-decoration: underline;
      color: $black;
    }
  }
}

.header {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .header-items {
    transition: padding 0.5s;
    padding: map-get($spacers, 1) 0;
    align-items: center;

    @include media-breakpoint-up(lg) {
      padding: map-get($spacers, 2) map-get($spacers, 3);
    }
  }

  .header-item {
    &__logo {
      @include make-col-ready();
      @include make-col(6);

      @include media-breakpoint-up(md) {
        @include make-col(4);

        order: 1;
      }

      .navbar-header {
        display: inline-block;
      }
    }

    &__search {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
    }

    &__controls--right {
      @include make-col-ready();
      @include make-col(6);

      display: flex;
      flex-direction: row-reverse;

      @include media-breakpoint-up(md) {
        @include make-col(4);

        order: 3;
      }
    }
  }

  .header-image {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: max-width 0.3s ease-in-out;

    &.studio-eight {
      max-height: rem-calc(50);
    }
  }

  &.container {
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  .header-element {
    margin-right: rem-calc(8);

    @include media-breakpoint-up(sm) {
      margin-right: rem-calc(10);
    }

    font-size: rem-calc($fontsize-small);
    line-height: rem-calc(25);

    > a {
      color: $black;

      font-size: rem-calc($fontsize-small);
      @include media-breakpoint-up(lg) {
        font-size: rem-calc(14);
      }

      &:hover {
        color: $secondary;
      }
    }

    &__icon {
      .font-icon {
        display: block;
        text-align: center;
        font-size: rem-calc(30);
        margin-bottom: rem-calc(5);

        &:not(.active) {
          color: $black;
        }
      }
    }

    &__menu {
      cursor: pointer;

      .font-icon {
        font-size: rem-calc(34);
      }
    }
    &__stores {
      display: none;
    }

    &__text,
    &__icon {
      display: block;
    }

    &.minicart {
      .minicart-link--active {
        .font-icon {
          &::before {
            content: map-get($icon-symbols, 'bag-full');
          }
        }
      }
      .minicart-grand-total {
        display: none;
      }
      @include media-breakpoint-up(lg) {
        .minicart-total {
          margin-left: 1rem;
        }
      }
    }

    &__wishlist {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    @include media-breakpoint-up(md) {
      &__text {
        display: none;
      }
      &__stores {
        display: inline-block;
      }
    }

    @include media-breakpoint-up(lg) {
      &__text {
        display: none;
      }
    }
  }
}

header {
  &.header-sticky {
    background-color: $white;
    position: sticky !important;
    top: 0;
  }
  @include media-breakpoint-down(md) {
    transition: top 0.35s linear;
    &.scroll-up {
      top: 0;
    }
  }
  &.js-is-sticky,
  &.js-is-stuck {
    .header {
      .header-items {
        @include media-breakpoint-up(lg) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .search-field {
        border-top: none;
        border-bottom: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  body:not(.new-pdp) {
    .compact-header.scroll-down {
      top: -175px !important;
    }
  }
}

// menu items
.main-menu {
  border-top: 1px solid $grey2;
}

.menu-toggleable-left {
  .menu-group {
    flex: 0 0 100%;
  }
}

.navbar-nav {
  .nav-item {
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.navbar-expand-md .navbar-nav {
  @include media-breakpoint-up(md) {
    text-align: center;
    display: inherit;
  }

  .nav-link {
    @include media-breakpoint-up(md) {
      letter-spacing: rem-calc(1.5);
      color: $black;
      text-align: justify;
      text-transform: uppercase;
      padding: rem-calc(5 30);
    }

    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
}

// header banner
.header-banner {
  background-color: $black;
  .carousel-item {
    color: $white;

    max-height: rem-calc(27);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .close-button-wrapper {
    margin-top: rem-calc(-5);

    .close-button {
      opacity: 1;
      color: $white;
      height: auto;
      float: none;

      &:hover {
        color: $white;
      }

      .icon-cross {
        &::before {
          font-size: rem-calc($fontsize-medium);
        }
      }
    }
  }

  &.slide-up {
    &:not(.header-banner-bottom) {
      padding-top: 0;
      padding-bottom: 0;
    }

    min-height: rem-calc(25);
    padding: rem-calc(4);

    &.hide {
      .content,
      .close-button-wrapper {
        display: none;
      }
    }
  }

  .content {
    text-align: center;
    line-height: rem-calc(27);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc($fontsize-small);
    }
  }

  .country-selector {
    &__current,
    &__current:not([href]):not([tabindex]) { // Overrides the Bootstrap reboot style
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}

.header,
.header-banner {
  .country-selector {
    &__current {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.header-sticky {
  width: 100%;
  position: relative;
  background-color: $page-bg;

  @include media-breakpoint-up(lg) {
    border-bottom: rem-calc(1) solid $border-color;
  }

  &.header-z-index {
    z-index: $header-sticky-z-index;
  }

  &--background-image {
    background-position: center;
    transition: height 0.3s ease-in-out;
  }
  @include media-breakpoint-down(md) {
    transition: top 0.35s linear;
  }
}

.mr-20 {
  margin-right: rem-calc(20);
}

// New Header PDP Changes
.header-search,
.pdpminicart {
  display: none;
}

.site-search {
  .clear-search {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .new-pdp {
    header.header-sticky {
      transition: opacity 0.35s ease-in-out;
    }
  }
}

@include media-breakpoint-down(md) {
  .rd_PDP {
    .primary-category-slider,
    .header .stores,
    .header-banner-bottom {
      display: none !important;
    }

    .header-sticky {
      background-color: transparent;
    }

    .header-search,
    .pdpminicart {
      display: block;
    }
  }
}

@include media-breakpoint-down(md) {
  .rd_PDP {
    .header-search {
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("../../../../static/default/images/pdpsearch.svg");
      background-size: 1.5rem;
      background-position: center;
      background-repeat: no-repeat;
    }

    .site-search {
      .clear-search {
        display: block;
      }
    }

    .header-item__search.search-container {
      display: none;
    }

    .header-item__search.active {
      display: block;

      .suggestions__content--categories {
        ul:last-child {
          margin-bottom: 1rem;
        }
      }

      .suggestions__content {
        ul li a {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.04375rem;
        }
      }

      .site-search input.form-control {
        height: auto;
        font-weight: 400;
        letter-spacing: 0.04688rem;
        background-color: $gray6;
      }

      .suggestions__content ul li:last-child {
        padding-bottom: 0.5rem;
      }

      .suggestions-wrapper {
        position: relative;

        .suggestions {
          top: 0;
          box-shadow: unset;
          margin-top: 0;
          border-top: 2px solid $white;
          background-color: $gray6;
        }
      }

      .site-search__controls {
        .clear-search {
          right: 1rem;
          top: -1.875rem;
          font-size: 0.8125rem;
          font-weight: 400;
          line-height: 1.225rem;
          letter-spacing: 0.04063rem;
          text-decoration-line: underline;
          text-underline-position: under;
        }
      }
    }
  }

  .compact-header.bak-Color {
    background-color: $white;

    .site-search__controls button,
    .close-button-wrapper {
      display: none !important;
    }

    .header-search {
      background-image: url("../../../../static/default/images/icons/closeicon.svg");
    }
  }
}
