$grid-size-selector-width: 30px !default;
$grid-size-selector-cell-height: 15px !default;
$grid-size-selector-cell-md-height: 9px !default;
$grid-size-selector-cell-margin: 1px !default;
$grid-size-selector-cell-bg: $grey3 !default;
$grid-size-selector-cell-current-bg: $primary !default;

.grid-size-selector {
  display: inline-block;
  width: $grid-size-selector-width;

  &__cell-container {
    padding: 0;
  }

  &__cell {
    height: $grid-size-selector-cell-height;
    margin: $grid-size-selector-cell-margin;
    background-color: $grid-size-selector-cell-bg;

    @include media-breakpoint-up(md) {
      height: $grid-size-selector-cell-md-height;
    }
  }

  &.current {
    .cell {
      background-color: $grid-size-selector-cell-current-bg;
    }
  }
}
