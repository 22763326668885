$header-search-border-style: rem-calc(1) solid $grey2;
$suggestions-shadow-color: rgba(23, 23, 24, 0.25);

.site-search {
  width: 100%;
  position: relative;
  max-width: 100%;
  padding: 0;

  @include media-breakpoint-down(md) {
    padding-bottom: rem-calc(4);
  }

  @include media-breakpoint-up(lg) {
    margin: 0;
    max-width: rem-calc(297);
  }

  &__controls {
    .clear-search {
      right: rem-calc(45);
      top: rem-calc(-28);
      font-size: 12px;
      color: $primary;
      font-weight: 400;
      cursor: pointer;
      text-transform: uppercase !important;
      letter-spacing: rem-calc(2.4);
      text-decoration: underline;
    }

    .search-button {
      right: 0;
      top: rem-calc(-33);
    }
  }

  .fa-close,
  .fa-search {
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(10);
  }

  .fa-search {
    padding-left: rem-calc(10);
    font-size: rem-calc($fontsize-medium);
    border-left: rem-calc(1) solid $white;

    @include media-breakpoint-up(md) {
      border-color: $grey2;
    }
  }

  input {
    font-size: rem-calc($fontsize-medium);
    height: rem-calc(32);
    @media only screen and (max-device-width: $bp-medium) {
      height: rem-calc(36);
    }

    line-height: rem-calc(40);
    padding-right: rem-calc(100);

    &.form-control {
      border: none;
      border-radius: 0;
      background-color: white;
      color: $black;
      border-bottom: 1px solid #cac7c0;
      letter-spacing: rem-calc(2.4px);
      text-transform: uppercase;
      font-size: 16px;
      padding-left: 16px;
      padding-top: 4px;
      padding-bottom: 4px;

      &::placeholder {
        font-size: 12px;
        color: #817d78;
        line-height: rem-calc(40);
        letter-spacing: 2px;
      }

      @include media-breakpoint-up(md) {
        background: $grey11;
        border: none;
      }

      &:focus {
        background: #f8f8f8;
        border-color: $black;
      }
    }
  }
}

.search-icon-wrapper {
  border-left-color: transparent;
  top: rem-calc(5);

  @include media-breakpoint-up(lg) {
    right: 0;
  }
}

.suggestions {
  top: rem-calc(60);
  left: 0;
  background-color: $white;
  box-shadow: 0 rem-calc(1) rem-calc(5) 0 $suggestions-shadow-color;
  width: rem-calc(1000);

  @include media-breakpoint-down(md) {
    width: rem-calc(700);
  }

  @include media-breakpoint-down(sm) {
    width: calc(100% + #{rem-calc(16)});
    margin-left: rem-calc(-8);
    margin-right: rem-calc(-8);
    max-height: calc(100vh - #{rem-calc(105)});
    top: rem-calc(40);

    .container-fluid {
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: rem-calc(10);
      padding-bottom: rem-calc(30);
      max-height: calc(100vh - #{rem-calc(155)});
    }
  }

  @include media-breakpoint-up(md) {
    left: 0;
  }

  .recent-search-wrapper {
    padding: map-get($spacers, 3);

    li {
      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .suggestions-title-row {
    margin-left: 0;
    margin-right: 0;
  }

  .close-button-wrapper {
    top: rem-calc(15);
    .close-button {
      .font-icon {
        &::before {
          display: block;
        }
      }
    }
  }
  .clear-search-footer {
    display: block;
    border-top: 1px solid $grey3;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $white;

    .close-button {
      border: none;
      background-color: transparent;
    }

    .font-icon {
      font-size: rem-calc(14);
    }
  }

  .suggestions__content,
  .suggestions__footer {
    ul {
      li {
        a {
          padding-left: 0;
          font-weight: bold;
          text-decoration: underline;

          &:hover {
            background-color: transparent;
          }
        }

        &.separator {
          opacity: 0;
        }
      }
    }
  }

  .suggestions__content {
    padding-left: 0;

    li:not(&__title) {
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    ul {
      margin-bottom: 0;

      li {
        padding-left: map-get($spacers, 2);

        &:first-child {
          padding-left: map-get($spacers, 2);
        }

        @include media-breakpoint-up(lg) {
          padding: 0 map-get($spacers, 4) map-get($spacers, 2) 0;

          &:first-child {
            padding-left: 0;
          }

          a {
            padding: 0;
          }
        }
      }
    }

    .popular-search-wrapper {
      &__title {
        display: none;
      }
    }
  }

  .suggestions__footer {
    .popular-search-wrapper {
      text-align: left;

      &__title {
        display: inline-block;

        text-transform: uppercase;
        letter-spacing: rem-calc(5.6);

        &::after {
          content: ': ';
        }
      }
    }
  }

  .suggestions-title {
    font-size: rem-calc(24);
    display: block;
    width: 100%;
    margin-bottom: map-get($spacers, 3);
    letter-spacing: rem-calc(9);
    font-weight: 300;

    @include media-breakpoint-up(lg) {
      @include border-after();
    }
  }

  .arrow-up {
    left: 19%;
  }

  .product-tile {
    &__body {
      text-align: center;
      letter-spacing: rem-calc(5);
      font-size: rem-calc(11);
    }

    &__name {
      margin-bottom: map-get($spacers, 2);
      font-size: rem-calc($fontsize-medium);
      text-transform: uppercase;
    }

    &__price {
      font-size: rem-calc($fontsize-medium);
    }

    &__brand {
      display: none;
    }
  }

  .product-tile__outofstock--sticker {
    text-transform: uppercase;
    background-color: $white;
    opacity: 0.8;
    font-size: 0.85rem !important;
  }
}

.header-item__search.search-container {
  display: block;
}

@include media-breakpoint-down(md) {
  .rd_PDP {
    .header-item__search.active {
      .search-container {
        padding: 1.5rem 0 0;
        border-top: 1px solid $gray16;
      }

      .site-search {
        padding-bottom: 0;
        border-top: 1px solid $gray16;
        input.form-control {
          text-transform: capitalize;
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: 0.08rem;
          height: auto;
          padding-left: 0;
          padding-top: 0.625rem;
          background-color: $white;
        }

        input.form-control:focus {
          background: $white;
          border-color: $gray16;
        }
        form {
          margin: 0 1rem;
        }
      }

      .site-search__controls {
        .clear-search {
          right: 0;
          top: -1.75rem;
          font-size: 0.75rem;
          font-weight: 600;
          letter-spacing: 0.0225rem;
          text-decoration-line: underline;
        }
        a {
          text-transform: capitalize !important;
          font-size: 0.75rem;
          font-weight: 600;
          letter-spacing: 0.0225rem;
          text-decoration-line: underline;
        }
      }

      .suggestions__content--categories {
        ul:last-child {
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid $gray16;
        }
      }

      .clear-search-footer {
        border-top: none;
      }

      .suggestions-wrapper {
        .suggestions {
          margin: 0;
          padding: 0;
          padding-top: 1.5rem;
          width: calc(100% + 2rem);
          left: -1rem;
          background-color: $white;
        }

        .suggestions-container {
          margin: 0;
        }

        .suggestions__content {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0 1rem;
          ul li:first-child,
          ul {
            padding-left: 0;
          }
        }

        .close-button {
          color: $black;
        }
      }
    }
  }
}
