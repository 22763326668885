#content__reviews {
  // header
  img.hidden-md-down + h4 {
    @include media-breakpoint-up(lg) {
      color: $white;
      position: absolute;
      top: calc(50% - #{$h4-font-size / 2});
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.reviews {
  &__tab {
    position: relative;
    top: 1px;
    background-color: $page-bg;
  }

  &__tab-link {
    position: relative;
    font-size: $fontsize-large;
    border-bottom: rem-calc(1) solid $border-color;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: rem-calc(5);
      position: absolute;
      top: 0;
      left: 0;
    }

    &.active {
      border-bottom: none;

      &::before {
        background-color: $secondary;
      }
    }
  }

  &__review {
    &-score {
      color: $secondary;
    }
  }
}
