@mixin pageElement($width: rem-calc(40), $height: rem-calc(40), $bg-color: transparent, $font-size : rem-calc(16), $font-weight : 400, $line-height: normal, $letter-spacing : normal, $color: $black, $text-transform: none, $border-color: $black) {
  width: $width;
  height: $height;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
  background-color: $bg-color;
  text-transform: $text-transform;
  border: $border-color;
}
