.newsletter-form {
  &__optin {
    &__wrapper {
      margin-bottom: map-get($spacers, 4);
    }

    &__field {
      margin-bottom: map-get($spacers, 3);
    }
  }
}
