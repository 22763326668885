$cart-product-card-modify-overlay-bg: $grey3 !default;

.coupon-error {
  margin: rem-calc(-30 0 30 -5);
}

.promo-code-submit {
  .promo-code-btn {
    height: rem-calc(40);
  }
}

.cart-page {
  > .row .col-12.text-center h1 {
    // Such SFRA, much WOW
    // This hides the empty cart message that gets appended on the client-side
    // while the cart is being reloaded after the last item is removed
    display: none;
  }

  .cart-header {
    text-align: center;
  }

  .mobile-checkout-btn-ctr {
    border-bottom: solid rem-calc(1) $border-color;
  }

  .product-card__quantity {
    display: none;
  }

  .cart-item {
    border-bottom: rem-calc(1) solid $border-color;
    margin-bottom: map-get($spacers, 4);

    .line-item-quantity {
      max-width: rem-calc(180);

      .form-group {
        select {
          padding-top: rem-calc(6);
        }
      }
    }

    .line-item-availability {
      margin-top: 0;
    }
  }

  .cart-item-bottom-section {
    margin-top: rem-calc(-20);
  }

  .cart-mobile-wishlist {
    padding-left: 0;
    padding-right: 0;
  }

  .totals {
    font-size: $fontsize-large;

    .payment-methods {
      display: none;
    }

    .order-totals {
      font-size: $fontsize-xlarge;
      margin-top: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 3);
      padding: 0;
    }

    .content-bottom {
      img {
        max-width: 100%;
      }
    }

    .order-discount {
      color: #f56415;
    }

    .cart-shipping-methods {
      select {
        font-size: rem-calc(12);
      }
      .col-12 {
        padding: 0;
      }
    }

    &__summary {
      border: 1px solid $border-color;
      padding: map-get($spacers, 3) map-get($spacers, 4);

      &__title {
        text-align: center;
        margin-bottom: map-get($spacers, 3);
        padding-bottom: map-get($spacers, 2);
        border-bottom: 1px solid $border-color;
      }
    }
  }

  .cart-shipping-methods .form-group {
    position: relative;

    &.shipping-methods-row {
      label {
        display: none;
      }
    }

    label {
      font-size: rem-calc(12);
    }

    .custom-select {
      font-size: rem-calc(12);
      background: none;
      margin-bottom: rem-calc(10);

      &.quantity {
        padding: 0 0 0 rem-calc(32);
      }
    }

    &::before {
      position: absolute;
      top: rem-calc(12);
      right: rem-calc(7);
      font-family: $icon-font;
      content: map-get($icon-symbols, 'chevron-down');
      pointer-events: none;
    }
  }

  .remove-movetowishlist-btns a {
    color: $grey1;
    margin-left: map-get($spacers, 3);
  }

  .cart-promo {
    padding-left: 0;
    padding-right: 0;
    order: 1;

    .cart-promo-section-input {
      @include make-col(7);
      margin-left: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 4);
    }

    .promo-code-submit {
      @include make-col(4);
    }
  }

  .promo-collapse {
    border-bottom: 1px solid $border-color;
    padding: map-get($spacers, 3) 0;
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 3);
    }

    &.collapsed {
      .icon-chevron-up {
        &::before {
          content: map-get($icon-symbols, 'chevron-down');
        }
      }
    }
  }

  .cart-summary-banner {
    padding-left: 0;
    padding-right: 0;
  }

  .cart-promo-section {
    &-input {
      @include make-col(9);
    }

    &-button {
      @include make-col(3);
    }

    &-input,
    &-button {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .wishlist__products {
    .product-detail__tile-row {
      overflow: overlay;

      @include media-breakpoint-up(md) {
        overflow: hidden;
      }
    }
  }

  .product-card {
    &__content {
      .line-item-availability {
        display: none;
        vertical-align: middle;
        margin-bottom: map-get($spacers, 2);

        p {
          margin-bottom: 0;
        }
      }
    }

    &__wrapper {
      overflow: hidden;
      position: relative;
      padding-top: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 2);

      .product-card {
        &__brand {
          margin-bottom: map-get($spacers, 2);
        }
      }
    }

    &__modifier {
      color: $grey1;

      &--remove {
        border-left: solid rem-calc(1) $border-color;
        padding-left: map-get($spacers, 2);
      }
    }

    &__modify-overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: $cart-product-card-modify-overlay-bg;
      transition: left 0.8s;

      &--active {
        left: 0;
      }

      &__icon-wrapper {
        margin-bottom: map-get($spacers, 3);

        .wishlist-icon {
          &--active {
            display: block;
          }

          &--inactive {
            display: none;
          }
        }
      }

      &__icon {
        font-size: rem-calc(36);
      }

      &__text {
        margin-bottom: map-get($spacers, 4);
      }

      &__undo {
        &__link {
          color: $primary;
          text-decoration: underline;
        }
      }
    }
  }

  .mobile-continue-shopping {
    text-align: center;
  }

  .apple-pay-store-delivery-msg {
    display: none;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500px;
    line-height: 19.6px;
    &::before {
      padding-top: 3px;
      margin-right: 8px;
      content: url('../../../../static/default/images/ellipse.svg');
    }
  }

  .dw-apple-pay-button.apple-pay-cart,
  .dw-apple-pay-button.apple-pay-cart:hover {
    margin-bottom: 16px;
  }

  .cart-left {
    .apple-pay-express-checkout {
      .apple-pay-cart-consents {
        margin-top: 16px;
      }
    }
  }

  .cart-optin__submit {
    display: none;
  }

  .cart-optin__submit.active {
    display: block;
  }

  .consents-dropdown,
  .privacy-notice {
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }

  .consents-dropdown:hover,
  .privacy-notice:hover {
    text-decoration: underline;
  }

  .apple-pay-express-checkout {
    display: none;
  }

  .express-checkout-container {
    padding: 16px 16px 0 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 24px;
  }

  .apple-pay-cart-consents {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #333;
  }

  .apple-pay-cart-consent-content-asset {
    margin-bottom: 16px;
  }

  .is-click-and-collect {
    .apple-pay-store-delivery-msg {
      display: flex;
    }
    .apple-pay-cart-consents,
    .apple-pay-cart {
      display: none;
    }
  }

  .disable-consent-checkbox {
    .apple-pay-cart-consents {
      display: none;
    }
  }

  .consent-row {
    display: flex;
    flex-wrap: nowrap;
  }

  .cart-input-checkbox {
    z-index: -1;
    opacity: 0;
    position: absolute;
    cursor: pointer;

    ~ .checkbox-tick {
      display: block;
      flex-shrink: 0;
      margin-right: 17px;
      width: 24px;
      height: 24px;
      position: relative;
      background-color: $white;
      border: 1px solid $grey-4;
      box-sizing: border-box;
    }

    &:checked ~ .checkbox-tick {
      &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 8px;
        width: 5px;
        height: 12px;
        border: solid $dark-background;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.wishlist__products {
  .carousel-control {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .product-card {
    &__content {
      &_qty {
        position: absolute;
        top: rem-calc(15);
        right: 0;
        padding-right: 0;
      }
    }
  }
}

.cartPageAddOns {
  .cart-item {
    margin-bottom: 1rem;
    .line-item-quantity {
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
  }

  .cart-item-bottom-section {
    margin-top: rem-calc(-8);
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    .product-card__content_qty {
      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .remove-movetowishlist-btns a {
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }

  .product-card {
    &__wrapper {
      margin-bottom: 0.5rem;
    }
    &__modify-overlay {
      &--active {
        z-index: 1;
      }
    }
  }

  .productLineItemRecomBtn {
    font-size: 14px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    line-height: 17px;
    letter-spacing: 0.05em;
    font-weight: 400;
    padding: 12px 24px;
    margin-top: -1.5rem;
    margin-left: 7rem;
    margin-bottom: 1.25rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-left: 0;
      margin-top: -0.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .productLineItemRecomContainer {
    .cart-recommendation-container {
      padding-bottom: 2rem;
      margin-right: -10px;

      @include media-breakpoint-up(lg) {
        margin-right: 0;
      }

      .cart-recommendation-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        border-top: rem-calc(1) solid $border-color;
        padding-top: 1.5rem;
        @include media-breakpoint-down(sm) {
          margin-right: 10px;
        }

        .cart-recommendation-title {
          h5 {
            margin-bottom: 0;
          }
        }
        .cart-recommendation-close-btn {
          cursor: pointer;

          .font-icon {
            display: flex;
            font-size: 1rem;
          }
        }
      }
      .cart-product-lineItem-recommendations {
        .product {
          &-tile {
            &__image {
              img {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.glide__arrows {
  .glide__arrow {
    top: 50%;
    transform: translateY(-50%);
    color: $black;
    background-color: rgba(255, 255, 255, 0.5);
    border-width: 1px;
    border-radius: 0;
    border: 1px solid $white;
    background-repeat: no-repeat;
    padding: 20px;
    background-position: center;
    @include media-breakpoint-down(sm) {
      display: none;
    }

    &:hover {
      color: $black;
      background-color: $grey1;
      border: 1px solid $grey2;
      opacity: 1;
    }

    &:active {
      background-color: $grey3;
      border: 1px solid $black;
      color: $white;
    }
  }

  .glide__arrow--left {
    left: 0;
    background-image: url('../../../../static/default/images/icons/prev.svg');
    &:hover {
      opacity: 1;
    }
  }

  .glide__arrow--right {
    right: 0;
    background-image: url('../../../../static/default/images/icons/next.svg');
    &:hover {
      opacity: 1;
    }
  }
}

.slider__bullets.glide__bullets {
  bottom: 0.5rem;

  .glide__bullet {
    border: 1px solid #c5b392;
    background-color: #c5b392;

    &--active {
      background-color: $white;
    }
  }
}

.cart-recommendations-bottom-row {
  margin-top: 2rem;

  .cart-recommendation-container-bottom {
    padding: 24px 10px 16px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 1rem;
      margin-left: -10px;
      margin-right: -10px;
    }
    background-color: #f5f2e8;

    .cart-recommendation-title-container-bottom {
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin: 0;

      .cart-recommendation-title-bottom {
        margin-bottom: 1rem;
        h5 {
          margin-bottom: 0;
        }
      }
      .cart-recommendation-close-btn-bottom {
        cursor: pointer;

        .font-icon {
          display: flex;
          font-size: 1rem;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .product-detail__tile-row-container {
        padding-bottom: 8px !important;
      }

      .slider__bullets.glide__bullets {
        bottom: 1px;
        height: 8px;
      }
    }

    .product-tile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: $white;
      padding: 0.5rem;

      &__image {
        height: 100%;
        width: 50%;
        padding-bottom: unset;
      }

      &__body {
        width: 50%;
      }

      &__rating {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem !important;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cart-item.cartItemActive,
  .cart-item.cartItemActivePrev {
    border-bottom: 0.25rem solid #f5f2e8;
  }
  .cart-item.cartItemActive:first-of-type {
    border: 10px solid #f5f2e8;
    padding: 1rem 0 0 10px;
    margin: 10px 0 1rem -10px !important;

    .product-card {
      &__content {
        &_qty {
          padding-right: 10px;
          .line-item-quantity .form-group {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .cart-recommendation-container {
      margin-right: 0;
      .cart-recommendation-title-container {
        margin-right: 10px;
      }
    }
  }
}

.cart-container.cartPageAddOnsEnable .cart-page h5 {
  text-align: left !important;
}

button.cart-applepay-button-desktop {
  margin-left: 0 !important;
}

.apple-pay-cart:not(.dw-apple-pay-button) {
  display: none;
}

.klarna-cart-osm {
  min-height: 5.75rem;

  @include media-breakpoint-up(lg) {
    min-height: 8.125rem;
  }

  @include media-breakpoint-up(xl) {
    min-height: 5.75rem;
  }
}

.paypal-cart-osm {
  min-height: 2.5rem;
}
