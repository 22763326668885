@font-face {
  font-family: 'washicons';
  src: url('../../../static/fonts/washicons.eot?uxu39p');
  src:
    url('../../../static/fonts/washicons.eot?uxu39p#iefix') format('embedded-opentype'),
    url('../../../static/fonts/washicons.woff2?uxu39p') format('woff2'),
    url('../../../static/fonts/washicons.ttf?uxu39p') format('truetype'),
    url('../../../static/fonts/washicons.woff?uxu39p') format('woff'),
    url('../../../static/fonts/washicons.svg?uxu39p#washicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
