.checkout {
  &__progress-step {
    text-transform: uppercase;
  }
  &__progress-step-number {
    font-weight: bold;
    font-size: $fontsize-xlarge;
    margin-bottom: map-get($spacers, 1);
  }
  &__progress-step-name {
    font-size: rem-calc(12);
    letter-spacing: rem-calc(5);
    margin-top: map-get($spacers, 2);

    @include media-breakpoint-up(md) {
      display: block;
      margin-top: map-get($spacers, 3);
    }
  }
}
