.breadcrumb {
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
}

@include media-breakpoint-down(md) {
  .product-breadcrumb.product-detail__nav-row__breadcrumbs {
    .breadcrumb-container {
      padding: 0.5rem 1rem;
      .breadcrumb {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        .breadcrumb-item {
          padding: 0;
          &::before {
            padding: 0 0.5rem;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}
