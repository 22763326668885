$font-family-sans-serif: 'Proxima Nova', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$icon-font: 'HobbsIconFont';

$green: #1c3632;
$dark-green: #283433;
$blue: #298ca4;
$beige: #cbc7bf;
$red: #8c2020;
$red-accent: #ee1749;
$pink: #e24d95;
$grey3: #eee;
$grey5: #757575;
$grey8: #a9a39b;
$grey11: #f8f8f8;
$grey12: #aa9e97;
$grey13: #959595;
$gray14: #ebebeb;
$gray15: #cac7c0;
$gray16: #ececec;
$mid-grey: #817d78;
$light-stone: #cac7c0;
$validate-green: #0c9533;
$invalid-red: #c92d2e;
$dark-grey: #1d1d1d;
$light-black: #1d1d1d;
$dark-black: #1e1e1e;
$overlay-white:rgba(255, 255, 255, 0.6);
$dark-red: #c13c4c;

$primary: #333;
$secondary: $green;

@import '~core-css/variables';

$border-color: $grey2;

$grid-breakpoints: (
  xs: map-get($grid-breakpoints, 'xs'),
  sm: map-get($grid-breakpoints, 'sm'),
  md: map-get($grid-breakpoints, 'md'),
  lg: map-get($grid-breakpoints, 'lg'),
  xl: map-get($grid-breakpoints, 'xl'),
  xl2: 2000px,
);

$container-max-widths: (
  sm: map-get($grid-breakpoints, 'md'),
  md: map-get($grid-breakpoints, 'lg'),
  lg: map-get($grid-breakpoints, 'xl'),
  xl: 1280px,
);

$headings-font-weight: lighter;
$headings-text-transform: uppercase;

$h1-font-size: rem-calc(36);
$h2-font-size: rem-calc(24);
$h3-font-size: rem-calc($fontsize-xlarge);
$h4-font-size: rem-calc($fontsize-large);
$h5-font-size: rem-calc($fontsize-medium);

$h1-letter-spacing: rem-calc(14);
$h2-letter-spacing: rem-calc(10);
$h3-letter-spacing: rem-calc(7);
$h4-letter-spacing: rem-calc(6);
$h5-letter-spacing: rem-calc(5);

$pdp-main-container-width: 70rem;
$tab-width:768px;
