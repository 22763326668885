$plp-side-col-width: rem-calc(300);
$plp-sort-dropdown-font-size: $fontsize-medium;
$tile-mobile-gutter: 0;
$tile-desktop-padding: rem-calc(28);
$tile-y-desktop-padding: rem-calc(45);
$tiles-offset-from-filter: rem-calc(4);
$tiles-extended-offset-from-filter: rem-calc(113);
$tile-swatch-size: rem-calc(30);
$tile-add-to-cart-overlay-size-size: rem-calc(34);
$tile-add-to-cart-overlay-bg: $white;
$tile-add-to-cart-overlay-opacity: 0.9;
$tile-add-to-cart-overlay-size-hover-bg: $secondary;
$tile-wishlist-icon-bg: rgba($tile-add-to-cart-overlay-bg, $tile-add-to-cart-overlay-opacity);
$tile-color-swatch-border-radius: 0;
$tile-name-height-mobile: auto;
