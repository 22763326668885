.additionalFields .adyen-checkout__label,
.adyen-checkout__label__text {
  float: none;
  width: 100%;
  text-align: left;
}

.form-horizontal #newCard label {
  float: none;
  width: 100%;
  text-align: left;
}

.creditCard {
  margin-left: 27%;
}

.paymentMethod {
  list-style-type: none;
  margin: 0.5em 0 0.5em 0;
}

.paymentMethod_img {
  margin: 0 0.3125em 0 0.3125em;
  vertical-align: middle;
}

.additionalFields {
  display: inline-block;
  margin: 0 0 0 0.625em;
}

.hiddenFields {
  display: none;
}

.security-code-input .adyen-checkout__field:first-child {
  display: none;
}

.checkoutComponent {
  display: none;
  margin-top: 1.25em;
}

#component_ach .form-row label {
  float: left;
}

#type {
  list-style-type: none;
  line-height: 3.125em;
}

#type .form-row {
  margin: 0.5rem 0 0.5rem;
}

#ssnValue {
  background: #fff;
  border: 0.0625em solid #b9c4c9;
  border-radius: 0.1875em;
  color: #001b2b;
  display: block;
  font-family: inherit;
  font-size: 1em;
  height: 3em;
  outline: none;
  padding: 0.3125em 0.5em;
  position: relative;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
  width: 12.5em;
}

.summary-label {
  font-weight: bold;
}

.adyen-payment-details {
  max-width: 25em;
  margin: auto;
}

.payment-method #type {
  margin-left: 22%;
}

.payment-method .issuer {
  margin: 1% 5%;
  display: none;
}

.payment-method-options .form-row {
  width: 25%;
}

.payment-method #type .logo {
  position: absolute;
}

.payment-method #type label {
  display: inline;
  float: none;
  width: auto;
}

.payment_method_label {
  padding: 0.75em 6em 0;
}

.openinvoiceInput {
  display: none;
}

.adyen-checkout__field {
  display: table;
}

.card-error {
  display: none;
}

.adyen-checkout__input-wrapper .adyen-checkout__dropdown__list {
  z-index: 10;
}

#adyenModalDialog {
  pointer-events: auto;
  background: white;
  min-height: fit-content !important;
  width: fit-content !important;
  margin: auto !important;

  &::before {
    height: fit-content !important;
  }
}

#action-container {
  margin-left: auto;

  .adyen-checkout__iframe--threeDSIframe,
  .adyen-checkout__threeds2__challenge {
    width: 375px !important;
  }
}

#giftcard-container {
  padding: 0 !important;
}

.modal-open {
  #action-modal.modal {
    display: flex !important;
  }
}

#action-modal-SG {
  display: none;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.action-modal-content-SG {
  background: white;
  margin-top: 10%;
  margin-left: 40%;
  width: max-content;
}

.invisible {
  display: none;
}

#paymentMethodsList {
  padding-left: 0;

  .paymentMethod {
    .paymentMethod-radio {

      input[type="radio"] {
        width: auto;
        height: auto;
      }

      label {
        display: flex;
        align-items: center;
      }
    }
  }
}
.payment-method--has-submethods {
  .checkout-billing__payment-form {
    padding: 0 !important;
  }
  li.paymentMethod {
    margin-bottom: 0;
    margin-top: 0;
    > div:not(.paymentMethod-radio) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .paymentMethod-radio {
      .paymentMethod_img {
        margin: 0;
      }
      label {
        margin-bottom: 0;
        flex-grow: 1;
      }
    }
  }
}
.checkout-billing__address-card-title {
  margin-left: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.24375rem;
}
