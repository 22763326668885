.address-fields {
  margin: 0 -map-get($spacers, 2);

  &__row {
    @include media-breakpoint-up(lg) {
      &:not(&--lookup) {
        @include make-col(6);
      }

      padding: 0 map-get($spacers, 2);
    }

    .address-lookup {
      margin: map-get($spacers, 4) 0;

      &__body {
        padding: 0;
        background-color: transparent;
      }

      &__title {
        @include h3;
        @include border-after();
        font-weight: lighter;
        margin-bottom: rem-calc(30);
      }

      &__field-wrapper {
        @include media-breakpoint-up(md) {
          max-width: rem-calc(300);
        }

        .font-icon {
          width: $form-control-height;
          height: $form-control-height;
          line-height: $form-control-height;
          top: 0;
          left: 0;
          text-align: center;
          background-color: $primary;
          color: $white;
        }
      }
      &__field {
        padding-left: $form-control-height + rem-calc(10);
      }

      &__footer {
        padding-left: 0;
        padding-right: 0;
      }

      &__toggle {
        font-weight: bold;
        letter-spacing: rem-calc(1);
      }
    }
  }
}

body .pca {
  .pcalist {
    background-color: $page-bg;
  }

  .pcaautocomplete {
    border-color: $primary;
    border-bottom: 1px solid;
  }
}
