.paging {
  @include media-breakpoint-up(lg) {
    margin-top: rem-calc(8);
  }

  &__status {
    &-text {
      font-weight: normal;
      padding: 0 map-get($spacers, 4);
    }

    &-bar {
      margin-bottom: rem-calc(40);
    }

    &-bar--progress {
      background-color: $green;
      top: rem-calc(-2);
      height: rem-calc(8);
    }
  }

  &__next-btn {
    @include btn-secondary();

    width: rem-calc(190);

    &:focus {
      box-shadow: none !important;
    }
  }
}

.paged-bottom {
  .paging {
    &__page {
      @media (max-width: $bp-medium) {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 0.875rem;

        &--button {
          width: auto;
        }
      }
    }
  }
}
