@mixin flyout($max-width, $max-width-desktop, $applyComponentStyles: false) {
  &__pane {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $flayout-pane-z-index;
  }

  .modal-background {
    opacity: 0;
    transition: 0.3s opacity;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: $max-width;
    right: -#{$max-width};
    overflow-y: auto;
    background-color: $page-bg;
    z-index: $modal-bg-z-index + 1;
    transition: 0.3s right;

    @include media-breakpoint-up(md) {
      max-width: $max-width-desktop;
      right: -#{$max-width-desktop};
    }
  }

  @if $applyComponentStyles {
    &__content {
      max-width: rem-calc(408);
      margin: 0 auto;
    }

    .flyout {
      &__heading {
        text-align: left;
        text-transform: uppercase;
      }

      &__close-label {
        font-size: rem-calc($fontsize-small);
        letter-spacing: rem-calc(5.6);
        text-transform: uppercase;
        display: block;
      }

      &__close-icon {
        display: inline-block;
        text-align: center;
      }
    }
  }

  &__pane.show {
    .modal-background {
      opacity: 0.4;
    }
  }

  &__pane.show & {
    &__container {
      right: 0;
    }
  }
}
