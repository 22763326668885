.reset-password-form {
  margin: map-get($spacers, 5) map-get($spacers, 2);
}

.request-password-title {
  font-size: rem-calc(24);
  text-align: center;
}

.reset-password {
  @include media-breakpoint-up(lg) {
    min-height: rem-calc(650);
  }

  .page-title {
    top: rem-calc(-12);
  }

  .new-password-text {
    color: $grey1;
  }
}

.request-password-body {
  text-align: center;
}
