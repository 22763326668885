.track-order {
  max-width: none;
  margin-top: map-get($spacers, 6);
  margin-bottom: map-get($spacers, 6);
  padding: map-get($spacers, 5) 0;
  background-color: $grey3;

  .trackorder-container {
    border: none;
    max-width: rem-calc(410);

    .has-float-label label {
      background: transparent;
      top: rem-calc(-18);
      left: 0;
    }

    .has-float-label input:placeholder-shown:not(:focus) + * {
      top: rem-calc(8);
    }

    .alert-danger {
      background-color: transparent;
    }
  }

  .track-order-header {
    @include h1;
    @include border-after();

    &::after {
      bottom: rem-calc(15);
    }

    text-align: left;
    font-weight: lighter;

    span {
      background-color: $grey3;
    }

    &-text {
      font-weight: lighter;
      font-size: $fontsize-medium;
      margin-top: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 4);
      text-align: left;
    }
  }

  .btn {
    @include btn-secondary();
  }
}
