$tab-width-max:768px;

.checkout {
  .summary {
    border-left: none;
    border-right: none;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);

    &__products-heading {
      letter-spacing: normal;
      text-transform: capitalize;

      text-align: left;
      border-bottom: none;
      font-size: $fontsize-xlarge;
      font-weight: lighter;
      padding-bottom: 0;
    }

    &__products-container {
      border-bottom: none;
    }

    &__totals-container {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0 !important;
      }
      border-color: $primary;
    }

    &__products,
    &__totals,
    &__products-container,
    &__totals-container,
    &__totals-grand-total-container {
      padding-left: 0;
      padding-right: 0;
    }

    &__totals-grand-total-container {
      padding-top: 1rem !important;
      border-bottom: none;
    }

    .arrival-time,
    .arrival-time-label {
      color: $primary;
    }
    .arrival-time {
      float: right;
    }

    .product-card {
      border-bottom: 1px solid $grey8;

      &__content {
        @media (max-width: $tab-width-max) {
          margin-left: 1rem !important;
        }
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      &__quantity {
        display: block;
        margin-top: rem-calc(10);

        &.inline-quantity {
          display: none;
        }
      }

      &__attribute {
        &--size {
          border: none;
        }
      }

      .line-item-availability {
        margin-top: map-get($spacers, 2);
      }
    }
  }
}

#editGiftMessage {
  border-color: $primary;
}

.gift-messaging {
  &__links {
    margin-bottom: 1rem;
  }
}

.gift-receipt-text-product-card {
  margin-top: -1rem;
  font-weight: normal;
  letter-spacing: rem-calc(5);
  text-transform: uppercase;
}
