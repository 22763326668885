@import "../utils/mixins";

.account-container {
  .account-header {
    display: none;

    [data-action="Account-Show"] & {
      display: flex;
    }
  }

  .account-heading,
  .account-heading + h1 {
    padding-left: $content-gutter;
    padding-right: $content-gutter;
  }
}

.account-pages-heading {
  flex-direction: column-reverse;
  &__text {
    @include h2;
    @include border-after();

    margin-bottom: map-get($spacers, 3);
    font-weight: lighter;
  }

  &--mobile & {
    align-items: left;
    border-bottom: none;

    &__component {
      display: block;

      &--heading {
        @include h2;
        @include border-after();

        font-weight: lighter;
        margin-top: rem-calc(20);
      }

      &__link {
        display: inline;
        text-align: left;
        font-weight: bold;
      }
    }
  }
}
