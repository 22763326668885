@import '../components/creditCard';

$saved-payments-list-item-min-height: rem-calc(320);
$saved-payments-list-card-icon-height: rem-calc(45);

.saved-payments-list {
  &__item-wrapper {
    @include media-breakpoint-up(md) {
      min-height: $saved-payments-list-item-min-height;
    }
  }

  &__add-wrapper {
    border: solid rem-calc(1) $secondary;
  }

  &__card-make-default {
    margin-bottom: 0;
    border-bottom: rem-calc(1) solid $border-color;

    &-input {
      margin-bottom: 0;
    }

    &.form-row label {
      font-size: $fontsize-medium;
    }
  }

  &__card-image {
    display: inline-block;
    width: $saved-payments-list-card-icon-height;
    height: auto;
  }

  &__card-address {
    border-top: 1px solid $border-color;
  }

  &__add-icon {
    color: $secondary;

    &--card {
      font-size: rem-calc(57px);
    }
    &--plus {
      font-size: rem-calc(29px);
      font-weight: bold;
    }
  }

  &__add-text {
    font-size: $fontsize-large;
  }
}

.save-payment {
  .payment-form {
    &__fields {
      @include media-breakpoint-up(lg) {
        padding: map-get($spacers, 4) map-get($spacers, 5);
      }
    }

    .cardNumber-wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    &__expiry-label {
      display: flex;
      color: $grey1;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &__expiry-disclaimer {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    &__card-number {
      @include credit-card-number-wrapper;
    }

    .payment-bottom-fields {
      padding: 0 map-get($spacers, 3);

      @include media-breakpoint-up(md) {
        padding: 0 map-get($spacers, 5);
      }
    }
  }

  .required-form-indication {
    display: none;
  }
}
