.rd {
  &-breadcrumb {
    @include breadcrumb-atom();
  }
  &-breadcrumb-atom-top {
    @include breadcrumb-atom(0.875rem, 400, 150%, 0.07rem, #1d1d1d, capitalize);
  }
  &-breadcrumb-atom-bottom {
    @include breadcrumb-atom(0.875rem, 400, 150%, 0.07rem, #1d1d1d, capitalize);
  }
}
