@import '../components/flyout';

$stores-pane-max-width: 50% !default;
$banner-height-mobile: rem-calc(70);

.appointments {
  // Allow flyouts to cover the footer
  position: relative;
  z-index: $appointment-bookings-flyouts-z-index;

  .appointments-top-slot {
    background-color: $primary;
    height: $banner-height-mobile;

    @include media-breakpoint-up(md) {
      height: $banner-height-mobile / 2;
    }

    .exclude-notification {
      font-size: rem-calc(12);
    }
  }

  &__stores {
    @include flyout($stores-pane-max-width, $stores-pane-max-width);

    &__container {
      .store-list {
        list-style: none;
      }
    }
  }
}

.appointments-booking {
  padding: rem-calc(20);
}
