.order-history {
  padding-left: 0;
  padding-right: 0;

  &__order-date {
    .short-date {
      display: inline-block;
    }
    .long-date {
      display: none;
    }
  }

  &__list {
    @include media-breakpoint-down(md) {
      margin-left: rem-calc(-20);
      margin-right: rem-calc(-20);
    }
  }

  &__order {
    background-color: $grey2;
    padding: rem-calc(20);
  }

  &__order-details {
    @include media-breakpoint-down(lg) {
      order: 1;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: wrap;

      .order-history {
        &__order-date {
          order: 1;
        }
        &__order-number {
          order: 2;
        }
        &__order-status {
          order: 3;
          @include make-col(6);
        }
        &__order-store {
          order: 4;
        }
      }
    }
  }

  &__order-items {
    overflow: hidden;
  }

  &__order-item {
    margin-right: map-get($spacers, 2);

    @include media-breakpoint-down(lg) {
      width: calc(50% - #{map-get($spacers, 2) / 2});
    }
  }

  &__additional-items {
    @include make-col(12);

    display: block;
    margin-top: map-get($spacers, 4);
    color: $primary;
    font-weight: bold;
    white-space: normal;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      @include make-col(4);

      font-weight: normal;
      margin-top: 0;
      top: calc(50% - #{$order-history-additional-items-size-desktop});
    }
  }

  &__order-details-link {
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    height: auto;
    white-space: normal;
    max-width: initial;

    &--icon {
      display: none;
    }
  }

  &__order-item.additional {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba($color: $white, $alpha: 0.6);
    }
  }
}

.order-details {
  @include media-breakpoint-up(lg) {
    &__payment,
    &__totals {
      @include make-col-ready();
      @include make-col(6);
      @include make-col-offset(6);
    }
  }

  &__summary-items {
    @include make-row();

    margin-left: 0;
    margin-right: 0;
  }

  &__summary-details,
  &__summary-addresses {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }

  &__totals-order-discount,
  &__totals-refunded {
    color: $red;
  }

  &__links {
    text-decoration: none;

    &-separator {
      display: none;
    }
  }

  &__link {
    @extend .btn;
    @include button-variant($white, $primary);

    min-width: rem-calc(200);
  }
}
