@import './flyout';

$country-gateway-width: 90% !default;
$country-gateway-max-width: rem-calc(1070) !default;
$country-selector-pane-max-width: 90% !default;
$country-selector-pane-max-width-desktop: rem-calc(440) !default;

.country-selector {
  @include flyout($country-selector-pane-max-width, $country-selector-pane-max-width-desktop);

  &__close {
    padding-right: map-get($spacers, 4);

    &-icon {
      padding-top: map-get($spacers, 2);
    }

    &__text {
      display: none;
    }
  }

  &__current {
    &__selection {
      font-size: 80%;
      font-weight: 400;
      margin-right: map-get($spacers, 1);
    }

    &__country-text {
      margin-top: map-get($spacers, 5);
    }
  }

  &__gateway {
    .modal-dialog {
      margin-left: auto;
      margin-right: auto;
      width: $country-gateway-width;
      max-width: $country-gateway-max-width;

      button.close {
        height: auto;
      }
    }

    &--form {
      padding: 0 map-get($spacers, 4);
    }
  }

  &__original-country {
    font-size: $fontsize-large;

    &-prompt {
      font-size: rem-calc($fontsize-medium);
    }
  }

  &__form {
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    margin-top: rem-calc(15);

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 5);
    }

    &__label {
      margin-bottom: map-get($spacers, 2);
    }

    &__field {
      &--country {
        .choices__list--dropdown {
          min-height: rem-calc(100);
        }
      }

      &__option {
        &__image-wrapper {
          display: inline-block;
        }
      }
    }
  }

  .choices__inner {
    background-color: $white;
    border-left: none;
    border-right: none;
  }
}
