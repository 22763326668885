.account-slot {
  color: $grey1;
  background-color: $primary;
  line-height: rem-calc(35);
}

.account-dashboard {
  &-content {
    img {
      max-width: 100%;
    }
  }
  .account-nav {
    &__item {
      font-size: $fontsize-large;
      border-top: 1px solid $border-color;
      padding-left: map-get($spacers, 4);

      &-text {
        padding-left: map-get($spacers, 2);
      }

      .font-icon {
        font-size: rem-calc(28);
        color: $secondary;
      }

      &--logout {
        border-bottom: 1px solid $border-color;
      }
    }
  }
}
