.shipment-summary {
  &__shipment-body {
    padding-top: map-get($spacers, 4);
    border-width: rem-calc(1) rem-calc(0);
    border-style: solid;
  }

  &__shipment-title {
    font-size: $fontsize-xxxlarge;
  }

  &__shipment-status {
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__shipment-status-value {
    font-weight: bold;
  }
}
