.product-detail__recently-viewed,
.product-detail_you-might-also-like {
  @include einsteinRecModule();
  position: relative;
  width: 100%;

  .product-detail__tile-row-container {
    margin-left: 0;

    @media (max-width: $bp-medium-max) {
      padding-left: 0.375rem;
    }

    .product-recommendations__heading {
      @media (max-width: $bp-small-max) {
        line-height: 1rem;
      }
    }

    .product-detail__product-bottom__heading {
      @extend .rd-recently-viewed-rec-heading;

      text-align: left;
      margin-bottom: 0 !important;

      @media (max-width: $bp-medium-max) {
        margin-bottom: 1rem;
      }

      @media (max-width: $bp-small-max) {
        font-size: 0.875rem;
        line-height: 1.05rem;
        letter-spacing: 0.175rem;
      }

      &::after {
        border-top: none;
      }
    }

    .pdp-glide-container {

      .product-detail__tile-row__item {
        padding-left: 0;
        padding-right: 0;
      }

      .glide__slide {
        @media (min-width: $bp-large) {
          max-width: 100% !important;
        }
      }

      .product-tile__body {
        padding-left: 0;
        margin-top: 0.75rem !important;

        @media (max-width: $bp-small-max) {
          font-size: 0.875rem;
          line-height: 1.3125rem;
          letter-spacing: 0.07rem;
        }

        .product-tile__name .pdp-link a {
          text-align: left;

          @media (max-width: $bp-small-max) {
            font-size: 0.875rem;
            line-height: 1.3125rem;
            letter-spacing: 0.07rem;
          }
        }

        .product-tile__name {
          margin-bottom: 0;
        }

        .product-tile__price {
          .price {
            text-align: left;

            @media (max-width: $bp-small-max) {
              font-size: 0.875rem;
              line-height: 1.3125rem;
              letter-spacing: 0.07rem;
            }

            .value {
              font-weight: 400;
            }
          }
        }

        .product-tile__promotions {
          .link {
            text-align: left;

            @media (max-width: $bp-small-max) {
              font-size: 0.875rem;
              line-height: 1.3125rem;
              letter-spacing: 0.07rem;
            }
          }
        }

        .product-tile__rating .font-icon {
          color: $black !important;
        }
      }

      .glide__arrow {
        z-index: 0;
        top: 0.625rem;
        left: unset;

        &.glide__arrow--left {
          margin-right: 1.875rem;
          right: 2rem;
          background-image: url('../../../../static/default/images/icons/recs-chevron-left.svg');
        }

        &.glide__arrow--right {
          background-image: url('../../../../static/default/images/icons/recs-chevron-right.svg');
        }
      }
    }
  }
}
