@import 'tippy.js/themes/light-border';

$card-type-icon-width: rem-calc(39) !default;
$card-type-icon-height: rem-calc(25) !default;
$card-type-icon-offset: rem-calc(8) !default;

@mixin credit-card-number-wrapper {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: $card-type-icon-offset;
    right: $card-type-icon-offset;
    width: $card-type-icon-width;
    height: $card-type-icon-height;
    background-size: contain;
  }

  &[data-type="amex"] {
    &::after {
      background-image: url('../../../../static/default/images/amex-dark.svg');
    }
  }
  &[data-type="diners"] {
    &::after {
      background-image: url('../../../../static/default/images/diners-dark.svg');
    }
  }
  &[data-type="discover"] {
    &::after {
      background-image: url('../../../../static/default/images/discover-dark.svg');
    }
  }
  &[data-type="mastercard"] {
    &::after {
      background-image: url('../../../../static/default/images/mastercard-dark.svg');
    }
  }
  &[data-type="visa"] {
    &::after {
      background-image: url('../../../../static/default/images/visa-dark.svg');
    }
  }
  &[data-type="maestro"] {
    &::after {
      background-image: url('../../../../static/default/images/maestro-dark.svg');
    }
  }
}

@mixin credit-card-cvv-wrapper {
  &__field {
    display: inline-block;
    width: calc(100% - #{rem-calc(45)});
    margin-right: map-get($spacers, 3);
    vertical-align: top;
  }

  &__tooltip-wrapper {
    display: inline-block;
    margin-top: ($form-control-height / 2) - ($icon-default-size / 2);
  }

  .tippy-popper span::before {
    content: '3';
  }

  &[data-type="amex"],
  &[data-type="diners"] {
    .tippy-popper span::before {
      content: '4';
    }
  }

  &__image {
    height: rem-calc(30);
    width: auto;
    position: absolute;
    left: calc(100% - #{rem-calc(110)});
    top: rem-calc(5);
  }
}
