.rd {
  &-left-arrow {
    @include svg-icon();
  }

  &-style-with-cta-icon {
    @include svg-icon(1.5rem, 1.5rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/stylewithCTA.svg');
  }

  &-close-icon {
    @include svg-icon(1.125rem, 1.125rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/closeicon.svg');
  }

  &-down-arrow {
    @include svg-icon(1.125rem, 1.25rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/arrowDown.svg');
  }
  &-down-arrow-small {
    @include svg-icon(1rem, 1rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/arrowDown.svg');
  }
  &-plus-sign {
    @include svg-icon(1.125rem, 1.25rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/plus.svg');
  }

  &-minus-sign {
    @include svg-icon(0.625rem, 1.25rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/minus.svg');
  }

  &-sticky-atb-wishlist-icon {
    @include svg-icon(1.5rem, 1.5rem, #1d1d1d, center, contain);
    background-image: url('../../../../static/default/images/icons/stickyATBwishlisticon.svg');
  }

  &-sticky-atb-wishlist-fill-icon {
    @include svg-icon(1.5rem, 1.5rem, #1d1d1d, center, contain);
    background-image: url('../../../../static/default/images/icons/stickyATBwishlistfill.svg');
  }

  &-outofstock-sign {
    @include svg-icon(2rem, 2rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/nostock.svg');
  }

  &-icon-fa-star {
    @include svg-icon(0.875rem, 0.8125rem, $black, center, 0.875rem 0.8125rem);
    background-image: url('../../../../static/default/images/icons/full-star.svg');
  }
  &-icon-fa-star-half-o {
    @include svg-icon(0.875rem, 0.8125rem, $black, center, 0.875rem 0.8125rem);
    background-image: url('../../../../static/default/images/icons/half-star.svg');
  }
  &-icon-fa-star-o {
    @include svg-icon(0.875rem, 0.8125rem, $black, center, 0.875rem 0.8125rem);
    background-image: url('../../../../static/default/images/icons/empty-star.svg');
  }
  &-instock-tick {
    @include svg-icon(1rem, 1rem, $light-black, center, contain);
    background-image: url('../../../../static/default/images/icons/tick.svg');
  }
  &-cross {
    @include svg-icon(1rem, 1rem, $light-black, center, contain);
    background-image: url('../../../../static/default/images/icons/cross.svg');
  }
  &-decorative-right-arrow {
    @include svg-icon(0.6763rem, 0.5875rem, $light-black, center, 0.6763rem 0.5875rem);
    background-image: url('../../../../static/default/images/icons/decorative-right-arrow.svg');
  }
  &-success-icon {
    @include svg-icon(1rem, 1rem, $light-black, center, 1rem 1rem);
    background-image: url('../../../../static/default/images/icons/success.svg');
  }
  &-store-icon {
    @include svg-icon(1rem, 1rem, $light-black, center, 1rem 1rem);
    background-image: url('../../../../static/default/images/icons/location.svg');
  }
  &-store-invalid-icon {
    @include svg-icon(1rem, 1rem, $light-black, center, 1rem 1rem);
    background-image: url('../../../../static/default/images/icons/error.svg');
  }
  &-horizontal-filter-close {
    @include svg-icon(1rem, 1rem, $light-black, center, contain);
    background-image: url('../../../../static/default/images/icons/horizontal_filter_close.svg');
  }
}

.customer-cta-rating {
  .icon-fa-star {
    @include svg-icon(0.625rem, 0.6094rem, $black, center, 0.625rem 0.6094rem);
    background-image: url('../../../../static/default/images/icons/full-star.svg');
  }
  .icon-fa-star-half-o {
    @include svg-icon(0.625rem, 0.6094rem, $black, center, 0.625rem 0.6094rem);
    background-image: url('../../../../static/default/images/icons/half-star.svg');
  }
  .icon-fa-star-o {
    @include svg-icon(0.625rem, 0.6094rem, $black, center, 0.625rem 0.6094rem);
    background-image: url('../../../../static/default/images/icons/empty-star.svg');
  }
}

.product-detail__fit-rating {
  .fit-marker {
    @include svg-icon(0.625rem, 0.625rem, #4F4F4F, center, contain);
    background-image: url('../../../../static/default/images/icons/fitmarker.svg');
  }
}

.review-star-bottom {
  .icon-fa-star {
    @include svg-icon(0.813rem, 0.875rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/full-star.svg');
  }
  .icon-fa-star-half-o {
    @include svg-icon(0.813rem, 0.875rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/half-star.svg');
  }
  .icon-fa-star-o {
    @include svg-icon(0.813rem, 0.875rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/empty-star.svg');
  }
}
