.product-detail__product-images {
  @media only screen and (max-width: $sm-width) {
    div[data-test="gallery-thumbnail-wrap"] {
      position: absolute;
      bottom: 1rem;
      z-index: 2;
    }
    div[data-test="gallery-spacer"] {
      display: none;
    }
  }
}

.product-set-detail {
  @media only screen and (max-width: $sm-width) {
    div[data-test="gallery-thumbnail-wrap"] {
      display: none;
    }
    div[data-test="gallery-spacer"] {
      display: none;
    }
    div[data-test="indicator-item-0"] {
      background-color: $mid-grey;
    }
  }
}
