@import "~core-css/utils/sizing";

@import "fonts";
@import "variables";
@import "variables/components/common";
@import "variables/components/forms";
@import "variables/components/buttons";
@import "variables/components/backToTop";
@import "variables/components/countrySelector";
@import "variables/components/miniCart";
@import "variables/components/menu";
@import "variables/components/gridSizeSelector";
@import "variables/components/checkout/progressIndicator";
@import "variables/components/checkout/savedAddresses";
@import "variables/components/checkout/hubbox";
@import "variables/components/checkout/pages/billing";
@import "variables/components/productDetail";
@import "variables/pages/login";
@import "variables/pages/orderHistory";
@import "variables/pages/productDetail";
@import "variables/pages/search";
@import "variables/pages/cart";
@import "variables/utils/icons";

@import "~core-css/redesignstyle";
@import "bootstrapOverrides";

@import "utils/mixins";
@import "components/common";
@import "components/forms";
@import "components/buttons";
@import "components/header";
@import "components/headerSearch";
@import "components/menu";
@import "components/addressForm";
@import "components/password";
@import "components/backToTop";
@import "components/countrySelector";
@import "components/productCard";
@import "components/footer";
@import "components/shippingSummary";
@import "components/miniCart";
@import "components/breadcrumbs";
@import "components/spinner";
@import "components/recs";
@import "pages/login";
@import "pages/trackOrder";
@import "pages/dashboard";
@import "pages/editProfile";
@import "pages/addresses";
@import "pages/savedPayments";
@import "pages/cart";
@import "pages/preferenceCenter";
@import "pages/orderHistory";
@import "pages/storeLocator";
@import "pages/contactUs";
@import "pages/error";
@import "pages/checkout";
@import "pages/wishlist";
@import "pages/content";
@import "pages/confirmation";
@import "pages/productDetail";
@import "pages/oldpdp";
@import "pages/search";
@import "pages/newsletter";
@import "pages/addToBagOverlay";
@import "pages/cldGalleryCustomization";
