@import '../zIndexes';

$wishlist-footer-width: 90%;
$wishlist-indicator-width: rem-calc(185);
$wishlist-indicator-arrow-size: rem-calc(20);
$wishlist-product-bottom-margin: rem-calc(150);

.wishlist {
  &.header-element {
    position: relative;
  }

  &__header {
    text-align: center;
  }

  &__indicator {
    position: absolute;
    top: calc(100% + #{$wishlist-indicator-arrow-size});
    right: calc(50% - #{$wishlist-indicator-width / 2});
    width: $wishlist-indicator-width;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: $wishlist-indicator-z-index;

    &--active {
      pointer-events: auto;
      opacity: 1;
    }

    &__content {
      position: relative;
      background-color: $white;
      text-align: center;
    }

    &__icon {
      svg {
        width: $fontsize-xxlarge;
        height: $fontsize-xxlarge;
      }
    }

    &__arrow-up {
      display: block;
      position: absolute;
      top: -($wishlist-indicator-arrow-size / 2);
      right: 0;
      left: 0;
      margin: 0 auto;
      width: $wishlist-indicator-arrow-size;
      height: $wishlist-indicator-arrow-size;
      transform: rotate(45deg);
      background-color: $white;
    }
  }

  &__send-email,
  &__send-product-email {
    a {
      font-weight: bold;
    }
  }

  &__send-email {
    a {
      text-decoration: underline;
    }
  }

  &__send-product-email {
    margin-top: map-get($spacers, 4);
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  .product {
    margin-bottom: $wishlist-product-bottom-margin;
  }

  .navigation-link {
    color: $gold;
  }

  &__close-button-wrapper {
    right: rem-calc(20px);
    z-index: $wishlist-close-button-wrapper-z-index;

    @include media-breakpoint-down(xs) {
      right: 5.5%;
    }

    button {
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }

  &__size-selector {
    .form-group {
      margin-bottom: 0;
    }
    select {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }

  &__product-added {
    background: $grey9;
  }

  &__product-footer {
    width: $wishlist-footer-width;
    bottom: 0;
    position: absolute;
  }

  .product-info {
    @include make-col-ready();
    @include make-col(6);

    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 3);
      padding-right: map-get($spacers, 3);
    }
  }
}
