.store-search-field {
  position: relative;

  .detect-location {
    position: absolute;
    top: 0;
    left: 0;
    width: rem-calc(40);
    height: rem-calc(40);
    line-height: rem-calc(20);
    z-index: 1;

    + .form-group {
      width: calc(100% - #{rem-calc(40)});
      margin-left: rem-calc(40);
    }
  }
}

#preferredStoreForm {
  .address-form-container {
    position: relative;
  }
  .detect-location-text {
    display: none;
  }
  .stores-list {
    position: absolute;
    width: 100%;
    top: 100%;
    max-height: rem-calc(200);
    overflow-x: auto;
  }

  .store-results-container {
    z-index: $storeLocator-store-results-container-z-index;
    position: absolute;
    top: 100%;
    background-color: $white;
    border-left: rem-calc(1) solid $border-color;
    border-right: rem-calc(1) solid $border-color;
    border-bottom: rem-calc(1) solid $border-color;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        padding: map-get($spacers, 2);
        margin-bottom: map-get($spacers, 2);

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover {
          color: $white;
          background-color: $grey7;
        }
      }
    }
  }
}
