$latterSpacing: 1.12px;
#paymentMethodsList {
  .paymentMethod {
    .checkout-billing__method-name {
      font-weight: 600;
    }
    .additionalFields {
      padding: 0 16px;
      margin: 0;
      div[class$=__buttons] {
        padding: 16px 16px 24px 16px;
        @include media-breakpoint-up(lg) {
          width: 400px;
          margin-left: 32px;
          padding-left: 0;
          padding-right: 0;
        }
      }
      .adyen-checkout__label__text,
      .adyen-checkout__checkbox__label,
      .adyen-checkout__error-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: $latterSpacing;
        text-transform: capitalize;
        color: #1d1d1d;
      }
      .adyen-checkout__input {
        color: #00112c;
        font-size: 16px;
        font-weight: 400;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      }
      .adyen-checkout__label__text--error,
      .adyen-checkout__label--focused .adyen-checkout__label__text {
        color: #1d1d1d;
      }
      .adyen-checkout__error-text {
        color: #bd2d20;
      }
      .adyen-checkout__input--error,
      .adyen-checkout__input--valid {
        border-color: #cac7c0;
      }
      label,
      .adyen-checkout__label {
        margin: 0;
        .adyen-checkout__label__text {
          padding-bottom: 8px;
          color: #1d1d1d;
          font-family: "Proxima Nova";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 4.8px;
          text-transform: uppercase;
        }
      }
      .adyen-checkout__input--focus,
      .adyen-checkout__input:active,
      .adyen-checkout__input:focus,
      .adyen-checkout__input:active:hover,
      .adyen-checkout__input:focus:hover,
      .adyen-checkout__input--focus:hover {
        border-color: #cac7c0;
        box-shadow: unset;
      }
      .adyen-checkout__checkbox__input:hover + .adyen-checkout__checkbox__label::after,
      .adyen-checkout__checkbox__input:active + .adyen-checkout__checkbox__label::after {
        border-color: $black;
        box-shadow: unset;
      }
      .adyen-checkout__field {
        margin-bottom: 16px;
        margin-top: 0;
      }
      .adyen-checkout-input {
        &__inline-validation--invalid {
          background-image: url('../../../../static/default/images/icons/invalid.svg');
          .adyen-checkout__icon {
            display: none;
          }
        }
      }
      .adyen-checkout__card__exp-cvc {
        @include media-breakpoint-down(md) {
          flex-direction: column;
          .adyen-checkout__field--storedCard {
            margin-bottom: 16px;
          }
          .adyen-checkout__field__cvc {
            margin: 0;
          }
        }
      }
    }
    div[id*=component_storedCar] {
      @include media-breakpoint-up(lg) {
        padding-right: 242px;
      }
      padding: 0 56px 24px;
      .adyen-checkout__field {
        margin-bottom: 0 !important;
        width: 100%;
      }
      @include media-breakpoint-down(md) {
        .adyen-checkout__field--storedCard:first-of-type {
          margin-bottom: 16px !important;
        }
      }
    }
    label[id=lb_scheme] {
      padding: 22px 0;
      .custom-selector-heading {
        flex: 0 0 45%;
        max-width: 45%;
      }
      .custom-image-container {
        width: 148px;
        max-width: 148px;
        flex: 0 0 45%;
      }
    }
    div[id=component_scheme] {
      background-color: #f8f8f8;
      .adyen-checkout__card-input {
        padding: 24px 0 8px 0;
        @include media-breakpoint-up(lg) {
          width: 400px;
        }
      }
      padding-top: 0;
      padding-bottom: 16px;
      .adyen-checkout__field:last-of-type {
        margin-bottom: 0 !important;
      }
      .adyen-checkout__store-details {
        margin: 0;
      }
      .adyen-checkout__card__cardNumber__brandIcon {
        display: none;
      }
      .adyen-checkout__card__exp-cvc {
        @include media-breakpoint-down(md) {
          margin-bottom: 16px;
        }
      }
    }
    p:has(+ #component_paypal) {
      display: none;
    }
    .adyen-checkout__card__cvc__hint__wrapper {
      margin-right: 16px;
    }
  }
}

.adyen-checkout__input-wrapper {
  .adyen-checkout__input {
    border-radius: 0;
    height: 48px;
    padding: 8px 16px;
    border-color: #cac7c0;
  }
  .adyen-checkout-input__inline-validation--valid {
    display: none;
  }
  .adyen-checkout__input::placeholder {
    color: #817d78 !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
  }
  div[class*=adyen-checkout__card__exp-date] {
    display: flex;
    align-items: center;
  }
  input[name=holderName] {
    padding-bottom: 16px;
  }
  input[name=holderName] + .adyen-checkout-input__inline-validation > .adyen-checkout__icon {
    padding-bottom: 16px;
  }
}
.adyen-checkout__store-details {
  .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label {
    line-height: 21px !important;
    padding-left: 32px;
    &::before {
      height: 18px;
      width: 9px;
      left: 2px;
      top: -3px;
    }
    &::after {
      border: 1px solid $black;
      height: 21px;
      width: 21px;
      border-radius: 0;
    }
  }
  .adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label {
    &::after {
      background-color: #fff;
      border: 1px solid $black;
      color: $black;
      box-shadow: unset;
    }
    &::before {
      border-color: transparent $black $black transparent;
      border-radius: 0;
      border-width: 1px;
      transform: rotate(45deg);
    }
  }
  .adyen-checkout__checkbox__input:active + .adyen-checkout__checkbox__label,
  .adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label {
    &::after {
      border: 1px solid $black;
      box-shadow: unset;
    }
  }
}
