@import '../components/intlTelInput';

.newsletter-subscription,
.competition-subscription {
  &.container {
    padding-top: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);
  }
  .right-side-offset {
    @include media-breakpoint-up(md) {
      @include make-col-offset(1);
    }
  }
  .newsletter-form,
  .competition-form {
    .form-group {
      width: 100%;
      padding: 0;
    }

    .form-field-description {
      &--phone {
        margin-top: -$forms-row-margin-bottom;
        margin-bottom: $forms-row-margin-bottom;
        color: $grey1;
      }
    }

    .phone-fields {
      margin-top: map-get($spacers, 4);
    }

    &__optin {
      &__field {
        margin-bottom: map-get($spacers, 2);
      }
    }

    &__terms-link,
    &__privacy-link {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .address-fields {
    &__row {
      &--lookup ~ &:nth-of-type(even) {
        padding-right: map-get($spacers, 0);
      }
    }
  }

  .newsletter-hero {
    display: none;
  }

  .country-dial-code-newsletter {
    margin: 1.5rem 0;
  }
}

.country-dial-code-newsletter {
  margin: 0;

  @include media-breakpoint-up(xl) {
    width: 30%;
  }
}

.down-arrow-newsletter {
  left: 6rem;

  @include media-breakpoint-down(sm) {
    left: 5.5rem;
  }
}

.newsletter-page {

  .mobile-number {
    @include media-breakpoint-up(lg) {
      width: 60% !important;
    }
    @include media-breakpoint-up(xl) {
      width: 70% !important;
    }
  }
}

.intl-tel-input {
  .country-list {
    bottom: 5rem;
  }
}
