@media print {
  header,
  footer,
  .no-print,
  .header-banner,
  .account-header,
  .order-detail-links,
  .back-btn {
    display: none !important;
  }
}
