.einsteinCustomStyles {
  h2.custom-recommendations-title {
    letter-spacing: 8.4px;
    line-height: 26px;
    font-size: 30px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    max-width: 97%;
    text-transform: uppercase;
    padding: 0 0 12px;
  }
  h2.custom-recommendations-title span {
    line-height: 67px;
    font-size: 39px;
    letter-spacing: 12px;
    display: block;
  }
  h2.custom-recommendations-title span::after {
    content: '';
    min-width: 76.4%;
    position: absolute;
    top: 72px;
    border-bottom: 1px solid #cbc7bf;
  }
  .product-recommendations__heading::after {
    border-top: 0;
  }
  .product-recommendations__heading {
    margin-bottom: 0 !important;
  }
  .plpRecsHeadingSpan {
    font-size: 29px !important;
    letter-spacing: 12px !important;
    line-height: 29px !important;
    @include media-breakpoint-down(md) {
      line-height: 30px !important;
      font-size: 4vw !important;
      letter-spacing: 1.1vw !important;
    }
  }
  .plpRecsHeadingSpan::after {
    top: 22px !important;
    @include media-breakpoint-down(md) {
      top: 22px !important;
    }
  }
  .cartRecsHeadingSpan {
    line-height: 14px !important;
    font-size: 22px !important;
    @include media-breakpoint-down(md) {
      line-height: 40px !important;
      font-size: 22px !important;
      margin-bottom: 10% !important;
      letter-spacing: 10px !important;
    }
  }
  .cartRecsHeadingSpan::after {
    top: 14px !important;
    @include media-breakpoint-down(md) {
      top: 85px !important;
      border-bottom: 0 !important;
    }
  }
  a {
    text-align: center;
    display: block;
    margin: 0 auto;
    font-family: Proxima Nova, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    color: #333;
  }
  .product-tile__sticker.product-tile__sticker--feature {
    display: none;
  }
  .product-tile__body {
    margin-top: 2rem;
  }
  .value:nth-child(1) {
    color: #8b0000;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 4.2px;
    text-align: center;
    margin-right: 10px;
  }
  .value:nth-child(2) {
    color: #1c3632;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 4.2px;
    text-align: center;
    margin-right: 10px;
  }
  .product-tile__rating {
    display: none;
  }
  .callout.my-2 {
    display: none;
  }
  .product-tile__add-overlay-size {
    border-radius: 0;
  }
  .product-tile__action:active .product-tile__action:hover {
    color: #fff !important;
  }
}

.product-detail__product-carousel {
  .container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.cart-page {
  .cartRecsBorder::after {
    border-top: 0 !important;
    @include media-breakpoint-down(md) {
      border-top: 0.0625rem solid #cbc7bf !important;
      bottom: 27% !important;
    }
  }
}
