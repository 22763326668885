$order-details-card-icon-width: rem-calc(45);
$order-history-additional-items-size: rem-calc(48) !default;
$order-history-additional-items-size-desktop: rem-calc(48) !default;
$order-details-address-label-width: rem-calc(150);

.order-history {
  padding-left: map-get($spacers, 5);
  padding-right: map-get($spacers, 5);

  &__order-date .short-date {
    display: none;
  }

  &__body {
    margin-left: 0;
    margin-right: 0;
  }

  &__list-paging {
    text-align: center;

    .paging {
      &__next-btn {
        @include btn-secondary();
      }
    }
  }

  &__order-wrapper {
    border: solid rem-calc(1) $border-color;
  }

  &__order-details {
    order: 2;
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }

  &__order-items {
    @include touch-scroll();

    overflow-y: hidden;
    order: 1;

    @include media-breakpoint-up(lg) {
      display: flex;
      order: 2;
    }
  }

  &__order-item {
    display: inline-block;
    width: 40%;
    vertical-align: middle;
    margin-right: map-get($spacers, 3);

    @include media-breakpoint-up(lg) {
      width: percentage(1 / 3);
      margin-right: map-get($spacers, 2);
    }

    &__pre-order {
      padding: 3px 18px 4px 4px;
      width: fit-content;
      background-color: $WhiteChocolate;
    }
  }

  &__additional-items {
    @include make-col-ready();
    @include make-col(4);

    display: none;
    font-size: $order-history-additional-items-size;
    line-height: $order-history-additional-items-size;

    @include media-breakpoint-up(lg) {
      display: inline;
      position: absolute;
      right: 0;
      top: calc(50% - #{$order-history-additional-items-size-desktop / 2});
      font-size: $order-history-additional-items-size-desktop;
      line-height: $order-history-additional-items-size-desktop;
      pointer-events: none;
      color: $secondary;
    }
  }

  &__order-links {
    order: 3;
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
  }

  &__order-details-link {
    &--icon {
      display: none;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }
  }
}

.order-details {
  &__summary {
    margin-left: 0;
    margin-right: 0;
  }

  .product-card {
    position: relative;

    @mixin top-right {
      position: absolute;
      top: 0;
      right: 0;
    }

    @mixin price-top-right {
      @include top-right;

      font-size: $fontsize-xlarge;
      font-weight: bold;
    }

    @mixin status-top-right {
      @include top-right;

      padding-top: rem-calc(20);
      text-align: right;
    }

    &__price {
      text-align: left;

      @include media-breakpoint-up(lg) {
        @include price-top-right;
      }
      @media print {
        @include price-top-right;
      }
    }

    &__status {
      @include media-breakpoint-up(lg) {
        @include status-top-right;
      }
      @media print {
        @include status-top-right;
      }
    }

    &__attribute {
      &--size {
        border: none;
      }
    }

    &__quantity {
      display: block;
      margin-top: map-get($spacers, 2);

      &.inline-quantity {
        display: none;
      }
    }
  }

  &__links {
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);

    span {
      font: normal normal normal 17px/21px Lato;
      letter-spacing: 0.36px;
      color: #000;
      text-transform: uppercase;
      opacity: 1;
      font-family: $font-family-sans-serif;
      line-height: 30px;
    }
  }
  &__link {
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0 12px;
    border: 1px solid #333;
    min-width: rem-calc(160) !important;
    height: auto;
  }

  &__payment {
    font-size: $fontsize-xlarge;
  }

  &__payment-title {
    font-weight: bold;
  }

  &__payment-card-type-image {
    display: inline-block;
    width: $order-details-card-icon-width;
    height: auto;
  }

  &__totals-start-line {
    font-weight: bold;
  }

  &__summary-items {
    @include media-breakpoint-up(lg) {
      @include make-col(9);
    }
  }

  &__summary-item {
    &--payment-method {
      display: none;
    }

    &--shipping-address,
    &--billing-address {
      b {
        width: $order-details-address-label-width;
      }
      .order-details__summary-item-label,
      .order-details__summary-item-value {
        float: left;
      }
    }
    &--billing-address {
      display: none;
    }
  }
}
