.site-search {
  width: 100%;
  position: relative;
  padding: 0;

  @include media-breakpoint-up(lg) {
    max-width: rem-calc(420);
    margin: auto;
  }

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &__controls {
    .clear-search {
      top: rem-calc(-25);
      right: rem-calc(45);
      font-size: $fontsize-small;
      color: $grey5;
      cursor: pointer;
      text-transform: capitalize;

      &__icon {
        display: none;
      }
    }

    .search-button {
      height: auto;
      line-height: 1;
      top: rem-calc(-31);
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
      right: 0;

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  .fa-close,
  .fa-search {
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(10);
  }

  .fa-search {
    padding-left: rem-calc(10);
    font-size: rem-calc($fontsize-medium);
    border-left: 1px solid $white;

    @include media-breakpoint-up(md) {
      border-color: $grey2;
    }
  }

  input {
    font-size: rem-calc($fontsize-small);
    height: rem-calc(35);
    line-height: rem-calc(35);
    padding-right: rem-calc(115);

    &.form-control {
      border-radius: 0;
      background-color: $grey9;
      color: $black;
      letter-spacing: 0.04em;
      font-size: $fontsize-large;

      &::placeholder {
        color: $black;
        line-height: rem-calc(35);

        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }
      }

      @include media-breakpoint-down(sm) {
        border: none;
      }

      @include media-breakpoint-up(md) {
        font-size: $fontsize-medium;
        background-color: $white;
      }
    }
  }

  input:placeholder-shown ~ &__controls > .clear-search {
    display: none;
  }
}

.search-icon-wrapper {
  border-left: 1px solid $grey3;

  &:hover,
  &:active {
    border-left-color: $grey3;
  }

  @include media-breakpoint-down(md) {
    border-left-color: $white;

    &:hover,
    &:active {
      border-left-color: $white;
    }
  }

  @include media-breakpoint-up(lg) {
    right: 0;
  }
}

.suggestions-wrapper {
  .container {
    width: 100%;
  }
}
.trending-suggestions {
  display: none;

  &--active {
    display: block;
  }

  .suggestions {
    display: block !important;
  }
}

.suggestions {
  position: absolute;
  width: 100%;
  left: 0;
  background-color: $grey9;
  margin-top: map-get($spacers, 1);
  margin-left: auto;
  margin-right: auto;
  padding: map-get($spacers, 3);
  z-index: $header-search-suggestions-z-index;

  @include media-breakpoint-up(md) {
    background-color: $white;
    width: rem-calc(755);
    left: calc(100% - 490px);
    top: rem-calc(42);
    border: rem-calc(1) solid #dee2e6;
  }

  &-container {
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
  }

  &__view-all {
    display: none;
  }

  &__content {
    @include make-col-ready();
    @include make-col(10);

    @include media-breakpoint-up(md) {
      @include make-col(3);
    }

    &--title {
      display: none;
    }

    &--categories {
      @include make-col-ready();
      @include make-col(12);
    }

    &--articles {
      display: none;
    }
  }

  &__products {
    @include make-col-ready();
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    .items {
      @include make-col-ready();

      @include media-breakpoint-up(md) {
        @include make-col(3);
      }

      .product {
        background-color: $white;
      }
    }

    .product-tile__outofstock--sticker {
      font-size: 1rem !important;
    }
  }

  .clear-search-footer {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  ul {
    list-style: none;

    li {
      color: $black;

      a {
        color: $black;
      }
    }
  }

  .suggestions-title {
    display: none;
  }

  .popular-search-wrapper {
    margin-bottom: 0;
    padding-top: map-get($spacers, 3);
    text-align: center;
    border-top: rem-calc(1) solid $border-color;

    li {
      display: block;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }

    .separator {
      display: block;
      margin-left: map-get($spacers, 1);
      margin-right: map-get($spacers, 1);

      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }
  }

  .suggestions__content {
    ul {
      padding-left: map-get($spacers, 2);

      li {
        display: block;
        padding-top: map-get($spacers, 2);
        padding-left: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 2);

        &:first-child {
          padding-top: 0;
          padding-left: map-get($spacers, 3);
        }

        a {
          padding: rem-calc(10) rem-calc(16);

          &:hover {
            background-color: $grey3;
          }
        }
      }
    }

    .popular-search-wrapper {
      padding-top: 0;
      border-top: none;
      text-align: left;

      .separator {
        display: none;
      }
    }
  }

  .suggestion-footer {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .product-suggestions,
  .suggestions__content {
    .product-tile__body {
      padding-left: 0;
      padding-right: 0;
      font-size: rem-calc($fontsize-small);
    }
  }

  .category-parent {
    font-size: rem-calc($fontsize-medium);
    opacity: 0.8;
  }

  .product-tile__action {
    &.add {
      display: none;
    }
  }

  .arrow-up {
    display: none;
    border: solid $grey3;
    border-width: 0 rem-calc(1) rem-calc(1) 0;
    padding: rem-calc(7);
    transform: rotate(-135deg);
    left: 40%;
    top: rem-calc(-9);

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .highlight {
    font-weight: bold;
  }

  .close-button-wrapper {
    position: absolute;
    right: rem-calc(15);
    top: rem-calc(5);
    z-index: 1;

    .close-button {
      opacity: 1;
      color: $black;
      height: auto;
      float: none;

      &:hover {
        color: $black;
      }

      .icon-cross {
        &::before {
          font-size: rem-calc($fontsize-medium);
        }
      }
    }
  }
}
