// Containers
$container-max-widths: () !default;
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1240px
  ),
  $container-max-widths
);

// Font sizes
$global-font-size: 100% !default;
$fontsize-small: 11px !default;
$fontsize-medium: 14px !default;
$fontsize-xmedium: 15px !default;
$fontsize-large: 16px !default;
$fontsize-xlarge: 18px !default;
$fontsize-xxlarge: 20px !default;
$fontsize-xxxlarge: 26px !default;

// Font weight
$global-weight-normal: 400 !default; /* Lato Regular */
$global-weight-bold: 700 !default; /* Lato Bold */
$global-weight-ultra-bold: 900 !default; /* Lato Black */

// Fonts
$font-family-sans-serif: 'Lato', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !default;
$body-font-family: $font-family-sans-serif !default;
$icon-font: null !default;
$icon-font-fa: 'FontAwesome' !default;
$fa-css-prefix: icon-fa !default;
$icon-font-wash: 'washicons' !default;

// This restores the original value of the variable overridden in SFRA
$flag-icon-css-path: "../flags/" !default;

// Body background
$body-bg: transparent !default;
$page-bg: white !default;
$primary: black !default;
$secondary: #c9b38e !default;
$contrast: #a34f79 !default;
$dark-background: #353535;

// Colors
$gold: #a58d69 !default;
$gold1: #c8ad85 !default;
$gold2: #f0e8e7;
$lipstick: #d52664 !default;
$grey9: #f3f3f3 !default;
$grey10: #efeeee !default;
$btn-disabled-background-color: #e2dfdf;
$text-dark: #343a40;
$grey14: #00000029;
$grey15: #d2d2d2;
$WhiteChocolate: #efe4de;
$neutral-grey: #828282;
$mid-grey: #817d78;
$cultured: #faf8f5;
$dark-red: #bb271a;

// Overridden colors
$grey1: #666 !default;
$grey2: #d7d7d7 !default;
$grey3: #ebebeb !default;
$grey-4: #bdbdbd;
$grey5: #545454 !default;
$gray6: #f6f3eb;
$red: #c00 !default;
$green: #0c9533 !default;

$spacer: 1rem;

$spacers: (
  6: ($spacer * 4)
) !default;

// berakpoints
$media-breakpoint-tablet: 64rem;
$md-extra-large: 80rem;
$md-extra-large-max: $md-extra-large + rem-calc(1);

$menu-animations-duration: 0.3s;
$slide-transition: all $menu-animations-duration cubic-bezier(0.785, 0.135, 0.15, 0.86);

$breadcrumb-item-padding: 0.2rem;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'light': $grey1,
  ),
  $theme-colors
);

// HTML Headings
$headings-font-family: $body-font-family !default;
$headings-text-transform: none !default;

$h1-letter-spacing: null !default;
$h2-letter-spacing: null !default;
$h3-letter-spacing: null !default;
$h4-letter-spacing: null !default;
$h5-letter-spacing: null !default;
$h6-letter-spacing: null !default;

// Overwrite bootsrap gutter width
$grid-gutter-width: 20px !default;

//breakpoints
$media-breakpoint-tablet: 1024px;

$enable-rounded: false;
$bp-tablet-med: 1024px !default;
$bp-tablet-sm-max: ($bp-tablet-med - 1) !default;

@import "~app_storefront_base-css/variables";
