.account-container {
  @include media-breakpoint-up(lg) {
    padding-left: $content-gutter;
    padding-right: $content-gutter;
  }

  .account-slot {
    color: $primary;
    background-color: $beige;
  }

  .account-dashboard {
    .account-nav {
      &__menu,
      &__item--logout {
        @include media-breakpoint-up(lg) {
          max-width: rem-calc(300);
        }
      }

      &__item {
        text-transform: uppercase;
        font-size: $fontsize-medium;
        border-color: $primary;
        padding-left: map-get($spacers, 2);
        letter-spacing: rem-calc(5.6);

        &-icon .font-icon {
          padding: 0;
          text-align: left;
          font-size: $fontsize-large;
          &.icon-exit {
            font-size: rem-calc(13);
          }
        }

        &-text {
          text-align: left;
        }

        &--current {
          background-color: $grey3;
        }
      }
    }
  }
}
