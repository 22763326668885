.order-content {
  .summary {
    &__products-heading {
      @include border-after();

      text-align: left;
      border: none;
      font-size: $fontsize-xlarge;
      margin-bottom: rem-calc(40);
    }
  }

  .product-card__content {
    display: flex;
    flex-direction: column;

    &__heading {
      font-weight: lighter;
      font-size: $fontsize-medium;
    }

    .product-card {
      &__name {
        order: 1;
      }

      &__price {
        order: 2;
        margin-bottom: map-get($spacers, 2);

        &__promo {
          order: 3;
          margin-bottom: map-get($spacers, 2);
        }
      }

      &__attribute {
        &--color {
          order: 4;
        }

        &--size {
          order: 5;
          border: none;
        }
      }

      &__quantity {
        display: block;
        order: 6;
      }
    }
  }

  .order-details {
    &__summary-item-label {
      @include h5;
    }

    &__summary-details,
    &__summary-addresses {
      @include media-breakpoint-up(md) {
        @include make-col(12);
      }
    }

    &__summary-items {
      padding: 0;
    }

    &__summary-item {
      @include make-col-ready();
      @include make-col(12);

      display: flex;
      flex-direction: column;
      margin-bottom: map-get($spacers, 5);

      &--type,
      &--date {
        display: none;
      }

      &--shipping-address,
      &--billing-address {
        display: flex;
      }
    }

    &__payment-card-number {
      span {
        font-weight: bold;
      }
    }
  }

  &__print-btn {
    @include btn-primary();
    @include make-col-ready();
    @include make-col(5);

    margin: 0;

    &--icon {
      display: none;
    }
  }

  &__footer {
    font-weight: bold;
  }

  &__right {
    &--container {
      border: none;

      legend::after {
        border: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__left {
      padding-right: map-get($spacers, 6);
    }
    &__right {
      padding-left: map-get($spacers, 6);
    }
  }
}

.confirmation {
  &.checkout-shipping {
    &__type-link {
      border: none;
      background-color: $grey3;

      &--wrapper {
        max-width: rem-calc(400);
        text-align: left;
        margin: 0 auto;
      }
    }
  }

  .checkout-shipping {
    &__type-indicator {
      display: none;
    }

    &__type-title {
      @include h2;
      @include border-after();

      font-weight: normal;

      span {
        background-color: $grey3;
      }

      &::after {
        border-color: $primary;
      }
    }
  }

  &__register-user-container {
    text-align: left;
    max-width: 100%;
    margin: 0;

    a {
      font-weight: bold;
    }
  }

  &__order-summary {
    @include h2;

    font-weight: normal;
  }

  &__login-form {
    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }

    .error-summary {
      background-color: transparent;
    }
  }
}
