.rd {
  &-spanElement {
    @include textElement();
  }

  &-productbadge {
    @include textElement(0.75rem, $font-weight: 400, $letter-spacing: 2.4px, $text-transform: uppercase, $line-height: 0.9rem);
  }

  &-productTitle {
    @include textElement(1.25rem, $font-weight: 400, $letter-spacing: 1.6px, $line-height: 1.875rem, $color: #000);
  }

  &-productPrice {
    @include textElement(1.25rem, $font-weight: 400, $letter-spacing: 1.6px, $line-height: 1.875rem);
  }

  &-productPromo {
    @include textElement(0.75rem, $font-weight: 400, $letter-spacing: 2.4px, $text-transform: uppercase, $color: $white);
  }

  &-fitValuetext {
    @include textElement(0.75rem, $font-weight: 400, $letter-spacing: 0.15rem, $text-transform: uppercase);
  }

  &-colorText {
    @include textElement(0.75rem, $font-weight: 600, $letter-spacing: 0.15rem, $text-transform: uppercase, $line-height: 0.9rem);
  }
  &-featureText {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.42px, $text-transform: capitalize, $line-height: 1.3125rem);
  }
}
