.track-order {
  margin-top: map-get($spacers, 5);

  &--page {
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 5);
  }

  .trackorder-container {
    border: 1px solid $border-color;
  }

  .track-order-header,
  .track-order-header-text {
    text-align: center;
  }
}
