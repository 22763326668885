.checkout-login {
  max-width: rem-calc(550);

  .checkout-as-guest {
    @include btn-secondary();
  }

  &__col,
  .col {
    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }
  }

  .card {
    border: none;
  }

  .card-body {
    padding: rem-calc(20) 0;

    .description {
      h6 {
        @include h5;
      }
    }

    .forgot-password {
      a {
        font-weight: bold;
      }
    }
  }

  .page-title {
    @include h2;
    @include make-col(12);
    @include border-after();

    display: block;
    flex: none;
    text-align: left;
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .page-subtitle {
    position: static;
    padding: 0;
    max-width: none;
    text-align: left;
    text-transform: none;
    font-weight: lighter;
    font-size: $fontsize-medium;
    letter-spacing: rem-calc(1);
    margin-bottom: map-get($spacers, 5);
  }

  .btn-choice-label {
    @include h4;
    @include border-after($forceAlignLeft: false);

    span {
      padding: 0 rem-calc(8) 0 rem-calc(16);
    }
  }

  &__options-separator {
    @include border-after($forceAlignLeft: false);

    display: block;
    font-weight: normal;

    span {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }

    &::after {
      bottom: rem-calc(9);
    }
  }
}
