.address-lookup {
  margin-left: rem-calc(-25);
  margin-right: rem-calc(-25);

  @include media-breakpoint-down(md) {
    margin-left: rem-calc(-10);
    margin-right: rem-calc(-10);
  }

  &__body {
    background-color: $grey2;
    padding: map-get($spacers, 3);
  }

  &__field[placeholder] {
    padding: rem-calc(5 5 5 40);
  }

  &__field-wrapper {
    position: relative;
  }
  &__icon {
    position: absolute;
    top: calc(50% - #{$icon-default-size / 2});
    left: rem-calc(15);
  }

  &__footer {
    padding: map-get($spacers, 3);
  }
}
