$color-swatch-size: rem-calc(32) !default;
$color-swatch-border-radius: 50% !default;
$color-selected-swatch-padding: rem-calc(3);
$flyout-style-headings: false !default;
$product-content-padding: rem-calc(10);
$product-size-attribute-height: rem-calc(45);
$product-add-to-wishlist-size: $button-height;
$product-add-to-wishlist-offset: rem-calc(5);
$product-add-to-wishlist-icon-size: rem-calc(24);
$product-delivery-pane-max-width: 90% !default;
$product-delivery-pane-max-width-desktop: rem-calc(600) !default;
$product-stock-check-pane-max-width: 90% !default;
$product-stock-check-pane-max-width-desktop: rem-calc(754) !default;
$product-stock-check-result-min-height: rem-calc(530) !default;
$product-stock-check-color-swatch-size: rem-calc(22) !default;
$product-stock-check-color-swatch-offset: rem-calc(4) !default;
$product-stock-check-product-col-width-desktop: rem-calc(420) !default;
$product-stock-check-color-result-details-offset: rem-calc(20) !default;
$product-stock-check-color-result-details-arrow-size: rem-calc(20) !default;
$product-stock-check-color-result-stores-width: $product-stock-check-product-col-width-desktop - $product-stock-check-color-result-details-offset !default;
$product-reviews-pane-max-width: 90% !default;
$product-reviews-pane-max-width-desktop: rem-calc(750) !default;
$product-size-chart-pane-max-width: 90% !default;
$product-size-chart-pane-max-width-desktop: rem-calc(440) !default;
$product-content-details-width: rem-calc(420) !default;
$continuity-flyout-pane-max-width: 90% !default;
$continuity-flyout-pane-max-width-desktop: rem-calc(440) !default;
$mobile-m-width: 375px;
$mobile-max-width: 768px;
$product-content-padding: rem-calc(10);

.product-detail {
  //old storefinder css
  &__stock-check {
    @include flyout($product-stock-check-pane-max-width, $product-stock-check-pane-max-width-desktop, $flyout-style-headings);
    margin-bottom: 1.5rem;
    padding: 0;
    &__container {
      padding: 0 map-get($spacers, 3);
    }

    &__link {
      width: 100%;
      text-align: left;

      &:hover {
        text-decoration: none;
      }
    }

    &__content {
      .detect-location {
        top: 50%;
        transform: translateY(-50%);
        background-color: $secondary;
        color: $white;
        border: none;
        font-size: rem-calc($fontsize-medium);

        &:hover {
          background-color: $dark-green;
          color: $white;
        }

        .font-icon {
          display: block;
        }
      }

      #store-postal-code {
        margin-bottom: 0;
      }
    }

    &__form {
      &__name {
        @include product-name;

        order: 1;
        font-size: rem-calc($fontsize-xlarge);
        text-transform: uppercase;
      }

      &__prices {
        order: 2;

        .has-strike-through span.value {
          font-size: rem-calc($fontsize-large);
        }

        .strike-through span.value {
          font-size: rem-calc($fontsize-medium);
        }
      }

      &__product {
        padding-bottom: rem-calc(65);

        @include media-breakpoint-up(lg) {
          padding-bottom: 0;
        }
      }

      &__attributes {
        position: unset;
      }

      &__fields {
        order: 3;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
          position: relative;
          width: auto;
        }

        .form-control.is-valid:not([disabled]) {
          border-color: $primary;
        }

        &__background {
          border-radius: $color-swatch-border-radius;
          width: rem-calc(16);
          height: rem-calc(16);
          top: rem-calc(7);
          left: rem-calc(8);
        }
      }

      &__store {

        input[type="text"].is-valid:not(select):not([disabled]) {
          border-color: $primary;
        }

        .btn.btn-storelocator-search {
          position: static;
          color: $white;
          border: none;
          font-size: rem-calc($fontsize-medium);

          &:hover {
            background-color: $dark-green;
          }
        }

        .store-search-field {
          input {
            &:focus + label {
              padding: 0 rem-calc(10);
            }
          }

          .has-float-label input:placeholder-shown:not(:focus) + * {
            font-style: italic;
          }

          .detect-location + .form-group {
            margin-bottom: map-get($spacers, 3);
          }
        }
      }

      .store-work-times {
        &__row {
          padding-top: map-get($spacers, 3);
          padding-bottom: map-get($spacers, 3);
        }

        &__col {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    &__result {
      min-height: 0;

      &__nearest-stocked-check {
        display: none;
      }

      &__table {
        &__back-to-top {
          width: auto;
          padding: rem-calc(7) rem-calc(15);
          margin: 0 auto;
          left: 0;
          right: 0;
          position: sticky;
          bottom: rem-calc(20);
          max-width: rem-calc(120);
          border-radius: rem-calc(30);
          text-transform: uppercase;
          height: rem-calc(34);
          border: none;
          box-shadow: 0 0 rem-calc(6) rem-calc(2) rgba(0, 0, 0, 0.18);
          letter-spacing: rem-calc(4);
          font-size: rem-calc(10);
          line-height: rem-calc(18);
          background-color: $white;
          color: $primary;
        }
      }

      &__col {
        &--store-name {
          text-align: left;
        }

        &__number {
          color: $green;
          margin-right: rem-calc(20);
        }

        &--size {
          font-size: rem-calc(12);
          letter-spacing: rem-calc(3.6);
          color: $grey5;

          &.selected {
            letter-spacing: rem-calc(4.2);
            font-weight: bold;
            font-size: rem-calc(14);
            color: $primary;
          }
        }
      }

      &__store-details-toggle:checked + &__store-details {
        display: block;
      }

      &__store-details {
        font-size: rem-calc($fontsize-large);
        left: 0;
        width: calc(100% + #{2 * map-get($spacers, 3)});
        margin-left: -#{map-get($spacers, 3)};

        &__phone__text {
          letter-spacing: rem-calc(5.6);
        }

        &-toggle {
          + label {
            background: $white;
          }

          &:checked + label {
            background: $grey10;
          }
        }

        &__worktimes + ul {
          width: 100%;

          li {
            margin-bottom: map-get($spacers, 2);

            &:last-child {
              margin-bottom: 0;
            }

            &.current-day {
              color: $primary;
              font-weight: normal;
            }

            span {
              letter-spacing: rem-calc(2.8);
              font-size: rem-calc(14);
            }
          }
        }

        &__phone {
          border: 1px solid $primary;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          padding: map-get($spacers, 2);

          a {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            i {
              margin-right: rem-calc(5);
            }
          }

          @include media-breakpoint-up(md) {
            border: none;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 0;
          }
        }

        &__phone-disclaimer {
          font-weight: 300;
        }
      }
    }
  }
  //End

  //old rating css
  &__rating {
    @include flyout($product-reviews-pane-max-width, $product-reviews-pane-max-width-desktop, $flyout-style-headings);

    .rating-star {
      font-size: $fontsize-medium;
      color: $green;

      &.icon-fa-star-o {
        &::before {
          @include fa-icon-override('fa-star', $fa-var-star);

          color: $grey8;
        }
      }
    }

    &__content {
      font-size: rem-calc($fontsize-medium);
    }

    &__close {
      text-align: center;
      margin-top: rem-calc(5);

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      .flyout__close-label {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    &__review {
      border-bottom: none;
      border-top: 1px solid $grey3;

      &-score {
        text-align: left;
      }

      &:first-child {
        border-top: none;
      }

      &-header {
        margin-top: map-get($spacers, 2);
      }

      &-detail {

        .review-attribute {
          letter-spacing: $pdp-elements-letter-spacing;
        }
      }
    }

    &__score__count {
      color: $primary;
      margin-left: rem-calc(5);
    }

    &__status--sort select {
      text-transform: uppercase;
      letter-spacing: $pdp-elements-letter-spacing;
    }

    &__status--paging {
      margin-bottom: map-get($spacers, 3);
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }
  //End
}

.new-pdp {
  .product-detail {
    @media (min-width: $bp-large) {
      &__tile-row {
        &__item {
          max-width: 100% !important;
        }
      }
    }
  }
}
