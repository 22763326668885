$meganav-background-color: rgba(255, 255, 255, 0.95);
$menu-items-border-style: rem-calc(1) solid $grey5;

.nav-menu {
  $root: &;

  .site-search {
    padding-bottom: 0;
  }

  &__search {
    padding-bottom: 1rem !important;
  }

  &__item {
    padding-top: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);
  }

  .nav-menu--visible &__body {
    background-color: white;
  }

  &__body {
    @include media-breakpoint-up(md) {
      background-color: #f8f8f8;
    }
  }

  &:not(.nav-menu--visible) .nav-menu__items--level-1 > .nav-menu__item > .nav-menu__item-link {
    font-size: 12px;
    line-height: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__items {
    &--level-1 {
      > li {
        @include media-breakpoint-down(md) {
          border-bottom: $menu-items-border-style;

          &:first-child {
            border-top: $menu-items-border-style;
          }
        }
        padding-top: 0;
        padding-bottom: 0;
      }
      @include media-breakpoint-up(lg) {
        border-top: none;
        > li {
          padding-left: map-get($spacers, 4);
          padding-right: map-get($spacers, 4);
        }
      }
    }

    &--level-2 {
      background-color: $meganav-background-color;

      @include media-breakpoint-down(md) {
        padding-left: 0;
        margin-top: rem-calc(15);
      }

      li {
        @include media-breakpoint-up(lg) {
          line-height: 0.8;
        }
      }

      @include media-breakpoint-up(lg) {
        #{$root}__item-link--level-2 {
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: rem-calc(4);
          display: inline-block;
          margin-top: map-get($spacers, 3);
        }
      }
    }
  }

  &.nav-menu--visible .nav-menu__items--level-1 > li {
    padding-top: map-get($spacers, 3) !important;
    padding-bottom: map-get($spacers, 3) !important;
  }

  &__item-link {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
    &--level-1 {
      margin-bottom: 0;
      font-weight: normal;
      text-transform: uppercase;
    }

    &--level-2 {
      text-transform: none;
      margin-bottom: map-get($spacers, 2);
      font-weight: bold;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    &--level-3 {
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__header {
    padding-bottom: 0;
    &__logo-row {
      padding-top: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 3);
    }

    &__logo {
      @include make-col(10);

      text-align: center;
    }
    &__close {
      position: absolute;
      top: rem-calc(12);
      right: 0;
    }
  }

  &__close-text {
    text-transform: uppercase;
  }

  &__footer {

    &__footer {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }

    #{$root}__footer-row {
      border: none;
      padding-top: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 2);
    }

    #{$root}__footer-item {
      border: none;

      &:nth-child(odd) {
        padding-right: map-get($spacers, 2);
      }
      &:nth-child(even) {
        padding-left: map-get($spacers, 2);
      }

      &__content {
        font-weight: normal;
        text-transform: uppercase;
        padding-top: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 4);
        background-color: $grey3;
        height: 100%;
        .font-icon {
          vertical-align: middle;
        }
      }
    }

    .country-selector {
      &__current {
        margin-bottom: 0;
        justify-content: center;

        &__selection {
          line-height: 20px;
          font-size: 14px;
        }
      }
    }
  }

  &__content--side {
    padding-right: rem-calc(10);
  }
}

@include media-breakpoint-up(md) {
  .nav-menu__items--level-1 > .nav-menu__item > .nav-menu__item-link {
    padding: 0;
    font-size: 12px;
    letter-spacing: rem-calc(2.4);
  }
}

.imagery-navigation {
  padding-left: 0.5rem;
  img {
    width: 6.5rem;
    height: 6.5rem;
  }

  .cat-name {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }

  .cat-Imagery {
    gap: 1rem;
  }
}

.right-container {

  .inner-container {
    justify-content: flex-end;
  }

  @include media-breakpoint-down(md) {
    @include make-col(4);
  }

  @include media-breakpoint-up(lg) {
    padding-left: rem-calc(100);
    @include make-col(4);
  }

  @include make-col(4);

  .header-item__search {
    &.right {
      display: none !important;
    }
  }
}

.header .header-element.logo {

  img {
    max-width: 100px;
    height: 34px;
    @media only screen and (min-device-width: $bp-medium) {
      max-width: 148px;
    }
    margin: auto;
  }
  @include make-col(4);

  @include media-breakpoint-up(sm) {
    &.center {
      display: block !important;
    }
  }

  @include media-breakpoint-between(md, lg) {
    @include make-col(4);
  }

  &.left {
    display: none !important;
  }
}
