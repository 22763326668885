.breadcrumb {
  justify-content: flex-start;
  font-size: rem-calc(12);

  .breadcrumb-item {
    a {
      color: $primary;
      text-decoration: underline;
    }

    &:last-child a {
      text-decoration: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .product-breadcrumb.product-detail__nav-row__breadcrumbs {
    .breadcrumb-container {
      padding: 0;
      .breadcrumb {
        margin: 0;
        &-item {
          &::before {
            content: "|";
            color: $black;
          }
          &:first-child {
            &::before {
              display: none;
            }
          }
          a {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 2.4px;
            text-underline-position: under;
          }
        }
      }
    }
  }
}
