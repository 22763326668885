$product-image-aspect-ratio: 1.4; // Height / Width

@mixin product-image-container {
  width: 100%;
  height: auto;
  aspect-ratio: 5/7; // stylelint-disable-line
  background-color: $grey3;

  img {
    display: block;
    width: 100%;
    height: auto;

    &:not([src]) {
      visibility: hidden;

      &.loading {
        visibility: visilbe;
      }
    }
  }
}
