.modal:not(#quickViewModal) {
  .modal {
    &-dialog {
      width: calc(100% - (0.5rem * 2));
      text-align: center;
      @extend .modal-dialog-centered;
    }

    &-header {
      padding-bottom: 0;
      border-bottom: none;

      .close {
        position: absolute;
        top: rem-calc(20);
        right: rem-calc(20);
        width: rem-calc(20);
        height: rem-calc(20);
        padding: 0;
        span {
          display: block;
          line-height: rem-calc(20);
        }
      }
    }

    &-title {
      width: 100%;
    }

    &-body {
      min-height: rem-calc(1); // Works around an IE bug where images with height: auto still extend their parent

      p {
        margin-top: rem-calc(10);
      }
    }

    &-footer {
      border-top: none;
      justify-content: center;

      .btn {
        width: 100%;
        &-primary {
          @include btn-secondary();
        }
      }
    }
  }
}
