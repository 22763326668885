#footer {
  padding-top: 0;
  padding-bottom: 0;

  a:hover {
    text-decoration: none;
  }

  .top-bottom-border {
    border-bottom: rem-calc(1) solid $grey4;

    @include media-breakpoint-up(lg) {
      border-top: rem-calc(1) solid $grey4;
      border-bottom: none;
    }
  }

  .contact-band {
    background-color: $secondary;

    .content-asset {
      width: 100%;
    }

    .font-icon {
      font-size: rem-calc(30px);
    }

    a {
      color: $white;
      font-size: rem-calc(20px);
    }
  }

  .contacts {
    @include font-size-letter-spacing(rem-calc(15), rem-calc(2), rem-calc(15), rem-calc(2));

    margin-left: 0;
    margin-right: 0;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-right: auto;
      padding: map-get($spacers, 4) 0;
      max-width: map-get($container-max-widths, xl);
      font-size: $fontsize-xlarge;
    }

    .footer-contacts-link {

      display: inline-block;
      width: calc(50% - #{2 * map-get($spacers, 1)});
      background-color: $white;

      &:nth-child(odd) {
        margin-right: map-get($spacers, 1);
      }
      &:nth-child(even) {
        margin-left: map-get($spacers, 1);
      }

      .font-icon {
        display: block;
        font-size: rem-calc(30);
        margin-bottom: map-get($spacers, 2);
      }

      a {
        margin-bottom: map-get($spacers, 3);
        padding-top: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 4);
        display: block;
        width: 100%;
        height: 100%;
        border: rem-calc(1) solid $grey4;
        &:active,
        &:hover {
          border-color: $secondary;
        }
      }

      @include media-breakpoint-up(lg) {
        width: auto;
        background-color: transparent;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;

        a {
          border: none;
          padding-top: 0;
          padding-bottom: 0;
          margin-right: 0;
        }

        p {
          text-transform: uppercase;
        }

        .font-icon {
          display: inline-block;
        }
      }
    }

    .contacts-asset-left {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .email-newsletter {
      &-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      &__heading {
        font-weight: bold;
      }

      &__form {
        position: relative;
        margin: 0 auto rem-calc(20) auto;
        width: 85%;
        max-width: rem-calc(340);
      }

      &__email-input {
        border: none;
        border-bottom: solid rem-calc(1) $grey2;
        padding-bottom: map-get($spacers, 1);
        padding-left: 0;
        width: 100%;
        color: $grey1;
        font-size: $fontsize-small;
        font-family: $body-font-family;
        @include font-size-letter-spacing(rem-calc($fontsize-medium), rem-calc(1), rem-calc(14), rem-calc(1));
      }

      &__title {
        @include font-size-letter-spacing(rem-calc($fontsize-large), rem-calc(1.5), rem-calc($fontsize-medium), rem-calc(1.5));
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
          font-size: $fontsize-xlarge;
        }
      }

      &__submit {
        position: absolute;
        right: 0;
        top: rem-calc(6);
        height: auto;
        border: none;
        background-color: transparent;

        .font-icon::before {
          font-size: rem-calc($fontsize-medium);
          color: $primary;
        }

        span {
          display: none;
        }
      }
    }
  }

  .fullwidth-color {
    @include media-breakpoint-up(lg) {
      background-color: $grey9;
    }

    .footer-columns {
      max-width: rem-calc(1100);
      color: $primary;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      h6 {
        @include font-size-letter-spacing(rem-calc($fontsize-large), rem-calc(1.5), rem-calc($fontsize-medium), rem-calc(1.5));

        font-weight: bold;
        margin-bottom: 0;
        margin-left: map-get($spacers, 4);
        margin-right: map-get($spacers, 4);
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          margin-left: 0;
          margin-right: 0;
          font-weight: normal;
        }

        .collapse-trigger {
          padding-top: map-get($spacers, 4);
          padding-bottom: map-get($spacers, 4);

          &[aria-expanded="true"] {
            .inactive-icon {
              display: none;
            }
          }

          &[aria-expanded="false"] {
            .active-icon {
              display: none;
            }
          }
        }

        .icon {
          font-weight: bold;
        }
      }

      .collapse-trigger {
        @include media-breakpoint-up(lg) {
          pointer-events: none;
        }
      }

      .accordion {
        .footer-column {
          @include media-breakpoint-down(md) {
            @include margin-horizontal(rem-calc(-10), rem-calc(-10));
            border-bottom: rem-calc(1) solid $grey4;

            ul {
              padding-inline-start: rem-calc(25);
            }
          }

          &.last-column {
            @include media-breakpoint-down(md) {
              border-bottom: none;
            }
          }
        }

        ul {
          list-style: none;
          font-weight: 300;
          @include font-size-letter-spacing(rem-calc($fontsize-large), rem-calc(0.5), rem-calc($fontsize-medium), rem-calc(1.5));

          li {
            padding-top: map-get($spacers, 4);
            padding-bottom: map-get($spacers, 4);

            @include media-breakpoint-up(lg) {
              font-weight: normal;
              padding-top: map-get($spacers, 1);
              padding-bottom: map-get($spacers, 1);
            }
          }
        }
      }
    }
  }

  .fullwidth {
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    .bottom-footer-icons {
      .social {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(md) {
          background-color: $grey9;
          @include padding-vertical(rem-calc(40), rem-calc(40));
        }

        a {
          margin-right: rem-calc(10);
          [class*="font-icon icon-"] {
            font-size: rem-calc(40);
            color: $primary;
            @include media-breakpoint-up(lg) {
              font-size: rem-calc(30);
            }
          }
        }
      }

      .payment-methods {
        padding-bottom: map-get($spacers, 4);

        @include media-breakpoint-up(lg) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .footer-copyright {
      text-align: center;
      background-color: $primary;
      color: $white;
      @include font-size-letter-spacing(rem-calc(15), rem-calc(0.5), rem-calc($fontsize-medium), rem-calc(0.5));
      @include padding-vertical(rem-calc(10), rem-calc(10));

      @include media-breakpoint-up(lg) {
        padding-top: 0;
        padding-bottom: rem-calc(10);
        color: $grey8;
        background-color: $white;
      }

      &:not(.row) {
        display: none;
      }
    }
  }

  .full-width-color {
    .social-icons-mobile {
      display: none;
    }
  }
}
