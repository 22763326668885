@import '../components/intlTelInput';

.edit-profile {
  padding-left: map-get($spacers, 5);
  padding-right: map-get($spacers, 5);

  .my-details-description,
  .change-password-description {
    font-size: $fontsize-medium;
  }

  .change-password-disclaimer,
  .required-field,
  .birthday-disclaimer {
    font-size: $fontsize-small;
  }

  .my-password-container {
    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }

  &__form-outer-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  &-form {
    padding-left: 0;
    padding-right: 0;
  }

  .title-select {
    @include make-col(7);

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }
}

.birthday-inputs {
  padding: map-get($spacers, 3) 0;

  .input-container {
    &.dob-asset {
      max-width: 100%;
    }

    max-width: rem-calc(80);
    margin-right: map-get($spacers, 3);

    &--yearofbirth {
      margin-right: 0;
    }
  }

  input {
    padding-right: 0;
  }
}

.mobile-number-dial-code {
  width: 100%;
}

.country-dial-code-profile-form {
  margin: 0;

  @include media-breakpoint-down(sm) {
    width: 110px !important;
    margin-bottom: 2rem;
  }
}

.down-arrow-profile-form {
  left: 6.7rem;

  @include media-breakpoint-down(sm) {
    left: 5.5rem;
  }
}

.mobile-number-profile-form {
  width: 70% !important;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.profile-page {
  .intl-tel-input {
    .country-list {
      width: 100%;
      position: relative;
      bottom: 3rem;
    }
  }
}

.save-payment-form-wrapper {
  .addressFields_mobile_dialCode {
    flex: 0 0 100% !important;
    max-width: 100% !important;

    .country-dial-code-address-form {
      @include media-breakpoint-up(sm) {
        width: 25% !important;
      }
      @include media-breakpoint-up(md) {
        width: 20% !important;
      }
      @include media-breakpoint-up(xl) {
        width: 15% !important;
      }
    }

    .mobile-number-address-form {
      @include media-breakpoint-up(sm) {
        width: 75% !important;
      }
      @include media-breakpoint-up(md) {
        width: 80% !important;
      }
      @include media-breakpoint-up(xl) {
        width: 85% !important;
      }
    }

    .intl-tel-input {
      .country-list {
        bottom: 5rem;
      }
    }
  }
}

.registration {
  .country-dial-code-newsletter {
    border: 1px solid $grey2;
    margin: 1.5rem 0 1rem 1rem;
    width: 40% !important;

    @include media-breakpoint-up(sm) {
      width: 30% !important;
      margin: 0 0 1.5rem 1rem;
    }
  }

  .mobile-number {
    width: 100% !important;
    margin: 1.5rem 0 1rem 1rem;

    @include media-breakpoint-up(sm) {
      width: 60% !important;
      margin: 0 0 1.5rem 0;
    }
  }
}

.save-address__form-wrapper {
  .country-dial-code-address-form {
    @include media-breakpoint-up(md) {
      width: 35% !important;
    }
    @include media-breakpoint-up(xl) {
      width: 27% !important;
    }
  }
  .mobile-number-address-form {
    @include media-breakpoint-up(sm) {
      width: 77% !important;
    }
    @include media-breakpoint-up(md) {
      width: 65% !important;
    }
    @include media-breakpoint-up(xl) {
      width: 73% !important;
    }
  }
}
