.account-pages-heading {
  display: block;

  &--mobile-hidden {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &--mobile & {
    align-items: center;
    border-bottom: 1px solid $border-color;

    &__component {
      &--heading {
        text-align: left;
        font-weight: bold;
      }

      &__link {
        display: block;
        width: 100%;
        text-align: right;
      }
    }
  }
}
