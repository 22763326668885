.quick-view-dialog {
  width: 100%;
  max-width: rem-calc(940);

  .modal-header {
    border-bottom: none;

    .full-pdp-link {
      display: none;
    }
  }

  .modal-footer {
    border-top: none;
    padding-left: 0;
    padding-right: 0;

    .full-pdp-link {
      background-color: $grey2;
    }
  }
}

.product-detail {
  &--quickview & {
    &__details-section {
      position: static;
    }
  }
}
