@mixin dob-container() {
  .birthday-inputs {
    .birthday-label {
      label {
        position: absolute;
        top: rem-calc(-6);
        left: rem-calc(8);
        padding-right: map-get($spacers, 2);
        background-color: $white;
        font-size: $fontsize-small;
        z-index: 1;
      }
    }

    .input-container {
      @include make-col(4);

      padding: 0;
      margin-right: 0;
      max-width: none;
      width: rem-calc(100);

      input {
        padding-top: rem-calc(8);
      }

      &:not(:last-child) {
        input {
          border-right: none;
        }
      }
    }

    .form-group {
      @include make-col-ready();

      padding: 0;
      margin-bottom: map-get($spacers, 2);

      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
    }
  }
}
