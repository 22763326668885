@import '../../flyout';

$hubbox-form-width: rem-calc(440) !default;
$hubbox-input-height: $button-height;
$hubbox-input-padding: rem-calc(10);
$hubbox-geolocation-icon-size: rem-calc(20);

$saved-stores-pane-max-width: 90% !default;
$saved-stores-pane-max-width-desktop: rem-calc(650) !default;

.saved-stores {
  @include flyout($saved-stores-pane-max-width, $saved-stores-pane-max-width-desktop);

  &__store-footer {
    margin-left: 16.66667%;
  }

  &__addresses-close {
    &__icon {
      padding-right: map-get($spacers, 2);
    }
    &__text {
      display: none;
    }
  }

  &__addresses-title {
    text-align: center;
    border-bottom: rem-calc(1) solid $border-color;
  }

  &__toggle {
    margin-left: auto;
    margin-right: auto;
    max-width: $hubbox-form-width;
  }

  .address-summary__line {
    color: $grey1;
  }
}

.saved-stores,
.hubbox-container {
  .hubbox-title {
    display: none;
  }

  .hubbox-message {
    text-align: center;
    font-size: $fontsize-large;
  }

  .form-hubbox {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: $hubbox-form-width;

    .hubbox-geo-wrapper {
      position: absolute;
      height: $hubbox-input-height;
      width: $hubbox-input-height;
      line-height: $hubbox-input-height;
      text-align: center;
      background-color: $black;

      svg {
        height: $hubbox-geolocation-icon-size;
        width: auto;

        path {
          fill: $white;
        }
      }
    }

    .hubbox-search-box {
      height: $hubbox-input-height;
      padding: $hubbox-input-padding;
      padding-left: $hubbox-input-height + $hubbox-input-padding;
    }

    #hubbox-search-button {
      @include btn-secondary();
      display: block;
      width: 100%;
      margin-top: 2 * $hubbox-input-padding;
    }
  }

  .private-only-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: $hubbox-form-width;

    label {
      color: $grey1;
    }
  }

  .hubbox-content-wrapper {
    display: none;
  }

  .hubbox-tabs {
    margin-bottom: map-get($spacers, 3);
    .hubbox-tab {
      position: relative;
      top: 1px;
      background-color: $page-bg;
    }

    .hubbox-tab-link {
      position: relative;
      font-size: $fontsize-large;
      border-bottom: rem-calc(1) solid $border-color;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: rem-calc(5);
        position: absolute;
        top: 0;
        left: 0;
      }

      &.active {
        border-bottom: none;

        &::before {
          background-color: $secondary;
        }
      }
    }
    .hubbox-tab-link-map {
      border-left: rem-calc(1) solid $border-color;
    }
  }

  .hubbox-sidebar {
    padding-left: map-get($spacers, 5);
    padding-right: map-get($spacers, 5);
  }

  .sidebar-cp,
  .saved-stores__store {
    padding-left: 0;
    padding-right: 0;

    .distance {
      color: $grey1;
      margin-bottom: map-get($spacers, 1);
    }

    .store-heading {
      display: none;
      .distance {
        float: right;
      }
    }

    .store-detail {
      padding-top: map-get($spacers, 4);

      .hours-toggle {
        display: block;
        margin-bottom: map-get($spacers, 2);
      }
    }

    .sidebar-icon-wrapper {
      text-align: center;
    }

    .store-address {
      div {
        color: $grey1;
      }
    }

    .store-price {
      @include make-col-offset(2);
      font-weight: bold;
    }
  }

  .selected-store-wrapper {
    .store-price {
      @include make-col-offset(0);
    }
  }

  .selected-store-footer,
  .selected-store-header {
    @include make-col-offset(2);
  }

  .selected-store-heading {
    color: $grey1;
  }

  .sidebar-icon,
  .selected-store-icon {
    fill: $secondary;
    width: rem-calc(40);
    height: auto;
  }

  .store-address {
    .store-name {
      font-size: $fontsize-large;
      margin-top: map-get($spacers, 2);
      margin-bottom: map-get($spacers, 2);
    }
  }

  .hubbox-map-wrapper {
    height: rem-calc(340);

    @include media-breakpoint-up(md) {
      height: rem-calc(520);
    }

    #hubbox-map {
      height: 100%;
    }
  }

  .hubbox-map-selection {
    padding-top: map-get($spacers, 3);
  }

  .confirmation-col-map {
    display: none;
  }

  .selected-store-form {
    border: 1px solid $border-color;
    padding: map-get($spacers, 4) map-get($spacers, 5);

    .form-group {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
