.ATBRecommendation_container {
  .modal {
    overflow-y: auto;
    &-content {
      @include media-breakpoint-down(xs) {
        border-top: 1px solid $black;
      }
      .modal-header {
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .ATBRecommendations-content {
        .product-card__wrapper {
          padding-right: 0;

          .product-card {
            border: none;
          }
        }
      }

      .modal-footer {
        .btn {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .addToBagOverlay-recommendation-container {
    .addToBagOverlay-recommendation-title-container {
      .addToBagOverlay-recommendation-title {
        h5 {
          margin-bottom: 1rem;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
  }

  .addToBagOverlay-recommendation-container-lowASP {
    background-color: #f8f8f8;

    .addToBagOverlay-recommendation-title-container-lowASP {
      .addToBagOverlay-recommendation-title {
        h5 {
          margin-bottom: 1rem;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }

    .addToBagOverlay-product-recommendations-lowASP {
      .product-tile {
        margin-bottom: 1.5rem !important;
        @include media-breakpoint-up(sm) {
          margin-bottom: 0 !important;
        }
      }
    }

    .glide__bullets {
      @include media-breakpoint-up(sm) {
        display: none;
      }
      .glide__bullet {
        border: 1px solid $green;
        background-color: #d2d7d6;

        &--active {
          background-color: $white;
        }
      }
    }
  }
}
