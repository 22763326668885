.checkout-login {
  @include media-breakpoint-up(lg) {
    min-height: rem-calc(650);
  }

  .card {
    padding-top: map-get($spacers, 4);
  }

  .page-title,
  .page-subtitle {
    position: absolute;
    left: 0;
    right: 0;
    top: rem-calc(-12);
    margin: 0 auto;
    padding: 0 map-get($spacers, 3);
    text-align: center;
  }

  .page-subtitle {
    top: rem-calc(-20);
    width: 100%; // IE11
    max-width: rem-calc(272);
    font-size: $fontsize-xmedium;
    background-color: $page-bg;
  }

  .signing-in-msg {
    color: $grey1;
  }

  &--existing {
    .page-title {
      display: none;
    }
  }

  &__options-separator {
    display: none;
  }
}
