.filters-container {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: $page-bg;
  padding-bottom: rem-calc(180); // the height of the filters' footer EDIT: adjusted the value to cover for the iPhone bottom bar
  margin-right: map-get($spacers, 4);
  transition: left 0.3s ease-in-out;
  z-index: $searchRefinements-filters-container-z-index;

  &.show {
    left: 0;
    .filters {
      &__footer {
        left: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: static;
    height: auto;
    overflow: hidden;
    padding-bottom: 0;
    z-index: auto;
  }
}

.filters {
  width: 100%;

  &__heading {
    text-transform: uppercase;
    text-align: center;
    padding: map-get($spacers, 4) 0;
  }

  &__close-button {
    padding-right: map-get($spacers, 3);
    float: right;

    &__text {
      display: none;
    }
  }

  &__items {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }

  &__body {
    height: 100%;
    overflow: auto;

    @include media-breakpoint-up(lg) {
      height: auto;
    }
  }

  &__value-count {
    font-size: rem-calc(12);
  }

  &__filter {
    border-top: none;
    border-left: none;
    border-right: none;

    &__applied {
      border: 1px solid $border-color;
      margin-bottom: map-get($spacers, 2);
      margin-right: map-get($spacers, 2);

      &__remove {
        margin-left: 0.2rem;
      }
    }

    @include media-breakpoint-up(lg) {
      &--size,
      &--shoesize {
        .values {
          display: flex;
          flex-wrap: wrap;
        }

        .filters {
          &__value {
            flex: 0 25%;
            margin-bottom: map-get($spacers, 3);

            @include media-breakpoint-up(lg) {
              margin-bottom: map-get($spacers, 3);
            }

            &--one-size {
              flex: 0 50%;
              .filters__value-text {
                width: calc(100% - #{rem-calc(15)});
              }
            }
          }

          &__value-text {
            padding: rem-calc(10) rem-calc(5);
            text-align: center;

            &.selected {
              @include media-breakpoint-up(lg) {
                border: rem-calc(1) solid $secondary;
              }
            }

            @include media-breakpoint-up(lg) {
              border: 1px solid $border-color;
            }
          }

          &__value-link,
          &__value-link.selected {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  .clear-all .filters__section__btn {
    display: none;
  }

  &__filter-header {
    border: none;
    padding: map-get($spacers, 4) 0;
    font-size: rem-calc($fontsize-large);

    &__icon:not(.clear-all) {
      padding-top: 0;
    }

    &__text {
      @include media-breakpoint-up(lg) {
        text-transform: uppercase;
      }
    }
  }

  &__filter-body {
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding-bottom: map-get($spacers, 2);
    }

    > .values {
      padding: 0;
    }

    &.one-col {
      .refinements__value {
        width: 50%;

        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }
    }

    &:not(.one-col) {
      > .values {
        column-count: 2;
        width: calc(100% - #{rem-calc(20)});
      }
    }
  }

  &__form {
    &--price {
      width: 100%;
      position: relative;

      .form-group {
        position: static;
      }

      .invalid-feedback {
        position: absolute;
        top: 100%;
        width: 150%;
        margin-top: 0;

        ~ .form-icon {
          right: rem-calc(10);
        }
      }

      &__symbol {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: rem-calc(8);
        color: $grey5;
      }
    }
  }

  &__input[type=number] {
    width: rem-calc(85);
    padding-left: rem-calc(20);
    padding-bottom: rem-calc(5);
  }

  &__value {
    border: rem-calc(1) solid $border-color;
    padding: map-get($spacers, 2) 0;
    margin-bottom: map-get($spacers, 2);
    text-align: center;
    list-style-type: none;

    // Prevent items being stretched over both columns when they're an odd number
    display: inline-block;
    width: 100%;
    vertical-align: top;

    &.selected {
      background-color: $border-color;
    }

    .disabled {
      .text {
        color: $border-color;
        text-decoration: line-through;
      }
    }

    @include media-breakpoint-up(lg) {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;

      &.selected {
        background-color: transparent;
      }
    }

    > .values {
      margin-top: map-get($spacers, 2);
    }

    &--category {
      border: none;

      text-align: left;

      &.selected {
        background-color: transparent;
      }
    }
  }

  &__value-link {
    display: block;
    position: relative;

    &::before {
      @include form-checkbox-control;

      position: static;
      display: none;
      vertical-align: middle;
      margin-right: map-get($spacers, 1);

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }

    &.selected {
      &::before {
        @include fa-icon-override('check', $fa-var-check);

        display: none;
        color: $secondary;

        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }
    }

    &--category {
      &::before {
        display: inline-block;
      }

      &.selected {
        &::before {
          display: inline-block;
        }
      }
    }
  }

  &__value-text {
    display: inline-block;
    vertical-align: middle;
    word-wrap: break-word;

    &--not-one-size {
      width: 75%;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 100%;
    transition: left 0.3s ease-in-out;
    width: 100%;
    background-color: $page-bg;
    box-shadow: 0 rem-calc(-5) rem-calc(5) $grey3;
    z-index: $searchRefinements-footer-z-index;

    @include media-breakpoint-up(lg) {
      position: static;
      width: auto;
      box-shadow: none;
    }
  }
  &__reset-link:not(.btn),
  &__close-link:not(.btn) {
    text-decoration: underline;
  }
}

.category-homewares-filter-size {
  flex: 0 50%;
  .filters {
    &__value {
      flex: 0 50%;

      .filters__value-text {
        &--not-one-size {
          width: calc(100% - 0.9375rem);
          text-transform: uppercase;
        }
      }
    }
  }
}
