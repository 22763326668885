.style-with-CTA {
  @extend .rd-style-with-cta-icon;
  @extend .rd-style-with-CTA-text;
  display: flex;
  z-index: 1;
  padding-left: 0.5rem;
  padding-right: 1rem;
  gap: 0.5rem;
  border-radius: 1.25rem 0 0 1.25rem;
  background: $overlay-white;
  position: absolute;
  top: 1.5rem;
  right: 0;
  height: 2.5rem;
  white-space: nowrap;
  width: 8.25rem;
  align-items: center;
  cursor: pointer;

  @media (max-width: $bp-medium-max) {
    top: 3.625rem;
    z-index: 11;
  }
}

body.nav-menu-toggled--left .style-with-CTA {
  z-index: 1;
}
