$hubbox-cp-bg: $grey3;

.saved-stores {
  &__addresses-close {
    text-align: center;
    margin-top: map-get($spacers, 3);

    &__icon {
      padding-right: 0;
    }
    &__text {
      display: inline;
    }
  }

  &__addresses-title {
    text-align: left;
    border-bottom: none;
    font-size: rem-calc(24);
    letter-spacing: rem-calc(9.6);
  }

  &__container {
    @include media-breakpoint-up(lg) {
      padding-left: rem-calc(80);
      padding-right: rem-calc(80);
    }
  }

  &__store {
    margin-top: map-get($spacers, 4);
  }
}

.hubbox-container {
  .hubbox-title {
    display: block;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 800;
  }

  .hubbox-message {
    text-align: left;
    font-size: rem-calc(14);

    &.long {
      text-align: center;
    }
  }

  .form-hubbox {
    @include media-breakpoint-up(md) {
      font-size: 0;

      .hubbox-search-box {
        width: 60%;
      }

      #hubbox-search-button {
        display: inline-block;
        width: calc(40% - #{rem-calc(11)});
        margin-top: 0;
        margin-left: rem-calc(10);
        vertical-align: top;
      }
    }
  }

  .private-only-wrapper {
    text-align: center;
    font-size: $fontsize-medium;
  }

  .hubbox-tabs {
    margin-bottom: map-get($spacers, 3);

    .hubbox-tab-link {
      border-top: none;
      border-left: none;
      border-bottom: none;
      text-transform: uppercase;

      &.active {
        border-bottom: rem-calc(3) solid;

        &::before {
          display: none;
        }
      }
    }
  }

  .hubbox-sidebar {
    padding-left: 0;
    padding-right: 0;
    padding-top: map-get($spacers, 5);
  }

  .sidebar-cp,
  .saved-stores__store {
    background-color: $hubbox-cp-bg;
    padding: map-get($spacers, 4);

    .sidebar-icon-wrapper {
      display: none;
    }

    .store-heading {
      display: block;
      margin-bottom: map-get($spacers, 3);

      @include media-breakpoint-up(lg) {
        margin-bottom: map-get($spacers, 4);
      }

      .store-name {
        @include border-after();
        width: calc(100% - 30px);
        max-width: calc(100% - 30px);
        text-transform: uppercase;
        letter-spacing: rem-calc(5.6);

        @include media-breakpoint-up(lg) {
          @include make-col(11);
        }

        span {
          background-color: $hubbox-cp-bg;
        }
        &::after {
          border-color: $primary;
        }
      }
      .distance {
        float: none;
        margin-top: rem-calc(10);

        @include media-breakpoint-up(lg) {
          position: absolute;
          right: 0;
          top: rem-calc(-10);
          background: $grey3;
          padding-left: rem-calc(10);
        }
      }
    }

    .store-address {
      @include make-col(12);

      padding-left: 0;

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }

      .distance,
      .store-name {
        display: none;
      }

      div {
        color: $primary;
      }
    }

    .store-detail {
      padding-top: 0;
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(6);
        text-align: right;
      }

      .hours-toggle {
        font-weight: bold;
        display: flex;
        align-items: center;
        font-size: rem-calc(12);
        margin-top: map-get($spacers, 2);

        @include media-breakpoint-up(lg) {
          justify-content: flex-end;
          margin-top: 0;
        }

        u {
          text-decoration: none;
        }
      }
    }

    .store-price {
      display: none;
    }

    .sidebar-confirm-col {
      @include make-col-offset(0);
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col-offset(4);
        @include make-col(4);
      }
    }
  }

  .hubbox-map-selection {
    padding-top: 0;
  }

  .selected-store-footer,
  .selected-store-header {
    @include make-col-offset(0);
  }

  .selected-store-wrapper {
    @include make-col(12);
  }

  .selected-store-icon-wrapper {
    display: none;
  }

  .selected-store-form {
    border: none;
    padding-left: 0;
    padding-right: 0;

    .form-group {
      @include media-breakpoint-up(lg) {
        &--text,
        &--tel {
          @include make-col(6);
        }
      }

      @include media-breakpoint-up(lg) {
        &:nth-child(odd) {
          padding-right: map-get($spacers, 2);
        }

        &:nth-child(even) {
          padding-left: map-get($spacers, 2);
        }

        .form-icon {
          right: rem-calc(10) + map-get($spacers, 2);
        }
      }
    }
  }
  .selected-store-form-title {
    margin-bottom: map-get($spacers, 3);
  }
}
