.product-desc-tab-container {
  padding: 2.5rem 0;
  background-color: $grey11;
  li {
    padding: 0;
  }
  &.product-desc-desktop {
    @media (min-width: $bp-large) {
      padding: 4rem 5rem;
    }
    .product-desc-tab-body {
      @media (min-width: $bp-large) {
        padding: 0;
      }
    }
    .product-desc-tab-header {
      @media (min-width: $bp-large) {
        padding: 0 0 1.938rem 0;
      }
    }
  }
}

.product-desc-tab-body {
  padding: 0 1rem;
  background-color: $grey11;
  @extend .rd-product-tab-body-text;
  ul {
    padding-left: 1.7rem !important;
  }
  p,
  .tab-pane {
    li {
      display: list-item;
      margin-bottom: 1rem;
    }
  }
  a {
    font-weight: 700;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 0.125rem;
    td {
      border: none !important;
      background-color: $grey11;
      @extend .rd-product-tab-body-text;
    }
  }
}

.product-desc-tab-header {
  padding: 1rem 0 1.938rem 1rem;
}

.Customer-Review-Rating-tab {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .product-detail__rating__score__count {
    @extend .rd-review-count-text;
    text-decoration: underline;
    margin-left: 0.25rem;
  }

  .review-title {
    margin-right: 0.5rem;
  }

  .customer-cta-rating {

    .product-detail__ratings-link {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .icon-fa-star {
      @extend .rd-icon-fa-star;
    }
    .icon-fa-star-half-o {
      @extend .rd-icon-fa-star-half-o;
    }
    .icon-fa-star-o {
      @extend .rd-icon-fa-star-o;
    }
  }
}
