.saved-addresses {
  &:not(&--stores) {
    margin-bottom: rem-calc(40);
  }

  &__card {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    &:not(&--store) {
      .address-summary {
        &__id {
          font-weight: bold;
          margin-bottom: map-get($spacers, 2);
        }

        &__phone {
          padding-top: rem-calc(10);
          display: inline-block;
        }
      }
    }
  }

  &__card-wrapper {
    display: flex;
    flex-direction: column;
    border-color: $grey3;
    background-color: $grey3;

    &--new {
      background-color: $white;
      border-color: $secondary;
    }
  }
  &__card-header {
    order: 2;
    border-bottom: none;
  }
  &__card-body {
    order: 1;
    border-bottom: 1px solid $grey8;
    flex-grow: 1;
    margin-bottom: rem-calc(20);
  }
  &__card-actions {
    order: 3;
  }

  &__edit,
  &__delete {
    @extend .btn;
    @include btn-primary();

    background-color: $grey3;
  }
  &__edit {
    margin-right: rem-calc(5);
    width: calc(100% - #{$button-height} - #{rem-calc(10)});
  }
  &__delete {
    padding-left: 0;
    padding-right: 0;
    width: $button-height;

    &__text {
      display: none;
    }
    &__icon {
      display: inline;
    }

    &--store {
      width: 100%;
    }
    &--store &__text {
      display: inline;
    }
  }
  &__separator {
    display: none;
  }

  &__store-name {
    font-weight: bold;
    margin-bottom: map-get($spacers, 2);
  }

  &__add-address-btn,
  &__add-store-btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: $fontsize-xlarge;
    letter-spacing: rem-calc(7.2);
  }

  &--addresses,
  &--stores {
    padding: 0 map-get($spacers, 5);
  }
}

.save-address {
  &__make-default,
  &__make-default-billing {
    display: inline-block;
    margin-right: rem-calc(10);
  }

  .btn-save {
    @include media-breakpoint-up(md) {
      width: calc(50% - #{map-get($spacers, 4)});
    }
  }
}

.account-section {
  .saved-addresses {
    &__edit,
    &__delete {
      color: $black;

      &:hover {
        color: $white;
      }
    }

    .font-icon {
      line-height: rem-calc(28);
    }
  }
}
