$nav-menu-width: 90% !default;

$nav-menu-header-height: 130px !default;
$nav-menu-footer-height: 100px !default;
$nav-menu-body-height: $nav-menu-header-height + $nav-menu-footer-height !default;

$menu-animations-duration: 0.3s !default;
$menu-animations-easing: cubic-bezier(0.785, 0.135, 0.15, 0.86) !default;

.imagery-navigation {
  padding-left: 0.75rem;
  img {
    width: 5.9rem;
    height: 5.9rem;
    border-radius: 50%;
  }

  .cat-name {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.0375rem;
    text-transform: capitalize;
    padding-top: 0.25rem !important;
  }

  .cat-Imagery {
    overflow-x: auto !important;
    overflow: hidden;
    gap: 1.5rem;
    padding-bottom: 1rem;
  }
}

.cookie-warning, // FF animation hack
body {
  left: 0;
  transition:
    left $menu-animations-duration $menu-animations-easing,
    right $menu-animations-duration $menu-animations-easing;

  &.nav-menu-toggled {
    overflow: hidden;

    // TODO: Disabled, as it causes issues with the menu's height and width
    // &--left .cookie-warning, // FF animation hack
    // &--left {
    //   left: $nav-menu-width;
    // }
    &--right .cookie-warning, // FF animation hack
    &--right {
      left: -$nav-menu-width;
    }
    &--left,
    &--right {
      .header-sticky,
      .header-banner {
        height: 0;
        min-height: 0;
        padding: 0;

        .header {
          &.container-fluid {
            visibility: hidden;
          }
        }
      }

      .header-banner {
        overflow: hidden;
      }
    }
  }
}

.nav-menu {
  $root: &;

  position: fixed;
  top: 0;
  height: 100vh;
  height: -webkit-fill-available; // The non-prefixed value would be stretch, but the autoprefixer outputs -moz-available, and it doesn't work on FF; this seems to cover all cases
  width: $nav-menu-width;
  background-color: $page-bg;
  z-index: $nav-menu-z-index;

  &__search {
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
    padding-bottom: 0.75rem !important;
  }

  &--left {
    left: -$nav-menu-width;
    transition: left $menu-animations-duration $menu-animations-easing;
  }
  &--left#{&}--visible {
    left: 0;
  }
  &--right {
    right: -$nav-menu-width;
    transition: right $menu-animations-duration $menu-animations-easing;
  }
  &--right#{&}--visible {
    right: 0;
  }
  &--left#{&}--visible,
  &--right#{&}--visible {
    > #{$root}__overlay {
      display: block;
    }
  }

  &__overlay {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.3;
    cursor: pointer;
    background-color: $black;

    @include media-breakpoint-up(md) {
      pointer-events: none; // So hovering on the overlay closes the flyout
    }
  }
  &--left & {
    &__overlay {
      left: 100%;

      @include media-breakpoint-up(lg) {
        left: 0;
      }
    }
  }
  &--right & {
    &__overlay {
      right: 100%;
    }
  }

  &__header {
    position: relative;
    padding-bottom: rem-calc(30);
  }

  &__close {
    float: right;
  }

  &__close-text {
    text-transform: none;
  }

  &__body {
    position: relative;
    height: calc(100% - #{$nav-menu-body-height});
    overflow-y: auto;
    overflow-x: hidden;

    // z-index added in order to make it appear properly on IE
    z-index: $nav-menu-z-index;
  }

  &__back {
    position: absolute;
    left: 100%;
    height: auto;
    bottom: -#{rem-calc(8)};
    transition: left $menu-animations-duration / 2 $menu-animations-easing;
    pointer-events: none;

    &.show {
      left: 0;
    }
  }
  &__back-link {
    display: inline-block; // IE ignores pointer events on inline elements
    pointer-events: auto;
  }

  &__items--level-1 {
    @include media-breakpoint-up(lg) {
      border-top: 1px solid $border-color;
    }
  }
  &__items--level-2 {
    width: 100%;
    background-color: $page-bg;
    padding-left: map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
      #{$root}__item #{$root}__item-link:hover {
        color: $secondary;
      }

      #{$root}__item-link--level-2 {
        font-weight: bold;
      }
    }

    // Simulates there being a .container wrapper around the level-3 items on the desktop menu
    @each $breakpoint, $container-max-width in $container-max-widths {
      @if $breakpoint != sm and breakpoint != md {
        @include media-breakpoint-only($breakpoint) {
          padding-left: calc((100% - #{$container-max-width}) / 2);
          padding-right: calc((100% - #{$container-max-width}) / 2);
        }
      }
    }
  }

  &__item-link {
    &--level-1 {
      font-weight: bold;
      margin-bottom: map-get($spacers, 3);
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    &--level-2 {
      text-transform: uppercase;
      margin-bottom: map-get($spacers, 4);

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  // Flyout-style Menu
  @include media-breakpoint-up(lg) {
    position: static;
    height: auto;

    &__body {
      overflow: visible;
    }

    &__items--level-1 {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
      }
      > #{$root}__item {
        > #{$root}__item-link {
          position: relative;
          letter-spacing: rem-calc(1.4);
          font-size: rem-calc(13);
          padding-top: rem-calc(10);
          padding-bottom: rem-calc(10);

          &::before {
            content: '';
            width: 0;
            height: rem-calc(2);
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $secondary;
            transition: width $menu-animations-duration $menu-animations-easing;
          }
        }

        #{$root}__overlay {
          top: 100%;
          right: 0;
        }

        &:active,
        &:hover {
          > #{$root}__item-link {
            &::before {
              width: 100%;
            }
          }

          #{$root}__items--level-2 {
            transform: scaleY(1);
            li {
              opacity: 1;
            }
          }

          #{$root}__overlay {
            display: block;
          }
        }
      }
    }

    &__items--level-2 {
      position: absolute;
      left: 0;
      top: 100%;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.01s;
      li {
        opacity: 0;
        line-height: 1.9;
        transition: opacity $menu-animations-duration $menu-animations-easing;
      }
    }
  }

  // Behaviors
  &--slide {
    #{$root}__items--level-1 {
      transition: transform $menu-animations-duration $menu-animations-easing;
      #{$root}__item {
        &-link {
          @include media-breakpoint-down(md) {
            background: $grey3;
            padding: rem-calc(20) rem-calc(10);
            margin-bottom: rem-calc(5);
            &:active,
            &:hover {
              background: $secondary;
              color: $white;
            }
          }
        }
      }
    }
    &#{$root}--toggled {
      #{$root}__items--level-1 {
        transform: translate3d(-100%, 0, 0);
      }
    }

    #{$root}__items--level-2 {
      height: auto;

      border-top: rem-calc(1) solid #dee2e6;
      border-bottom: rem-calc(1) solid #dee2e6;

      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        left: 100%;
        transition: none;
        transition-duration: $menu-animations-duration;
        transition-timing-function: $menu-animations-easing;
        padding-left: 0;

        #{$root}__item-link {
          background: transparent;

          &--level-2 {
            background: $grey3;
            padding: rem-calc(20) rem-calc(10);
            margin-bottom: rem-calc(10);

            #{$root}__item-link-chevron {
              transition: transform $menu-animations-duration $menu-animations-easing;
            }

            &[aria-expanded="true"] {
              margin-bottom: 0 !important;

              #{$root}__item-link-chevron--collapsed {
                display: none;
              }

              #{$root}__item-link-chevron--expanded {
                display: block;
              }
            }

            &[aria-expanded="false"] {
              margin-bottom: 0 !important;

              #{$root}__item-link-chevron--collapsed {
                display: block;
              }

              #{$root}__item-link-chevron--expanded {
                display: none;
              }
            }
          }
        }
      }

      &.collapsing {
        display: block;
      }
    }
    #{$root}__items--level-3 {
      @include media-breakpoint-down(md) {
        #{$root}__item {
          border-bottom: 1px solid $grey3;
          &:last-child {
            border-bottom: none;
          }
          &-link {
            padding: rem-calc(20);
            &:active,
            &:hover {
              background: white;
              color: $secondary;
            }
          }
        }
      }
    }
  }

  &--expand {
    #{$root}__item-link-chevron {
      transition: transform $menu-animations-duration $menu-animations-easing;
    }
    [aria-expanded="true"] {
      #{$root}__item-link-chevron {
        transform: rotateZ(90deg);
      }
    }
  }

  &__link {
    @include media-breakpoint-only(md) {
      vertical-align: bottom;

      &__text {
        display: none;
      }
    }
  }

  &__footer {
    padding-top: map-get($spacers, 2);
    padding-left: 0;
    padding-right: 0;
  }
  &__footer-row {
    padding-top: map-get($spacers, 1);
    padding-bottom: map-get($spacers, 1);
    border-top: 1px solid $border-color;
  }
  &__footer-item {
    &:first-child {
      border-right: 1px solid $border-color;
    }

    &__content {
      padding-top: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 2);
    }

    .header-element__icon {
      display: inline-block;
    }
  }

  &__content--side {
    padding-right: 0;
  }
}

.inline-menu {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.primary-category-slider {
  white-space: nowrap;
  overflow: auto;
  margin-bottom: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  span {
    text-transform: uppercase;
    margin-right: rem-calc(10);
  }
}
