@import "../checkout/checkoutLogin";
@import "../components/checkout/common";
@import "../components/checkout/summary";
@import "../components/checkout/pickers";
@import "../components/checkout/progressIndicator";
@import "../components/checkout/savedAddresses";
@import "../components/checkout/pages/shipping";
@import "../components/checkout/pages/billing";
@import "../components/checkout/pages/summary";
@import "../components/adyenCss";

$checkout-flyout-pane-max-width: 90% !default;
$checkout-flyout-pane-max-width-desktop: rem-calc(650) !default;

.checkout {
  &__flyout {
    @include flyout($checkout-flyout-pane-max-width, $checkout-flyout-pane-max-width-desktop);

    &__close {
      position: relative;
      z-index: 1;
    }
  }

  &__content {
    margin-left: 0;
    margin-right: 0;
  }

  &__step {
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: map-get($spacers, 5);
    }
  }

  .spinner {
    position: fixed;
  }
}

//checkout header
.checkout-header {
  overflow: hidden;
  background-color: $page-bg;

  .navbar-header {
    margin-bottom: map-get($spacers, 1);
  }

  .header-secure-checkout {
    font-size: rem-calc($fontsize-large);
    color: $black;

    .secure-checkout-icon {
      font-size: rem-calc(24);
    }
  }

  .continue-shopping {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .header-need-help {
    color: $black;
    margin-top: rem-calc(-10);
    text-align: right;

    .need-help-content {
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      margin-top: rem-calc(-20);
    }
  }
}

//checkout footer
#checkout-footer {
  @extend #footer;

  overflow: hidden;
  padding-top: map-get($spacers, 3);
  border-top: solid rem-calc(1) $border-color;
  padding-bottom: 0;

  li {
    display: inline-block;
    text-decoration: underline;
    width: 49%;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.checkout-step {
  &__heading {
    text-align: center;
  }
}
