@import "float-label-css/scss/float-label";

$form-control-height: rem-calc(40);
$forms-radio-indicator-width: rem-calc(20);
$forms-radio-indicator-selected-width: rem-calc(10);
$forms-row-margin-bottom: rem-calc(30);
$forms-checkbox-indicator-size: rem-calc(14) !default;
$forms-checkbox-indicator-offset: rem-calc(-7) !default;
$forms-selection-controls-border-color: $border-color !default;
$form-control-border-color: $grey2 !default;

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

select::-ms-expand {
  display: none;
}

.form-row-select {
  position: relative;
}

input,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="time"],
input[type="number"],
input[type="date"],
input[type="password"],
select,
textarea {
  width: 100%;
  padding: rem-calc(15 40 15 7);
  letter-spacing: rem-calc(0.05);
  border: 1px solid $form-control-border-color;
  color: $black;
  height: rem-calc(40);

  &:not([disabled]) {
    &.is-valid:not(select) {
      border-color: $green;

      ~ .form-icon {
        @include fa-icon();

        color: $green;
        right: rem-calc(20);
        display: inline-block;
        font-family: $icon-font-fa;

        &::before {
          @include fa-icon-override('fa-check', $fa-var-check);
        }
      }
    }

    &.is-invalid:not(select) {
      border-color: $red;

      ~ .invalid-feedback {
        display: block;

        ~ .form-icon {
          @include fa-icon();

          color: $red;
          right: rem-calc(20);
          font-size: rem-calc(16);
          font-family: $icon-font-fa;

          &::before {
            @include fa-icon-override('fa-exclamation-circle', $fa-var-exclamation-circle);
          }
        }
      }
    }
  }

  &[data-uppercase="true"] {
    text-transform: uppercase;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.is-valid,
  &.is-invalid {
    right: rem-calc(10);
  }

  ~ .form-icon {
    color: $black;
    top: rem-calc(1);

    &::before {
      font-family: $icon-font;
      content: map-get($icon-symbols, 'chevron-down');
    }
  }

  &:focus {
    outline: none;
    border-color: $black;
  }
}

input[placeholder],
input::placeholder,
textarea::placeholder {
  padding-bottom: rem-calc(5);
  color: $grey5;
  font-size: $fontsize-large;
  opacity: 1;
}

.form-icon {
  position: absolute;
  display: inline-block;
  top: rem-calc(3);
  right: rem-calc(10);
  margin-top: rem-calc(10);
  //width: rem-calc(15);
  //height: rem-calc(15);
  box-sizing: content-box;
  pointer-events: none;
}

.form-control {
  height: $form-control-height;
  background-color: $white;
  border-radius: 0;

  &.is-valid,
  &.is-invalid {
    &:focus {
      box-shadow: none;
    }
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.form-group {
  margin: 0 0 $forms-row-margin-bottom;

  &--textarea {
    &.has-float-label {
      background-color: $white;
      padding-top: $fontsize-large;

      textarea {
        padding-top: 0;
      }
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    cursor: default;

    &::before {
      background-color: transparent;
      border: 1px solid $forms-selection-controls-border-color;
    }
  }
}

.invalid-feedback,
.error {
  letter-spacing: rem-calc(0.6);
  font-size: rem-calc(12);

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.alert-danger {
  color: $red;
  border-color: $red;
  background-color: $white;
  border-radius: 0;

  ul {
    margin-top: rem-calc(15);
  }

  a {
    text-decoration: underline;
    color: $red;
  }
}

.has-float-label label,
.has-float-label > span {
  color: $grey1;
  top: rem-calc(3);
  left: rem-calc(8);
  font-size: rem-calc(12);

  &::after {
    content: "";
  }
}

@mixin form-checkbox-control {
  content: '';
  position: absolute;
  left: $forms-checkbox-indicator-offset;
  background-color: transparent;
  border: $border-width solid $forms-selection-controls-border-color;
  width: $forms-checkbox-indicator-size + (2 * rem-calc($border-width));
  height: $forms-checkbox-indicator-size + (2 * rem-calc($border-width));
}

@mixin form-radio-label {
  cursor: default;
  margin-bottom: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: $forms-radio-indicator-width;
    height: $forms-radio-indicator-width;
    background-color: transparent;
    border-radius: rem-calc(10);
    margin-top: rem-calc(-4);
    border: $border-width solid $forms-selection-controls-border-color;
  }
}

@mixin form-checked-radio-label {
  &::before {
    content: '';
    top: rem-calc(9);
    position: absolute;
    margin-left: rem-calc(5);
    width: $forms-radio-indicator-selected-width;
    height: $forms-radio-indicator-selected-width;
    z-index: $forms-radio-indicator-z-index;
    box-shadow: none;
    background-color: $black;
  }

  &::after {
    background-image: none;
    background-color: transparent;
  }
}

.has-float-label {
  select {
    border: 1px solid $grey2;
    padding: rem-calc(14 31 5 7);
    background-image: none;
    background-color: $page-bg;
  }

  &.form-row-radio {
    .radio-box {
      padding-left: 0;
      margin-left: map-get($spacers, 3);

      .custom-control-input.is-valid,
      .is-valid.input-radio {
        ~ label {
          color: $black;
        }
      }

      label {
        @include form-radio-label;

        position: relative;
        font-size: $fontsize-large;
      }

      .input-radio {
        &:checked {
          ~ label {
            @include form-checked-radio-label;
          }
        }
      }
    }
  }

  &.form-row-checkbox {
    padding-left: 0;

    .input-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &.is-valid {
        ~ .form-icon {
          color: transparent;
        }

        &:checked {
          ~ .form-icon {
            color: $black;
          }
        }
      }

      &:checked {
        ~ .form-icon {
          @include fa-icon();

          width: $forms-checkbox-indicator-size;
          height: $forms-checkbox-indicator-size;
          line-height: $forms-checkbox-indicator-size;
          font-family: $icon-font-fa;
          top: rem-calc(4);
          left: rem-calc(1);
          border: 1px solid $black;
          text-align: center;

          &::before {
            @include fa-icon-override('fa-check', $fa-var-check);
          }
        }
      }
    }

    label {
      position: relative;
      padding-top: rem-calc(2);
      padding-left: rem-calc(20);
      top: rem-calc(10);
      color: $black;
      font-size: $fontsize-large;
      cursor: pointer;
      background-color: transparent;

      &::before {
        @include form-checkbox-control;
      }
    }
  }
}

.has-float-label input[type="hidden"][placeholder]:not(.focus) + *,
.has-float-label input:placeholder-shown:not(:focus) + *,
.has-float-label select:placeholder-shown:not(:focus) + *,
.has-float-label textarea:placeholder-shown:not(:focus) + * {
  top: rem-calc(10);
  color: $grey5;
  font-size: $fontsize-large;
  opacity: 1;
  pointer-events: none;
}

.has-float-label input:focus::placeholder,
.has-float-label textarea:focus::placeholder {
  color: transparent;
}

.form-group:not(.has-float-label) {
  label {
    display: none;
  }
  input,
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="time"],
  input[type="number"],
  input[type="date"],
  input[type="password"],
  select,
  textarea {
    padding-top: rem-calc(5);
  }
}

.example-value {
  margin-top: rem-calc(-30);
}

fieldset {
  legend {
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    font-size: $fontsize-xlarge;
  }

  &.inset-legend {
    border: 1px solid $border-color;
    padding: map-get($spacers, 4) map-get($spacers, 5);

    legend {
      padding-left: map-get($spacers, 4);
      padding-right: map-get($spacers, 4);
    }
  }
}

.required-form {
  color: $grey1;
  margin-bottom: $forms-row-margin-bottom;
}

.error-summary {
  &__heading {
    text-align: center;
  }
}
