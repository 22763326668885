$product-card-name-color: $primary;
$product-card-list-price-color: $grey5 !default;
$product-card-discounted-price-color: $lipstick !default;
$product-card-image-width: 85px !default;

.product-card {
  width: 100%;
  border: none;
  margin-bottom: map-get($spacers, 4);

  &__details {
    &--unavailable {
      .line-item-availability {
        color: $red;
      }
    }

    .line-item-availability {
      margin: map-get($spacers, 2) 0;
      display: none;

      p {
        margin-bottom: 0;
      }
    }
  }

  .line-item-pre-order-message {
    background-color: $WhiteChocolate;
    padding: 3px 18px 4px 4px;
    margin: 12px 0;
  }

  &__image {
    position: relative;
    overflow: visible;
    width: $product-card-image-width;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    text-align: left;
    font-size: rem-calc($fontsize-medium);

    .line-item-instock-date {
      display: none;
    }
  }

  &__price {
    .strike-through {
      color: $product-card-list-price-color;
      text-decoration: line-through;
    }

    .has-strike-through {
      font-weight: bold;
      color: $product-card-discounted-price-color;
    }

    &__promo {
      font-size: $fontsize-medium;
      font-weight: normal;
      color: $product-card-discounted-price-color;
    }
  }

  &__name {
    margin-bottom: rem-calc(10);
    overflow: hidden;
  }

  &__link {
    color: $product-card-name-color;
  }

  &__attribute {
    margin-bottom: map-get($spacers, 2);

    &--size {
      margin-right: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
      border-right: rem-calc(1) solid $grey1;
    }
  }

  &__quantity {
    margin-bottom: map-get($spacers, 2);
  }
}

.gift-messaging {

  &__gift-message-display-heading {
    font-weight: bold;
  }
}
