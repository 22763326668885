@import '../components/storeSearch';

.preference-centre {
  padding-left: map-get($spacers, 5);
  padding-right: map-get($spacers, 5);

  .privacy-policy-message .content-asset {
    font-size: rem-calc(12);
  }

  .detect-location-text {
    display: none;
  }

  .newsletter {
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 3);
    @include media-breakpoint-up(lg) {
      border-right: 1px solid $grey2;
      border: none;
      padding: map-get($spacers, 4) map-get($spacers, 5) map-get($spacers, 6) map-get($spacers, 5);
    }

    &__heading {
      text-align: left;

      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }
  }

  .newsletterForm {
    .form-row {
      margin-bottom: map-get($spacers, 1);

      label {
        padding-top: map-get($spacers, 1);
        font-size: rem-calc(14);
      }
    }
  }

  .preferred-store {
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 3);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      padding-bottom: map-get($spacers, 6);
    }

    .preferred-store-details {
      margin-bottom: auto;
      text-align: center;

      .store-address-wrapper {
        p {

          &.store-title {
            text-transform: uppercase;
          }

          &.store-title,
          &.store-phone {
            font-weight: bold;

            .font-icon {
              display: none;
            }
          }
        }
      }

      .store-hours-wrapper {
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-up(lg) {
          padding-left: map-get($spacers, 2);
          padding-right: map-get($spacers, 2);
        }

        .open-hours-text {
          min-width: rem-calc(200);
        }

        .open-close-bold {
          font-weight: bold;
        }

        .font-icon {
          font-weight: bold;
        }

        .store-work-times {
          width: 100%;

          .current-day {
            color: $secondary;
            font-weight: bold;
          }
        }
      }
    }

    &__heading {
      text-align: left;

      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }
  }
}
