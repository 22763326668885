.filters {
  &__heading {
    text-align: left;
    border-bottom: none;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }
  &__heading-text {
    font-weight: lighter;
    font-size: $fontsize-large;
    letter-spacing: rem-calc(6.4);
  }

  &__close-button {
    text-align: center;
    padding-right: 0;

    &__text {
      display: inline;
      font-size: $fontsize-small;
      text-transform: uppercase;
      letter-spacing: rem-calc(4.2);
    }
  }

  &__filter-body {
    &--size:not(.one-col),
    &--shoesize:not(.one-col) {
      > .values {
        column-count: 6;
        width: calc(100% - #{rem-calc(20)});
        @include media-breakpoint-down(md) {
          display: flex;
          flex-flow: row wrap;
          column-gap: 2.5%;
          .filters__value--size {
            flex: 0 0 15%;
          }
        }
      }
    }
    &:not(.one-col) {
      > .values {
        transform: translateX(0);
      }
    }
  }

  &__value {
    @include media-breakpoint-down(sm) {
      &.selected {
        border-color: $primary;
        background-color: $primary;

        .text {
          color: $white;
        }
      }

      &.disabled {
        border-color: $grey5;

        .text {
          color: $grey5;
        }
      }

      &--category {
        &.selected {
          border-color: transparent;
          background-color: transparent;

          .text {
            color: $primary;
          }
        }

        &.disabled {
          border-color: transparent;
        }
      }
    }

    &--size,
    &--shoesize {
      @include media-breakpoint-up(lg) {
        .text {
          border-color: $primary;
        }

        &.selected {
          .text {
            color: $white;
            background-color: $primary;
          }
        }
      }
    }

    &-link {
      &::before {
        width: rem-calc(17);
        height: rem-calc(17);
        vertical-align: top;
      }

      &.selected {
        &::before {
          content: '';
        }

        @include media-breakpoint-up(lg) {
          &::after {
            content: map-get($icon-symbols, 'check');
            font-family: $icon-font;
            font-size: rem-calc(17);
            position: absolute;
            top: calc(50% - 12px);
            left: rem-calc(4);
          }
        }
      }

      &.disabled {
        &::before {
          border-color: $grey5;
        }
      }

      &--size,
      &--shoesize {
        &::after {
          display: none;
        }
      }
    }

    &-text {
      color: $primary;
    }
  }

  &__filter {
    border-color: $grey8;

    &-header {
      padding: map-get($spacers, 3) 0;

      &__text {
        color: $primary;
        font-size: $fontsize-medium;
        letter-spacing: rem-calc(5.6);
        text-transform: uppercase;
      }

      &__icon {
        padding-top: map-get($spacers, 3);
        margin-top: 0;

        &:not(.clear-all) {
          padding-top: map-get($spacers, 3);
        }
      }
    }

    &--applied-filters {
      position: relative;

      @include media-breakpoint-up(lg) {
        padding-bottom: map-get($spacers, 4);
      }

      .filters__toggle-section__btn-icon {
        position: static;
      }

      .reset {
        display: none;
        font-weight: bold;
        position: absolute;
        left: 0;
        bottom: map-get($spacers, 2);

        @include media-breakpoint-up(lg) {
          display: inline;
        }
      }

      @include media-breakpoint-down(sm) {
        .filters__filter-header {
          display: none;
        }
      }
    }

    &__applied {
      border-color: $primary;

      &__remove {
        line-height: 1;

        &__icon {
          font-size: rem-calc(13);
        }
      }
    }
  }

  &__footer {
    box-shadow: none;
    border-top: rem-calc(1) solid $grey8;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
