.contact-us {
  &__container {
    @include make-container();
    @include make-container-max-widths();
  }

  &__content {
    .font-icon {
      color: $secondary;
      font-size: rem-calc(40);
      margin-bottom: map-get($spacers, 3);
    }

    .content-asset,
    .container,
    .row {
      height: 100%;
    }

    [class*="col"] {
      line-height: 1.4;

      .content-asset {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @include media-breakpoint-up(lg) {
        height: 50%;
      }
    }
  }

  &__form {
    .font-icon {
      font-size: rem-calc(40);
    }
  }

  &__wrapper {
    .confirmation-page {
      text-align: center;
    }
  }
}

.contact-form {
  background-color: $grey9;
  padding: rem-calc(18);
  text-align: center;
  .additional-info {
    font-weight: 600;
    font-size: rem-calc(14);
  }
}
