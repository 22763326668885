$login-inactive-tab-bg: $grey2 !default;
$login-inactive-tab-text-color: $grey1 !default;
$login-disclaimer-color: $grey1 !default;
$registration-form-max-width: rem-calc(400) !default;

@mixin login-registration-form($base-selector) {
  #{$base-selector}__register-info {
    line-height: 2;
  }

  #{$base-selector}__disclaimer {
    font-size: rem-calc($fontsize-small);
    color: $login-disclaimer-color;
    margin-bottom: rem-calc(10);

    &--large {
      font-size: rem-calc($fontsize-medium);
    }
  }
}

.login {
  @include login-registration-form(&);

  @include media-breakpoint-up(md) {
    &__tab-panes > &__tab-pane {
      display: block;
    }

    &__tab-item {
      padding-left: 0;
      padding-right: 0;
    }

    &__tab-item--login,
    &__tab-pane--login {
      border-right: 1px solid $border-color;
    }
    &__tab-pane--login {
      padding-right: map-get($spacers, 5);
    }
    &__tab-pane--pre-register {
      padding-left: map-get($spacers, 5);
    }
  }

  &__tab-panes,
  &__tab-items {
    padding-left: 0;
    padding-right: 0;
  }

  &__tab-link {
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
    font-size: rem-calc(20);

    &.nav-link {
      border: 1px solid $border-color;
    }

    &.active {
      border-bottom: none;
    }

    @include media-breakpoint-down(sm) {
      &:not(.active) {
        color: $login-inactive-tab-text-color;
        background-color: $login-inactive-tab-bg;
      }
    }

    @include media-breakpoint-up(md) {
      cursor: default;
      font-size: rem-calc(24);
      border: none;

      &.nav-link {
        border: none;
      }
    }
  }

  &__content {
    .login-error {
      @include make-col-ready();
      @include make-col(12);

      max-width: rem-calc(400);
      margin-left: 50%;
      margin-right: 50%;
    }
  }

  // Ensures that the submit buttons of the forms are always aligned next to each other
  &__form {
    position: relative;
    height: 100%;
    padding-bottom: $button-height;

    &__heading {
      text-align: center;
      margin-bottom: map-get($spacers, 4);

      @include media-breakpoint-up(md) {
        margin-bottom: map-get($spacers, 5);
      }
    }

    .btn {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }

  &__onbehalf-text {
    color: white;
  }

  &__forgot-password {
    a {
      text-decoration: underline;
    }
  }
}

.registration {
  @include login-registration-form(&);

  &__form {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $registration-form-max-width;
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    .form-title {
      text-align: center;
    }
    #registration-form-title {
      min-width: 85px;
    }
  }

  .login__register-info {
    display: none;
  }

  &__intro {
    text-align: center;
  }

  .address-fields {
    &__row {
      @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
