.saved-addresses {
  &__saved-toggle {
    font-weight: bold;

    &__icon {
      display: none;
    }
  }

  &__container {
    @include media-breakpoint-up(md) {
      padding-left: rem-calc(80);
      padding-right: rem-calc(80);
    }
  }

  &__addresses-title {
    font-weight: lighter;
    font-size: $fontsize-xxxlarge;
    margin-bottom: map-get($spacers, 4);
  }

  &__addresses-close {
    text-align: center;
    margin-top: map-get($spacers, 4);

    &__text {
      display: inline;
      text-transform: uppercase;
      padding-right: map-get($spacers, 2);
    }
  }

  &__saved-address {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    margin-bottom: map-get($spacers, 4);
  }

  &__address-content {
    background-color: $grey3;
  }

  &__address-header {
    font-weight: bold;
  }

  &__address-body {
    padding-left: 0;
    padding-right: 0;
  }

  &__new-toggle {
    display: block;
    padding: map-get($spacers, 3) 0;
    border-top: rem-calc(1) solid $primary;
    border-bottom: rem-calc(1) solid $primary;

    &__icon {
      float: right;
    }

    &__text {
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: rem-calc(5.6);
    }
  }
}
