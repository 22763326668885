.product-detail__fit-rating {
  margin-bottom: 1.5rem;

  .customer-fit-rating {
    @extend .rd-customer-fit-rating-label;
    height: 0.875rem;
    align-items: center;
  }

  .customer-fit-reviews-count {
    display: none;
  }

  .fit-rating-marker {
    height: 1rem;
    padding: 0 5%;

    #rd-fit-marker {
      @extend .fit-marker;
      display: block;
      position: relative;
      top: 0.7rem;
      width: 0.75rem;
      height: 1rem;
    }
  }

  .rd-fit-rating-scale {
    @extend .rd-fit-rating-scale;
    margin-bottom: 1rem;
  }

  .fit-labels {
    @extend .rd-fits-small;
    @extend .rd-prefect-fit;
    @extend .rd-fits-large;
    height: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
  }
}
