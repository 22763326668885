@import './productCard';
@import './flyout';

$minicart-max-width: 90% !default;
$minicart-max-width-desktop: rem-calc(390) !default;
$minicart-flyout__heading-styles: false !default;
$spacer-4: map-get($spacers, 4);

.minicart {
  @include flyout($minicart-max-width, $minicart-max-width-desktop, $applyComponentStyles: $minicart-flyout__heading-styles);

  &__close-btn {
    text-align: right;
    float: right;
  }

  &__pane {
    &.collapse {
      &.show {
        pointer-events: auto;
      }

      &:not(.show) {
        pointer-events: none;
        display: block;
      }
    }
  }

  &__content {
    position: relative;
    text-transform: none;
    height: 100%;
    padding: map-get($spacers, 4);
  }

  &__body {
    max-height: 100%;
    overflow-y: auto;

    .line-item-availability {
      display: none;
    }
  }

  &__notes {
    font-size: rem-calc($fontsize-small);
    line-height: rem-calc(15.4);
  }

  &__header {
    margin-bottom: map-get($spacers, 3);

    &__heading {
      @include make-col(8);

      padding: 0;
    }

    &__close-icon {
      @include make-col(4);
    }

    > .row {
      padding-bottom: map-get($spacers, 3);
    }
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $page-bg;
    padding: 0 $spacer-4 $spacer-4;
    margin-top: $spacer-4;

    .estimated-total {
      font-size: rem-calc($fontsize-large);
    }
  }

  &__totals {
    margin-top: map-get($spacers, 3);
    padding-top: map-get($spacers, 3);
    line-height: rem-calc(22.4);
  }

  .flyout__close-label {
    display: none;
  }

  .product-card {
    // Using half of the value because a duplicate class is causing this
    // to be applied to two nested containers
    margin-bottom: map-get($spacers, 4) / 2;

    &__content {
      line-height: rem-calc(19.6);
    }

    &__quantity {
      &.inline-quantity {
        display: none;
      }
    }

    &__price {
      margin-top: map-get($spacers, 2);
    }
  }
}
