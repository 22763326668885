$active-btn-selector: ":not(:disabled):not(.disabled):active";
$active-focus-btn-selector: ":not(:disabled):not(.disabled):active:focus";
$button-height: rem-calc(45) !default;
$button-line-height: rem-calc(30) !default;
$button-letter-spacing: rem-calc(0.8) !default;
$button-font-size: rem-calc($fontsize-large) !default;

$button-primary-bg: $primary !default;
$button-primary-color: $white !default;
$button-primary-border: $button-primary-bg !default;
$button-primary-hover-bg: $grey1 !default;
$button-primary-hover-bg-mobile: $button-primary-bg !default;
$button-primary-hover-color: $white !default;
$button-primary-hover-color-mobile: $button-primary-color !default;
$button-primary-hover-border: $button-primary-hover-bg !default;
$button-primary-hover-border-mobile: $button-primary-hover-bg-mobile !default;
$button-primary-active-bg: $grey1 !default;
$button-primary-active-color: $white !default;
$button-primary-active-border: $button-primary-active-bg !default;

$button-secondary-bg: $secondary !default;
$button-secondary-color: $white !default;
$button-secondary-border: $button-secondary-bg !default;
$button-secondary-hover-bg: $gold !default;
$button-secondary-hover-bg-mobile: $button-primary-bg !default;
$button-secondary-hover-color: $white !default;
$button-secondary-hover-color-mobile: $button-primary-color !default;
$button-secondary-hover-border: $button-secondary-hover-bg !default;
$button-secondary-hover-border-mobile: $button-secondary-hover-bg-mobile !default;
$button-secondary-active-bg: $gold1 !default;
$button-secondary-active-color: $white !default;
$button-secondary-active-border: $button-secondary-active-bg !default;

@mixin btn-primary() {
  @include button-variant(
    $background: $button-primary-bg,
    $border: $button-primary-border,
    $hover-background: $button-primary-hover-bg,
    $hover-border: $button-primary-hover-border,
    $active-background: $button-primary-active-bg,
    $active-border: $button-primary-active-border
  );

  color: $button-primary-color;

  &:hover {
    color: $button-primary-hover-color-mobile;

    @include media-breakpoint-up(lg) {
      color: $button-primary-hover-color;
    }
  }

  &#{$active-btn-selector} {
    color: $button-primary-active-color;
  }
}

@mixin btn-secondary() {
  @include button-variant(
    $background: $button-secondary-bg,
    $border: $button-secondary-border,
    $hover-background: $button-secondary-hover-bg,
    $hover-border: $button-secondary-hover-border,
    $active-background: $button-secondary-active-bg,
    $active-border: $button-secondary-active-border
  );

  color: $button-secondary-color;

  &:hover {
    color: $button-secondary-hover-color-mobile;

    @include media-breakpoint-up(lg) {
      color: $button-secondary-hover-color;
    }
  }

  &#{$active-btn-selector} {
    color: $button-secondary-active-color;
  }
}

button,
.btn {
  height: $button-height;
  line-height: $button-line-height;
  letter-spacing: $button-letter-spacing;
  font-size: $button-font-size;
  text-transform: uppercase;
  border-radius: 0;

  &#{$active-focus-btn-selector} {
    box-shadow: none;
  }

  > .btn-outline-primary,
  &.btn-outline-primary {
    background-color: transparent;
    border-color: $button-primary-bg;
    color: $primary;

    &:hover {
      background-color: transparent;
      border-color: $button-primary-hover-bg;
      color: $button-primary-hover-bg;
    }

    &#{$active-btn-selector} {
      background-color: transparent;
      border-color: $grey2;
      color: $grey2;
    }
  }

  &.btn-primary {
    background-color: $button-primary-bg;
    border-color: $button-primary-border;
    color: $button-primary-color;

    &:hover {
      background-color: $button-primary-hover-bg-mobile;
      border-color: $button-primary-hover-border-mobile;
      color: $button-primary-hover-color-mobile;

      @include media-breakpoint-up(lg) {
        background-color: $button-primary-hover-bg;
        border-color: $button-primary-hover-border;
        color: $button-primary-hover-color;
      }
    }

    &#{$active-btn-selector} {
      background-color: $button-primary-active-bg;
      border-color: $button-primary-active-border;
      color: $button-primary-active-color;
    }
  }

  &.btn-secondary {
    background-color: $button-secondary-bg;
    border-color: $button-secondary-border;
    color: $button-secondary-color;

    &:hover {
      background-color: $button-secondary-hover-bg-mobile;
      border-color: $button-secondary-hover-border-mobile;
      color: $button-secondary-hover-color-mobile;

      @include media-breakpoint-up(lg) {
        background-color: $button-secondary-hover-bg;
        border-color: $button-secondary-hover-border;
        color: $button-secondary-hover-color;
      }
    }

    &#{$active-btn-selector} {
      background-color: $button-secondary-active-bg;
      border-color: $button-secondary-active-border;
      color: $button-secondary-active-color;
    }
  }

  &.close {
    height: rem-calc(11);
  }

  &.btn-slim {
    height: rem-calc(28);
    min-width: rem-calc(170);
    padding-top: 0;
    padding-bottom: 0;
    font-size: $fontsize-medium;

    @include media-breakpoint-up(md) {
      height: rem-calc(34);
      min-width: rem-calc(200);
    }
  }

  &:focus {
    box-shadow: none;
  }
}
