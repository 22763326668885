$product-detail-bottom: -1;
$cookie-warning-message-z-index: 99;
$forms-radio-indicator-z-index: 1;
$nav-menu-z-index: 2;
$minicart-z-index: 1;
$tile-sticker-outofstock-z-index: 1;
$tile-active-quick-add-to-bag-btn-z-index: 2;
$checkout-progress-indicator-step-z-index: 1;
$product-detail-z-index: 1;
$wishlist-close-button-wrapper-z-index: 1;
$product-detail-attribute-unselectable-z-index: 1;
$wishlist-icon-z-index: 1;
$wishlist-indicator-z-index: 3;
$appointment-bookings-flyouts-z-index: 1;
$product-tile-cart-and-ipay-add-to-wish-list-z-index: 2;
$plp-filters-sorting-panels: 2;
$flayout-pane-z-index: 16;
$storeLocator-hide-left-right: 3;
$modal-bg-z-index: 5;
$header-search-suggestions-z-index: 9;
$storeLocator-store-results-container-z-index: 10;
$header-sticky-z-index: 10;
$searchRefinements-filters-container-z-index: 11;
$searchRefinements-footer-z-index: 1;
$loading-indicator-veil-z-index: 1;
$loading-indicator-veil-in-body-z-index: 100;
$store-locator-filter-result-container-z-index: 1;
$back-to-top-link-z-index: 2;
$wishlist-carousel-controls: 1;
