$checkout-progress-indicator-md-padding: 10% !default;
$checkout-progress-indicator-lg-padding: 30% !default;
$checkout-progress-indicator-step-size: 35px !default;

$checkout-progress-indicator-step-bg: $white !default;
$checkout-progress-indicator-step-color: $grey2 !default;
$checkout-progress-indicator-step-active-bg: $secondary !default;
$checkout-progress-indicator-step-active-color: $white !default;

.checkout {
  &__progress,
  &__progress-step {
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      top: $checkout-progress-indicator-step-size / 2;
      background-color: $grey2;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }

  &__progress-step-content {
    display: inline-block;
  }

  &__progress {
    padding: 0;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 0 $checkout-progress-indicator-md-padding;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 $checkout-progress-indicator-lg-padding;
    }

    &::before,
    &::after {
      @include media-breakpoint-up(md) {
        width: $checkout-progress-indicator-md-padding;
      }
      @include media-breakpoint-up(lg) {
        width: $checkout-progress-indicator-lg-padding;
      }
    }
    &::before {
      background-color: $checkout-progress-indicator-step-active-bg;
    }
  }

  &__progress-step {
    z-index: $checkout-progress-indicator-step-z-index;

    &::before,
    &::after {
      width: calc(50% - #{$checkout-progress-indicator-step-size / 2});
    }

    &--current,
    &--completed {
      .checkout__progress-step-number {
        color: $checkout-progress-indicator-step-active-color;
        background-color: $checkout-progress-indicator-step-active-bg;
      }

      &::before {
        background-color: $checkout-progress-indicator-step-active-bg;
      }
    }
    &--completed {
      cursor: pointer;
      &::after {
        background-color: $checkout-progress-indicator-step-active-bg;
      }
    }
  }

  &__progress-step-number {
    width: $checkout-progress-indicator-step-size;
    height: $checkout-progress-indicator-step-size;
    color: $checkout-progress-indicator-step-color;
    background-color: $checkout-progress-indicator-step-bg;
    margin-bottom: map-get($spacers, 3);
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .completed-number {
    display: none;
  }
}
