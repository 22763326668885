.checkout-summary {
  &--hidden {
    display: none;
  }

  .checkout-shipping__summary-content {
    margin-bottom: map-get($spacers, 4);
    padding: map-get($spacers, 4) map-get($spacers, 5);

    @include media-breakpoint-up(md) {
      margin-left: map-get($spacers, 5);
      margin-right: map-get($spacers, 5);
    }
  }

  .order-details__summary-items {
    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }
  }
  .order-details__summary-item {
    a {
      text-decoration: underline;
      float: right;
    }
    &--type,
    &--date {
      display: none;
    }
    &--payment-method {
      display: block;
    }
    &--shipping-address,
    &--billing-address {
      display: block;
      margin-bottom: map-get($spacers, 3);

      .order-details__summary-item-label,
      .order-details__summary-item-value {
        @extend .float-none;
      }

      .order-details__summary-item-value {
        display: block;
      }
    }
    &-label {
      font-weight: normal;
    }
  }
  &__submit {
    .checkout-billing__submit-subscribe {
      @include media-breakpoint-up(md) {
        margin-left: map-get($spacers, 5);
        margin-right: map-get($spacers, 5);
        padding-left: map-get($spacers, 5);
        padding-right: map-get($spacers, 5);
      }

      label {
        color: $grey1;
      }
    }
    .checkout-billing__submit-terms__link {
      color: $grey1;
      text-decoration: underline;
    }
  }
}
.summary-page {
  .paypal-summary--hidden,
  #checkout-footer {
    visibility: hidden;
  }
  .veil {
    height: 100vh;
    .underlay {
      background-color: #000;
    }
    .spinner {
      position: fixed;
    }
  }
}
