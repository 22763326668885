@mixin header($font-size, $letter-spacing) {
  text-transform: $headings-text-transform;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;

  @if $font-size {
    font-size: $font-size;
  }

  @if $letter-spacing {
    letter-spacing: $letter-spacing;
  }
}

@mixin h1 {
  @include header($h1-font-size, $h1-letter-spacing);
}
@mixin h2 {
  @include header($h2-font-size, $h2-letter-spacing);
}
@mixin h3 {
  @include header($h3-font-size, $h3-letter-spacing);
}
@mixin h4 {
  @include header($h4-font-size, $h4-letter-spacing);
}
@mixin h5 {
  @include header($h5-font-size, $h5-letter-spacing);
}
@mixin h6 {
  @include header($h6-font-size, $h6-letter-spacing);
}

h1,
.h1 {
  text-transform: $headings-text-transform;

  @if $h1-letter-spacing {
    letter-spacing: $h1-letter-spacing;
  }
}

h2,
.h2 {
  text-transform: $headings-text-transform;

  @if $h2-letter-spacing {
    letter-spacing: $h2-letter-spacing;
  }
}

h3,
.h3 {
  text-transform: $headings-text-transform;

  @if $h3-letter-spacing {
    letter-spacing: $h3-letter-spacing;
  }
}

h4,
.h4 {
  text-transform: $headings-text-transform;

  @if $h4-letter-spacing {
    letter-spacing: $h4-letter-spacing;
  }
}

h5,
.h5 {
  text-transform: $headings-text-transform;

  @if $h5-letter-spacing {
    letter-spacing: $h5-letter-spacing;
  }
}

h6,
.h6 {
  text-transform: $headings-text-transform;

  @if $h6-letter-spacing {
    letter-spacing: $h6-letter-spacing;
  }
}
