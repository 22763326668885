.back-to-top {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border: rem-calc(2) solid $grey13;
  width: rem-calc(70);
  height: rem-calc(75);
  padding: rem-calc(5);
  line-height: rem-calc(10);

  &.active {
    opacity: 1;
  }

  &__description {
    font-size: rem-calc(10);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: rem-calc(3);
  }

  .icon-chevron-up {
    transform: rotate(-90deg);
    margin-bottom: rem-calc(10);

    &::before {
      @include fa-icon-override('fa-arrow-right', $fa-var-arrow-right);
    }
  }
}
