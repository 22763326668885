.rd-sticky-atb-desktop {
  background: $overlay-white;

  .sticky-atb-container {
    flex-wrap: nowrap;

    @media (max-width: $bp-medium-max) {
      margin-right: 0.0625rem;
      margin-left: 0.00625rem;
    }

    @media (min-width: $bp-large) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-right: 1.5rem;
    }

    .stickyBarBagButton.inactive {
      display: none;
    }
  }

  .sticky-atb__product-name--text {
    @extend .rd-sticky-atb-product-name;
    margin-right: 1.25rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: auto;
  }

  a.stickyViewToBag,
  button.stickyAddToBag,
  .rd-sticky-atb-cart-atom {

    @media (max-width: $bp-medium-max) {
      width: 100%;
    }

    @media (min-width: $bp-large) {
      width: 16rem;
    }
  }

  a.stickyViewToWishlist,
  button.stickyAddToWishlist,
  .rd-sticky-atb-wishlist-atom {
    width: 3.5rem;
  }

  &.stickyBarMenu {
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    transition: transform 0.1s linear;
    z-index: 15;

    .btn-secondary.stickyAddToBag {
      padding: 0.5rem;
      border: none;
    }
    .btn {
      height: 3rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 0.175rem;

      @media (max-width: $bp-medium-max) {
        height: 2.5rem;
      }
    }
  }

  &.stickyBarMenuShow {
    transform: translateY(0);
  }

  .stickyBarBagButtonWishlist {
    position: absolute;
    line-height: 1;
  }

  .stickyAddToWishlist.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stickyViewToWishlist {
    display: none;
    &.active {
      display: flex;
      align-items: center;
    }
  }

  .rd-sticky-atb-wishlist-atom {
    flex: 0 0 3.5rem;
    .stickyViewToWishlist.btn-secondary,
    .stickyAddToWishlist.btn-secondary {
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background-color: transparent;
      }
      padding: 0.25rem 1rem;
      border: none;
      background-color: transparent;
    }

    .stickyViewToWishlist .icon-heart2-filled::before {
      @extend .rd-sticky-atb-wishlist-fill-icon;
      content: '';
    }

    .stickyAddToWishlist .icon-heart2::before {
      @extend .rd-sticky-atb-wishlist-icon;
      content: '';
    }

    .stickyViewToWishlist .viewBtnContainer {
      display: flex;

      .viewBtnText {
        display: none;
      }
    }
  }

  .stickyAddToWishlist {
    display: none;
  }

  .stickyViewToBag {
    &.btn-secondary {
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $green;
      color: $black;
      background: $white;
    }
  }
}
