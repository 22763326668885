$md-width: 1280px;
$sm-width: 991px;

.intelligent-reach-section {
  .fill_page {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 7px;
    padding-right: 7px;
    background: #f8f8f8;
  }
  .bg-color {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .product_details {
    margin-top: -5px;
    width: 98% !important;
    padding-right: 10px;
    padding-left: 5px;
    @media only screen and (max-width: $sm-width) {
      margin-top: 2px;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .product_name {
    font-size: 17px;
    font-weight: 500;
    color: #000 !important;
    // white-space: nowrap;
    width: 93%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    @media only screen and (max-width: $md-width) {
      font-size: 14px;
    }
  }

  .pricing {
    // letter-spacing: .04375rem;
    line-height: 1.7;
    padding-left: 0 !important;
  }

  .image-column {
    @media only screen and (min-width: $md-width) {
      max-width: 98%;
    }
    @media only screen and (min-width: $sm-width) and (max-width: $md-width) {
      max-width: 99%;
    }
    #img_url {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .rating {
    display: inline-block;
    font-size: 14px;
    padding-top: 10px;
    @media only screen and (min-width: $sm-width) and (max-width: $md-width) {
      font-size: 11px;
    }
    .font-icon {
      font-size: 14px;
      @media only screen and (min-width: $sm-width) and (max-width: $md-width) {
        font-size: 11px;
      }
    }
  }
  .description {
    font-size: 14px;
    letter-spacing: 0.04em;
    padding-top: 10px;
    width: 95%;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 70px;
    @media only screen and (max-width: $md-width) {
      font-size: 11px;
      margin-top: 2px;
      max-height: 55px;
    }
  }
  .add_to_cart {
    .add_button {
      width: 100%;
    }
  }

  /* .add_to_cart {
    width: 100%;
    // position: absolute;
    // bottom: 0;
    padding-right: 0.4rem;
    // padding-top: 1rem;
    @media only screen and (max-width: $md-width) {
      position: relative;
      margin-bottom: 13px;
    }
    .add_button {
      cursor: pointer;
      // background-color: #c9b38e;
      // border-color: #c9b38e;
      // color: #fff;
      height: 45px;
      padding: 10px 0;
      line-height: initial;
      letter-spacing: 0.05rem;
      font-size: 15px;
      text-transform: uppercase;
      border-radius: 0;
      border: 1px solid transparent;
      width: 100%;
      @media only screen and (max-width: $md-width) {
        font-size: 11px;
        height: auto;
      }
    }
  } */

  .more_info {
    font-size: 14px;
    color: black;
    line-height: 1.4;
    @media only screen and (max-width: $md-width) {
      font-size: 11px;
    }
    .more_information_link {
      text-decoration: underline;
    }
  }
  .product-full-page {
    display: none;
    @media only screen and (max-width: $sm-width) {
      font-size: 13.5px;
      display: block;
      text-align: center;
      margin-top: 1.4%;
      font-weight: 600;
      color: #333;
    }
    a {
      color: #333;
      text-decoration: underline;
    }
  }

  /* .all_sizes {
    width: 100%;
    .size_new {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 10px;
      width: 100%;
      margin-left: -0.17rem;
    }
    .size-box-new {
      -webkit-box-flex: 0;
      flex: auto;
      min-height: 1px;
      font-size: 14px;
      // line-height: 2.4em;
      padding-left: 0.17rem;
      padding-right: 0.17rem;
      text-align: center;
      font-weight: 700;
      margin-bottom: 0.5rem;
      max-width: 40px;
      display: flex;
      width: 100%;
      height: 35px;
      @media only screen and (max-width: $md-width) {
        font-size: 11px;
      }

      .size-link {
        width: 100%;
        height: 100%;
        // border: 1px solid #dee2e6;
        //color: #000;
        cursor: pointer;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        &.out_of_stock {
          position: relative;
          background-image: url('../../../../static/default/images/size-unavailable.svg');
          background-size: 100% 100%;
          color: #dee2e6;
          pointer-events: none;
        }
        &.sizeSelected {
          border: 1px solid #000;
        }
      }
    }
  } */
  .all_sizes {
    .size-box-new {
      max-width: none;
    }
  }
  &.WH {
    margin-bottom: 10px;
    .product_name {
      letter-spacing: 2px;
      @media only screen and (max-width: $sm-width) {
        padding-top: 1rem;
      }
    }
    .description {
      letter-spacing: 0.125rem;
      font-size: 12px;
    }
    .add_button {
      letter-spacing: 0.125rem;
    }
    .more_info {
      font-size: 12px;
    }
    .all_sizes {
      .size-box-new {
        .size-link {
          width: auto;
          padding: 0.75rem;
        }
      }
    }
  }
  &.P8 {
    @media only screen and (min-width: $sm-width) {
      padding-right: 1.25rem;
      padding-left: 0;
    }
    .product_details {
      @media only screen and (max-width: $sm-width) {
        margin-top: 4px;
      }
    }
    .product_name {
      @media only screen and (min-width: $md-width) {
        font-size: 20px;
      }
    }
    .all_sizes {
      .size-box-new {
        .size-link.out_of_stock {
          .size_inner_space_new {
            background-color: #f8f8f8;
          }
        }
      }
    }
    .pricing {
      @media only screen and (min-width: $md-width) {
        font-size: 1rem;
      }
    }
  }
  &.HB {
    padding: 2.8125rem 1.75rem 0 0;
    @media only screen and (max-width: $sm-width) {
      padding: 0;
    }
    .product-detail__add-to-cart__notification {
      background-color: #f8f8f8;
    }
    .product_name {
      @media only screen and (max-width: $sm-width) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: $sm-width) {
    padding: 0;
    .all_images {
      // padding-right: 0;
      max-width: 100%;
      flex: 0 0 100%;
    }
    .image_col {
      max-width: 50%;
      flex: 0 0 50%;
      padding-left: 0;
      &.image-0 {
        padding-right: 5px;
      }
      &.image-1 {
        padding-left: 5px;
        padding-right: 0;
      }
    }
    .mob-hide {
      display: none;
    }
  }
}
