.product-detail__stock-check__pane {
  .product-detail__stock-check {
    &__container {
      padding: 0 !important;
      max-width: 23.438rem !important;
      scrollbar-width: none;
      @media only screen and (min-width: $md-width) {
        max-width: 32.5rem !important;
      }
      .form-icon {
        display: none;
      }
    }
    &__heading {
      top: 0;
      z-index: 2;
      margin-bottom: 0;
      position: sticky;
      padding-top: 1rem;
      padding-left: 1rem;
      padding-bottom: 1rem;
      background-color: white;
      @extend .rd-store-heading;
    }
    &__close {
      padding-top: 1rem;
      padding-right: 1rem;
      position: sticky;
      top: 0;
      z-index: 3;
      .icon-cross {
        @extend .rd-cross;
        &::before {
          content: none;
        }
      }
    }
    &__swatch-label {
      padding-left: 0;
      padding-bottom: 0.75rem;
      @extend .rd-store-name;
    }
    &__form {
      padding: 0 1rem;
      &__store {
        margin-top: 0;
      }
      &__attribute {
        &--fitType,
        &--color {
          display: none;
        }
        &-container {
          margin: 0;
        }
        &--size {
          margin-bottom: 1rem !important;
        }
      }
      &__fields {
        position: unset !important;
        min-width: 100%;
        &__background {
          display: none;
        }
      }
      &__product {
        padding-bottom: 0 !important;
      }
      &__attributes {
        flex: 0 0 100%;
        max-width: 100%;

        .fit-style {
          @media (min-width: $bp-desktop-min) {
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .store-search-field {
    .form-group {
      margin-bottom: 1rem;
    }
    input {
      height: 3rem;
      padding-left: 1rem;
      padding-top: 1.375rem;
      @extend .rd-store-form-text;
      border: 1px solid $light-stone;
      &:focus + label {
        top: 0.3rem;
        padding-left: 0.438rem;
      }
      &:placeholder-shown:not(:focus) + label {
        top: 0.875rem;
      }
    }
    .form-icon {
      right: 1rem;
      top: 0.313rem !important;
    }
    label {
      margin-bottom: 0;
      top: 0.3rem;
      background-color: transparent;
      font-size: 0.75rem !important;
      color: $mid-grey !important;
      font-style: normal !important;
      padding-left: 0.438rem !important;
      @extend .rd-store-placeholder-text;
    }
    input[type="text"]:not([disabled]) {
      &.is-invalid:not(select) {
        border: 1px solid $dark-red;
      }
      &.is-valid:not(select) {
        border: 1px solid $light-stone;
      }
      &.is-invalid:not(select) ~ .form-icon {
        right: 1rem;
        @extend .rd-store-invalid-icon;
        &::before {
          content: none;
        }
      }
      &.is-valid:not(select) ~ .form-icon {
        right: 1rem;
        @extend .rd-success-icon;
        &::before {
          content: none;
        }
      }
    }
    .invalid-feedback {
      &::before {
        content: none;
      }
      @extend .rd-store-invalid-text;
    }
    .rd-store-icon {
      border: none;
      font-size: 0.875rem;
      background-color: transparent;
      right: 1rem;
      left: unset;
      width: 1rem;
      height: 1rem;
      &:hover,
      &:focus {
        outline: unset;
        border: none;
        background-color: transparent;
      }
    }
    &:has(.is-valid, .is-invalid, .focus-visible) {
      .rd-store-icon {
        display: none;
      }
    }
  }
  .color-section {
    .tippy-popper {
      display: none;
    }
  }
  .btn-storelocator-search {
    @media (min-width: $bp-desktop-min) {
      height: 3rem;
    }
    margin-bottom: 1rem;
    font-weight: 600;
    letter-spacing: 0.175rem;
    line-height: 1.05rem;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-color: $green !important;
    }
  }
  .product-detail__stock-check__content {
    margin: unset;
    max-width: 100%;
  }
  .flyout__close-label {
    display: none !important;
  }
  .attribute_current {
    border: 1px solid $black;
  }
  .dropdown-swatch {
    img {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
    margin-right: 0.5rem;
  }
  .fit {
    &-variation {
      margin-bottom: 1rem;
    }
    &-style {
      border: 1px solid $gray15;
      width: -webkit-fill-available;
      padding: 0.625rem 0;
      max-width: 100%;
      text-align: center;
      margin-right: 0.5rem;
      cursor: pointer;
      &.selectedFitVariant {
        border: 1px solid $black;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .size-dropdown {
    .form-icon {
      @media (min-width: $md-width) {
        margin-top: 0.875rem;
      }
      right: 1rem;
      display: block;
    }
    .input-select {
      height: 2.5rem;
      @media (min-width: $md-width) {
        height: 3rem;
      }
      padding-left: 1rem;
      @extend .rd-store-form-text;
      border: 1px solid $light-stone;
    }
  }
  .countryField-holder {
    .form-group {
      margin-bottom: 1rem;
    }
    .form-icon {
      @media (min-width: $md-width) {
        margin-top: 0.875rem;
      }
      right: 1rem;
      display: block;
    }
    .input-select {
      height: 2.5rem;
      @media (min-width: $md-width) {
        height: 3rem;
      }
      padding: 0.313rem 1rem;
      @extend .rd-store-form-text;
      border: 1px solid $light-stone;
      &:focus {
        border: 1px solid $light-stone;
      }
    }
    label {
      display: none;
    }
  }
}

.product-detail__stock-check {
  &__link {
    max-width: max-content;
    &__text {
      @extend .rd-findinstore-cta;
    }
    .icon-chevron-right {
      margin-left: 0.3125rem;
      @extend .rd-decorative-right-arrow;
      &::before {
        content: none;
      }
    }
  }
}

.product-detail__stock-check__result {
  margin: 0 1rem 1rem 1rem;
  padding: 0;
  margin-top: 0;
  &:has(.product-detail__stock-check__result__row) {
    background-color: $grey11;
  }
  &__table {
    &:has(.product-detail__stock-check__result__row) {
      background-color: $grey11;
    }
    padding: 1rem 1rem 0;
  }
  &__row {
    padding: 0.75rem 0;
    justify-content: space-between;
    border-top: 1px solid $light-stone;
    &.active {
      border-bottom: none;
    }
    &.active .rd-down-arrow {
      transform: rotate(180deg);
    }
  }
  &__col {
    &--store-name {
      margin-bottom: 0.5rem;
    }
  }
  &__store-details {
    padding: 0.75rem 1rem;
    &-toggle:checked + &__store-details {
      border-bottom: 1px solid $light-stone;
    }
    &__distance {
      display: flex;
      margin-bottom: 0;
      justify-content: end;
      text-transform: uppercase;
      @extend .rd-store-name;
    }
    &__address-container {
      padding: 0 1.5rem 1.5rem;
    }
    &__get-directions {
      @extend .rd-store-result-direction;
      max-width: 16.563rem;
      width: 100%;
      height: 2.5rem;
      border: 1px solid $grey8;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      margin-bottom: 0;
    }
  }
  .rd-stock-heading {
    margin: 0.625rem 0 1.625rem 0;
  }
  .stock-result-heading-msg {
    text-align: left;
  }
  .stock-status-message {
    align-items: center;
    span {
      margin-left: 0.5rem;
      @extend .rd-store-text;
    }
    .icon-check {
      @extend .rd-instock-tick;
      &::before {
        content: none;
      }
    }
    .icon-cross {
      @extend .rd-cross;
      &::before {
        content: none;
      }
    }
  }

  .address-summary {
    line-height: 1.313rem !important;
    @extend .rd-store-text;
    &__line {
      margin-bottom: 0 !important;
    }
    margin-bottom: 2rem;
  }

  .store-work-times {
    max-width: 16.563rem;
    display: block !important;
    padding-bottom: 2rem;
    margin-bottom: 0;
    @extend .rd-store-text;
    &__row {
      margin-bottom: 0.75rem;
      &__col {
        padding: 0;
      }
    }
  }
}
