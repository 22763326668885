@import '../components/accountNav';
@import '../components/accountHeading';

.account-sections {
  .account-section {
    @include make-col-ready();
    @include make-col(4);

    padding: 0;
    text-align: center;
    margin-bottom: map-get($spacers, 4);

    &:nth-child(3n + 1) {
      padding-right: map-get($spacers, 3);
    }
    &:nth-child(3n + 2) {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }
    &:nth-child(3n + 3) {
      padding-left: map-get($spacers, 3);
    }

    &__link {
      border: 1px solid $border-color;
      padding: map-get($spacers, 4);
    }

    &__icon {
      font-size: rem-calc(40);
      color: $secondary;
      border-radius: rem-calc(70);
      border: solid rem-calc(2) $secondary;
      width: rem-calc(80);
      margin: map-get($spacers, 3) auto;
      padding: map-get($spacers, 3);
    }

    &__cta {
      width: 100%;
    }

    .content-asset {
      font-size: $fontsize-large;
    }
  }
}
