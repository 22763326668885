@include media-breakpoint-down(md) {
  .overflow-text {
    .overlay {
      position: fixed;
      z-index: 5;
      opacity: 0.4;
      background-color: $black;
      top: 0;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }

  .sizelist {
    max-height: 80%;
    overflow: scroll;
  }
}

@include media-breakpoint-down(sm) {
  .overflow-text {
    position: relative;
    overflow: hidden;
    z-index: 5;
    vertical-align: top;
    touch-action: none;
    -ms-touch-action: none;
  }
}

.select-dropdown {
  position: relative;

  .continuity-flyout__button {
    font-size: 0.75rem;
    letter-spacing: 0.06rem;
    font-weight: 700;
  }

  #notify-btn {
    margin-left: 0.5rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
  }

  .sizeguidelabel {
    padding: 0.75rem 0 0.563rem 0.875rem;
    font-size: 1.125rem;
    .close-icon {
      position: absolute;
      right: 0.875rem;
      @extend .rd-close-icon;
    }
  }

  .lowstock-value {
    letter-spacing: 0.125rem;
    font-size: 0.625rem;
    font-weight: 600;
    line-height: normal;
  }

  li.Selected {
    background-color: #f8f8f8;
    letter-spacing: 2px;
    font-size: 0.625rem;
    text-transform: uppercase;

    span:nth-child(2) {
      font-size: 0.625rem;
      font-weight: 600;
      letter-spacing: 0.125rem;
    }
  }

  li.out-of-stock {
    pointer-events: none;

    .text-decoration-underline {
      pointer-events: all;
    }
  }

  li {
    span {
      line-height: 1.313rem;
      letter-spacing: 0.42px;
      font-size: 0.75rem;
    }

    .disabled-item {
      text-decoration-line: line-through;
      opacity: 0.5;
    }

    .nostockmsg {
      color: #4f4f4f;
      font-size: 0.625rem;
      font-weight: 400;
      letter-spacing: 0.125rem;
      line-height: 0.75rem;
    }

    .low-stock {
      color: #817d78;
      letter-spacing: 0.125rem;
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 0.75rem;
    }
  }

  .select-dropdown__button.active {
    &::after {
      transform: translate(0, -50%) rotate(180deg);
    }
  }

  &__button {
    width: 100%;
    cursor: pointer;
    border: 1px solid $gray15;
    background-color: $white;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 500;
    color: $black;
    padding: 0 1rem;
    height: 3rem;
    display: flex;
    gap: 0.625rem;
    align-items: center;
    @include media-breakpoint-down(md) {
      height: 2.5rem;
    }

    span {
      letter-spacing: 0.15rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: normal;
    }

    &:focus {
      outline: none;
    }

    &::after {
      content: '';
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
      @extend .rd-down-arrow;
    }
  }

  .size-guide-l {
    list-style: none;
    padding: 0.25rem 0 0.563rem 1rem;
    cursor: pointer;
  }

  .text-decoration-underline {
    text-decoration: underline;
  }

  &__list {
    position: absolute;
    display: block;
    top: 38px;
    left: 0;
    right: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    border: 1px solid $gray15;
    background: $white;
    border-top: 0;
    transform-origin: top left;
    transform: scale(1, 0);
    transition: all ease-in-out 0.3s;
    z-index: 2;

    @include media-breakpoint-down(md) {
      position: fixed;
      top: 40%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 16;
      transform-origin: bottom left;
      max-height: 80%;
      padding-bottom: 10rem;
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1, 1);
    }
  }
  &__list-item {
    display: block;
    padding: 0.625rem 1rem;
    text-transform: uppercase;
    height: 2.5rem;
    background-color: $white;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
  }
}
