.search-results-container {
  &--paging-top {
    .paging-container {
      &--top {
        top: rem-calc(25);
        right: rem-calc(20);
      }
    }
    @include media-breakpoint-down(md) {
      .paging-container {
        &--top {
          top: rem-calc(40);
        }
      }
      .search-results__controls {
        .side-col {
          margin-bottom: rem-calc(20);
        }
      }
    }

    .grid-footer {
      .paged-bottom {
        p.paging__page {
          line-height: 2.5rem !important;
          letter-spacing: unset !important;
          font-weight: lighter !important;
        }
      }
    }
  }
}

.product-grid {
  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    [data-grid-size="4"] & {
      padding-left: $tiles-extended-offset-from-filter;
    }

    .filters-container:not(.show) + &-container & {
      padding-left: 0;
    }
  }

  .product-tile {
    &__add-overlay-header {
      @include media-breakpoint-down(md) {
        padding: map-get($spacers, 2);
      }
    }

    &__add-overlay-sizes {
      max-width: 100%;
      flex-direction: row;

      &-title {
        text-align: center;
        font-weight: normal;
        text-transform: uppercase;
        font-size: $fontsize-small;
        letter-spacing: rem-calc(3);

        @include media-breakpoint-up(md) {
          font-size: $fontsize-medium;
          letter-spacing: rem-calc(5.6);
          text-align: left;
        }
      }

      @include media-breakpoint-up(xl) {
        @include make-col(12);
        @include make-col-offset(0);

        flex: 0 0 100%;
      }
    }

    &__add-overlay-size {
      border-radius: 0;
      font-weight: normal;

      &-wrapper {
        flex-basis: percentage(1 / 7);

        @include media-breakpoint-up(md) {
          max-width: percentage(1 / 7);
        }
      }

      &-input {
        font-weight: normal;
      }

      &-btn {
        .continuity-flyout {
          @include media-breakpoint-down(md) {
            height: auto;

            &__button {
              white-space: normal;
              height: auto;
              font-size: $fontsize-small;
              letter-spacing: rem-calc(3);
              line-height: rem-calc(12);
            }
          }
        }
      }
    }

    &__body {
      text-align: center;
    }

    &__brand {
      display: none;
    }

    &__sticker {
      &--image {
        top: 0;
        bottom: auto;
        left: rem-calc(20);
        transform: rotate(90deg);
        transform-origin: left top;
      }

      &--feature {
        font-size: rem-calc(12);
        text-transform: uppercase;
        letter-spacing: rem-calc(4.8);
        margin: map-get($spacers, 1) 0;

        &--empty {
          display: block;
          height: rem-calc(16);
        }
      }

      &__content {
        &--image {
          display: inline-block;
          padding: 0 rem-calc(40) 0 0;
          transform: rotateX(180deg) rotateY(180deg);
          text-transform: uppercase;
          letter-spacing: rem-calc(4.8);

          &::after {
            content: '';
            display: inline-block;
            width: rem-calc(35);
            height: rem-calc(1);
            position: absolute;
            bottom: rem-calc(5);
            right: rem-calc(10);
            background-color: $primary;
          }
        }
      }
    }

    &__name,
    &__outofstock--sticker {
      font-size: rem-calc($fontsize-large);
      text-transform: uppercase;
      letter-spacing: rem-calc(5.6);
      font-weight: normal;
    }
    &__name {
      line-height: 1.2;
      padding-top: map-get($spacers, 1);
      padding-bottom: map-get($spacers, 1);
    }
    &__outofstock--sticker {
      opacity: 0.6;
      background-color: $gray14;
    }

    &__price {
      @extend .product-detail__prices;

      @include make-col-ready();
      @include make-col(12);

      padding-right: 0;
      padding-top: map-get($spacers, 1);
      padding-bottom: map-get($spacers, 1);

      span {
        &.has-strike-through {
          display: inline-block;

          span.value {
            font-size: rem-calc($fontsize-large);
            font-weight: normal;
            letter-spacing: rem-calc(4.6);
          }
        }

        &.strike-through {
          span.value {
            font-size: rem-calc(12);
            letter-spacing: rem-calc(3.6);
          }
        }

        &.range {
          span {
            font-size: rem-calc($fontsize-large);
          }
        }
      }
    }

    &__swatches-pdp-link {
      display: inline-block;
      width: $tile-swatch-size;
      height: $tile-swatch-size;
      line-height: ($tile-swatch-size + (2 * $tile-selected-swatch-padding)) / 2;
      border: rem-calc(1) solid $primary;
      padding: $tile-selected-swatch-padding;
      text-decoration: none;
      font-weight: normal;
    }

    .wishlist-icon,
    .add-to-wish-list,
    &__wishlist--link {
      padding-top: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
      font-size: rem-calc(25);
      color: $primary;

      @include media-breakpoint-up(md) {
        padding-top: map-get($spacers, 2);
        padding-right: map-get($spacers, 2);
      }
    }

    &__promotions {
      .callout {
        color: $red;
        letter-spacing: rem-calc(1.1);
      }
    }

    &__rating {
      display: none;
    }

    &__action {
      font-weight: normal;
      color: $primary;
      text-align: left;
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);

      &__arrow {
        display: block;
        position: absolute;
        right: rem-calc(10);
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        background: $tile-action-bg;
        color: $primary;
      }
    }

    .product-tile__outofstock--sticker {
      height: 100%;
      background-color: $white;
      opacity: 0.8;
    }
  }

  .plp-sale-category-link {
    a {
      letter-spacing: 0.32em;
    }
  }
}

@media (max-width: $bp-medium) {
  .product-grid {
    .product-tiles {
      .product-tile__wrapper {
        .product-tile {
          &__name {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
