$image-gallery-backdrop-bg: #eae9e7;
$image-gallery-bg: rgba($white, 0.5);
$image-gallery-zoom-btn-size: rem-calc(44);

.lg-actions,
.lg-toggle-thumb,
#lg-counter,
#lg-download,
#lg-actual-size {
  display: none;
}

.lg-backdrop {
  background-color: $image-gallery-backdrop-bg;
}

.lg-toolbar {
  background-color: transparent;
}

.lg-outer .lg-img-wrap {
  padding-left: 0;
  padding-right: 0;
}

.lg-outer .lg-thumb-outer {
  height: auto;
  background-color: $image-gallery-bg;
}

.lg-outer .lg-image {
  position: relative;
  max-height: none;
  width: 100vw !important; // Overrides important 100% in the library's style
  cursor: default;
}

.lg-thumb.lg-group {
  margin: 0 auto;

  .lg-thumb-item {
    border-radius: 0;
    border: rem-calc(1) solid $border-color;

    &.active {
      border-color: $primary;
    }

    .pdp-video-thumb-playButton {
      position: relative;
      bottom: calc(50% + 12px);
      height: 24px;
      width: 24px;
      left: calc(50% - 12px);
    }
  }

  .lg-toolbar-custom {
    display: flex;
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  #lg-zoom-out,
  #lg-zoom-in {
    width: $image-gallery-zoom-btn-size;
    height: $image-gallery-zoom-btn-size;
    line-height: $image-gallery-zoom-btn-size;

    &::after {
      display: none;
    }
  }
}

.lg-outer {
  .lg-has-vimeo {
    max-height: 2000px !important;
    width: 100% !important;

    .lg-video-poster {
      height: auto !important;
      width: 100% !important;
      max-width: 100%;
      max-height: unset;
      display: none;
    }
  }
}

.lg-sub-html {
  display: none;
}

.lg-content {
  top: 0 !important;
  bottom: unset !important;
}
