.rd-breadcrumb.breadcrumb-bottom {
  .breadcrumb-container {
    max-width: 100vw;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none;

    .breadcrumb {
      display: flex;
      font-size: 0.875rem;
      flex-wrap: nowrap;
      padding-left: 0;
      padding-right: 1rem;

      &-item {
        flex: 0 0 auto;

        &::before {
          padding: 0 0.75rem;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }

        a {
          @extend .rd-breadcrumb-atom-bottom;
          text-decoration: none;
        }
      }
    }
  }
}

.rd-breadcrumb.breadcrumb-top {
  .product-detail__nav-row {
    padding-left: 2rem;

    @media (min-width: $bp-large) and (max-width: $bp-med-max) {
      padding-left: 2.6rem;
    }

    @media (min-width: $bp-desktop-min) {
      padding-left: 0;
    }

    .breadcrumb-container {
      padding-left: 0;

      .breadcrumb {
        padding: 0;
        justify-content: flex-start;

        &-item {
          &::before {
            content: "|";
            padding: 0 0.75rem;
          }
          &:first-child {
            &::before {
              display: none;
            }
          }
          &:last-child {
            display: none;
          }
          a {
            @extend .rd-breadcrumb-atom-top;
            text-decoration: none;
          }
        }
      }
    }
  }
}
