$media-mobile-s: 320px;
$media-mobile-m: 375px;
$media-mobile-l: 425px;

.newsletter-subscription {
  .address-fields {
    margin-left: 0;
    margin-right: 0;
    margin-top: map-get($spacers, 4);

    &__row {
      @include make-col(12);

      padding-left: 0;
      padding-right: 0;
    }

    .address-lookup {
      margin-top: 0;
    }
  }

  .newsletter-form {
    &__heading {
      @include border-after();

      font-size: rem-calc(36);
      margin-bottom: map-get($spacers, 3);

      @include media-breakpoint-down(md) {
        font-size: rem-calc($fontsize-xxlarge);
      }
    }

    .form-group {
      &.newsletter-form__optin__field--sms {
        height: rem-calc(60);
      }
    }

    label {
      font-size: rem-calc($fontsize-medium);
    }

    .phone-fields {
      margin-top: 0;
    }
  }
  .country-dial-code {
    border: 1px solid $primary;
    @media (min-width: $media-mobile-s) {
      width: 45%;
    }
    @media (min-width: $media-mobile-m) {
      width: 40%;
    }
    @media (min-width: $media-mobile-l) {
      width: 35%;
    }
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
    @include media-breakpoint-up(xl) {
      width: 33%;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
  }

  .intl-tel-input {
    .selected-flag {
      .iti-flag {
        bottom: 0.5rem;
      }
    }
  }

  .intl-tel-input.separate-dial-code {
    .selected-dial-code {
      top: auto;
      bottom: 0.2rem;
    }
  }

  .down-arrow-newsletter {
    left: 7rem;
    bottom: 0.4rem;
  }

  .mobile-number {
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
    @include media-breakpoint-up(sm) {
      width: 67% !important;
    }
    @include media-breakpoint-up(lg) {
      width: 60% !important;
    }
    @include media-breakpoint-up(xl) {
      width: 67% !important;
    }
  }

  .country-dial-code-newsletter {
    margin: 1.5rem 0;
    border: 1px solid $primary;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }
}
