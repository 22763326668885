button,
.btn {
  &:disabled,
  &.disabled {
    background-color: $grey3;
    color: $grey5;
    border-color: $grey5;
    pointer-events: none;
    border: none;
  }
}

.btn-outline-primary {
  @include button-variant($primary, $primary);
  background-color: $button-primary-bg;
  border-color: $button-primary-border !important;
  color: $button-primary-color;
}
