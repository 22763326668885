@import '../components/productGrid';
@import '../components/paging';
@import '../components/searchControls';
@import '../components/searchRefinements';
@import '../components/quickView';

$media-breakpoint-tablet-wide: 1024px + 1;

.side-col {
  &--right {
    @include media-breakpoint-up(md) {
      padding-right: $tile-desktop-padding - rem-calc($grid-gutter-width / 2);
    }
  }
}

.search-header {
  @media only screen and (min-width: $bp-large) {
    &.horizontal-filter-enabled {
      margin-bottom: 0;
    }
  }

  &__phrase {
    margin-bottom: 0.75rem;
  }

  &__breadcrumbs {

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
    }

    &__container {
      @include make-container-max-widths($pdp-widths, $grid-breakpoints);
    }
    .breadcrumb {
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-up(md) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }

  &__category {
    text-align: center;
    padding-top: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  &__category-name {
    font-size: rem-calc(24);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(36);
    }
  }

  &__category-description {
    display: block;
    max-width: none;
    font-weight: normal;
  }

  &__no-results {
    text-align: left;
    background-color: $page-bg;

    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 3);
      padding-right: map-get($spacers, 3);
    }

    &__heading {
      @include h1;

      font-weight: normal;
      letter-spacing: rem-calc(10.6);

      &__search-keywords {
        font-weight: bold;
      }
    }

    .site-search {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.search-content {
  .breadcrumb-container {
    padding-left: 0;
  }

  .search-header__phrase {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.35rem;
    text-transform: uppercase;
    margin-top: 2rem !important;
    margin-bottom: 0 !important;

    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
      padding-left: 2.3rem;
    }
  }

  .container {
    @include make-container-max-widths($pdp-widths, $grid-breakpoints);
  }

  .search-results__controls {
    padding-bottom: 0;
  }
}

.product-recommendations {
  @include productRecommendationStyles();

  &__wrapper {
    display: block;
  }
}

.product-tiles {
  .link {
    .discount {
      margin: auto;
      span {
        font-size: 1rem;
      }
    }
  }
}
.horizontal-filter-container {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  #filters-container {
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: $media-breakpoint-tablet-wide) {
    display: flex;
  }
  .filters {
    &__items {
      flex-wrap: wrap;
      align-items: center;
      .filters__section__btn {
        border: none;
      }
    }

    &__body {
      overflow: unset;
    }

    .filter__section {
      .filters__section__btn {
        border: none;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      &__filter-header {
        padding: 0;
        position: relative;
        margin-right: 24px;
        line-height: normal !important;
        height: 1rem;
        font-size: 0.75rem;
        &__text {
          @include horizontal-filter-header-text();
        }
      }
      &__section__btn {
        border: none;
        &-icon {
          top: 0;
          right: -19px;
          margin-top: 0;
        }
      }
      .close-horizontal-dropdown {
        z-index: 1;
        right: 1.5rem;
        position: absolute;
        cursor: pointer;
        display: block !important;
        top: 1rem;
      }
      .apply-button-container {
        display: flex !important;
        justify-content: right;
        .apply-button {
          height: 3rem;
          @media only screen and (max-width: $media-breakpoint-tablet) {
            height: 2.75rem;
          }
          display: flex;
          color: $grey8;
          cursor: pointer;
          width: 11.75rem;
          font-weight: 600;
          align-items: center;
          font-size: 0.875rem;
          line-height: 1.05rem;
          margin-right: 1.75rem;
          justify-content: center;
          letter-spacing: 0.175rem;
          text-transform: uppercase;
          background-color: $light-stone;
        }
        &.active span {
          color: $white;
          background-color: $green;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .filters__section {
        &.active {
          .filters__filter-header__text {
            font-weight: 600;
          }
        }
        .filters__filter-header__text {
          @include horizontal-filter-header-text();
        }
      }
      &__items {
        display: flex;
        gap: 0.625rem;
      }
    }
    .apply-button-container,
    .close-horizontal-dropdown {
      display: none;
    }

    &__filter {
      @media only screen and (min-width: $bp-large) {
        margin-right: 9px;
        border: none;
      }
      @include media-breakpoint-down(md) {
        .filters__section__btn-icon {
          right: 0;
          top: 20px;
          z-index: 1;
        }
      }
      &-body {
        @media only screen and (min-width: $bp-large) {
          position: absolute;
          top: 2.125rem;
          left: 0;
          right: 0;
          padding-bottom: 1.5rem;
          background-color: white;
          z-index: 2;
          &.collapse,
          &.collapse.show,
          &.collapsing {
            transition: none !important;
          }
          &.keep-open {
            display: block;
          }
          ul {
            display: grid;
            grid-auto-flow: column dense;
            grid-auto-columns: max-content;
            grid-template-columns: none;
            grid-template-rows: repeat(3, auto);
            width: 100% !important;
            padding: 1rem 1rem 0 0;
            overflow: scroll;
            scrollbar-width: none;
          }
        }
      }
      @media only screen and (min-width: $bp-large) {
        &--applied-filters {
          display: none;
        }
        &--size,
        &--shoesize {
          .filters__value-text {
            padding: 0;
            border: none;
            margin-top: 2px;
          }
        }
      }
    }
    &__value {
      @media only screen and (min-width: $bp-large) {
        margin-bottom: 0.5rem;
        padding-right: 4rem;
        &-text--not-one-size {
          max-width: 100%;
          width: unset;
        }
        &-text {
          @include horizontal-filter-text();
          &.selected {
            font-weight: 600;
            background-color: white;
            color: $light-black !important;
          }
        }
      }
    }
  }
  .show-more {
    display: block;
  }
  @media only screen and (min-width: $bp-large) {
    .show-less {
      display: none;
    }
  }
  .show-container {
    @media only screen and (max-width: $sm-width) {
      display: none;
    }
  }
}

.horizontal-container {

  @include media-breakpoint-up(xl) {
    .search-results__count-row,
    .product-grid {
      [data-grid-size="4"] & {
        padding-left: 0 !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .product-grid-container {
      margin-top: 0 !important;
    }
    .product-tile__wrapper {
      padding-top: 0 !important;
    }
    .applied-refinements-container.desktop:has(ul) + .search-results__count-row__image-type-toggle {
      padding-right: 1rem;
    }
    .paging-container--top {
      display: none;
    }
    .image-type-toggle-wrapper {
      flex-grow: 1;
      align-items: center;
      max-width: 100%;
      padding: 1rem 0 1.25rem 0;
      .search-results__count-row__image-type-toggle {
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: end;
        background-color: white;
        padding-right: 0.313rem;
        max-width: 100%;
        .toggle-switch {
          font-size: 0.75rem;
          color: $mid-grey;
          align-items: center;
          margin-right: 0 !important;
          text-transform: uppercase;
          max-width: fit-content;
          .switch-container {
            width: 1.5rem;
            label {
              span {
                top: 0.188rem;
                width: 1.5rem;
                height: 0.75rem;
                background-color: $light-stone;
              }
              a {
                top: 0;
                width: 0.75rem;
                height: 0.75rem;
                background-color: $mid-grey;
              }
            }
          }
          span {
            line-height: normal;
            letter-spacing: 0.15rem;
          }
        }
      }
    }
  }

  @media (max-width: $sm-width) {
    .image-type-toggle-wrapper .search-results__count-row__image-type-toggle {
      display: none;
    }
  }

  .search-results__controls {
    .horizontal-filter {
      padding: 0;
      @media only screen and (max-width: $sm-width) {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
      }
      @media only screen and (min-width: $bp-large) {
        padding-top: 1.25rem;
        display: flex;
        max-width: 70%;
        width: 100%;
      }
      .sort-toggle.side-col {
        width: 100%;
        max-width: 100%;
      }
      @include media-breakpoint-up(lg) {
        z-index: 10;
        background-color: $white;
        #filters-container {
          transition: none;
          margin-right: 0;
        }
        .sorting {
          &__toggle-section {
            max-width: 6.875rem;
            &__btn {
              padding: 0 !important;
            }
          }
        }
      }
    }

    @media only screen and (min-width: $bp-large) {
      padding: 0 0.375rem;
      position: sticky;
      top: 115px;
      background-color: white;
      z-index: 5;
      .applied-filter-text {
        margin-right: 1.5rem;
        @include horizontal-filter-header-text();
        font-weight: 600;
      }
    }

    .applied-refinements-container {
      margin-bottom: 0;
      align-items: center;
      &:has(ul) {
        padding-left: 0.625rem;
      }
      &.desktop {
        @media only screen and (min-width: $bp-large) {
          display: flex !important;
        }
        .clear-all {
          margin-top: unset;
          position: initial;
          padding-top: 0;
          transform: unset;
        }
      }
      ul {
        margin-bottom: 0;
      }
      .span {
        @include horizontal-filter-header-text();
        margin-right: 16px;
      }
      .filters__reset-link {
        text-decoration: underline;
        transform: unset;
        @include horizontal-filter-header-text();
        font-weight: 700;
      }

      .values.content {
        @media (min-width: $bp-large) {
          display: flex;
        }
      }
    }
    .filters {
      &__filter {
        &__applied {
          @media only screen and (min-width: $bp-large) {
            margin-bottom: 0;
            padding: 10px 1rem !important;
            height: 40px;
            margin-right: 8px;
            border: 1px solid $dark-background;
            display: flex !important;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            @include horizontal-filter-header-text();
            &__text {
              @include horizontal-filter-header-text();
            }
            &:last-child {
              margin-right: 1.5rem;
            }
          }

          &__remove {
            @media (min-width: $bp-large) {
              margin-left: 0;
              position: relative;
              top: 1px;
            }
          }
        }
        &.filters__section {
          @media only screen and (min-width: $bp-large) {
            &.active {
              .show-icon {
                display: none;
              }
              .hide-icon {
                display: block;
              }
            }
            &:not(.active) {
              .hide-icon {
                display: none;
              }
              .show-icon {
                display: block;
              }
            }
            .show-icon::before,
            .hide-icon::before {
              font-size: 1rem;
            }
          }
        }
      }
      &__value-link {
        &::before {
          width: 1.5rem;
          height: 1.5rem;
          border: 1px solid $light-stone;
          margin-right: 0.5rem;
        }
        &.selected::before {
          padding-top: 2px;
          padding-left: 4px;
          border: 1px solid $light-black;
          color: $light-black;
          content: '\EDFB';
        }
        @media only screen and (min-width: $bp-large) {
          &--size {
            display: flex;
            &.selected::before,
            &::before {
              display: block;
            }
            &.text.selected {
              background-color: none;
              color: $light-black;
            }
          }
        }
      }
    }
    .show-more-container {
      display: none;
      @media only screen and (min-width: $bp-large) {
        display: block;
      }
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
      text-transform: uppercase;
    }
    .right-wrapper {
      display: none;
      @media only screen and (min-width: $bp-large) {
        padding-top: 1.25rem;
        max-width: 30%;
        padding-left: 0;
        flex: 0 0 auto;
        justify-content: end;
      }

      .page-size-toggle {
        margin-top: 0;
      }
      @media only screen and (min-width: $bp-large) {
        display: flex;
        .sort-toggle {
          max-width: 155px;
          padding-right: 0;
        }
      }
      .results-count {
        &__separator,
        &__page-sizes,
        &--desktop {
          display: none;
        }
        &--mobile {
          display: block;
          padding-right: 0.875rem;
        }
        @media only screen and (min-width: $bp-large) {
          @include horizontal-filter-header-text();
        }
      }
      .sorting {
        margin-top: 0;
        @media only screen and (min-width: $bp-large) {
          &__section__btn {
            height: auto;
          }
          &__toggle-section__btn {
            font-size: 0.75rem !important;
            padding: 0 !important;
            padding-left: 0.5rem !important;
            @include horizontal-filter-header-text();
            &-wrapper {
              border: none;
              max-width: 155px;
              select {
                padding-bottom: 0;
              }
              .form-icon {
                right: 0;
                top: 60%;
              }
            }
          }
        }
      }
    }
  }
}

.product-tile__image {
  .image-container {
    .primary-images__controls {
      &.carousel-indicators {
        bottom: 2.875rem;
        li {
          width: 0.25rem;
          height: 0.25rem;
          margin-left: 0.125rem;
          margin-right: 0.125rem;
          background-color: $grey11;

          &.active {
            background-color: $gray15;
          }
        }

        @media (min-width: $bp-tablet-med) {
          display: none;
        }
      }
    }
  }
}
