@mixin checkout-option() {
  @include form-radio-label;

  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    left: 5%;
    top: calc(50% - #{$forms-radio-indicator-width / 2});
    margin: 0;
  }
}

@mixin checkout-selected-option() {
  @include form-checked-radio-label;

  border: rem-calc(1) solid $secondary;

  &::before {
    top: calc(50% - #{$forms-radio-indicator-selected-width / 2});
  }

  &::after {
    background-color: $page-bg;
  }
}
