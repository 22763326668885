$paging-status-bar-height: 4px !default;
$paging-status-bar-bg: $grey3 !default;
$paging-status-bar-progress-bg: $grey5 !default;
$paging-more-btn-width: 360px !default;
$paging-container-max-width: rem-calc(416);
$paging-page-size: rem-calc(30px);
$paging-page-size-md: rem-calc(40px);
$paging-page-hover-bg: $grey2;
$paging-page-current-bg: $grey1;
$paging-page-current-color: $white !default;

.paging {
  width: 100%;
  font-size: rem-calc(12);

  @include media-breakpoint-up(md) {
    font-size: $fontsize-medium;
  }

  &-status {
    display: inline-block;
  }

  &__status-bar {
    position: relative;
    height: $paging-status-bar-height;
    background-color: $paging-status-bar-bg;
    margin-bottom: map-get($spacers, 4);

    &--progress {
      position: absolute;
      left: 0;
      top: 0;
      height: $paging-status-bar-height;
      background-color: $paging-status-bar-progress-bg;
    }
  }

  &__next-btn {
    @include btn-primary();

    width: 100%;
    max-width: $paging-more-btn-width;

    &:focus {
      box-shadow: none;
    }
  }

  &__page {
    display: inline-block;
    width: $paging-page-size;
    height: $paging-page-size;
    line-height: $paging-page-size;
    border: solid rem-calc(1) $border-color;

    @include media-breakpoint-up(md) {
      width: $paging-page-size-md;
      height: $paging-page-size-md;
      line-height: $paging-page-size-md;
    }

    &:not(&--current) {
      &:hover,
      &:active {
        background-color: $paging-page-hover-bg;
      }
    }

    &--of-last,
    &--of {
      display: none;
    }

    &--back,
    &--next {
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);

      @include media-breakpoint-up(md) {
        padding-left: map-get($spacers, 2);
        padding-right: map-get($spacers, 2);
      }

      .font-icon {
        display: none;

        @include media-breakpoint-up(md) {
          display: inline-block;
          position: relative;
          top: rem-calc(-2);
        }
      }
    }

    &--current {
      color: $paging-page-current-color;
      background-color: $paging-page-current-bg;
    }

    &--button {
      width: auto;
    }
  }

  &__page-icon {
    vertical-align: middle;
    font-size: rem-calc($fontsize-medium);
  }
  &__ellipsis {
    .of {
      display: none;
    }
  }
  .pagination-page {
    display: none;
  }
}
