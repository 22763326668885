.error-search-container {
  display: inline-block;
}

.error-search {
  width: 100%;

  .site-search {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }

    margin: auto;
  }
}

.error-page-header {
  @include border-after();
}

.error-image {
  order: 1;
}
